import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { assessmentIds } from "../../utils/constant";
import IntroExtroResult from "../assessment/IntroExtroResult";
import VasanaTypeResult from "../assessment/VasanaTypeResult";
import VSNBreadcrumbs from "../../shared/VSNBreadcrumbs";
import { getAssessmentState } from "../../shared/CustomFunc";
import OnePagerResult from "../assessment/OnePagerResult";
import ProQOLResult from "../assessment/ProQOLResult";
import XRayDashboardResult from "./physicalAssessment/XRayDashboardResult";
import MRFDashboardResult from "./physicalAssessment/MRFDashboardResult";
import PhysicalExaminationResult from "./physicalAssessment/PhysicalExaminationResult";
import PhysicalTwoPagerResult from "./physicalAssessment/PhysicalTwoPagerResult";
import VitalsResult from "./physicalAssessment/VitalsResult";
import UltraSoundFireFightersResult from "./physicalAssessment/UltraSoundFireFightersResult";
import UltraSoundPoliceResult from "./physicalAssessment/UltraSoundPoliceResult";
import CPETDashboardResult from "./physicalAssessment/CPETDashboard/CPETDashboardResult";

const QuickView = () => {
  const { assessmentId } = useParams();
  const userData = useSelector((state) => state?.app?.userData);
  const introExtroAssessment = useSelector(
    (state) => state?.api?.assessmentResult?.[assessmentIds?.introExtro]
  );
  const loaders = useSelector((state) => state?.api?.loader);
  const currentAssessment = getAssessmentState({
    assessments: userData?.assessments,
    id: assessmentId,
  });
  const breadcrumbsRoutes = [
    {
      path: "/assessments",
      label: "Assessments",
    },
    {
      path: `/assessments/${assessmentId}`,
      label: `${currentAssessment?.result} Dashboard`,
    },
  ];

  const getAssessmentResultComponent = (assessmentId) => {
    switch (assessmentId) {
      case "2":
        return <IntroExtroResult />;
      case "3":
      case "4":
      case "5":
      case "7":
      case "8":
      case "9":
        return <OnePagerResult />;
      // return <UltraSoundPoliceResult />;
      // return <VitalsResult />;
      case "16":
        return <XRayDashboardResult />;
      case "17":
        return <MRFDashboardResult />;
      case "18":
        return <PhysicalExaminationResult />;
      // case "19":
      //   return <CPETDashboardResult />;
      // return <OnePagerResult />; `
      // return <UltraSoundFireFightersResult />;
      // return <UltraSoundPoliceResult />;
      case "6":
        return <ProQOLResult />;
      // return <PhysicalTwoPagerResult />;
      default:
        return <VasanaTypeResult />;
    }
  };
  return (
    <>
      {!(loaders?.assessmentResult || !introExtroAssessment) &&
      assessmentId?.toString() === assessmentIds?.introExtro ? (
        <Box pt={2} mb={-1} pl={{ sm: 4, xs: 2 }}>
          <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
        </Box>
      ) : (
        ""
      )}
      {getAssessmentResultComponent(assessmentId)}
    </>
  );
};

export default QuickView;
