import React from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Stack } from "@mui/material";
import { ERROR_MESSAGE } from "../../description/errorMessage.description";
import VSNImage from "../../shared/VSNImage";
import { ReactComponent as Round } from "../../assets/admin/svg/org-image.svg";
import VSNTypography from "../../shared/VSNTypography";
import { upperCase } from "../../utils/javascript";
import VSNButton from "../../shared/VSNButton";
import VSNLabel from "../../shared/VSNLabel";
import { toggleModelShow } from "../../shared/CustomFunc";
import shape1 from "../../assets/admin/png/model-shape-1.png";
import shape2 from "../../assets/admin/png/model-shape-2.png";

const formPath = { parent: "eventScheduleInfo" };
const useStyles = makeStyles((theme) => ({
  heading: {
    position: "sticky",
    top: 0,
    borderBottom: "1px solid var(--primary)",
    padding: "12px 25px",
    margin: "0 -25px",
    backgroundColor: "var(--white)",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 15px",
      margin: "0 -15px",
    },
    "&:after": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      height: 25,
      width: "100%",
      bottom: "100%",
      background: "var(--white)",
      zIndex: 8,
    },
    "& svg": {
      borderRadius: "50%",
      [theme.breakpoints.down("md")]: {
        width: 40,
        height: 40,
      },
      [theme.breakpoints.down("sm")]: {
        width: 30,
        height: 30,
      },
    },
    "& h5": {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "31px",
      marginLeft: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        lineHeight: "26px",
        maxWidth: "70%",
      },
    },
  },
  detailHeading: {
    "&.MuiTypography-root": {
      color: "rgba(134, 101, 10, 1)",
      fontSize: 14,
      fontWeight: 700,
      marginBottom: 24,
      [theme.breakpoints.down("md")]: {
        marginBottom: 20,
      },
    },
  },
  cancelButton: {
    "&.MuiButtonBase-root": {
      padding: [[12, 62]],
      [theme.breakpoints.down("md")]: {
        padding: [[8, 15]],
      },
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#F6F6FD",
    padding: "20px 25px",
    gap: 10,
    marginLeft: "-25px",
    marginRight: "-25px",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "15px",
      gap: 10,
      marginLeft: "-15px",
      marginRight: "-15px",
    },
    "& .MuiButtonBase-root": {
      margin: 0,
      padding: "9.5px 25px",
      lineHeight: "21px",
      width: "auto",
      minWidth: "170px",
      borderRadius: 10,
      "& span": {
        fontSize: 16,
        fontWeight: 700,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  shape: {
    position: "absolute",
    zIndex: -2,
  },
  shape1: {
    top: "20%",
    right: "10%",
  },
  shape2: {
    bottom: "5%",
    left: "0%",
  },
  formGrid: {
    "& .react-tel-input": {
      "& .form-control": {
        fontSize: 18,
        fontWeight: 700,
        color: "#202024",
      },
    },
    "& .MuiFormControl-root.MuiTextField-root": {
      width: "100%",
    },
  },
}));

const ViewEventSchedule = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const eventScheduleInfo = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const createEventScheduleAttribute = [
    {
      label: "Lab Draws",
      value: [
        {
          control: "date",
          label: "Start Date",
          name: "lab_draws_start_date",
          value: null,
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.start_date,
          fullWidth: true,
          md: 6,
          disabledPast: true,
          minDate: dayjs(),
        },
        {
          control: "date",
          label: "End Date",
          name: "lab_draws_end_date",
          value: "",
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.end_date,
          fullWidth: true,
          md: 6,
          minDate: dayjs(eventScheduleInfo?.lab_draws_start_date),
        },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "lab_draws_address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "lab_draws_address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "lab_draws_city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "lab_draws_zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
      ],
    },
    {
      label: "Diagnostic Testing",
      value: [
        {
          control: "date",
          label: "Start Date",
          name: "diagnostic_testing_start_date",
          value: null,
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.start_date,
          fullWidth: true,
          md: 6,
          disabledPast: true,
          minDate: dayjs(),
        },
        {
          control: "date",
          label: "End Date",
          name: "diagnostic_testing_end_date",
          value: "",
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.end_date,
          fullWidth: true,
          md: 6,
          minDate: dayjs(eventScheduleInfo?.diagnostic_testing_start_date),
        },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "diagnostic_testing_address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "diagnostic_testing_address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "diagnostic_testing_city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "diagnostic_testing_zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
      ],
    },
    {
      label: "Provider Exam",
      value: [
        {
          control: "date",
          label: "Start Date",
          name: "provider_exam_start_date",
          value: null,
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.start_date,
          fullWidth: true,
          md: 6,
          disabledPast: true,
          minDate: dayjs(),
        },
        {
          control: "date",
          label: "End Date",
          name: "provider_exam_end_date",
          value: "",
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.end_date,
          fullWidth: true,
          md: 6,
          minDate: dayjs(eventScheduleInfo?.provider_exam_start_date),
        },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "provider_exam_address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "provider_exam_address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "provider_exam_city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "provider_exam_zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
      ],
    },
  ];
  return (
    <Box>
      <>
        <VSNImage
          src={shape1}
          alt=""
          className={`${classes.shape} ${classes.shape1}`}
        />
        <VSNImage
          src={shape2}
          alt=""
          className={`${classes.shape} ${classes.shape2}`}
        />
        <Stack direction="row" alignItems="center" className={classes.heading}>
          <Stack>
            <Round />
          </Stack>
          <VSNTypography variant="h5" component="h5">
            Event Schedule
          </VSNTypography>
        </Stack>
      </>
      {createEventScheduleAttribute?.map(({ label, value }, index) => (
        <Box my={{ md: 3.8, xs: 2 }} key={index}>
          <VSNTypography variant="body1" className={classes.detailHeading}>
            {upperCase(label)}
          </VSNTypography>
          <Grid
            container
            rowSpacing={{ xs: 0, md: 2 }}
            columnSpacing={4}
            className={classes.formGrid}
          >
            {value?.map((props, index) => {
              const { isRequired, label, name, md, doNotShow } = props;
              return (
                !doNotShow &&
                eventScheduleInfo?.[name] && (
                  <Grid
                    item
                    xs={12}
                    xsm={6}
                    md={md}
                    key={index}
                    mb={{ xs: 2, md: 0 }}
                  >
                    <VSNLabel required={isRequired} label={label} />
                    <Box fontWeight={700} fontSize={18}>
                      {eventScheduleInfo?.[name]}
                    </Box>
                  </Grid>
                )
              );
            })}
          </Grid>
        </Box>
      ))}
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        className={classes.buttons}
      >
        <VSNButton
          handleClick={() => dispatch(toggleModelShow("modelName"))}
          variant="outlined"
          className={classes.cancelButton}
        >
          Cancel
        </VSNButton>
      </Stack>
    </Box>
  );
};

export default ViewEventSchedule;
