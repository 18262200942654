import { makeStyles } from "@mui/styles";

export const TestLayoutStyles = makeStyles((theme) => ({
  authWrapper: {
    marginTop: 40,
  },
  authDetail: {
    maxWidth: 615,
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      padding: [[0, 20, 56]],
    },
  },
  orDivider: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "23px",
    color: "#86650a",
    position: "relative",
    display: "block",
    textAlign: "center",
    marginBottom: 60,
    "&::after,&::before": {
      position: "absolute",
      content: '""',
      width: "39%",
      top: "50%",
      transform: "translateY(-50%)",
      height: 1,
      backgroundColor: "#fbcebd",
      [theme.breakpoints.down("sm")]: {
        width: "32%",
      },
    },
    "&::after": {
      left: 0,
    },
    "&::before": {
      right: 0,
    },
  },
  shape: {
    position: "absolute",
  },
  shapeOne: {
    bottom: 0,
    left: "20%",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      bottom: "0%",
      left: "10%",
      width: 138,
    },
  },
  shapeTwo: {
    left: "50%",
    top: "2%",
    zIndex: -1,
    transform: "translateY(-30%)",
    [theme.breakpoints.down("md")]: {
      left: "10%",
      width: 70,
    },
  },
  shapeThree: {
    top: "0%",
    right: 0,
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      top: "14%",
      width: 70,
    },
    [theme.breakpoints.down("sm")]: {
      top: "8%",
      height: 55,
      width: 55,
    },
  },
  shapeFour: {
    right: "-25px",
    bottom: "20%",
    zIndex: -1,
    width: 120,
    [theme.breakpoints.down("md")]: {
      right: "-22px",
      width: 70,
    },
  },
  shapeSix: {
    top: 0,
    left: 0,
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  redirectText: {
    textAlign: "center",
    color: "var(--blackGray)",
    fontSize: 16,
    "& a": {
      fontWeight: 700,
      color: "var(--primary)",
    },
  },
  mainWrapper: {
    position: "relative",
    overflowX: "hidden",
    minHeight: "calc(100svh - 105px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
