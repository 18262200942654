import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ON_FORM_CHANGE } from "../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    maxWidth: 1180,
    width: "100%",
    margin: "0 auto 48px",
    "& table": {
      "& th": {
        borderBottom: 0,
        padding: 8,
        fontSize: 22,
        fontWeight: 600,
        color: "#2D2F39",
        [theme.breakpoints.down("sm")]: {
          fontSize: 18,
        },
      },
      "& td": {
        borderBottom: 0,
        padding: 8,
        "& input": {
          height: 67,
          boxSizing: "border-box",
          boxShadow: "0px 9px 20px 0px #968FB01A",
        },
        "& fieldset": {
          borderColor: "#968FB01A",
        },
        "& .btnWrap": {
          gap: 16,
          "& button": {
            height: 67,
            minWidth: 160,
            maxWidth: 160,
            padding: "13px",
            backgroundColor: "var(--white)",
            border: "1px solid #EAEDF0",
            borderRadius: 5,
            color: "var(--gray)",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out",
            textAlign: "center",
            boxShadow: "0px 9px 20px 0px #968FB01A",
            "&:hover": {
              color: "var(--primary)",
              borderColor: "var(--primary)",
            },
            "&.active": {
              borderColor: "var(--primary)",
              color: "var(--primary)",
              boxShadow: "0px 9px 20px 0px #968FB01A",
            },
          },
        },
        "&:last-child": {
          width: 352,
        },
      },
    },
  },
  ImgWrapper: {
    position: "relative",
    padding: [[0, 45]],
    maxWidth: 710,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  small: {
    position: "absolute",
  },
  small2: {
    top: "-6%",
    left: "10%",
    zIndex: -1,
    [theme.breakpoints.down("1200")]: {
      left: "9%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  small6: {
    top: "-14%",
    right: "17%",
    zIndex: -1,
    [theme.breakpoints.down("1200")]: {
      right: "9%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  progressBtn: {
    "&.MuiButton-root": {
      background: "var(--white)",
      minHeight: 67,
      color: "var(--gray)",
      border: "1px solid #EAEDF0",
      borderRadius: 5,
      maxWidth: 169,
      width: "100%",
      height: "100%",
      padding: 12,
      display: "flex",
      alignItems: "center",
      transition: "all 0.3s ease-in-out",
      [theme.breakpoints.down("sm")]: {
        width: "48%",
      },
      "& .content": {
        fontSize: 16,
        fontWeight: 500,
      },
      "&:hover": {
        background: "var(--white)",
        border: `1px solid var(--primary)`,
        color: "var(--primary)",
      },
    },
  },
}));

const StaticQuestion = ({ question }) => {
  const classes = useStyles();
  const assessmentData = useSelector((state) => state.api?.assessmentData);
  const assessmentFormData = useSelector(
    (state) => state.form?.formData?.assessmentFormData
  );
  const value = assessmentFormData?.[question?.id]?.value;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!assessmentFormData?.[question?.id]) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            assessmentFormData: {
              ...assessmentFormData,
              [question?.id]: { ...question, value: {} },
            },
          },
        },
      });
    }
  }, []);

  const onChange = (value, name, index) => {
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          assessmentFormData: {
            ...assessmentFormData,
            [question?.id]: {
              ...question,
              value: {
                ...assessmentFormData?.[question?.id]?.value,
                [index]: {
                  ...assessmentFormData?.[question?.id]?.value?.[index],
                  [name]: value,
                },
              },
            },
          },
        },
      },
    });
  };
  return (
    <Box className={classes.tableWrapper}>
      <TableContainer>
        <Table aria-label="medication table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Medication Name</TableCell>
              <TableCell align="center">Reason Taken</TableCell>
              <TableCell align="center">Frequency</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(5)].map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    value={value?.[index]?.medication_name || ""}
                    onChange={(e) => {
                      onChange(e.target.value, "medication_name", index);
                    }}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter medication name"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter reason"
                    value={value?.[index]?.reason_taken || ""}
                    onChange={(e) => {
                      onChange(e.target.value, "reason_taken", index);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <Stack direction="row" className="btnWrap">
                    {question?.choices?.map(({ phrase, id }) => (
                      <Button
                        className={
                          value?.[index]?.frequency === id ? "active" : ""
                        }
                        variant="outlined"
                        color="secondary"
                        onClick={() => onChange(id, "frequency", index)}
                      >
                        {phrase}
                      </Button>
                    ))}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StaticQuestion;
