import { makeStyles } from "@mui/styles";
import { ceil, transform } from "lodash";
import homeBanner from "../../assets/landing/png/homeBanner.webp";
import leaf from "../../assets/landing/svg/leaf.svg";
import greenFlower from "../../assets/landing/svg/green-flower.svg";
import BgImg from "../../assets/landing/png/list-shape.png";

export const LandingDashboardStyles = makeStyles((theme) => ({
  heroSection: {
    minHeight: "100vh",
    justifyContent: "center",
    position: "relative",
    background: `url(${homeBanner})`,
    padding: [[100, 0]],
    [theme.breakpoints.down("1025")]: {
      minHeight: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[150, 0, 50]],
    },
    [theme.breakpoints.down("xsm")]: {
      padding: [[150, 0, 50]],
    },
    "&:before": {
      content: "''",
      background:
        "linear-gradient(2.83deg, #FEFEFE 2%, rgba(230, 241, 250, 0.95) 97.65%)",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      mixBlendMode: "screen",
    },
    "& .desc": {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: "48px",
      color: "var(--gray)",
      marginBottom: 20,
      marginTop: 25,
      maxWidth: 550,
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("md")]: {
        fontSize: 28,
        lineHeight: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        lineHeight: "30px",
        marginTop: 15,
        marginBottom: 15,
      },
      "& span": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
    "& .MuiButtonBase-root": {
      padding: "12px 22px",
      borderRadius: "10px",
      background:
        "linear-gradient(132.61deg, var(--primaryDarker) 32.09%, var(--primaryGradientLighter) 163.78%)",
    },
  },
  bannerTextSlider: {
    "&.swiper-fade": {
      "& .swiper-slide": {
        visibility: "hidden",
        "&.swiper-slide-active": {
          visibility: "visible",
        },
      },
    },
  },
  arrowDown: {
    "& a": {
      display: "inline-block",
      color: "var(--primary)",
      paddingTop: 20,
      "& svg": {
        animation: `scroll 1.5s linear infinite`,
        cursor: "pointer",
        fontSize: 34,
      },
    },
  },
  sectionShape: {
    position: "absolute",
    zIndex: -1,
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  section1Bg1: {
    left: "8%",
    top: "12%",
    width: 60,
  },
  section1Bg2: {
    left: "8%",
    bottom: "12%",
  },
  section1Bg3: {
    right: "20%",
    top: "35%",
  },
  section1Bg4: {
    right: "8%",
    top: "50%",
    width: 100,
  },
  section1Bg5: {
    right: "8%",
    bottom: 0,
    width: 134,
  },
  bottomShape: {
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 2,
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },
  welcomeSection: {
    position: "relative",
    overflow: "hidden",
    "& p": {
      fontSize: 24,
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 16,
      },
    },
    "& span": {
      fontSize: 24,
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 16,
      },
    },
  },
  welcomeImage: {
    margin: "0 auto",
    display: "table",
    position: "relative",
    zIndex: 9,
    [theme.breakpoints.down("xlg")]: {
      maxWidth: "30%",
    },
    [theme.breakpoints.down("xsm")]: {
      maxWidth: "20%",
    },
  },
  circleBg: {
    borderRadius: " 100%",
    height: 600,
    width: 600,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "0 auto",
    [theme.breakpoints.down("xlg")]: {
      height: 500,
      width: 500,
    },
    [theme.breakpoints.down("lg")]: {
      height: 400,
      width: 400,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 20,
    },
    [theme.breakpoints.down("sm")]: {
      height: 350,
      width: 350,
    },
    [theme.breakpoints.down("xsm")]: {
      height: 280,
      width: 280,
    },
    "& .circle": {
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: "50%",
      "&:nth-child(3)": {
        margin: 40,
        [theme.breakpoints.down("lg")]: {
          margin: 30,
        },
      },
      "&:nth-child(4)": {
        margin: 80,
        [theme.breakpoints.down("lg")]: {
          margin: 60,
        },
      },
      "&:nth-child(5)": {
        margin: 120,
        [theme.breakpoints.down("lg")]: {
          margin: 90,
        },
      },
    },
  },
  section2Bg1: {
    left: "5%",
    top: "50%",
    width: 100,
  },
  lgHeading: {
    "&.MuiTypography-root": {
      fontSize: 72,
      lineHeight: "86px",
      fontWeight: 400,
      color: "#2D2F39",
      [theme.breakpoints.down("lg")]: {
        fontSize: 52,
        lineHeight: "66px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 42,
        lineHeight: "56px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 32,
        lineHeight: "46px",
      },
      [theme.breakpoints.down("xts")]: {
        fontSize: 26,
        lineHeight: "40px",
      },
      "& .orangeText": {
        color: "var(--primary)",
        fontWeight: 700,
      },
      "& .hText": {
        position: "relative",
        zIndex: 2,
        "&:before": {
          position: "absolute",
          content: "''",
          height: 77,
          width: 77,
          bottom: "43%",
          right: "17%",
          background: `url(${greenFlower}) no-repeat`,
          backgroundSize: "contain",
          zIndex: -1,
          [theme.breakpoints.down("sm")]: {
            height: 45,
            width: 45,
          },
          [theme.breakpoints.down("xsm")]: {
            height: 30,
            width: 30,
          },
        },
      },
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 50,
      lineHeight: "52px",
      color: "#2D2F39",
      [theme.breakpoints.down("lg")]: {
        fontSize: 35,
        lineHeight: "37px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
        lineHeight: "28px",
      },
      "& span": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
  },
  description: {
    color: "var(--gray)",
    fontWeight: 700,
    "& .orangeText": {
      color: "var(--primary)",
      fontWeight: 700,
    },
    "& .mahendi": {
      color: "#86650A",
      fontWeight: 700,
    },
    "& .sa": {
      color: "#8B3F9E",
      fontWeight: 700,
    },
    "& .ra": { color: "#3498DB", fontWeight: 700 },
    "& .ga": { color: "#FFB142", fontWeight: 700 },
    "& .ma": { color: "#2D8F4E", fontWeight: 700 },
  },
  spacing: {
    padding: "100px 0",
    [theme.breakpoints.down("md")]: {
      padding: "50px 0",
    },
  },
  lines: {
    position: "absolute",
    top: "10%",
    left: "57%",
    transform: "translateX(-50%)",
  },
  missionSection: {
    paddingTop: 50,
    "& .desc": {
      maxWidth: 880,
      margin: "0 auto",
      textAlign: "center",
    },
  },
  imageWrapper: {
    maxWidth: 640,
    width: "100%",
    padding: 12,
    borderRadius: 15,
    background: "var(--white)",
    maxHeight: 480,
    [theme.breakpoints.down("md")]: {
      maxWidth: 500,
    },
    "& div": {
      height: "100%",
      overflow: "hidden",
    },
    "& video": {
      height: "auto",
      width: "100%",
      objectFit: "contain !important",
    },
    "& img": {
      height: "auto",
      width: "100%",
      borderRadius: 10,
    },
    "&:before": {
      position: "absolute",
      content: "''",
      height: "50%",
      width: "100%",
      maxWidth: "60%",
      top: -12,
      left: -12,
      borderRadius: 15,
      background: "linear-gradient(180deg, #F2E7F5, #E3F1F9)",
      backgroundSize: "contain",
      zIndex: -1,
    },
    "&:after": {
      position: "absolute",
      content: "''",
      height: "50%",
      width: "100%",
      maxWidth: "60%",
      bottom: -12,
      right: -12,
      borderRadius: 15,
      background: "linear-gradient(180deg, #F2E7F5, #E3F1F9)",
      backgroundSize: "contain",
      zIndex: -1,
    },
    "& .innerWrapper": {
      position: "relative",
      overflow: "unset",
      "&:after": {
        position: "absolute",
        content: "''",
        top: -12,
        left: -12,
        bottom: -12,
        right: -12,
        borderRadius: 15,
        background: "var(--white)",
      },
      "& img": {
        position: "relative",
        zIndex: 2,
      },
    },
  },
  vasanaApproachSection: {
    background: "linear-gradient(94.43deg, #FFF8F8 14.47%, #E6F1FA 105%)",
    borderRadius: 24,
    padding: 72,
    [theme.breakpoints.down("lg")]: {
      padding: 50,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 30,
    },
    [theme.breakpoints.down("xsm")]: {
      padding: 20,
    },
    "& p": {
      fontSize: 24,
      color: "var(--gray)",
      [theme.breakpoints.down("lg")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& ul": {
      "& li": {
        padding: [[0, 0, 0, 30]],
        fontSize: 24,
        color: "var(--gray)",
        marginTop: 20,
        lineHeight: "34px",
        backgroundImage: `url(${BgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: 20,
        backgroundPosition: "left top 6px",
        display: "block",
        [theme.breakpoints.down("lg")]: {
          fontSize: 20,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
          lineHeight: "24px",
          backgroundSize: 16,
          padding: [[0, 0, 0, 25]],
          backgroundPosition: "left top 4px",
        },
        "& span": {
          fontSize: 24,
          [theme.breakpoints.down("lg")]: {
            fontSize: 20,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 16,
          },
        },
      },
    },
  },
  opportunityImage: {
    borderRadius: "0 50% 0 50%",
    border: "15px solid #FFF7EC",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto 30px",
      display: "table",
    },
  },
  section3Bg1: {
    right: "5%",
    top: "20%",
    width: 60,
  },
  section3Bg2: {
    right: "5%",
    top: "50%",
    width: 60,
  },
  section3Bg3: {
    left: "5%",
    top: "50%",
    width: 100,
  },
  conceptualView: {
    position: "relative",
    width: 480,
    height: 480,
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    boxShadow: "5px 5px 20px rgba(0,0,0,0.4)",
    "& img": {
      position: "relative",
      zIndex: "10",
      [theme.breakpoints.down("sm")]: {
        maxWidth: 160,
      },
      [theme.breakpoints.down("xts")]: {
        maxWidth: 125,
      },
    },
    "&::before": {
      content: "''",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%) rotate(0)",
      height: "calc(100% - 40px)",
      width: "calc(100% - 40px)",
      boxShadow: "inset 5px 5px 20px rgba(0,0,0,0.4)",
      zIndex: 10,
      borderRadius: "100%",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%) rotate(0)",
      height: "calc(100% - 40px)",
      width: "calc(100% - 40px)",
      borderRadius: "100%",
      padding: 20,
      zIndex: 9,
      background:
        "linear-gradient(white, white), linear-gradient(30deg, #8B3F9E 36%, #8B3F9E 30%), linear-gradient(120deg, #FFB142 36%, #FFB142 30%), linear-gradient(300deg, #2D8F4E 36%, #2D8F4E 30%), linear-gradient(210deg, #1780C6 36%, #1780C6 30%)",
      backgroundPosition:
        "center center, left top, right top, left bottom, right bottom",
      backgroundOrigin:
        "content-box, border-box, border-box, border-box, border-box",
      backgroundSize: "100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%",
      backgroundRepeat: "no-repeat",
      backgroundClip:
        "content-box, border-box, border-box, border-box, border-box",
      animation: "$rotate 10s linear infinite",
      transformOrigin: "center center",
    },
    [theme.breakpoints.down("xlg")]: {
      width: 440,
      height: 440,
    },
    [theme.breakpoints.down("mlg")]: {
      width: 330,
      height: 330,
    },
    [theme.breakpoints.down("md")]: {
      width: 480,
      height: 480,
    },
    [theme.breakpoints.down("sm")]: {
      width: 380,
      height: 380,
    },
    [theme.breakpoints.down("xts")]: {
      width: 280,
      height: 280,
    },
  },
  "@keyframes rotate": {
    "0%": {
      transform: "translate(-50%, -50%) rotate(0)",
    },
    "100%": {
      transform: "translate(-50%, -50%) rotate(360deg)",
    },
  },
  rotateText: {
    position: "relative",
    "& span": {
      position: "absolute",
    },
  },
  genomeSection: {
    position: "relative",
  },
  "@keyframes lineMove": {
    "0%": {
      strokeDashoffset: 0,
    },
    "100%": {
      strokeDashoffset: 400,
    },
  },
  "@keyframes lineMoveReverse": {
    "0%": {
      strokeDashoffset: 400,
    },
    "100%": {
      strokeDashoffset: 0,
    },
  },
  diagnosticInputs: {
    position: "relative",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    "& p": {
      color: "#2D3652",
      fontWeight: 700,
    },
    "& .MuiList-root": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flex: 1,
      "& .MuiListItem-root": {
        position: "relative",
        padding: 0,
        marginTop: 35,
        [theme.breakpoints.down("mlg")]: {
          marginTop: 30,
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
          marginTop: 15,
        },
        "& p": {
          backgroundColor: "#FFF2EB",
          width: "100%",
          padding: [[16, 70, 16, 20]],
          fontSize: 32,
          color: "var(--darkerGray)",
          fontWeight: 400,
          borderRadius: 16,
          [theme.breakpoints.down("xl")]: {
            fontSize: 28,
          },
          [theme.breakpoints.down("lg")]: {
            fontSize: 20,
          },
          [theme.breakpoints.down("md")]: {
            padding: 16,
            textAlign: "center",
            fontSize: 20,
          },
        },
        "& svg": {
          position: "absolute",
          left: "100%",
          "& path": {
            animation: `$lineMove 5s linear infinite`,
          },
        },
        "&:first-child": {
          "& svg": {
            width: 125,
            height: 200,
            top: "80%",
            left: "95%",
            [theme.breakpoints.down("xl")]: {
              top: "70%",
            },
            [theme.breakpoints.down("lg")]: {
              left: "90%",
              width: 105,
              height: 160,
            },
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
            "& path": {
              animation: `$lineMoveReverse 5s linear infinite`,
            },
          },
        },
        "&:nth-child(2)": {
          "& svg": {
            width: 82,
            height: 90,
            top: "50%",
            [theme.breakpoints.down("lg")]: {
              left: "100%",
              width: 62,
              height: 70,
            },
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
        },
        "&:nth-child(3)": {
          "& svg": {
            width: 82,
            height: 90,
            bottom: "50%",
            transform: "scaleY(-1)",
            [theme.breakpoints.down("lg")]: {
              left: "100%",
              width: 62,
              height: 70,
            },
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
        },
        "&:nth-child(4)": {
          "& svg": {
            width: 125,
            height: 200,
            bottom: "80%",
            left: "95%",
            transform: "scaleY(-1)",
            [theme.breakpoints.down("xl")]: {
              bottom: "70%",
            },
            [theme.breakpoints.down("lg")]: {
              left: "90%",
              width: 105,
              height: 160,
            },
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
            "& path": {
              animation: `$lineMoveReverse 5s linear infinite`,
            },
          },
        },
      },
    },
  },
  therapeuticsList: {
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
    "& > p": {
      color: "#2D3652",
      fontWeight: 700,
      fontSize: 32,
      textAlign: "center",
      [theme.breakpoints.down("xl")]: {
        fontSize: 26,
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 20,
      },
    },
    "& > .MuiList-root": {
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "column",
      "& > .MuiListItem-root": {
        display: "block",
        marginTop: 10,
        padding: [[0, 10]],
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        "& .MuiBox-root": {
          padding: 20,
          backgroundColor: "#FFF2EB",
          borderRadius: 16,
          height: "100%",
          [theme.breakpoints.down("lg")]: {
            padding: 15,
          },
          "& p": {
            color: "#2D3652",
            fontSize: 32,
            marginBottom: 5,
            fontWeight: 700,
            [theme.breakpoints.down("xl")]: {
              fontSize: 26,
            },
            [theme.breakpoints.down("xlg")]: {
              fontSize: 24,
            },
            [theme.breakpoints.down("lg")]: {
              fontSize: 18,
            },
          },
          "& .MuiListItem-root": {
            color: "var(--darkerGray)",
            paddingLeft: 30,
            backgroundImage: `url(${BgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: 15,
            backgroundPosition: "left 12px",
            display: "block",
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 24,
            [theme.breakpoints.down("xl")]: {
              fontSize: 20,
            },
            [theme.breakpoints.down("lg")]: {
              fontSize: 16,
            },
            "& span": {
              color: "var(--primary)",
              fontSize: 24,
              [theme.breakpoints.down("xl")]: {
                fontSize: 20,
              },
              [theme.breakpoints.down("lg")]: {
                fontSize: 16,
              },
            },
          },
        },
        "& > svg": {
          position: "absolute",
          right: "100%",
          pointerEvents: "none",
          "& path": {
            animation: `$lineMoveReverse 5s linear infinite`,
          },
        },
        "&:first-child": {
          "& > svg": {
            width: 90,
            height: 210,
            top: "55%",
            [theme.breakpoints.down("xl")]: {
              height: 180,
            },
            [theme.breakpoints.down("lg")]: {
              height: 160,
              right: "95%",
            },
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
        },
        "&:nth-child(2)": {
          "& > svg": {
            height: 10,
            top: "50%",
            width: 90,
            transform: "translateY(-50%)",
            [theme.breakpoints.down("lg")]: {
              width: 70,
              height: 7,
            },
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
        },
        "&:nth-child(3)": {
          "& > svg": {
            width: 90,
            height: 205,
            bottom: "50%",
            transform: "scaleY(-1)",
            [theme.breakpoints.down("xl")]: {
              height: 180,
            },
            [theme.breakpoints.down("lg")]: {
              right: "95%",
              height: 160,
            },
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
        },
      },
    },
  },
  mobileArrow: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "& svg": {
      margin: "0 auto",
      display: "table",
      [theme.breakpoints.down("sm")]: {
        height: "100px",
      },
      "& path": {
        animation: `$lineMoveReverse 5s linear infinite`,
      },
    },
  },
  section4Bg1: {
    right: "6%",
    top: 0,
  },
  section4Bg2: {
    left: "5%",
    top: "32%",
    width: 60,
  },
  section4Bg3: {
    right: "5%",
    top: "40%",
    width: 100,
  },
  section4Bg4: {
    left: "7%",
    top: "60%",
    width: 40,
  },
  section4Bg5: {
    left: "3%",
    bottom: 0,
    width: 100,
  },
  healthPathListImage: {
    width: "40%",
    flex: "0 0 40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flex: "0 0 100%",
    },
    "& img": {
      height: "100%",
      objectFit: "cover",
    },
  },
  healthPathList: {
    display: "flex",
    borderRadius: 16,
    overflow: "hidden",
    marginTop: 30,
    position: "sticky",
    minHeight: 390,
    maxHeight: 390,
    top: 100,
    [theme.breakpoints.down("sm")]: {
      display: "block",
      minHeight: "auto",
      maxHeight: "inherit",
      position: "static",
    },
    "& .MuiBox-root": {
      padding: [[20, 40]],
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        padding: [[20, 30]],
      },
      [theme.breakpoints.down("md")]: {
        padding: 20,
      },
      "& h6": {
        fontSize: 28,
        marginBottom: 10,
        fontWeight: 700,
        [theme.breakpoints.down("lg")]: {
          fontSize: 20,
        },
      },
      "& p": {
        color: "var(--gray)",
        fontSize: 24,
        [theme.breakpoints.down("lg")]: {
          fontSize: 18,
        },
      },
    },
  },
  section5Bg1: {
    right: "10%",
    top: "5%",
    width: 60,
  },
  section5Bg2: {
    right: "10%",
    top: "25%",
    width: 40,
  },
  section5Bg3: {
    left: "9%",
    top: "20%",
    width: 100,
  },
  section5Bg4: {
    right: "9%",
    top: "45%",
    width: 100,
  },
  section5Bg5: {
    left: "9%",
    top: "50%",
    width: 100,
  },
  section5Bg6: {
    right: "8%",
    top: "60%",
    width: 60,
  },
  section5Bg7: {
    left: "8%",
    top: "70%",
    width: 100,
  },
  section5Bg8: {
    right: "7%",
    top: "75%",
    width: 50,
  },
  section5Bg9: {
    left: "7%",
    top: "90%",
    width: 130,
  },
  section5Bg10: {
    right: "5%",
    bottom: "5%",
    width: 100,
  },
  "@keyframes marquee": {
    "0%": {
      transform: "translateX(0%)",
    },
    "100%": {
      transform: "translateX(-300%)",
    },
  },
  uniqueSection: {
    overflow: "hidden",
    paddingBottom: 200,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 150,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 100,
    },
  },
  understandingList: {
    display: "flex",
    marginBottom: 60,
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
      animation: "$marquee 10s linear infinite",
    },
    "& p": {
      fontSize: 40,
      color: "var(--gray)",
      padding: [[0, 30, 0, 60]],
      backgroundImage: `url(${BgImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: 25,
      backgroundPosition: "left center",
      [theme.breakpoints.down("xlg")]: {
        fontSize: 32,
        backgroundSize: 20,
        padding: [[0, 20, 0, 40]],
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 28,
        padding: [[0, 15, 0, 30]],
        backgroundSize: 15,
      },
      "&:first-child": {
        paddingLeft: 0,
        backgroundImage: "none",
        marginLeft: "auto",
      },
      "&:last-child": {
        paddingRight: 0,
        marginRight: "auto",
      },
      "& span": {
        fontWeight: "bold",
        fontSize: 40,
        marginLeft: 10,
        [theme.breakpoints.down("xlg")]: {
          fontSize: 32,
        },
        [theme.breakpoints.down("lg")]: {
          fontSize: 28,
        },
      },
    },
  },
  sectionBg1: {
    top: "-30%",
    right: "20%",
    [theme.breakpoints.down("md")]: {
      top: "10%",
      width: 30,
    },
  },
  sectionBg2: {
    top: -80,
    right: "8%",
  },
  sectionBg3: {
    top: "20%",
    left: "8%",
  },
  sectionBg4: {
    bottom: "10%",
    left: "6%",
    width: 90,
  },
  sectionBg5: {
    top: "5%",
    right: "8%",
    width: 100,
  },
  uniqueNeeds: {
    borderRadius: 16,
    height: "calc(100% - 40px)",
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
      height: "auto",
    },
    "& img": {
      display: "table",
      marginLeft: "auto",
      marginTop: "auto",
      maxHeight: 265,
      [theme.breakpoints.down("lg")]: {
        maxHeight: 185,
      },
    },
  },
  uniqueNeedsContent: {
    padding: 32,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {
      padding: [[24, 24, 0]],
    },
    "& h5": {
      fontSize: 28,
      fontWeight: 500,
      marginBottom: 10,
      [theme.breakpoints.down("lg")]: {
        fontSize: 26,
      },
      [theme.breakpoints.down("slg")]: {
        fontSize: 24,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
      },
    },
    "& p": {
      color: "var(--gray)",
      marginBottom: 20,
      fontSize: 22,
      [theme.breakpoints.down("lg")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
    },
    "& .MuiButtonBase-root": {
      padding: [[10, 20]],
      [theme.breakpoints.down("sm")]: {
        padding: [[8, 16]],
      },

      "& .content": {
        fontSize: 18,
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
    },
  },
  purpleBtn: {
    "&.MuiButtonBase-root": {
      background: "linear-gradient(132.61deg, #E87637 32.09%, #FFBE78 163.78%)",
    },
  },
  footer: {
    marginTop: "auto",
  },
  footerPartTwoList: {
    "&.MuiList-root": {
      display: "flex",
      flexWrap: "wrap",
      padding: 0,
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.down("sm")]: {
        gap: 2,
      },
      "& li": {
        width: "auto",
        padding: [[0, 16]],
        position: "relative",
        "&:first-child": {
          paddingLeft: 0,
        },
        "&:not(:first-child)": {
          "&:after": {
            position: "absolute",
            content: "''",
            background: "#bbbbbb",
            top: 0,
            left: 0,
            width: 2,
            height: "100%",
          },
        },

        "& a": {
          fontSize: 14,
          color: "var(--gray)",
          "&:hover": {
            color: "var(--primary)",
          },
        },
      },
    },
  },
  copyrightFooter: {
    padding: "10px",
    backgroundColor: "#DFE1F84D",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px",
    },
    "& .MuiTypography-body1": {
      color: "var(--gray)",
      textAlign: "center",
      fontSize: 14,
    },
  },
  mainFooterListItem: {
    "&.MuiList-root": {
      display: "flex",
      flexWrap: "wrap",
      padding: 0,
      fontWeight: 500,
      fontSize: 14,
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.down("sm")]: {
        gap: 10,
        justifyContent: "center",
      },
      "& li": {
        width: "auto",
        padding: [[0, 16]],
        position: "relative",
        "& a": {
          fontSize: 14,
          color: "var(--gray)",
          "&:hover": {
            color: "var(--primary)",
          },
        },
      },
    },
  },
  singleIcon: {
    height: 40,
    width: 40,
    borderRadius: "50%",
    backgroundImage: "linear-gradient(to bottom, #F2E7F5, #E3F1F9)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "not-allowed",
    "& a": {
      display: "flex",
      "& svg": {
        verticalAlign: "middle",
      },
    },
  },
}));
