import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ON_FLAG_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_USER_DATA,
} from "../redux/constants";
import { ApiContainer } from "../utils/api";
import { findMaxValue, findPer, keys } from "../utils/javascript";
import { getAssessmentState } from "../shared/CustomFunc";
import { assessmentIds } from "../utils/constant";
import { showToast } from "../utils/toastService";
import {
  answer_type_with_result,
  remove_form_multi_choice,
} from "../description/questionSet.description";

const formPath = { parent: "assessmentFormData" };
export const questionSetContainer = () => {
  const progressBar = useRef(null);
  const giftBox = useRef(null);
  const dispatch = useDispatch();
  const assessmentData = useSelector((state) => state.api?.assessmentData);
  const AllQuestionsData = useSelector(
    (state) => state.api?.assessmentData?.questions
  );
  const userData = useSelector((state) => state?.app?.userData);
  const tieQuestionsData = useSelector(
    (state) => state.api?.assessmentData?.tieBreaker?.sections
  );
  const [magnitude, setMagnitude] = useState(dayjs());
  const [isTieBreakerQuestionAdded, setIsTieBreakerQuestionAdded] =
    useState(false);
  const { sections: questionsList, allow_resubmission } = AllQuestionsData;
  const { form } = useSelector((state) => state);
  const assessmentFormData = form.formData?.[formPath.parent];
  const tieBreakerFormData = form.formData?.tieBreaker;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentQuestion = questionsList?.[currentQuestionIndex];
  const userRoleData = useSelector((state) => state?.app?.userData);
  const currentAssessmentId = localStorage.getItem("currentAssessmentId");
  const assessmentList = useSelector((state) => state?.api?.assessmentList);
  const logOut = useSelector((state) => state?.app?.flags?.logOut);
  const allAssessmentResults = useSelector(
    (state) => state?.api?.assessmentResult
  );
  const currentAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: currentAssessmentId,
  });
  const callResultApi = useSelector(
    (state) => state?.app?.flags?.callResultApi
  );
  const navigate = useNavigate();
  const [isAssessmentCompleted, setIsAssessmentComplete] = useState(false);
  const { api } = ApiContainer();

  const resultApi = async () => {
    const totalMagnitude = dayjs().diff(dayjs(magnitude), "milliseconds");
    const payload = {
      submission_id: assessmentData?.subId,
      answers: keys(assessmentFormData)
        .map((item) => {
          const element = assessmentFormData?.[item];
          let returnObj = {};
          if (element.answer_type === "multiple-choice") {
            returnObj = element?.value?.map((ele) => {
              return {
                choice_id: ele?.id,
                question_id: element?.id,
                result: ele?.result,
                tie_breaker: element?.tie_breaker,
              };
            });
          } else if (answer_type_with_result.includes(element.answer_type)) {
            returnObj = {
              question_id: element?.id,
              result: element?.value?.result,
              markers: element?.markers,
              tie_breaker: element?.tie_breaker,
            };
          } else if (element.answer_type === "static-json") {
            returnObj = {
              question_id: element?.id,
              result: JSON.stringify(element?.value),
              markers: element?.markers,
              tie_breaker: element?.tie_breaker,
            };
          } else {
            returnObj = {
              choice_id: element?.value?.id,
              question_id: element?.id,
              result: element?.value?.result,
              markers: element?.markers,
              tie_breaker: element?.tie_breaker,
            };
          }
          if (currentAssessmentId !== assessmentIds?.vasanaType) {
            delete returnObj.markers;
          }
          return returnObj;
        })
        .flat(),
    };
    const totalQuestionLength = payload?.answers.length;
    payload.answers = payload?.answers.map((item) => ({
      ...item,
      magnitude: Math.round(totalMagnitude / totalQuestionLength),
    }));
    dispatch({
      type: ON_FLAG_CHANGE,
      payload: { callResultApi: false },
    });
    setIsAssessmentComplete(true);
    const response = await api({
      method: "POST",
      endPoint: `assessment/${currentAssessmentId}/answer`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "resultSubmit",
      data: payload,
    });
    const result = await api({
      method: "GET",
      endPoint: `assessment/${currentAssessmentId}/result?submission_id=${assessmentData?.subId}`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "resultSubmit",
      data: payload,
    });
    if (result?.status) {
      const clonedAssessments = [...userData?.assessments];
      const index = clonedAssessments?.findIndex(
        (el) =>
          el?.assessment_id?.toString() === currentAssessmentId?.toString()
      );
      clonedAssessments[index] = {
        ...currentAssessment,
        result: result?.data?.result || currentAssessment?.result,
        submission_id: assessmentData?.subId,
      };
      dispatch({
        type: SET_USER_DATA,
        payload: {
          assessments: clonedAssessments,
        },
      });

      const clonedAssessmentList = [...assessmentList];
      const currIndex = clonedAssessmentList?.findIndex(
        (el) =>
          el?.assessment_id?.toString() === currentAssessmentId?.toString()
      );
      clonedAssessmentList[currIndex] = {
        ...clonedAssessmentList[currIndex],
        assessment_taken: true,
      };
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentList: clonedAssessmentList,
        },
      });
      if (currentAssessmentId?.toString() === assessmentIds?.vasanaType) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            assessmentResult: {
              ...allAssessmentResults,
              [currentAssessment?.assessment_id]: {
                ...result?.data,
                tie_markers: result?.data?.tie_markers?.split(","),
              },
            },
          },
        });
      }
      const findAssessment = assessmentList.find(
        (item) => item?.assessment_id === Number(currentAssessmentId)
      );
      if (!["10", "11", "12"]?.includes(currentAssessmentId?.toString())) {
        if (currentAssessmentId === "1") {
          navigate("/");
        } else if (
          findAssessment?.is_locked ||
          !findAssessment?.is_result_available
        ) {
          navigate("/assessments");
        } else {
          navigate(`/assessments/${currentAssessmentId}`);
        }
      }
    } else {
      showToast(
        "Sorry for inconvenience, looks like there was network issues, Please retake the assessment",
        "info"
      );
    }
  };

  const onChange = (newValue, question, remove = false) => {
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...assessmentFormData,
            [question?.id]: remove ? null : { ...question, value: newValue },
          },
          tieBreaker: {
            ...tieBreakerFormData,
            [newValue?.result]:
              (tieBreakerFormData?.[newValue?.result] || 0) + 1,
          },
        },
      },
    });
  };
  const onChangeMultiChoice = (newValue, question) => {
    let valueOfCurrentOption = assessmentFormData?.[question?.id]?.value || [];
    const valueToRemove = valueOfCurrentOption.find(
      (item) => item?.id === newValue?.id
    );
    if (valueToRemove) {
      valueOfCurrentOption = valueOfCurrentOption.filter(
        (item) => item?.id !== newValue?.id
      );
    } else {
      valueOfCurrentOption = remove_form_multi_choice?.includes(
        newValue?.phrase
      )
        ? [newValue]
        : [...valueOfCurrentOption, newValue];
    }
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...assessmentFormData,
            [question?.id]:
              valueOfCurrentOption && valueOfCurrentOption.length === 0
                ? null
                : {
                    ...question,
                    value: valueOfCurrentOption,
                  },
          },
        },
      },
    });
  };

  const callResultApiFunc = () =>
    dispatch({
      type: ON_FLAG_CHANGE,
      payload: { callResultApi: true },
    });

  const handelNextQuestion = (currentQuestionIndex, newValue) => {
    setCurrentQuestionIndex(currentQuestionIndex);
    if (
      currentQuestionIndex === questionsList?.length &&
      currentAssessment?.has_tie_breaker_questions
    ) {
      if (!isTieBreakerQuestionAdded) {
        const tieBreakerValue = {
          ...tieBreakerFormData,
          [newValue?.result]: (tieBreakerFormData?.[newValue?.result] || 0) + 1,
        };
        const tieBreakerMaxValue = findMaxValue(tieBreakerValue);
        if (tieBreakerMaxValue.length > 1) {
          const questionToAdd = tieQuestionsData?.filter((item) => {
            return item?.questions?.some((question) => {
              const markersArray = question.markers.split(",");
              return tieBreakerMaxValue.every((marker) =>
                markersArray.includes(marker)
              );
            });
          });
          if (questionToAdd) {
            dispatch({
              type: SET_API_DATA,
              payload: {
                assessmentData: {
                  ...assessmentData,
                  questions: {
                    ...assessmentData.questions,
                    sections: [
                      ...assessmentData.questions.sections,
                      ...questionToAdd,
                    ],
                  },
                },
              },
            });
            setIsTieBreakerQuestionAdded(true);
          }
        } else {
          callResultApiFunc();
        }
      } else {
        callResultApiFunc();
      }
    } else if (currentQuestionIndex === questionsList?.length) {
      callResultApiFunc();
    }
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  const getTransformValue = () => {
    const pxToTranslate =
      progressBar?.current?.offsetWidth - giftBox?.current?.offsetWidth;
    const progress = findPer(currentQuestionIndex, questionsList?.length - 1);
    const translate = (progress / 100) * pxToTranslate;
    return `translateX(${translate}px)`;
  };

  useEffect(() => {
    if (callResultApi) resultApi();
  }, [callResultApi]);
  useEffect(() => {
    return () =>
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [formPath.parent]: {},
            tieBreaker: {},
          },
        },
      });
  }, []);

  return {
    currentQuestion,
    handelNextQuestion,
    currentQuestionIndex,
    onChange,
    assessmentFormData,
    allow_resubmission,
    totalQuestion: questionsList?.length,
    progressBar,
    giftBox,
    getTransformValue,
    isAssessmentCompleted,
    logOut,
    onChangeMultiChoice,
  };
};
