import React from "react";
import { makeStyles } from "@mui/styles";
import VSNTabs from "../../shared/VSNTabs";
import VSNImage from "../../shared/VSNImage";
import shape1 from "../../assets/admin/png/shape-1.png";
import shape2 from "../../assets/admin/png/shape-2.png";
import shape3 from "../../assets/admin/png/shape-3.png";
import shape4 from "../../assets/admin/png/shape-4.png";
import shape5 from "../../assets/admin/png/shape-5.png";
import CreateOrgModal from "./createOrg";
import VSNModel from "../../shared/VSNModel";
import VSNLoader from "../../shared/VSNLoader";
import SEO from "../../shared/SEO";
import CopyLinkModal from "./createOrg/CopyLinkModal";
import SummaryCard from "./SummaryCard";
import { summaryCardHeaders } from "../../description/dashboard.description";
import CreateLocationModal from "./createLocation";
import CreateDepartmentModal from "./createDepartment";
import bgFlower from "../../assets/Teams/png/team-bg-banner.png";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    padding: [[32, 32, 42, 32]],
    zIndex: 5,
    position: "relative",
    background:
      "linear-gradient(92deg, #F6FCFF 7.06%, rgba(255, 244, 238, 0.50) 86.87%)",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
      background: `url(${bgFlower})`,
      minHeight: 310,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[10, 16, 20, 16]],
    },
  },
  summary: {
    marginBottom: 32,
    "& h5": {
      fontSize: 34,
      fontWeight: 700,
      color: "var(--blackGray)",
      lineHeight: "44px",
      [theme.breakpoints.down("md")]: {
        fontSize: 23,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 5,
      },
    },
    "& .MuiButtonBase-root": {
      padding: "12px 18px",
      lineHeight: "21px",
      fontSize: 16,
      borderRadius: 10,
      color: "var(--white)",
    },
  },
  saregamaCards: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: [[20, 16]],
    borderRadius: 8,
    border: "1px solid white",
    "&:hover": {
      border: "1px solid #c9c9c9",
      background:
        "linear-gradient(92deg, rgb(225 225 225 / 50%) 1.56%, rgba(242, 231, 245, 0.00) 77.91%)",
    },
    "&.saPer": {
      "&:hover": {
        border: "1px solid #8304A3",
        background:
          "linear-gradient(92deg, rgba(242, 231, 245, 0.50) 1.56%, rgba(242, 231, 245, 0.00) 77.91%)",
      },
    },
    "&.rePer": {
      "&:hover": {
        border: "1px solid #3498DB",
        background:
          "linear-gradient(92deg, rgba(227, 241, 249, 0.50) 1.56%, rgba(227, 241, 249, 0.00) 77.91%)",
      },
    },
    "&.gaPer": {
      "&:hover": {
        border: "1px solid #FFB142",
        background:
          "linear-gradient(92deg, rgba(255, 224, 180, 0.20) 1.56%, rgba(255, 224, 180, 0.00) 77.91%)",
      },
    },
    "&.maPer": {
      "& img": {
        transform: "scale(1.1)",
      },
      "&:hover": {
        border: "1px solid #56A06F",
        background:
          "linear-gradient(92deg, rgba(200, 233, 211, 0.25) 1.56%, rgba(200, 233, 211, 0.00) 77.91%)",
      },
    },
    boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.1)",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      minWidth: "145px",
      padding: [[15]],
    },
    [theme.breakpoints.down("375")]: {
      minWidth: "120px",
      padding: [[15]],
    },
    "& p": {
      fontSize: 20,
      fontWeight: 700,
      flexGrow: 1,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
      },
    },
    "& h5": {
      fontSize: 16,
      fontWeight: 700,
      color: "var(--blackGray)",
      marginRight: 15,
      lineHeight: "21px",
      textAlign: "left",
    },
    "& img": {
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        height: 35,
      },
      [theme.breakpoints.down("375")]: {
        height: 30,
      },
    },
  },
  assesmentCard: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: [[20, 16]],
    borderRadius: 8,
    boxShadow: "0px 3.05px 7.63px 0px #D4D9DE1A",
    border: "1px solid white",
    "&:hover": {},
    zIndex: 5,
    [theme.breakpoints.down("md")]: {
      padding: 15,
    },
    "& h5": {
      fontSize: 16,
      fontWeight: 700,
      color: "var(--blackGray)",
      marginRight: 15,
      lineHeight: "21px",
      textAlign: "left",
    },
    "& h4": {
      fontSize: 20,
      lineHeight: "27px",
      fontWeight: 700,
      flexGrow: 1,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("xsm")]: {
        textAlign: "right",
      },
    },
  },
  shape: {
    position: "absolute",
    zIndex: -2,
  },
  shape2: {
    top: -25,
    left: 0,
  },
  shape3: {
    top: -60,
    left: "50%",
    transform: "translateX(-50%)",
  },
  shape4: {
    bottom: "0%",
    left: "0%",
  },
  shape5: {
    top: "47%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 24,
      fontWeight: 700,
      color: "var(--blackGray)",
      lineHeight: "31px",
      marginBottom: 32,
      [theme.breakpoints.down("md")]: {
        marginBottom: 15,
        fontSize: 20,
      },
    },
  },
  createButton: {
    "&.MuiButton-root": {
      "& .content": {
        fontSize: 16,
        [theme.breakpoints.down("md")]: {
          fontSize: 14,
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: "8px 12px",
      },
    },
  },
}));

const Dashboard = ({
  modelState,
  toggleModelShow,
  assessmentSummary,
  loaders,
  handleTabChange,
  activeTab,
  tabs,
  onFilters,
  orgFilters,
  onFilterChange,
  onCommonFilterFilterChange,
  filters,
  commonFilter,
}) => {
  const classes = useStyles();
  const summaryHeaders = summaryCardHeaders({ assessmentSummary, classes });
  if (loaders?.summaryLoader || assessmentSummary === undefined) {
    return <VSNLoader variant="fullPage" />;
  }

  return (
    <div className={`${classes.dashboard}`}>
      <SEO contentKey="admin" />
      {loaders?.editOrgLoader && <VSNLoader variant="transParent" />}
      {loaders?.editLocationLoader && <VSNLoader variant="transParent" />}
      {loaders?.editDepartmentLoader && <VSNLoader variant="transParent" />}
      <VSNImage
        src={shape2}
        className={`${classes.shape} ${classes.shape2}`}
        alt="shape2"
      />
      <VSNImage
        src={shape3}
        className={`${classes.shape} ${classes.shape3}`}
        alt="shape3"
      />
      <VSNImage
        src={shape4}
        className={`${classes.shape} ${classes.shape4}`}
        alt="shape4"
      />
      <VSNImage
        src={shape5}
        className={`${classes.shape} ${classes.shape5}`}
        alt="shape5"
      />

      <SummaryCard
        {...{
          platformAdimn: true,
          assessmentSummary,
          onFilters,
          tabs,
          activeTab,
          toggleModelShow,
          title: "Summary",
          btnAction: "createOrg",
          btnAction1: "createLocation",
          btnAction2: "createDepartment",
          btnTitle: "Create an Organization",
          btnTitle1: "Create an Location",
          btnTitle2: "Create an Department",
          summaryHeaders,
          onFilterChange,
          orgFilters,
          onCommonFilterFilterChange,
          commonFilter,
          handleTabChange,
          filters: { ...filters, activeType: filters?.keyword?.toLowerCase() },
          activeSummaryHeader: ({ tab }) =>
            activeTab === tab && !filters?.keyword?.toLowerCase()
              ? "active"
              : "",
        }}
      />

      <section className={classes.tabsMain}>
        <VSNTabs
          tabs={tabs}
          handleTabChange={handleTabChange}
          activeTab={activeTab}
        />
      </section>

      {modelState.editOrg && (
        <VSNModel
          open={modelState.editOrg}
          onClose={() => toggleModelShow("editOrg")}
          mobileDrawer
        >
          <CreateOrgModal mode="edit" toggleModelShow={toggleModelShow} />
        </VSNModel>
      )}
      {modelState.copyLink && (
        <VSNModel
          open={modelState.copyLink}
          onClose={() => toggleModelShow("copyLink")}
          mobileDrawer
        >
          <CopyLinkModal />
        </VSNModel>
      )}
      {modelState.createlocation && (
        <VSNModel
          open={modelState.createlocation}
          onClose={() => toggleModelShow("createlocation")}
          mobileDrawer
        >
          <CreateOrgModal toggleModelShow={toggleModelShow} />
        </VSNModel>
      )}
      {modelState.createDepartment && (
        <VSNModel
          open={modelState.createDepartment}
          onClose={() => toggleModelShow("createDepartment")}
          mobileDrawer
        >
          <CreateOrgModal toggleModelShow={toggleModelShow} />
        </VSNModel>
      )}

      {/* create location */}
      {modelState.createLocation && (
        <VSNModel
          open={modelState.createLocation}
          onClose={() => toggleModelShow("createLocation")}
          mobileDrawer
        >
          <CreateLocationModal toggleModelShow={toggleModelShow} />
        </VSNModel>
      )}
      {modelState.editLocation && (
        <VSNModel
          open={modelState.editLocation}
          onClose={() => toggleModelShow("editLocation")}
          mobileDrawer
        >
          <CreateLocationModal mode="edit" toggleModelShow={toggleModelShow} />
        </VSNModel>
      )}

      {/* create Department */}

      {modelState.createDepartment && (
        <VSNModel
          open={modelState.createDepartment}
          onClose={() => toggleModelShow("createDepartment")}
          mobileDrawer
        >
          <CreateDepartmentModal toggleModelShow={toggleModelShow} />
        </VSNModel>
      )}
      {modelState.editDepartment && (
        <VSNModel
          open={modelState.editDepartment}
          onClose={() => toggleModelShow("editDepartment")}
          mobileDrawer
        >
          <CreateDepartmentModal
            mode="edit"
            toggleModelShow={toggleModelShow}
          />
        </VSNModel>
      )}
    </div>
  );
};

export default Dashboard;
