import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import OtpInput from "react-otp-input";
import VSNButton from "../../shared/VSNButton";
import VSNLabel from "../../shared/VSNLabel";
import VSNTypography from "../../shared/VSNTypography";
import { ReactComponent as ClockIcon } from "../../assets/common/svg/clock.svg";
import VSNForm from "../../shared/VSNForm";
import { equal, length } from "../../utils/javascript";

const useStyles = makeStyles((theme) => ({
  titleHead: {
    "&.MuiTypography-root": {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "26px",
      color: "var(--blackGray)",
      maxWidth: 504,
      marginBottom: 96,
      paddingTop: 16,
    },
  },
  otpInput: {
    "& input": {
      display: "flex",
      justifyContent: "center",
      width: "90px !important",
      height: "40px",
      fontSize: "18px",
      color: "#202424",
      fontWeight: "700",
      borderWidth: "0 0 1px",
      borderStyle: "solid",
      borderBottomColor: "#CBCBCF",
      marginBottom: 10,
      [theme.breakpoints.down("sm")]: {
        width: "69px !important",
        lineHeight: "42px",
        fontSize: 15,
      },
      [theme.breakpoints.down("360")]: {
        width: "55px !important",
      },
    },
  },
  separator: {},
  resendOtp: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "23px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginBottom: 40,
    "& .MuiBox-root": {
      "& .MuiButtonBase-root": {
        marginBottom: 0,
        minWidth: "auto",
        padding: [[0]],
        background: "transparent",
        color: "#D3D3D3",
      },
    },
    "& p": {
      fontSize: 25,
      margin: [[0, 10, 0, 30]],
      fontWeight: 700,
      position: "relative",
      top: "-1px",
      color: "var(--primary)",
    },
    "& h6": {
      fontWeight: 500,
      fontSize: 14,
      marginBottom: 0,
      color: "var(--primary)",
    },
    "& h4": {
      fontSize: 18,
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
  },
  greyText: {
    color: "#D3D3D3",
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  nonGreyText: {
    color: "var(--primary) !important",
    cursor: "pointer",
  },
  resendOtpText: {
    marginTop: 50,
  },
  nextButton: {
    "&.MuiButtonBase-root.MuiButton-root": {
      margin: "0 auto 30px",
    },
  },
  otpMain: {
    "& > div": {
      justifyContent: "space-between",
      gap: "16px",
    },
  },
  otpSixInput: {
    "& input": {
      width: "40px !important",
      [theme.breakpoints.down("sm")]: {
        width: "33px !important",
      },
      [theme.breakpoints.down("360")]: {
        width: "25px !important",
      },
    },
  },
  otpSixMain: {
    "& > div": {
      justifyContent: "space-between",
      gap: 0,
    },
  },
  adminOTP: {
    "& > div": {
      [theme.breakpoints.down("450")]: {
        gap: 20,
      },
    },
    "& input": {
      [theme.breakpoints.down("400")]: {
        width: "45px !important",
      },
    },
  },
}));
const OTP = ({
  handleNext,
  code,
  handleChange,
  handleResend,
  seconds,
  className,
  btn,
  codeLength,
  adminClassName,
  loaders,
  isDisable,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.otpSend}>
      <Box align="left">
        <VSNLabel required label="Enter One-Time Password" />
      </Box>
      <VSNForm>
        <Box
          className={`${classes.otpMain} ${
            codeLength === 6 && classes.otpSixMain
          } ${adminClassName && classes[adminClassName]}`}
        >
          <OtpInput
            className={`${classes.otpInput} ${
              codeLength === 6 && classes.otpSixInput
            }`}
            value={code}
            onChange={handleChange}
            numInputs={codeLength}
            separator={<span className={classes.separator} />}
            isInputNum
            shouldAutoFocus
            focusStyle={{
              outline: "none",
            }}
          />
        </Box>
        <Box className={className} textAlign="center">
          <Box variant="h4" className={classes.resendOtp}>
            <Box>
              <VSNButton
                variant=""
                className={classNames({
                  [classes.resendOtpLine]: true,
                  [classes.nonGreyText]: seconds <= 0,
                })}
                disabled={seconds > 0}
                isLoading={loaders?.resendOTPLoader}
                handleClick={handleResend}
              >
                Resend OTP
              </VSNButton>
            </Box>
            <VSNTypography
              component="p"
              color={seconds > 0 ? "var(--primary)" : "var(--gray) !important"}
            >
              <ClockIcon />
            </VSNTypography>

            <VSNTypography
              variant="h6"
              component="h6"
              color={seconds > 0 ? "var(--primary)" : "var(--gray) !important"}
            >
              {" "}
              {`00:${equal(length(seconds), 1) ? `00` : seconds}`}
            </VSNTypography>
          </Box>
          <VSNButton
            type="submit"
            isLoading={loaders.OTPLoader || loaders.reportLoader}
            disabled={!equal(length(code), codeLength) || isDisable}
            handleClick={handleNext}
            className={classes.nextButton}
          >
            {btn || "Next"}
          </VSNButton>
        </Box>
      </VSNForm>
    </div>
  );
};
export default OTP;
