import { makeStyles } from "@mui/styles";
import bannerBg from "../../assets/landing/png/bannerBg.png";

export const WhoWeAreStyle = makeStyles((theme) => ({
  heroSection: {
    width: "100%",
    padding: "56px 48px",
    background: `url(${bannerBg})`,
    borderRadius: 24,
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    [theme.breakpoints.down("lg")]: {
      padding: "46px 38px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "36px 28px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "36px 15px 25px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      background: "linear-gradient(94.43deg, #FEFEFE 14.47%, #E6F1FA 105%)",
      bottom: 0,
      zIndex: -1,
      borderRadius: 24,
    },
  },
  lgHeading: {
    "&.MuiTypography-root": {
      fontSize: 64,
      lineHeight: "70px",
      color: "#2D2F39",
      [theme.breakpoints.down("xl")]: {
        fontSize: 58,
        lineHeight: "60px",
      },
      [theme.breakpoints.down("xlg")]: {
        fontSize: 50,
        lineHeight: "50px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 40,
        lineHeight: "40px",
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 34,
        lineHeight: "38px",
      },
      "& .orangeText": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
  },
  bannerBg: {
    position: "relative",
    display: "flex",
    margin: "76px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      margin: "76px 0 0 0",
    },
    "& .desc": {
      fontSize: 20,
      color: "var(--gray)",
      marginBottom: 32,
      marginTop: 24,
      [theme.breakpoints.down("lg")]: {
        fontSize: 18,
        marginBottom: 15,
        marginTop: 15,
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 16,
      },
      "& span": {
        color: "var(--primary)",
      },
    },
    "& .MuiButtonBase-root": {
      padding: "12px 32px",
      borderRadius: "10px",
      background:
        "linear-gradient(132.61deg, var(--primaryDarker) 32.09%, var(--primaryGradientLighter) 163.78%)",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        padding: "10px 22px",
      },
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 40,
      lineHeight: "46px",
      fontWeight: "bold",
      color: "#2D2F39",
      [theme.breakpoints.down("xl")]: {
        fontSize: 34,
        lineHeight: "40px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 28,
        lineHeight: "34px",
      },
      "& span": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
  },
  heroCapsule: {
    position: "relative",
  },
  heroCapsuleImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "0 -10px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "0 -5px",
    },
    "& .capsule-img": {
      width: "33.33%",
      "&:first-child": {
        marginTop: 50,
      },
      "&:last-child": {
        marginTop: 100,
      },
      "& img": {
        borderRadius: "500px",
        padding: "0 10px",
        [theme.breakpoints.down("sm")]: {
          padding: "0 5px",
        },
      },
    },
  },
  bannerShape1: {
    position: "absolute",
    right: "28%",
    top: "4%",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
    [theme.breakpoints.down("sm")]: {
      width: 40,
    },
  },
  bannerShape2: {
    position: "absolute",
    left: "26%",
    bottom: "24%",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      width: 40,
    },
    [theme.breakpoints.down("sm")]: {
      width: 30,
    },
  },
  bannerShape3: {
    position: "absolute",
    right: "25%",
    bottom: "8%",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
    [theme.breakpoints.down("sm")]: {
      width: 50,
    },
  },
  halfOrange: {
    position: "absolute",
    bottom: 0,
    left: "45%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("lg")]: {
      width: 120,
    },
    [theme.breakpoints.down("md")]: {
      width: 100,
    },
  },
  spacing: {
    padding: "80px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0",
    },
  },
  teamAboutImage: {
    borderRadius: "0 50% 0 50%",
    border: "15px solid #F0F9F2",
  },
  mdText: {
    "&.MuiTypography-root": {
      color: "var(--gray)",
      fontSize: 20,
      padding: [[15, 0, 20, 0]],
      fontWeight: "700",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        lineHeight: "22px",
        fontWeight: 500,
      },
      "& span": {
        color: "var(--primary)",
      },
    },
  },
  smText: {
    "&.MuiTypography-root": {
      fontSize: 50,
      lineHeight: "60px",
      fontWeight: 400,
      color: "#2D2F39",
      [theme.breakpoints.down("1651")]: {},
      [theme.breakpoints.down("lg")]: {
        maxWidth: 965,
        margin: "0 auto",
        fontSize: 35,
        lineHeight: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
        lineHeight: "36px",
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 22,
        lineHeight: "28px",
      },
      "& .orangeText": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
  },
  headingMd: {
    "&.MuiTypography-root": {
      fontWeight: 400,
      fontSize: 50,
      color: "var(--darkGray)",
      [theme.breakpoints.down("lg")]: {
        fontSize: 35,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
      },
      "& span": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
  },
  logoWrapper: {
    height: 285,
    width: "100%",
    maxWidth: 300,
    [theme.breakpoints.down("md")]: {
      height: 240,
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
    "& svg": {
      height: "100%",
      width: "100%",
    },
    "& ~ .MuiTypography-root": {
      color: "#86650A",
      fontSize: 24,
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      "& span": {
        color: "var(--primary)",
      },
    },
  },
  sectionShape: {
    position: "absolute",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  section1Bg1: {
    top: "10%",
    left: "10%",
    width: 60,
  },
  section1Bg2: {
    top: "15%",
    right: "20%",
    width: 60,
  },
  section1Bg3: {
    top: "25%",
    right: "10%",
    width: 100,
  },
  section1Bg4: {
    top: "45%",
    left: "10%",
    width: 40,
  },
  section1Bg5: {
    top: "50%",
    right: "8%",
    width: 100,
  },
  section1Bg6: {
    bottom: "30%",
    left: "8%",
    width: 100,
  },
  section2Bg1: {
    bottom: 0,
    right: "8%",
    width: 60,
  },
  missionQuote: {
    background: "linear-gradient(94.43deg, #FFF8F8 14.47%, #E6F1FA 105%)",
    padding: 24,
    borderRadius: 24,
    margin: [[150, 0]],
    position: "relative",
    [theme.breakpoints.down("md")]: {
      margin: [[100, 0]],
    },
    "& h5": {
      color: "#2D3652",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: 28,
      [theme.breakpoints.down("lg")]: {
        fontSize: 26,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
      },
    },
  },
  "@keyframes lineMove": {
    "0%": {
      strokeDashoffset: 0,
    },
    "100%": {
      strokeDashoffset: -200,
    },
  },
  topShape: {
    position: "absolute",
    left: "30%",
    bottom: "100%",
    marginBottom: 15,
    "& path": {
      animation: `$lineMove 2s linear infinite`,
    },
    [theme.breakpoints.down("md")]: {
      left: "50%",
      marginBottom: 0,
      height: 100,
    },
  },
  bottomShape: {
    position: "absolute",
    right: "22%",
    top: "100%",
    marginTop: 15,
    "& path": {
      animation: `$lineMove 2s linear infinite`,
    },
    [theme.breakpoints.down("md")]: {
      right: "50%",
      marginTop: 0,
      height: 100,
    },
  },
  whoWeAreCTA: {
    background:
      "linear-gradient(132.61deg, var(--primaryDarker) 32.09%, var(--primaryGradientLighter) 163.78%)",
    borderRadius: 24,
    padding: 70,
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      padding: 50,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 30,
    },
    [theme.breakpoints.down("xsm")]: {
      padding: 24,
    },
  },
  weAreList: {
    display: "flex",
    "& > h4": {
      flex: "none",
      marginRight: 30,
      position: "sticky",
      top: 100,
      alignSelf: "flex-start",
      [theme.breakpoints.down("md")]: {
        marginRight: 20,
      },
      [theme.breakpoints.down("sm")]: {
        marginRight: 10,
      },
    },
    "& .MuiListItem-root": {
      color: "var(--gray)",
      "&:not(:first-child)": {
        marginTop: 20,
      },
      "& p": {
        fontSize: 32,
        [theme.breakpoints.down("lg")]: {
          fontSize: 26,
        },
        [theme.breakpoints.down("md")]: {
          fontSize: 20,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
        "&:first-letter": {
          color: "var(--primary)",
          fontSize: 58,
          [theme.breakpoints.down("lg")]: {
            fontSize: 48,
          },
          [theme.breakpoints.down("md")]: {
            fontSize: 38,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 24,
          },
        },
        "& span": {
          color: "#2D3652",
          fontSize: 32,
          fontWeight: "bold",
          [theme.breakpoints.down("lg")]: {
            fontSize: 26,
          },
          [theme.breakpoints.down("md")]: {
            fontSize: 20,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 16,
          },
        },
      },
    },
  },
  section3Bg1: {
    right: "8%",
    top: "25%",
    width: 40,
  },
  section3Bg2: {
    left: "11%",
    top: "35%",
    width: 100,
  },
  section3Bg3: {
    right: "6%",
    bottom: "15%",
    width: 90,
  },
  section3Bg4: {
    left: "6%",
    bottom: 0,
    width: 60,
  },
  section4Bg1: {
    right: "6%",
    top: "15%",
    width: 100,
  },
  section4Bg2: {
    left: "12%",
    top: "28%",
    width: 40,
  },
  section4Bg3: {
    right: "12%",
    top: "48%",
    width: 100,
  },
  section4Bg4: {
    left: "12%",
    bottom: "28%",
    width: 60,
  },
  videoWrapper: {
    position: "relative",
    maxWidth: 930,
    margin: "40px auto 0",
    overflow: "hidden",
    borderRadius: 16,
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
    },
    "& img": {
      borderRadius: 16,
      height: 500,
      display: "block",
      width: "100%",
      objectFit: "cover",
      [theme.breakpoints.down("smd")]: {
        height: 400,
      },
      [theme.breakpoints.down("sm")]: {
        height: 300,
      },
    },
    "& svg": {
      position: "absolute",
      top: "50%",
      left: 0,
      right: 0,
      margin: "0 auto",
      color: "var(--white)",
      backgroundColor: "var(--primary)",
      borderRadius: "100%",
      cursor: "pointer",
      width: 70,
      height: 70,
      padding: 10,
      transform: "translateY(-50%)",
    },
  },
  folkBox: {
    "& p": {
      display: "flex",
      alignItems: "center",
      "& img": {
        maxHeight: 20,
        maxWidth: 50,
        marginLeft: 10,
        [theme.breakpoints.down("sm")]: {
          maxHeight: 17,
          maxWidth: 43,
        },
      },
    },
    "&:hover": {
      "& .front": {
        transform: "rotateY(-180deg)",
        WebkitTransformStyle: "preserve-3d",
        transformStyle: "preserve-3d",
      },
      "& .back": {
        transform: "rotateY(0deg)",
        WebkitTransformStyle: "preserve-3d",
        transformStyle: "preserve-3d",
        opacity: 1,
      },
    },
  },
  flipBox: {
    transformStyle: "preserve-3d",
    WebkitPerspective: 1000,
    perspective: 1000,
    "& .front": {
      WebkitBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      transition:
        "transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1)",
      "& > img": {
        borderRadius: 10,
      },
    },
    "& .back": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      bottom: 0,
      backgroundColor: "#F2E7F5",
      borderRadius: 5,
      padding: 15,
      marginBottom: 10,
      opacity: 0,
      transform: "rotateY(180deg)",
      WebkitTransformStyle: "preserve-3d",
      transformStyle: "preserve-3d",
      WebkitBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      transition:
        "all .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1)",
      "& p": {
        WebkitPerspective: "inherit",
        perspective: "inherit",
      },
      "& .MuiBox-root": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      },
    },
  },
  bottomFolks: {
    "& .swiper-wrapper": {
      alignItems: "flex-end",
    },
  },
  description: {
    "&.MuiTypography-root": {
      color: "var(--gray)",
      height: "fit-content",
      marginTop: "auto",
      fontSize: 16,
    },
  },
  advisors: {
    textAlign: "center",
    "& > img": {
      borderRadius: "100%",
      width: 200,
      height: 200,
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        width: 150,
        height: 150,
      },
    },
    "& p": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        maxHeight: 20,
        maxWidth: 50,
        marginLeft: 10,
        [theme.breakpoints.down("sm")]: {
          maxHeight: 17,
          maxWidth: 43,
        },
      },
    },
  },
}));
