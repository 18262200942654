import { ReactComponent as Bullet1 } from "../assets/privacypolicy/svg/Bullet1.svg";
import { ReactComponent as Bullet2 } from "../assets/privacypolicy/svg/Bullet2.svg";
import { ReactComponent as Bullet3 } from "../assets/privacypolicy/svg/Bullet3.svg";
import { ReactComponent as Bullet4 } from "../assets/privacypolicy/svg/Bullet4.svg";
import { ReactComponent as Bullet5 } from "../assets/privacypolicy/svg/Bullet5.svg";
import { ReactComponent as Bullet6 } from "../assets/privacypolicy/svg/Bullet6.svg";

export const privacyPolicyHeaderDescription = [
  {
    heading: "Privacy Policy",
    description: (
      <>
        This privacy policy (&quot;policy&quot;) explains how RedAzure
        (&quot;us&quot;, &quot;we&quot;, &quot;our&quot;) collects, uses, and
        protects the data you provide when you visit and use
        <a href="/">www.vasana.ai </a>(&quot;website&quot;,
        &quot;service&quot;). We reserve the right to change this policy at any
        time, and we will notify you of any changes. Please check this page
        regularly to ensure you are up to date with the latest information.
      </>
    ),
  },
];

export const privacyPolicyDescription = [
  {
    color: "#27AE60",
    title: "What User Data We Collect",
    description: [
      {
        mainPoint:
          "When you visit our website, we may collect the following data:",
        innerPoints: [
          <>
            <span>Personal Identification Information:</span> This includes your
            name, email address, contact information, and other personal details
            necessary for registration and communication.
          </>,
          <>
            <span>Health Information:</span> Information related to your mental
            health assessments and any other data you provide that may be
            considered sensitive health information.
          </>,
          <>
            <span>Technical Data:</span> Your IP address, browser type, device
            information, and other technical data collected through cookies and
            similar technologies.
          </>,
          <>
            <span>Behavioral Data:</span> Information about your interactions
            with our website, including pages visited, time spent on each page,
            and your preferences.
          </>,
        ],
      },
    ],
    hasPoint: true,
  },
  {
    color: "var(--primary)",
    title: "Why We Collect Your Data",
    description: [
      {
        mainPoint: "We are collecting your data for several reasons:",
        innerPoints: [
          <>
            <span>To Provide and Improve Services:</span> To understand your
            needs, personalize your experience, and improve our offerings,
            including tailored assessments and therapeutic content.
          </>,
          <>
            <span>To Communicate with You:</span> To send you information about
            updates, promotions, surveys, and other information relevant to your
            interests.
          </>,
          <>
            <span>To Conduct Research:</span> To invite you to participate in
            market research that helps us understand trends and improve our
            services.
          </>,
          <>
            <span>Legal Compliance: </span> To comply with applicable laws and
            regulations, including those related to data protection and mental
            health services.
          </>,
        ],
      },
    ],
    hasPoint: true,
  },
  {
    color: "#8B3F9E",
    title: "Safeguarding and Securing the Data",
    description: [
      "RedAzure is committed to securing your data and keeping it confidential. We have implemented robust security measures, including encryption, firewalls, and secure servers, to prevent unauthorized access, data breaches, and disclosure of the information we collect.",
    ],
    hasPoint: false,
  },
  {
    color: "#97C8E9",
    title: "Our Cookie Policy",
    description: [
      {
        mainPoint:
          "Our website uses cookies to enhance your experience. By using our site, you agree to the use of cookies that analyze web traffic and improve service personalization.",
        innerPoints: [
          <>
            <span>Cookie Usage:</span> Cookies are used to track your
            preferences and improve site functionality. They do not give us
            access to your computer or personal information beyond what you
            share with us.
          </>,
          <>
            <span>Disabling Cookies:</span> You can disable cookies through your
            browser settings, but this may affect your experience on our
            website.
          </>,
        ],
      },
    ],
    hasPoint: true,
  },
  {
    color: "#FFB142",
    title: "Links to Other Websites",
    description: [
      "Our website may contain links to external sites. We are not responsible for the privacy practices or content of these sites. We encourage you to review their privacy policies when you visit them.",
    ],
    hasPoint: false,
  },
  {
    color: "var(--primary)",
    title: "Restricting the Collection of Your Personal Data",
    description: [
      {
        mainPoint: "You can restrict data collection in the following ways:",
        innerPoints: [
          <>
            <span>Consent:</span> Opt-in or opt-out options are available when
            providing personal information. Ensure you check the appropriate
            boxes on our forms to indicate your preferences.
          </>,
          <>
            <span>Changing Preferences:</span> If you have previously consented
            to share your data, you can change your preferences by contacting us
            via email.
          </>,
          <>
            <span>Data Sharing:</span> We will not lease, sell, or distribute
            your personal information to third parties without your consent,
            except as required by law or for providing services you have
            requested.
          </>,
        ],
      },
    ],
    hasPoint: true,
  },
  {
    color: "#8B3F9E",
    title: "Data Retention and Access",
    description: [
      {
        innerPoints: [
          <>
            <span>Retention:</span> We retain your personal data only for as
            long as necessary to fulfill the purposes outlined in this policy
            unless a longer retention period is required or permitted by law.
          </>,
          <>
            <span>Access and Control:</span> You have the right to access,
            update, and delete your personal information. To exercise these
            rights, contact us at{" "}
            <a href="mailto:huma@vasana.ai">huma@vasana.ai</a>.
          </>,
        ],
      },
    ],
    hasPoint: true,
  },
  {
    color: "#27AE60",
    title: "Children's Privacy",
    description: [
      "Our services are not intended for individuals under 13 years of age. We do not knowingly collect personal information from children under 13. If you believe that a child has provided us with personal information, please contact us immediately.",
    ],
    hasPoint: false,
  },
  {
    color: "#97C8E9",
    title: "Contact Us",
    description: [
      <>
        If you have any questions or concerns about our privacy policy, please
        contact us at <a href="mailto:huma@vasana.ai">huma@vasana.ai</a>.
      </>,
    ],
    hasPoint: false,
  },
];
