import { Box } from "@mui/material";
import styled from "@emotion/styled";
import RotateLoader from "react-spinners/RotateLoader";
import classNames from "classnames";

const Loader = styled(Box)(({ theme }) => ({
  "&.fullPage": {
    marginTop: 0,
    top: 64,
    position: "fixed",
    right: 0,
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99,
  },
  "&.transParent": {
    background: "rgba(0,0,0,0.2)",
  },
  "&.inline": {
    marginTop: 0,
    height: "auto",
  },
}));

const VSNLoader = ({
  className,
  speedMultiplier = 1,
  size = 18,

  variant,
  ...props
}) => {
  return (
    <Loader
      className={classNames({
        fullPage: variant === "transParent" || variant === "fullPage",
        transParent: variant === "transParent",
        inline: variant === "inline",
      })}
      {...props}
    >
      <RotateLoader
        color="var(--primary)"
        size={size}
        speedMultiplier={speedMultiplier}
        {...props}
      />
    </Loader>
  );
};

export default VSNLoader;
