import { makeStyles } from "@mui/styles";
import bannerBg from "../../assets/landing/png/bannerBg.webp";

export const ContactStyle = makeStyles((theme) => ({
  lgHeading: {
    "&.MuiTypography-root": {
      fontSize: 64,
      color: "#2D2F39",
      [theme.breakpoints.down("xl")]: {
        fontSize: 50,
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 40,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
      "& .orangeText": {
        color: "var(--primary)",
        fontWeight: 700,
        display: "inline-block",
      },
    },
  },
  bannerBg: {
    position: "relative",
    display: "flex",
    margin: "76px 24px 0 24px",
    "& .desc": {
      fontSize: 20,
      color: "var(--gray)",
      marginBottom: 32,
      marginTop: 24,
      [theme.breakpoints.down("xsm")]: {
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 10,
        marginTop: 15,
      },
      "& span": {
        color: "var(--primary)",
      },
    },
    "& .MuiButtonBase-root": {
      padding: "12px 32px",
      borderRadius: "10px",
      background:
        "linear-gradient(132.61deg, var(--primaryDarker) 32.09%, var(--primaryGradientLighter) 163.78%)",
      fontWeight: 500,
    },
  },
  heroSection: {
    width: "100%",
    padding: "130px 48px",
    background: `url(${bannerBg}) no-repeat`,
    backgroundSize: "cover",
    borderRadius: 24,
    position: "relative",
    minHeight: 550,
    [theme.breakpoints.down("lg")]: {
      padding: "100px 28px",
      minHeight: 500,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "60px 28px",
      minHeight: 450,
    },
    "& .leftText": {
      maxWidth: "847px",
      width: "100%",
      marginLeft: "auto",
    },
  },
  contactFormBox: {
    boxShadow: "0px 0px 24px 0px #00000014",
    borderRadius: 40,
    padding: 56,
    marginTop: -250,
    position: "relative",
    zIndex: 1,
    backgroundColor: "var(--white)",
    [theme.breakpoints.down("md")]: {
      padding: 40,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 30,
    },
  },
  required: {
    color: "#FF5656",
  },
  customSelect: {
    "& .MuiSvgIcon-root": {
      border: "1px solid #ddd",
      borderRadius: "100%",
      color: "var(--primary)",
      width: 30,
      height: 30,
      padding: 4,
    },
  },
  sectionShape: {
    position: "absolute",
  },
  section1Bg1: {
    left: "8%",
    bottom: "20%",
    width: 60,
  },
  formGrid: {
    "& .react-tel-input": {
      "& .form-control": {
        fontSize: 18,
        fontWeight: 700,
        color: "#202024",
      },
    },
  },
}));
