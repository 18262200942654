import React from "react";
import { Box, Checkbox, Grid, Stack } from "@mui/material";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { PhysicalOnePagerResultStyle } from "./PhysicalOnePagerResultStyle";
import VSNTypography from "../../../shared/VSNTypography";
import VSNLoader from "../../../shared/VSNLoader";
import VSNBreadcrumbs from "../../../shared/VSNBreadcrumbs";
import onePagerResultContainer from "../../../container/onePager.container";
import { FrontLineLogo, StarIcon } from "../../../shared/icon";
import VSNButton from "../../../shared/VSNButton";

const arrayOCheckBoxLeft = [
  {
    checked: true,
    description:
      "Wearing personal protective equipment (PPE) and self-contained breathing apparatus (SCBA) while performing firefighting tasks (e.g., hose line operations, extensive crawling, lifting and carrying heavy objects, ventilating roofs or walls using power or hand tools, forcible entry), rescue operations, and other emergency response actions under stressful conditions, including working in extremely hot or cold environments for prolonged time periods.=",
  },

  {
    checked: false,
    description:
      "Wearing an SCBA, which includes a demand-valve-type positive-pressure facepiece or HEPA filter mask, which requires the ability to tolerate increased respiratory workloads.",
  },

  {
    checked: false,
    description:
      "Exposure to toxic fumes, irritants, particulates, biological (i.e., infectious) and nonbiological hazards, or heated gases, despite the use of PPE and SCBA.",
  },

  {
    checked: true,
    description:
      "Climbing at least six flights of stairs or walking a similarly strenuous distance and incline in jurisdictions without tall buildings while wearing PPE and SCBA, commonly weighing 40–50 lb (18–23 kg) and carrying equipment/tools weighing an additional 20–40 lb (9–18 kg).",
  },

  {
    checked: false,
    description:
      "Wearing PPE and SCBA that is encapsulating and insulated, which will result in significant fluid loss that frequently progresses to clinical dehydration and can elevate core temperature to levels exceeding 102.2°F (39°C).",
  },

  {
    checked: true,
    description:
      "Working alone while wearing PPE and SCBA, searching, finding, and rescue-dragging or carrying victims ranging from newborns to adults weighing over 165 lb (75 kg) to safety despite hazardous conditions and low visibility.",
  },

  {
    checked: false,
    description:
      "While wearing PPE and SCBA, advancing water-filled hose lines up to 1 3⁄4 in. (45 mm) in diameter from fire apparatus to occupancy [approximately 150 ft (50 m)], which can involve negotiating multiple flights of stairs, ladders, and other obstacles.",
  },
];
const arrayOCheckBoxRight = [
  {
    checked: false,
    description:
      "While wearing PPE and SCBA, climbing ladders, operating from heights, walking or crawling in the dark along narrow and uneven surfaces that might be wet or icy, and operating in proximity to electrical power lines or other hazards.",
  },

  {
    checked: false,
    description:
      "Unpredictable, prolonged periods of extreme physical exertion as required by emergency operations without benefit of a warm- up period, scheduled rest periods, meals, access to medication(s), or hydration.",
  },

  {
    checked: false,
    description:
      "Operating fire apparatus or other vehicles in an emergency mode with emergency lights and sirens. ",
  },

  {
    checked: false,
    description:
      "Critical, time-sensitive, complex problem solving during physical exertion in stressful, hazardous environments, including hot, dark, tightly enclosed spaces, that is further aggravated by fatigue, flashing lights, sirens, and other distractions.",
  },

  {
    checked: false,
    description:
      "Ability to communicate (i.e., give and comprehend verbal orders) while wearing PPE and SCBA under conditions of high background noise, poor visibility, and drenching from hose lines or fixed protection systems (e.g., sprinklers).",
  },

  {
    checked: false,
    description:
      "Functioning as an integral component of a team, where sudden incapacitation of a member can result in mission failure or in risk of injury or death to members of the public or other team members.",
  },

  {
    checked: false,
    description:
      "Working in shifts, including during night time, that can extend beyond 12 hours.",
  },
];

export default function MRFDashboardResult({
  endPointToCall,
  showBreadCrumbs = true,
  breadCrumbAndButton,
  releaseButton,
}) {
  const classes = PhysicalOnePagerResultStyle();
  const { onePagerAssessmentResult, loader, currentAssessment } =
    onePagerResultContainer({ endPointToCall });

  const breadcrumbsRoutes = [
    {
      path: "/assessments",
      label: "Assessments",
    },
    {
      path: `/assessments/${currentAssessment?.assessment_id}`,
      label: `Medical Recommendation Form`,
    },
  ];

  // if (loader?.assessmentResult || !onePagerAssessmentResult) {
  //   return <VSNLoader variant="fullPage" />;
  // }

  return (
    <div className={classes.mainLayout}>
      {showBreadCrumbs ? (
        <Box mt={2.5} mb={1.8}>
          <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
        </Box>
      ) : (
        <Box className={classes.headingButton}>
          <Box>{breadCrumbAndButton}</Box>
          <Box>{onePagerAssessmentResult?.is_locked ? releaseButton : ""}</Box>
        </Box>
      )}

      <Stack
        direction={{ xsm: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        pb={0.5}
      >
        <VSNTypography
          fontSize={{ xsm: 24, sm: 34 }}
          sx={{ fontWeight: 700 }}
          mb={1}
        >
          Medical Recommendation Form
        </VSNTypography>
        <VSNButton className={classes.PrintButton}>
          <Stack direction="row" alignItems="center" gap={1}>
            <PrintOutlinedIcon />
            Print Medical Recommendations
          </Stack>
        </VSNButton>
      </Stack>

      <Grid item xl={6} lg={6} xs={12}>
        <Stack className={classes.assessmentReportCard}>
          <VSNTypography
            variant="h3"
            component="h3"
            className={classes.mdHeadingprimary}
            mb={1}
            sx={{ fontSize: "20px" }}
          >
            Medical Recommendation Form
          </VSNTypography>
          <VSNTypography
            component="p"
            className={classes.desc}
            fontSize={{ xsm: 16, sm: 16 }}
          >
            You are recommended for full operational suppression duty without
            restrictions for 365 days.
          </VSNTypography>

          <Box py={1} display="flex">
            <Box sx={{ height: 12, width: 12 }} mr={2} mt={0.5}>
              <StarIcon />
            </Box>
            <VSNTypography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                color: "#0A0A0B",
                lineHeight: "24px",
              }}
            >
              No medical condition was found to be present during the evaluation
              that would medically limit the above named individual from
              performing the essential job tasks listed in NFPA Chapter 5.1.1 -
              5.1.3. The medical recommendation is for full duty without
              restrictions
            </VSNTypography>
          </Box>

          <VSNTypography component="p" className={classes.desc} pt={1}>
            Provider Name: Russ Burnham, APA-C
          </VSNTypography>
        </Stack>
      </Grid>

      <Stack
        className={classes.assessmentReportCard}
        position="relative"
        overflow="hidden"
        mt="25px"
      >
        <VSNTypography
          variant="h3"
          component="h3"
          className={classes.mdHeadingprimary}
          mb={1}
          sx={{ fontSize: "20px" }}
        >
          The evaluation performed revealed specific limitations in performing
          the essential job tasks listed in NFPA 1582 Chapter 5.1.1-5.1.3. The
          medical recommendation is therefore limited from performing the
          following essential job tasks
        </VSNTypography>
        <Stack
          direction={{ sm: "row" }}
          alignItems="center"
          spacing={4}
          mt={1.75}
        >
          <Stack
            direction={{ md: "row" }}
            sx={{
              "&.MuiStack-root": {
                position: "relative",
                gap: "0 64px",
                "&::after": {
                  content: { md: "''" },
                  position: "absolute",
                  top: 0,
                  left: "50% ",
                  height: "100%",
                  width: "1px",
                  backgroundColor: "#DAB9E2",
                },
              },
            }}
            position="relative"
          >
            <Stack
              sx={{
                "&.MuiStack-root": {
                  width: { xs: "100%", lg: "calc(50% - 32px)" },
                },
              }}
            >
              {arrayOCheckBoxLeft.map(({ checked, description }, index) => {
                const isLastElement = index === arrayOCheckBoxRight.length - 1;

                return (
                  <Stack
                    direction="row"
                    alignItems="start"
                    gap={1}
                    key={index}
                    sx={{
                      borderBottom: isLastElement
                        ? "none"
                        : "1px solid #DAB9E2",
                      height: "fit-content",
                    }}
                    padding={3}
                  >
                    <Checkbox
                      checked={checked}
                      sx={{
                        padding: 0,
                        paddingTop: "2.5px",
                        borderRadius: 4,
                        "&.Mui-checked": {
                          color: "var(--primary)",
                        },
                        "&.MuiCheckbox-root": {
                          color: "var(--primary)",
                        },
                      }}
                    />
                    <VSNTypography
                      fontSize={{ sm: "16px", smd: "20px" }}
                      sx={{ color: "var(--gray)", fontWeight: 400 }}
                    >
                      {description}
                    </VSNTypography>
                  </Stack>
                );
              })}
            </Stack>
            <Stack
              sx={{
                "&.MuiStack-root": {
                  width: { xs: "100%", lg: "calc(50% - 32px)" },
                },
              }}
            >
              {arrayOCheckBoxRight.map(({ checked, description }, index) => {
                const isLastElement = index === arrayOCheckBoxRight.length - 1;

                return (
                  <Stack
                    direction="row"
                    alignItems="start"
                    gap={1}
                    key={index}
                    sx={{
                      borderBottom: isLastElement
                        ? "none"
                        : "1px solid #DAB9E2",
                      height: "fit-content",
                    }}
                    padding={3}
                  >
                    <Checkbox
                      checked={checked}
                      sx={{
                        padding: 0,
                        paddingTop: "2.5px",
                        borderRadius: 4,
                        "&.Mui-checked": {
                          color: "var(--primary)",
                        },
                        "&.MuiCheckbox-root": {
                          color: "var(--primary)",
                        },
                      }}
                    />
                    <VSNTypography
                      fontSize={{ sm: "16px", smd: "20px" }}
                      sx={{ color: "var(--gray)", fontWeight: 400 }}
                    >
                      {description}
                    </VSNTypography>
                  </Stack>
                );
              })}
            </Stack>
            <Box position="absolute" sx={{ bottom: 0, right: 0 }}>
              <VSNTypography sx={{ textAlign: "end", paddingBottom: "6px" }}>
                Powered by
              </VSNTypography>
              <FrontLineLogo />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
