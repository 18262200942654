import { useDispatch, useSelector } from "react-redux";
import { LOADING_CHANGE, SET_API_DATA, SET_ERROR } from "../redux/constants";
import { checkValid } from "../shared/validation";
import { ApiContainer } from "../utils/api";
import { clearFormData } from "../shared/CustomFunc";
import { equal, formDataConvert, length, values } from "../utils/javascript";
import { EditOrgAttribute } from "../description/orgDashboard.description";
import ApiCalls from "../utils/apiCall";

export const editOrgContainer = ({ toggleModelShow }) => {
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const { getRoleInfo } = ApiCalls();
  const formPath = { parent: "organizationInfo" };
  const { form } = useSelector((state) => state);
  const organizationInfo = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const orgToEdit = useSelector((state) => state.api?.orgToEdit) ?? {};

  const organizationSummary = useSelector(
    (state) => state?.api?.organizationSummary
  );

  const apiCall = async () => {
    dispatch({
      type: LOADING_CHANGE,
      payload: { organizationInfo: true },
    });
    const { name, logo } = organizationInfo;
    const dataToSend = formDataConvert({
      name,
      address_line_1: organizationInfo?.address_line_1,
      address_line_2: organizationInfo?.address_line_2,
      city: organizationInfo?.city,
      zip_code: organizationInfo?.zip_code,
    });
    if (logo)
      dataToSend.append(
        "logo",
        logo?.photo_file || (typeof logo === "string" && logo)
      );

    const data = await api({
      method: "PUT",
      endPoint: `organization/${orgToEdit?.original_id}`,
      data: dataToSend,
      showToastMessage: true,
      urlencoded: false,
      needLoader: true,
      parent: "organizationInfo",
      header: { "Content-Type": "multipart/form-data" },
    });

    if (data?.status) {
      dispatch(clearFormData(formPath.parent));
      dispatch({ type: LOADING_CHANGE, payload: { organizationInfo: false } });
      dispatch({
        type: SET_API_DATA,
        payload: {
          organizationSummary: {
            ...organizationSummary,
            organization_name: data.data.organization_name,
          },
        },
      });
    }
    dispatch(toggleModelShow("editOrg"));
    dispatch(getRoleInfo({ needLoader: true, parent: "getRoleInfo" }));
    dispatch({
      type: SET_API_DATA,
      payload: {
        organizationInfo: data?.data,
      },
    });
  };

  const editOrganization = (e) => {
    e.preventDefault();
    let errors = {};

    EditOrgAttribute?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value: organizationInfo?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [formPath.parent]: { ...errors },
            },
          },
        });
      });
    });
    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      const noChanges = equal(
        JSON.stringify(organizationInfo),
        JSON.stringify(orgToEdit)
      );
      if (!noChanges) {
        apiCall();
      }
    }
  };

  return {
    editOrganization,
    orgToEdit,
    EditOrgAttribute,
  };
};
