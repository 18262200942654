import { makeStyles } from "@mui/styles";
import BgImg from "../../assets/quickView/png/explore-bg.png";
import BgPattern from "../../assets/common/png/background-pattern.png";
import arrow from "../../assets/quickView/svg/swiper-arrow.svg";
import bgFlower from "../../assets/Teams/png/team-bg-banner.png";

export const VasanaTypeResultStyle = makeStyles((theme) => ({
  mainLayout: {
    overflow: "hidden",
    padding: [[30, 30, 45, 30]],
    position: "relative",
    height: "100%",
    zIndex: 2,
    background:
      "linear-gradient(92deg, #F6FCFF 7.06%, rgba(255, 244, 238, 0.50) 86.87%)",
    backgroundSize: "cover",
    minHeight: "calc(100vh - 105px)",
    "&:before": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      top: 0,
      background: `url(${bgFlower})`,
      zIndex: -1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      minHeight: 310,
      [theme.breakpoints.down("sm")]: {
        minHeight: 140,
        backgroundSize: "cover",
        backgroundPosition: "0 -10px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[30, 16, 42, 16]],
    },
  },
  firtBox: {
    background: "#FCFCFD",
    padding: [25],
    textAlign: "left",
    position: "relative",
    border: "1px solid #fff",
    boxShadow: "0px 7.798px 17.329px 0px rgba(81, 125, 132, 0.07)",
    [theme.breakpoints.down("md")]: {
      padding: [[16, 16, 25, 16]],
    },
    "& h4": {
      color: "#3A3A40",
      lineHeight: "30px",
      fontSize: 34,
      fontWeight: 700,
      marginRight: 10,
      [theme.breakpoints.down("lg")]: {
        fontSize: 28,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
    },
    "& h6": {
      fontSize: 16,
      lineHeight: "20px",
      color: "var(--blackGray)",
      marginBottom: 18,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
        lineHeight: "18px",
        marginBottom: 13,
      },
    },
  },
  vasanaTypeImage: {
    marginLeft: 10,
    display: "inline-block",
    verticalAlign: "bottom",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8,
    },
    "& img": {
      width: "76px",
      height: "44px",
      objectFit: "contain",
      objectPosition: "bottom",
      verticalAlign: "sub",
      [theme.breakpoints.down("md")]: {
        verticalAlign: "bottom",
      },
    },
  },
  shape: {
    position: "absolute",
    lineHeight: 0,
  },
  shapeOne: {
    top: "92px",
    left: "20px",

    [theme.breakpoints.down("md")]: {
      top: "80px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "88px",
    },
  },
  shapeFive: {
    bottom: "11px",
    right: "-13px",
    zIndex: -1,
  },
  shapeThree: {
    top: "-42px",
    right: "30%",
  },
  shapeFour: {
    top: "170px",
    left: "-10px",
    [theme.breakpoints.down("md")]: {
      top: "190px",
    },
  },
  shapeTwo: {
    top: "150px",
    right: "40%",
    [theme.breakpoints.down("md")]: {
      top: "115px",
      right: "50px",
    },
  },
  downloadReportBox: {
    position: "relative",
    border: "0",
    padding: [[20, 20, 49, 20]],
    "& h5": {
      fontSize: 20,
      color: "var(--blackGray)",
      lineHeight: "26px",
      marginBottom: 51,
      textAlign: "left",
    },
    "& button": {
      padding: [[12, 16]],
      marginBottom: 0,
    },
  },
  shapeSix: {
    top: "-12%",
    right: "-5%",
  },
  shapeSeven: {
    left: "-8%",
    bottom: "-1%",
  },
  personalitySlider: {
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
    "& h5": {
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: 700,
      color: "#4F4F4F",
      marginBottom: 8,

      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        lineHeight: "23px",
      },
    },
  },
  personalityBoxes: {
    padding: [[0, 17]],
    boxShadow: "none",
    border: 0,
    position: "relative",
    height: "100%",
  },
  activeCard: {
    "&:after": {
      display: "none",
    },
  },
  personalityMain: {
    boxShadow: " 0px 7.798px 17.329px 0px rgba(81, 125, 132, 0.07)",
    borderRadius: 10,
    background: "var(--white)",
    "& .swiper-wrapper": {
      "& .swiper-slide": {
        "&:after": {
          position: "absolute",
          content: "''",
          top: 0,
          bottom: 0,
          right: -10,
          width: 2,
          background:
            "linear-gradient(180deg, rgba(85, 95, 221, 0.00) 0%, rgba(85, 95, 221, 0.15) 48.44%, rgba(85, 95, 221, 0.00) 100%)",
        },
        "&:last-child": {
          "&:after": {
            display: "none",
          },
        },
      },
    },
    "& .swiper-button-prev, .swiper-button-next": {
      "&:after": {
        background: `url(${arrow})`,
        fontSize: 0,
        height: 27,
        width: 27,
        backgroundSize: "contain",
      },
    },
    "& .swiper-button-next": {
      transform: "rotate(180deg)",
    },
  },
  greenFlower: {
    position: "absolute",
    top: 20,
    right: "40%",
  },
  halfRound: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  personalityBoxMain: {
    "& h5": {
      fontSize: 18,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 16,
      textAlign: "left",
      color: "var(--blackGray)",
      "& img, .icon-wrapper": {
        marginRight: 10,
        objectFit: "none",
        height: 32,
        width: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& p": {
      fontSize: 16,
      color: "var(--gray)",
      lineHeight: "21px",
    },
  },
  videosBox: {
    padding: [[16, 18, 24]],
    border: "none",
    boxShadow: "0px 7.798px 17.329px 0px rgba(81, 125, 132, 0.07)",
    height: "100%",
  },
  video: {
    width: "100%",
    height: 150,
    position: "relative",
  },
  videoImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: 8,
  },
  combinationHeading: {
    "& h5": {
      fontSize: 20,
      fontWeight: 700,
      textAlign: "left",
      lineHeight: "26px",
      marginLeft: 5,
      color: "#4F4F4F",
      marginBottom: 5,
    },
    "& p": {
      fontSize: 16,
      lineHeight: "21px",
      color: "var(--gray)",
      textAlign: "left",
      marginLeft: 5,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
        lineHeight: "18px",
      },
    },
    "& img": {
      marginRight: 10,
      marginLeft: 10,
    },
  },
  downloadBtn: {
    "&.MuiButton-root": {
      padding: [[10, 16]],
      lineHeight: "14px",
      height: "max-content",
      whiteSpace: "nowrap",
      width: "fit-content",
      "& .content": {
        fontWeight: 500,
        fontSize: 14,
      },
    },
  },
  textBtn: {
    "&.MuiButton-root": {
      padding: 0,
      lineHeight: "16px",
      height: "max-content",
      background: "transparent",
      color: "var(--primary)",
      "& .content": {
        fontWeight: 500,
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
      },
      "&:hover": {
        background: "transparent",
      },
    },
  },
  quickAssessmentCard: {
    padding: [[20, 14]],
    background: "linear-gradient(180deg, #FEFEFE 17.02%, #E6F1FA 124.07%)",
    marginBottom: 15,
    borderRadius: 5,
    border: "1px solid #EAEDF0",
    position: "relative",
    "& .cardHeading": {
      fontWeight: 700,
      margin: "2px 0",
    },
    "& .imgLineWrapper": {
      width: "100%",
      "& p": {
        display: "inline",
        fontWeight: 700,
        verticalAlign: "super",
        marginRight: "0px  !important",
      },
    },
  },
  quickSimpleCard: {
    background: "var(--white)",
  },
  combinationMain: {
    border: "0",
    padding: [[26, 16, 13, 16]],
    zIndex: 5,
    boxShadow: "0px 7.798px 17.329px 0px rgba(81, 125, 132, 0.07)",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      padding: [[16, 16, 5, 16]],
    },
  },
  personalityRange: {
    padding: [[12, 24]],
    borderRadius: 10,
    transition: "all 0.5s",
    border: "1px solid rgba(79, 79, 79, 0.10)",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 7px 4px 0px rgba(171, 148, 177, 0.10)",
      transition: "all 0.5s",
      "& .MuiBox-root": {
        "&  .MuiCircularProgress-root .MuiCircularProgress-svg": {
          boxShadow: "0px 0px 0px 4px rgb(255 255 255 / 100%) inset",
        },
        "& .MuiBox-root .MuiBox-root": {
          background: "var(--white)",
        },
      },
    },
    "& sapn": {
      lineHeight: 0,
    },
    [theme.breakpoints.down("lg")]: {
      padding: 12,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 6,
    },
    "& h4": {
      flex: "none",
      minWidth: 73,
      textAlign: "left",
      "& img": {
        height: 45,
        width: "auto",
      },
    },
    "& p": {
      fontSize: 16,
      lineHeight: "18px",
      color: "var(--gray)",
      marginLeft: 6,
      textAlign: "left",
    },
    "&.saPer": {
      "&:hover": {
        borderColor: "#8304A3",
        background:
          "linear-gradient(92deg, rgba(242, 231, 245, 0.50) 1.56%, rgba(242, 231, 245, 0.00) 77.91%)",
        "& p": {
          color: "#8B3F9E",
        },
      },
    },
    "&.rePer": {
      "&:hover": {
        borderColor: "#3498DB",
        background:
          "linear-gradient(92deg, rgba(227, 241, 249, 0.50) 1.56%, rgba(227, 241, 249, 0.00) 77.91%)",
        "& p": {
          color: "#3498DB",
        },
      },
    },
    "&.gaPer": {
      "&:hover": {
        borderColor: "#FFB142",
        background:
          "linear-gradient(92deg, rgba(255, 224, 180, 0.20) 1.56%, rgba(255, 224, 180, 0.00) 77.91%)",
        "& p": {
          color: "#FFB142",
        },
      },
    },
    "&.maPer": {
      "&:hover": {
        borderColor: "#56A06F",
        background:
          "linear-gradient(92deg, rgba(200, 233, 211, 0.25) 1.56%, rgba(200, 233, 211, 0.00) 77.91%)",
        "& p": {
          color: "#56A06F",
        },
      },
    },
  },
  personalityImg1: {
    right: "-18%",
    bottom: "19%",
    zIndex: -1,
    lineHeight: 0,
    opacity: "60%",
  },
  personalityImg2: {
    right: "-32%",
    top: "20%",
    zIndex: -1,
    lineHeight: 0,
  },
  personalityImg3: {
    right: "-19%",
    top: "26%",
    lineHeight: 0,
    zIndex: -1,
    "& svg": {
      opacity: "30%",
    },
  },
  personalityImg4: {
    right: "-42%",
    top: "28%",
    zIndex: -1,
    lineHeight: 0,
  },
  explore: {
    border: 0,
    backgroundImage: `url(${BgImg})`,
    padding: [[103, 37, 43, 37]],
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    "& img": {
      marginBottom: 38,
    },
    "& p": {
      fontSize: 16,
      color: "var(--gray)",
      lineHeight: "21px",
      marginBottom: 24,
      fontWeight: 700,
    },
    "& a": {
      color: "#E97738",
      fontWeight: 700,
      "& svg": {
        fontSize: 18,
        verticalAlign: "sub",
      },
    },
  },
  bg1: {
    top: "33%",
    left: 0,
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bg2: {
    top: "28%",
    right: "32%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bg3: {
    top: "-1%",
    left: "0%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bg4: {
    top: "-1%",
    right: "0%",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bg5: {
    bottom: "14%",
    right: "0%",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& svg": {
      width: 150,
      height: 150,
    },
  },
  downloadButton: {
    "&.MuiButtonBase-root": {
      padding: [[12, 16]],
      borderRadius: 10,
      minWidth: 200,
      [theme.breakpoints.down("lg")]: {
        fontSize: 16,
        padding: [[10, 16]],
      },
    },
  },
  description: {
    padding: [[20]],
    "& .MuiTypography-root": {
      padding: [[3, 0]],
    },
  },
  yourPersonalitySliderMain: {
    "& .swiper": {
      padding: [[36, 27, 26]],
      [theme.breakpoints.down("sm")]: {
        padding: [[20, 20, 30]],
      },
      "& .swiper-slide": {
        height: "auto",
      },
      "& .swiper-pagination": {
        bottom: 0,
        "& .swiper-pagination-bullet": {
          background: "#DFE1F84D",
          opacity: 1,
          width: 24,
          height: 4,
          transition: "all 0.4s ease-in-out",
          borderRadius: 4,
        },
        "& .swiper-pagination-bullet-active": {
          background: "var(--primary)",
          width: 11,
        },
      },
    },
  },
  videoSlider: {
    "& .swiper": {
      padding: [[0, 4]],
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: 700,
    },
  },
  contentDescription: {
    textAlign: "left",
    marginTop: 16,
    "& span": {
      fontSize: 12,
      color: "var(--gray)",
      fontWeight: 500,
      lineHeight: "15px",
    },
  },
  desc: {
    "&.MuiTypography-root": {
      fontSize: 14,
      color: "var(--gray)",
      lineHeight: "20px",
    },
  },
  coachInformation: {
    display: "flex",
    "& img": {
      flex: "none",
      marginRight: 15,
      borderRadius: "100%",
    },
    "& p": {
      color: "#A5A9BD",
      fontSize: 12,
      margin: 0,
      lineHeight: "15px",
      fontWeight: 500,
    },
  },
  coachName: {
    "&.MuiTypography-root": {
      fontSize: 14,
      color: "var(--blackGray)",
      lineHeight: "18px",
      margin: [[3, 0]],
    },
  },
  uploadInfo: {
    "&.MuiTypography-root": {
      color: "#808080",
    },
  },
  views: {
    display: "inline-block",
    color: "var(--gray)",
    paddingLeft: 0,
    "& li": {
      paddingLeft: 16,
      fontSize: 12,
      listStyle: "none",
      position: "relative",
      fontWeight: 500,
      "&:after": {
        position: "absolute",
        width: 4,
        height: 4,
        background: "var(--gray)",
        borderRadius: "100%",
        content: "''",
        left: "6px",
        top: "7px",
      },
    },
  },
  quickViewContent2: {
    "&.MuiGrid-item": {
      [theme.breakpoints.down("md")]: {
        paddingTop: "0px !important",
      },
    },
  },
  marketInfo: {
    "&.MuiTypography-root": {
      fontSize: 16,
      color: "var(--blackGray)",
      lineHeight: "21px",
      [theme.breakpoints.down("lg")]: {
        marginBottom: 20,
      },
    },
  },
  marketTends: {
    "&.MuiTypography-root": {
      color: "#86650A",
      fontWeight: "700",
    },
  },

  vasanatype: {
    marginBottom: 37,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 20,
    },
    "& h4": {
      display: "inline-block",
      marginRight: 0,
    },
  },
  range: {
    lineHeight: "0",
    marginRight: 32,
    [theme.breakpoints.down("md")]: {
      marginRight: 16,
    },
  },
  lgHeading: {
    "&.MuiTypography-root": {
      fontSize: 26,
      fontWeight: "700",
      color: "#4F4F4F",
      lineHeight: "30px",
      [theme.breakpoints.down("md")]: {
        fontSize: 22,
        lineHeight: "24px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        lineHeight: "22px",
      },
    },
  },
  naturalViewPointsBox: {
    padding: 16,
    textAlign: "left",
    boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.1)",
    border: 0,
    borderRadius: 8,
    height: "100%",
    marginBottom: 8,
    "& h5": {
      fontSize: 16,
      fontWeight: "700",
      color: "var(--blackGray)",
      lineHeight: "21px",
      marginBottom: 4,
    },
    "& p": {
      fontSize: 14,
      lineHeight: "18px",
      color: "var(--gray)",
    },
  },
  naturalViewpointsBoxShpae: {
    right: 0,
    bottom: 0,
  },
  naturalViewSlider: {
    padding: 16,
    boxShadow: " 0px 7.798px 17.329px 0px rgba(81, 125, 132, 0.07)",
    border: "1px solid #fff",
    backgroundColor: "var(--white)",
    borderRadius: 10,
    "& .swiper": {
      "& .swiper-slide": {
        height: "auto",
      },
      "& .swiper-pagination": {
        bottom: 0,
        "& .swiper-pagination-bullet": {
          background: "var(--primaryLighter)",
          opacity: 3,
        },
        "& .swiper-pagination-bullet-active": {
          background: "var(--primary)",
        },
      },
    },
  },
  naturalCardBgImage1: {
    bottom: -23,
    right: -23,
  },
  naturalCardBgImage2: {
    bottom: -8,
    right: -23,
  },
  naturalCardBgImage3: {
    bottom: -23,
    right: -52,
  },
  naturalCardBgImage4: {
    bottom: -47,
    right: -19,
  },
  diffrentAspectHeading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: "700",
      color: "#4F4F4F",
      lineHeight: "26px",
      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        lineHeight: "23px",
      },
    },
  },
  diffrentPersonalityBox: {
    padding: [[0, 24, 24, 24]],
    border: 0,
    backgroundImage: `url(${BgPattern})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
    boxShadow:
      "0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      padding: [[24, 24, 24, 0]],
    },
  },
  positionPersonality: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
    "& img": {
      marginBottom: 8,
      display: "flex",
      margin: "0 auto",
    },
    "& p": {
      color: "#4f4f4f",
      fontSize: 14,
      lineHeight: "18px",
      textAlign: "center",
    },
    "& h6": {
      color: "var(--Tertiary)",
      fontSize: 18,
      lineHeight: "23px",
      fontWeight: 700,
      textAlign: "center",
    },
  },
  imgOut: {
    flex: "0 0 auto",
    textAlign: "left",
  },
  stick: {
    width: 2,
    marginBottom: 8,
    borderRadius: 4,
    flex: "none",
    backgroundColor: "var(--primaryLighter)",
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
      marginRight: 8,
    },
  },
  stickOne: {
    height: 98,
    [theme.breakpoints.down("md")]: {
      // maxWidth: 320,
      width: "100%",
      height: 2,
      flex: "auto",
    },
  },
  stickTwo: {
    height: 247,
    [theme.breakpoints.down("md")]: {
      height: 2,
      width: 80,
    },
    [theme.breakpoints.down("sm")]: {
      width: 22,
    },
  },
  stickThree: {
    height: 56,
    [theme.breakpoints.down("md")]: {
      height: 2,
      maxWidth: 400,
      width: "100%",
      flex: "1",
    },
  },
  stickFour: {
    height: 204,
    [theme.breakpoints.down("md")]: {
      height: 2,
      width: 100,
    },
    [theme.breakpoints.down("sm")]: {
      width: 22,
    },
  },
  roundShape: {
    width: 12,
    height: 12,
    borderRadius: "100%",
    backgroundColor: "var(--primaryLighter)",
    marginBottom: 10,
    flex: "none",
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
      marginRight: 8,
      width: 8,
      height: 8,
    },
  },
  diffrentAspectShape1: {
    position: "absolute",
    bottom: -65,
    right: "10%",
    transform: "rotate(270deg)",
    [theme.breakpoints.down("md")]: {
      bottom: "29%",
      right: "auto",
      left: "-8%",
    },
  },
  diffrentAspectShape2: {
    position: "absolute",
    top: -23,
    left: "50%",
    transform: "rotate(290deg)",
    [theme.breakpoints.down("md")]: {
      top: "37%",
      right: "-6%",
      left: "auto",
    },
  },
  diffrentAspectShape3: {
    position: "absolute",
    top: "20%",
    right: "-3%",
    [theme.breakpoints.down("md")]: {
      bottom: "7%",
      top: "auto",
      right: "-16%",
    },
  },
  futureStroke: {
    [theme.breakpoints.down("md")]: {
      width: 320,
    },
    [theme.breakpoints.down("sm")]: {
      height: 2,
      width: 171,
    },
  },
  bigPictureStroke: {
    height: 257,
    [theme.breakpoints.down("md")]: {
      width: 300,
    },
    [theme.breakpoints.down("sm")]: {
      width: 141,
      height: 2,
    },
  },
  vasanaTypeAccordian: {
    position: "relative",
    marginTop: 42,
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
    },
  },
  flowerBgImg: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  influence: {
    "&.MuiTypography-root": {
      fontSize: 20,
      color: "var(--blackGray)",
      lineHeight: "26px",
      fontWeight: 700,

      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "21px",
      },
    },
  },
  readMoreButtons: {
    "&.MuiButtonBase-root": {
      color: "var(--primary)",
      fontWeight: 700,
      textTransform: "capitalize",
      fontSize: 18,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  theFacilitarMain: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  vasanaTypeCollaped: {
    marginBottom: 18,
  },
  readmoreButtonsMain: {
    marginTop: 10,
    textAlign: "right",
    [theme.breakpoints.down("lg")]: {
      marginTop: 0,
      marginBottom: 10,
      textAlign: "center",
    },
  },
  vasanaTypeCollapsed: {
    "&.MuiTypography-root": {
      color: "var(--blackGray)",
    },
  },
  typeMarker: {
    maxWidth: 42,
  },
  btnWrapper: {
    "& .MuiButtonBase-root": {
      [theme.breakpoints.down("lg")]: {
        padding: "6px 14px",
      },
    },
  },
  DownBtnWrapper: {
    "& .MuiButtonBase-root": {
      "& .content": {
        whiteSpace: "nowrap",
      },

      [theme.breakpoints.down("sm")]: {
        "&:last-child": {
          marginLeft: 0,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      rowGap: "20px",
      flexDirection: "column !important",
    },
  },
}));
