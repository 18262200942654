import React, { Fragment } from "react";
import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoader from "react-spinners/PulseLoader";
import VSNTypography from "../../../shared/VSNTypography";
import { ReactComponent as VasanaLogo } from "../../../assets/Teams/svg/vasana-chat-logo.svg";
import { ReactComponent as Search } from "../../../assets/admin/svg/search.svg";
import { ReactComponent as Send } from "../../../assets/common/svg/send.svg";
import VSNTextField from "../../../shared/VSNTextField";
import VSNButton from "../../../shared/VSNButton";
import VSNForm from "../../../shared/VSNForm";

const useStyles = makeStyles((theme) => ({
  teamViewCard: {
    borderRadius: 10,
    background: "#FCFCFD",
    border: `2px solid var(--white)`,
    height: "auto",
    boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.07)",
    position: "relative",
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 22,
      lineHeight: "28px",
      fontWeight: 700,
      color: "var(--blackGray)",
      position: "relative",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 11,
        bottom: 3,
        height: 22,
        left: -70,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: "var(--primary)",
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 20,
        lineHeight: "26px",
      },
      [theme.breakpoints.down("360")]: {
        fontSize: 17,
        lineHeight: "23px",
      },
    },
  },
  chatHeader: {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "#DFE1F84D",
    borderRadius: [[8, 8, 0, 0]],
    padding: [[15, 19]],
    gap: [[0, 9]],
    borderBottom: "4px solid #fff",
    zIndex: 9,
  },
  chatLogoMain: {
    height: 42,
    maxWidth: 42,
    width: "100%",
    backgroundColor: "var(--white)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    "& svg": {
      verticalAlign: "middle",
    },
  },
  smHeading: {
    color: "var(--gray)",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  chatBodyMain: {
    padding: [[0, 20]],
    height: "100%",
    flex: 1,
    overflow: "hidden",
    justifyContent: "end",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 350px)",
    },
  },
  chatBody: {
    position: "relative",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 4,
      position: "absolute",
      left: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CDD0E1",
      borderRadius: 4,
      padding: 0,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
      padding: 0,
    },
  },
  // chatBodyWrapper: {
  //   maxHeight: "100%",
  //   position: "absolute",
  //   right: 0,
  //   bottom: 0,
  //   left: 0,
  // },
  messageImg: {
    height: 34,
    maxWidth: 34,
    width: "100%",
    boxShadow: "none",
    backgroundColor: "#FFF3EC",
    "& svg": {
      height: 20,
      width: 20,
    },
  },
  chatMessage: {
    "&.MuiTypography-root": {
      fontWeight: 500,
      color: "var(--gray)",
      padding: [[15, 12]],
      backgroundColor: "#DFE1F84D",
      borderRadius: [[0, 8, 8, 8]],
      lineHeight: "18.23px",
      maxWidth: 390,
      whiteSpace: "pre-line",
      [theme.breakpoints.down("sm")]: {
        padding: "11px 12px",
      },
    },
  },
  receivMessage: {
    gap: [[0, 8]],
    marginTop: 15,
  },
  sendMessage: {
    gap: [[0, 8]],
    marginTop: 15,
    "& $chatMessage": {
      backgroundColor: "#FDF3EF",
      marginLeft: 42,
      color: "var(--primary)",
      borderRadius: [[8, 8, 0, 8]],
    },
  },
  messageTime: {
    "&.MuiTypography-root": {
      color: "var(--gray)",
      fontSize: 12,
      marginTop: 5,
      marginBottom: 5,
    },
  },
  senderTime: {
    "&.MuiTypography-root": {
      textAlign: "end",
      marginRight: 5,
    },
  },
  chatInputMain: {
    borderTop: "1px solid #7477871A",
    [theme.breakpoints.down("sm")]: {
      margin: "0 -18px -16px -18px",
    },
  },
  chatInput: {
    "& .MuiOutlinedInput-root": {
      padding: [[5, 20]],
      gap: 9,
      "& svg": {
        height: 22,
        width: 22,
      },
    },
    "& fieldset": {
      border: 0,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "var(--gray)",
      fontFamily: "DM Sans",
      fontSize: 14,
      fontWeight: 500,
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      padding: "8px 10px !important",
    },
  },
  sendBtn: {
    "&.MuiButtonBase-root": {
      padding: 0,
      minWidth: 0,
      maxWidth: 0,
    },
  },
}));

const ChatBot = ({
  message,
  messages,
  typing,
  onMessageChange,
  onSendMessage,
  onNext,
  inputRef,
  className,
  headerStyle,
  chatBoxFooter,
  bodyStyle,
  sx,
  height,
}) => {
  const classes = useStyles();

  return (
    <Stack className={`${classes.teamViewCard} ${className || ""}`} sx={sx}>
      <Stack className={classes.chatHeader} direction="row" sx={headerStyle}>
        <Stack className={classes.chatLogoMain}>
          <Box>
            <VasanaLogo />
          </Box>
        </Stack>
        <Stack>
          <VSNTypography variant="h3" className={classes.mdHeading}>
            Ask Huma
          </VSNTypography>
          <VSNTypography variant="body2" className={classes.smHeading}>
            Your Vasana Expert at Your Fingertips
          </VSNTypography>
        </Stack>
      </Stack>
      <Stack className={classes.chatBodyMain} sx={bodyStyle}>
        <Stack className={classes.chatBody}>
          {/* <Stack className={classes.chatBodyWrapper}> */}
          <InfiniteScroll
            dataLength={messages?.data?.length || 0}
            // next={onNext}
            // hasMore={
            //   messages?.page?.current_page < messages?.page?.pageLimit
            // }
            height={height}
            inverse
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              paddingRight: "6px",
            }}
          >
            {typing ? (
              <>
                <Stack
                  className={classes.receivMessage}
                  direction="row"
                  justifyContent="start"
                  mb={1}
                >
                  <Box
                    className={`${classes.messageImg} ${classes.chatLogoMain}`}
                  >
                    <VasanaLogo />
                  </Box>
                  <PulseLoader
                    size={7}
                    color="var(--primary)"
                    speedMultiplier={0.8}
                    cssOverride={{
                      dispaly: "flex",
                      alignItems: "center",
                    }}
                  />
                </Stack>
              </>
            ) : null}
            {messages?.data?.map((data, chatIndex) => {
              const utcSeconds = Number(
                data?.ai ? data?.ai?.time : data?.human?.time
              );
              const time = new Date(0); // The 0 there is the key, which sets the date to the epoch
              time.setUTCSeconds(utcSeconds);
              return (
                <Fragment key={chatIndex}>
                  <Stack
                    className={`${
                      data?.ai ? classes.receivMessage : classes.sendMessage
                    }`}
                    direction="row"
                    justifyContent={data?.ai ? "start" : "end"}
                  >
                    {data?.ai && (
                      <Box
                        className={`${classes.messageImg} ${classes.chatLogoMain}`}
                      >
                        <VasanaLogo />
                      </Box>
                    )}
                    <Stack ml={data?.human ? "auto" : "unset"}>
                      <VSNTypography
                        variant="body2"
                        className={classes.chatMessage}
                      >
                        {data?.ai ? data?.ai?.phrase : data?.human?.phrase}
                      </VSNTypography>
                      <VSNTypography
                        variant="body2"
                        className={`${classes.messageTime} ${
                          data?.human ? classes.senderTime : ""
                        }`}
                      >
                        {dayjs(time).format("LT")}
                      </VSNTypography>
                    </Stack>
                  </Stack>
                </Fragment>
              );
            })}
          </InfiniteScroll>
          {/* </Stack> */}
        </Stack>
      </Stack>
      <VSNForm>
        <Stack
          className={classes.chatInputMain}
          sx={{
            margin: { sm: 0, xs: chatBoxFooter },
            ...(typing ? { cursor: "not-allowed" } : {}),
          }}
        >
          <VSNTextField
            variant="outlined"
            inputRef={inputRef}
            sx={typing ? { pointerEvents: "none" } : {}}
            placeholder="Type your message"
            className={classes.chatInput}
            onChange={onMessageChange}
            value={message}
            disabled={typing}
            InputProps={{
              startAdornment: <Search className={classes.searchIcon} />,
              endAdornment: (
                <VSNButton
                  type="submit"
                  startIcon={<Send />}
                  variant="text"
                  className={classes.sendBtn}
                  handleClick={onSendMessage}
                  disabled={typing}
                />
              ),
            }}
          />
        </Stack>
      </VSNForm>
    </Stack>
  );
};

export default ChatBot;
