import { Breadcrumbs } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  "& .MuiBreadcrumbs-li": {
    "& a": {
      fontSize: 16,
      color: "var(--gray)",
      fontWeight: 500,
      "&.active": {
        color: "var(--blackGray)",
      },
    },
  },
  "& .MuiBreadcrumbs-separator": {
    color: "var(--gray)",
    margin: "0 4px",
  },
}));

export default function VSNBreadcrumbs({ breadcrumbsRoutes }) {
  const { pathname } = useLocation();
  return (
    <StyledBreadcrumbs aria-label="breadcrumb" mb={0.5}>
      {breadcrumbsRoutes?.map((route, i) => (
        <Link
          key={i}
          to={route?.path || ""}
          className={pathname === route?.path ? "active" : ""}
        >
          {route?.label || ""}
        </Link>
      ))}
    </StyledBreadcrumbs>
  );
}
