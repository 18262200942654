import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import VSNTable from "../../shared/VSNTable";

import VSNTypography from "../../shared/VSNTypography";
import { ReactComponent as Round } from "../../assets/admin/svg/org-image.svg";
import VSNButton from "../../shared/VSNButton";
import { toggleModelShow } from "../../shared/CustomFunc";

const useStyles = makeStyles((theme) => ({
  heading: {
    position: "sticky",
    top: 0,
    borderBottom: "1px solid var(--primary)",
    padding: "12px 25px",
    margin: "0 -25px",
    backgroundColor: "var(--white)",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 15px",
      margin: "0 -15px",
    },
    "&:after": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      height: 25,
      width: "100%",
      bottom: "100%",
      background: "var(--white)",
      zIndex: 8,
    },
    "& svg": {
      borderRadius: "50%",
      [theme.breakpoints.down("md")]: {
        width: 40,
        height: 40,
      },
      [theme.breakpoints.down("sm")]: {
        width: 30,
        height: 30,
      },
    },
    "& h5": {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "31px",
      marginLeft: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        lineHeight: "26px",
        maxWidth: "70%",
      },
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#F6F6FD",
    padding: "20px 25px",
    gap: 10,
    marginLeft: "-25px",
    marginRight: "-25px",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "15px",
      gap: 10,
      marginLeft: "-15px",
      marginRight: "-15px",
    },
    "& .MuiButtonBase-root": {
      margin: 0,
      padding: "10px 25px",
      lineHeight: "21px",
      width: "auto",
      borderRadius: 10,
      "& span": {
        fontSize: 16,
        fontWeight: 400,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  createLinkButton: {
    "&.MuiButtonBase-root": {
      color: "var(--white)",
    },
  },
  expandTableWrapper: {
    "& .MuiPaper-root": {
      margin: "0 !important",
      padding: "0 !important",
    },
    "& .MuiTableCell-root": {
      textAlign: "left",
    },
    "& th": {
      zIndex: 1,
    },
    "& tbody": {
      "& .MuiTableCell-root": {
        padding: "10px 16px",
        border: 0,
      },
    },
  },
}));

const columns = [
  {
    label: "Assessment",
    name: "name",
  },
  {
    action: ["enabled"],
    label: "Access?",
    type: "actions",
  },
];

export default function AssessmentPreference({
  data,
  handleAction,
  isLoading,
  hidePagination = true,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <Stack direction="row" alignItems="center" className={classes.heading}>
        <Stack>
          <Round />
        </Stack>
        <VSNTypography variant="h5" component="h5">
          Assessment Preferences
        </VSNTypography>
      </Stack>
      {isLoading ? (
        <Box
          sx={{
            textAlign: "center",
            height: 45,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={24} color="inherit" />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.expandTableWrapper}>
              <VSNTable
                handleAction={handleAction}
                columns={columns}
                data={data}
                hidePagination={hidePagination}
                isLoading={isLoading}
                minWidth="unset"
              />
            </Box>
          </Grid>
        </Grid>
      )}

      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        className={classes.buttons}
      >
        <VSNButton
          handleClick={() => dispatch(toggleModelShow("createOrg"))}
          className={classes.createLinkButton}
        >
          Save
        </VSNButton>
      </Stack>
    </>
  );
}
