import React, { useState } from "react";
import { Box, Grid, List, ListItem, Stack, Tab, Tabs } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import onePagerResultContainer from "../../../container/onePager.container";
import VSNBreadcrumbs from "../../../shared/VSNBreadcrumbs";
import { PhysicalOnePagerResultStyle } from "./PhysicalOnePagerResultStyle";
import VSNTypography from "../../../shared/VSNTypography";
import { VSNSwiper } from "../../../shared/VSNSwiper";
import VSNPaper from "../../../shared/VSNPaper";
import VSNImage from "../../../shared/VSNImage";
import vitals6 from "../../../assets/assesments/physical/vitals/Vitals6.svg";
import vitals7 from "../../../assets/assesments/physical/vitals/Vitals7.svg";
import vitals8 from "../../../assets/assesments/physical/vitals/Vitals8.svg";
import vitals9 from "../../../assets/assesments/physical/vitals/Vitals9.svg";
import vitals1 from "../../../assets/assesments/physical/vitals/vital1.svg";
import vitals5 from "../../../assets/assesments/physical/vitals/vital5.svg";
import vitals4 from "../../../assets/assesments/physical/vitals/vital4.svg";
import vitals3 from "../../../assets/assesments/physical/vitals/vital3.svg";
import vitals2 from "../../../assets/assesments/physical/vitals/vital2.svg";
import { PurpalFlowerBullet } from "../../../shared/icon";
import SwiperCardComponent from "../../assessment/SwiperCardComponent";
import ChartTable, { AudioGramTable } from "../../assessment/ChartTable";
import PageStatisticsChart from "../../assessment/PageStatisticsChart";

const swiperData = [
  {
    image: vitals6,
    title: "Maintain a Healthy Weight",
    description:
      "Engage in regular physical activity and follow a balanced diet to manage body fat and visceral fat levels.",
  },
  {
    image: vitals7,
    title: "Stay Hydrated",
    description:
      "Ensure adequate hydration to maintain total body water levels, which is essential for sustained energy and proper bodily functions.",
  },
  {
    image: vitals8,
    title: "Protect Hearing",
    description:
      "Use hearing protection in loud environments and undergo regular audiograms to detect any early signs of hearing loss.",
  },
  {
    image: vitals9,
    title: "Prioritize Vision Health",
    description:
      "Schedule routine vision exams and ensure corrective lenses are worn as needed to maintain sharp vision, crucial for job safety and accuracy during critical operations.",
  },
];

const current_composition = [
  {
    Svg: vitals4,
    header: "Body Fat Mass",
    detail: [
      {
        label: (
          <>
            Weight <span>(lbs)</span>
          </>
        ),
        value: "87.6",
      },
      {
        label: (
          <>
            Lean Body Mass<span>(lbs)</span>
          </>
        ),
        value: "198.2",
      },
      {
        label: (
          <>
            Height<span> (Inches) </span>
          </>
        ),
        value: "77.5",
      },
    ],
  },
  {
    Svg: vitals3,
    header: "Dry Lean Body Mass",
    detail: [
      {
        label: (
          <>
            Weight<span>(lbs)</span>
          </>
        ),
        value: "52.7",
      },
      {
        label: (
          <>
            Total Weight <span>(lbs)</span>
          </>
        ),
        value: "285.8",
      },
      {
        label: (
          <>
            Lean Body Mass <span>(lbs)</span>
          </>
        ),
        value: "198.2",
      },
      {
        label: (
          <>
            Height <span>(Inches)</span>
          </>
        ),
        value: "77.5",
      },
    ],
  },
  {
    Svg: vitals1,
    header: "Intracellular Water",
    detail: [
      {
        label: (
          <>
            Weight<span>(lbs)</span>
          </>
        ),
        value: "89.5",
      },
      {
        label: (
          <>
            Total Body Water <span>(lbs)</span>
          </>
        ),
        value: "145.5",
      },
      {
        label: (
          <>
            Lean Body Mass <span>(lbs)</span>
          </>
        ),
        value: "198.2",
      },
      {
        label: (
          <>
            Total Weight <span>(lbs)</span>
          </>
        ),
        value: "285.8",
      },
    ],
  },
  {
    Svg: vitals2,
    header: "Extracellular Water",
    detail: [
      {
        label: (
          <>
            Weight<span>(lbs)</span>
          </>
        ),
        value: "56.0",
      },
      {
        label: (
          <>
            Total Body Water <span>(lbs)</span>
          </>
        ),
        value: "145.5",
      },
      {
        label: (
          <>
            Lean Body Mass <span>(lbs)</span>
          </>
        ),
        value: "198.2",
      },
      {
        label: (
          <>
            Total Weight <span>(lbs)</span>
          </>
        ),
        value: "285.8",
      },
    ],
  },
];

const history = [
  {
    year: 2024,
    detail: [
      {
        label: (
          <>
            Weights <span>(lbs)</span>
          </>
        ),
        value: "285.8",
      },
      {
        label: "Body Fat Percentage",
        value: "30.7",
      },
      {
        label: "Visceral Fat Level",
        value: "198.2",
      },
      {
        label: (
          <>
            Lean Body Mass <span>(lbs)</span>
          </>
        ),
        value: "198.2",
      },
      {
        label: (
          <>
            Skeletal Muscle Mass <span>(lbs)</span>
          </>
        ),
        value: "112.2",
      },
    ],
  },
  {
    year: 2023,
    detail: [
      {
        label: (
          <>
            Weights <span>(lbs)</span>
          </>
        ),
        value: "285.8",
      },
      {
        label: "Body Fat Percentage",
        value: "30.7",
      },
      {
        label: "Visceral Fat Level",
        value: "198.2",
      },
      {
        label: (
          <>
            Lean Body Mass <span>(lbs)</span>
          </>
        ),
        value: "198.2",
      },
      {
        label: (
          <>
            Skeletal Muscle Mass <span>(lbs)</span>
          </>
        ),
        value: "112.2",
      },
    ],
  },
  {
    year: 2022,
    detail: [
      {
        label: (
          <>
            Weights <span>(lbs)</span>
          </>
        ),
        value: "285.8",
      },
      {
        label: "Body Fat Percentage",
        value: "30.7",
      },
      {
        label: "Visceral Fat Level",
        value: "198.2",
      },
      {
        label: (
          <>
            Lean Body Mass <span>(lbs)</span>
          </>
        ),
        value: "198.2",
      },
      {
        label: (
          <>
            Skeletal Muscle Mass <span>(lbs)</span>
          </>
        ),
        value: "112.2",
      },
    ],
  },
];

const vital_faqs = [
  {
    title: "Body Fat Percentage:",
    description:
      "The proportion of body weight that is made up of fat. This helps assess an individual’s risk for health issues such as heart disease, diabetes, and other conditions linked to high body fat.",
  },
  {
    title: "Lean Body Mass (LBM):",
    description:
      "This includes muscle, bones, organs, and other non-fat tissues. A higher lean body mass generally indicates better muscle strength and physical fitness.",
  },
  {
    title: "Total Body Water:",
    description:
      "It represents the amount of water in the body, both inside and outside cells. It is crucial for hydration, nutrient transport, and overall bodily functions, helping assess proper hydration and health balance.",
  },
  {
    title: "Visceral Fat Level:",
    description:
      "This measures fat stored around internal organs, which is a strong predictor of metabolic health. High levels of visceral fat are associated with increased risks of heart disease and other metabolic disorders.",
  },
];

const BodyConsumption = () => {
  const classes = PhysicalOnePagerResultStyle();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid item xl={6} lg={6} xs={12}>
        <Stack className={classes.assessmentReportCard}>
          <VSNTypography
            variant="h3"
            component="h3"
            className={classes.mdHeadingprimary}
            mb={1}
            sx={{ fontSize: "20px" }}
          >
            Your Body Composition
          </VSNTypography>
          <VSNTypography
            component="p"
            className={classes.desc}
            fontSize={{ xsm: 16, sm: 16 }}
            fontWeight={500}
          >
            {` Body composition refers to the breakdown of an individual's body
            into different components, primarily focusing on fat, muscle, and
            water. It provides a more detailed understanding of a person's
            physical makeup than weight alone.`}
          </VSNTypography>

          <Grid container mt={5} spacing={2.5}>
            <Grid item xl={6} lg={6} xs={12}>
              <Tabs
                className={classes.swiperCustomTabs}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab disableRipple label="Current Composition" />
                <Tab disableRipple label="History" />
              </Tabs>

              {value === 0 ? (
                <Box position="relative">
                  <Stack
                    direction="row"
                    spacing={1.4}
                    className={`${classes.VitalcustomArrow} ${classes.customArrow}`}
                  >
                    <div className="swiper-button image-swiper-button-next" />
                    <div className="swiper-button image-swiper-button-prev" />
                  </Stack>
                  <VSNSwiper
                    className={classes.containerCard}
                    navigation={{
                      prevEl: ".image-swiper-button-next",
                      nextEl: ".image-swiper-button-prev",
                      disabledClass: "swiper-button-disabled",
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {current_composition?.map((item, index) => (
                      <SwiperSlide key={index}>
                        <VSNPaper
                          sx={{
                            borderRadius: "12px",
                            textAlign: "start",
                            border: "none",
                          }}
                          className={classes.mainWrapper}
                        >
                          <Stack
                            direction={{ sm: "row", xs: "column" }}
                            gap={{ md: "45px", xs: "24px" }}
                            alignItems="center"
                            className="inner"
                          >
                            <Stack key={index} className={classes.bodyCard}>
                              <VSNImage src={item?.Svg} alt="icon" />
                            </Stack>
                            <Stack justifyContent="center" width="100%" gap={3}>
                              <VSNTypography
                                variant="subtitle1"
                                component="h6"
                                color="#202024"
                                fontWeight={700}
                                fontSize={{ xs: 18, md: 24 }}
                              >
                                {item?.header}
                              </VSNTypography>

                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                flexWrap="wrap"
                                gap={{ xl: 5, xs: 2 }}
                              >
                                {item?.detail?.map(
                                  ({ label, value }, index) => (
                                    <Stack
                                      key={index}
                                      className={classes.singleValueBox}
                                    >
                                      <VSNTypography component="p">
                                        {label}
                                      </VSNTypography>
                                      <VSNTypography
                                        variant="subtitle1"
                                        color="#202024"
                                        fontWeight={700}
                                        fontSize={{ xs: 26, xl: 34 }}
                                      >
                                        {value}
                                      </VSNTypography>
                                    </Stack>
                                  )
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                        </VSNPaper>
                      </SwiperSlide>
                    ))}
                  </VSNSwiper>
                </Box>
              ) : (
                <Box position="relative">
                  <Stack
                    direction="row"
                    spacing={1.4}
                    className={`${classes.VitalcustomArrow} ${classes.customArrow}`}
                  >
                    <div className="swiper-button image-swiper-button-next" />
                    <div className="swiper-button image-swiper-button-prev" />
                  </Stack>
                  <VSNSwiper
                    className={classes.containerCard}
                    navigation={{
                      prevEl: ".image-swiper-button-next",
                      nextEl: ".image-swiper-button-prev",
                      disabledClass: "swiper-button-disabled",
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {history?.map((item, index) => (
                      <SwiperSlide key={index}>
                        <VSNPaper
                          sx={{
                            borderRadius: "12px",
                            textAlign: "start",
                            border: "none",
                          }}
                          className={classes.mainWrapper}
                        >
                          <Stack direction="row" gap="45px" alignItems="center">
                            <Stack justifyContent="center" width="100%" gap={3}>
                              <VSNTypography
                                variant="subtitle1"
                                component="h6"
                                color="#202024"
                                fontWeight={700}
                                fontSize={{ xs: 18, md: 24 }}
                              >
                                {item?.year}
                              </VSNTypography>

                              <Stack
                                direction="row"
                                flexWrap="wrap"
                                gap={{ md: 5, xs: 3 }}
                              >
                                {item?.detail?.map(
                                  ({ label, value }, index) => (
                                    <Stack
                                      key={index}
                                      className={`${classes.singleValueBox} history`}
                                    >
                                      <VSNTypography
                                        variant="subtitle1"
                                        fontSize={{ xs: 14, md: 18 }}
                                        color="#898994"
                                        fontWeight={600}
                                      >
                                        {label}
                                      </VSNTypography>
                                      <VSNTypography
                                        component="h5"
                                        color="#202024"
                                        fontWeight={700}
                                        fontSize={{ xs: 26, md: 34 }}
                                      >
                                        {value}
                                      </VSNTypography>
                                    </Stack>
                                  )
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                        </VSNPaper>
                      </SwiperSlide>
                    ))}
                  </VSNSwiper>
                </Box>
              )}
            </Grid>

            <Grid item xl={6} lg={6} xs={12}>
              <Stack className={classes.assessmentReportCardWhite}>
                <VSNTypography
                  variant="h3"
                  component="h3"
                  className={classes.mdHeading}
                  mb={1}
                >
                  Body Composition: Understanding the Terminology
                </VSNTypography>
                <VSNTypography component="p" className={classes.desc}>
                  Learn what the different body composition terms mean and how
                  they relate to your overall health and fitness.
                </VSNTypography>
                <List
                  className={`${classes.dailyFunctioning} ${classes.vitalList}`}
                >
                  {vital_faqs?.map((el, index) => (
                    <ListItem key={index}>
                      <Box mt={0.5}>
                        <PurpalFlowerBullet />
                      </Box>
                      <VSNTypography color="var(--blackGray)">
                        <span style={{ color: "#86650A", fontWeight: 500 }}>
                          {el?.title ?? ""}{" "}
                        </span>
                        <br />
                        {el?.description}
                      </VSNTypography>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>
          </Grid>

          <VSNTypography
            mt={{ md: "32px", xs: 3 }}
            component="h1"
            variant="h3"
            fontSize={{ xs: 24, md: 34 }}
            fontWeight={400}
            sx={{
              backgroundColor: "var(--white)",
              padding: { sm: "16px 32px", xs: "16px" },
            }}
            textAlign="center"
            borderRadius="8px"
          >
            Current Body Fat Percentage: <b>26.7%</b>
          </VSNTypography>
        </Stack>
      </Grid>

      <Stack mt="25px" className={classes.assessmentReportCard}>
        <VSNTypography
          variant="h3"
          component="h3"
          className={classes.mdHeadingprimary}
          mb={1}
          sx={{ fontSize: "20px" }}
        >
          Clinician Recommendations
        </VSNTypography>
        <VSNTypography
          component="p"
          className={classes.desc}
          fontSize={{ xsm: 16, sm: 16 }}
          fontWeight={500}
        >
          {`Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.`}
        </VSNTypography>
      </Stack>
    </>
  );
};
const VisionCheck = () => {
  const classes = PhysicalOnePagerResultStyle();
  return (
    <Grid item md={12} xs={12}>
      <Stack className={classes.assessmentReportCard}>
        <VSNTypography
          variant="h3"
          component="h3"
          className={classes.mdHeadingprimary}
          mb={1}
          sx={{ fontSize: "20px" }}
        >
          Vision Check
        </VSNTypography>
        <VSNTypography
          component="p"
          className={classes.desc}
          fontSize={{ xsm: 16, sm: 16 }}
          fontWeight={500}
        >
          Visual acuity measures the sharpness of vision at near and far
          distances for each eye and binocularly. It identifies conditions like
          farsightedness or astigmatism and notes if corrective lenses are
          needed. Good visual acuity is essential for safety and job performance
          in high-risk roles.
        </VSNTypography>

        <ChartTable />
      </Stack>

      <Stack mt="25px" className={classes.assessmentReportCard}>
        <VSNTypography
          variant="h3"
          component="h3"
          className={classes.mdHeadingprimary}
          mb={1}
          sx={{ fontSize: "20px" }}
        >
          Clinician Recommendations
        </VSNTypography>
        <VSNTypography
          component="p"
          className={classes.desc}
          fontSize={{ xsm: 16, sm: 16 }}
          fontWeight={500}
        >
          {`Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.`}
        </VSNTypography>
      </Stack>
    </Grid>
  );
};
const HearingTest = () => {
  const classes = PhysicalOnePagerResultStyle();
  return (
    <Grid item md={12} xs={12}>
      <Stack className={classes.assessmentReportCard}>
        <VSNTypography
          variant="h3"
          component="h3"
          className={classes.mdHeadingprimary}
          mb={1}
          sx={{ fontSize: "20px" }}
        >
          Hearing Test
        </VSNTypography>
        <VSNTypography
          component="p"
          className={classes.desc}
          fontSize={{ xsm: 16, sm: 16 }}
          fontWeight={500}
        >
          The audiogram tests hearing sensitivity across various sound
          frequencies, showing the softest sounds a person can hear. It helps
          detect hearing loss or changes in hearing over time, which is crucial
          for individuals exposed to loud environments, ensuring they can take
          preventive action if needed.
        </VSNTypography>

        <Grid container spacing={4} py={2}>
          <Grid item xl={6} lg={6} xs={12}>
            <PageStatisticsChart name="Right" lineColor="#F54346" />
          </Grid>
          <Grid item xl={6} lg={6} xs={12}>
            <PageStatisticsChart name="Left" lineColor="#34A259" />
          </Grid>
          <Grid item xl={6} lg={6} xs={12}>
            <AudioGramTable />
          </Grid>
          <Grid item xl={6} lg={6} xs={12}>
            <AudioGramTable color />
          </Grid>
        </Grid>
      </Stack>

      <Stack mt="25px" className={classes.assessmentReportCard}>
        <VSNTypography
          variant="h3"
          component="h3"
          className={classes.mdHeadingprimary}
          mb={1}
          sx={{ fontSize: "20px" }}
        >
          Clinician Recommendations
        </VSNTypography>
        <VSNTypography
          component="p"
          className={classes.desc}
          fontSize={{ xsm: 16, sm: 16 }}
          fontWeight={500}
        >
          {`Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.`}
        </VSNTypography>
      </Stack>
    </Grid>
  );
};

const result = {
  body_composition: {},
  vision_check: {},
  hearing_test: {},
};

const VitalsResult = ({
  endPointToCall,
  showBreadCrumbs = true,
  breadCrumbAndButton,
  releaseButton,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = PhysicalOnePagerResultStyle();
  const { onePagerAssessmentResult, loader, currentAssessment } =
    onePagerResultContainer({ endPointToCall });
  const breadcrumbsRoutes = [
    {
      path: "/assessments",
      label: "Assessments",
    },
    {
      path: `/assessments/${currentAssessment?.assessment_id}`,
      label: `Vitals Dashboard`,
    },
  ];
  return (
    <div className={classes.mainLayout}>
      {showBreadCrumbs ? (
        <Box mt={2.5} mb={1.8}>
          <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
        </Box>
      ) : (
        <Box className={classes.headingButton}>
          <Box>{breadCrumbAndButton}</Box>
          <Box>{onePagerAssessmentResult?.is_locked ? releaseButton : ""}</Box>
        </Box>
      )}
      <Stack
        direction={{ xsm: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        pb={0.5}
      >
        <VSNTypography
          fontSize={{ xsm: 26, sm: 34 }}
          sx={{ fontWeight: 700 }}
          mb={1}
        >
          Your Vitals Overview: Body Composition, Vision, and Hearing Explained{" "}
        </VSNTypography>
      </Stack>
      <Box sx={{ width: "100%" }} pb="24px">
        <Tabs
          className={classes.CustomTabs}
          value={value}
          onChange={handleChange}
        >
          <Tab disableRipple label="Body Composition" />
          <Tab disableRipple label="Vision Check" />
          <Tab disableRipple label="Hearing Test" />
        </Tabs>
        {value === 0 ? (
          <BodyConsumption />
        ) : value === 1 ? (
          <VisionCheck />
        ) : (
          <HearingTest />
        )}
      </Box>

      <SwiperCardComponent
        title="Be Proactive: Recommendations for Maintaining Optimal Health"
        description="Prioritize your health with these tailored recommendations based on your vitals to help you maintain peak physical condition and overall well-being."
        data={swiperData ?? []}
      />
    </div>
  );
};

export default VitalsResult;
