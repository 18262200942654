import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  quickNote: {
    boxShadow: "0px 0px 30px rgba(66, 68, 80, 0.1)",
    padding: [[55, 70]],
    borderRadius: 16,
    textAlign: "center",
    border: "1px solid #EAEDF0",
    background: "var(--white)",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: [[40, 16]],
    },
  },
}));

const VSNPaper = ({ children, className, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.quickNote} ${className}`} {...props}>
      {children}
    </Box>
  );
};

export default VSNPaper;
