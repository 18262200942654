import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Grid, Stack } from "@mui/material";
import VSNTypography from "../../shared/VSNTypography";

const labelAndDetail = {
  organization_admin: {
    header: "ORGANIZATIONAL DETAILS",
    detail: [
      {
        label: "Organization Name",
        value: "name",
      },
      {
        label: "Organization ID",
        value: "id",
      },
      {
        label: "Organization Address",
        value: ["address_line_1", "address_line_2", "city", "zip_code"],
        xs: 6,
        lg: 4,
      },
    ],
  },
  location_admin: {
    header: "LOCATION DETAILS",
    detail: [
      {
        label: "Location Name",
        value: "name",
      },
      {
        label: "Location ID",
        value: "original_id",
      },
      {
        label: "Location Address",
        value: ["address_line_1", "address_line_2", "city", "zip_code"],
        xs: 6,
        lg: 4,
      },
    ],
  },
  department_admin: {
    header: "DEPARTMENT DETAILS",
    detail: [
      {
        label: "Department Name",
        value: "name",
      },
      {
        label: "Department ID",
        value: "id",
      },
      {
        label: "Department Type",
        value: "type_name",
      },
      {
        label: "Location",
        value: "location",
      },
      {
        label: "Department Address",
        value: ["address_line_1", "address_line_2", "city", "zip_code"],
        xs: 6,
        lg: 4,
      },
    ],
  },
};

const useStyles = makeStyles((theme) => ({
  label: {
    "&.MuiTypography-root": {
      fontSize: 14,
      color: "var(--gray)",
      fontWeight: 700,
      lineHeight: "18px",
      minHeight: 22,
    },
  },
  data: {
    "&.MuiTypography-root": {
      fontSize: 16,
      color: "var(--black)",
      fontWeight: 400,
      lineHeight: "20px",
      padding: "8px 0",
    },
  },
}));

const ShowDetail = ({ activeTab, findTab }) => {
  const classes = useStyles();
  const { permissions } =
    useSelector((state) => state?.app?.userData?.user_profile) ?? {};
  const summaryData = useSelector((state) => state?.api?.organizationSummary);
  return (
    <>
      {findTab?.showDetail &&
        permissions[findTab.showDetail] &&
        permissions[findTab.showDetail]?.includes("view") && (
          <Stack sx={{ maxWidth: 1200, width: "100%", mb: 5 }}>
            <VSNTypography
              variant="h4"
              color="#86650A"
              fontSize={14}
              fontWeight={700}
              mb={3.5}
            >
              {labelAndDetail?.[activeTab]?.header}
            </VSNTypography>
            <Grid container spacing={3.5}>
              {labelAndDetail?.[activeTab]?.detail?.map(
                ({ label, value, ...props }, index) => {
                  const getAddress = (values) => {
                    if (Array?.isArray(values)) {
                      const address = values
                        ?.map((key) => summaryData[key] || "")
                        ?.filter(Boolean)
                        ?.join(", ");
                      return address ? `${address}.` : "";
                    }
                    return "";
                  };

                  return (
                    <Grid item xs={6} lg={2} {...props} key={index}>
                      <VSNTypography className={classes.label} variant="h5">
                        {label}
                      </VSNTypography>
                      <VSNTypography className={classes.data}>
                        {Array.isArray(value)
                          ? getAddress(value)
                          : summaryData?.[value]}
                      </VSNTypography>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Stack>
        )}
      <VSNTypography
        variant="h4"
        color="#86650A"
        fontSize={14}
        fontWeight={700}
        mb={2}
      >
        {findTab?.tableName}
      </VSNTypography>
    </>
  );
};

export default ShowDetail;
