import { Box, Grid, Hidden, IconButton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import { formContainer } from "../../container/form.container";
import { inviteMembersModalContainer } from "../../container/inviteMembersModal.container";
import { inviteMembersContainer } from "../../description/inviteMembersModal.description";
import VSNButton from "../../shared/VSNButton";
import EmailAutoComplete from "../../shared/VSNEmailAutoComplete";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import VSNLabel from "../../shared/VSNLabel";
import VSNTypography from "../../shared/VSNTypography";
import ControlForm from "../../shared/form/ControlForm";
import { DeleteIcon, FileIcon, UploadCsvIcon } from "../../shared/icon";
import { ERROR_MESSAGE } from "../../description/errorMessage.description";
import VSNSelect from "../../shared/VSNSelect";
import {
  location_admin_name,
  organization_admin_name,
  organization_location_admin,
} from "../../utils/constant";

const useStyles = makeStyles((theme) => ({
  title: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      lineHeight: "31.25px",
      fontSize: "24px",
      paddingBottom: 20,
      [theme.breakpoints.down("md")]: {
        marginBottom: 32,
        padding: 0,
        fontSize: 18,
      },
    },
  },

  stepColor: {
    "&.MuiTypography-root": {
      background: "var(--primaryLighter)",
      width: "98px",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18.23px",
      padding: "5px 10px",
      borderRadius: "5px",
      margin: "15px 0",
      color: "var(--gray)",
    },
  },
  question: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      lineHeight: "23.44px",
      fontSize: "18px",
      color: "#2D2F39",
      position: "relative",
      display: "inline-block",
      "& .MuiFormLabel-root": {
        position: "absolute",
        left: "100%",
      },
    },
  },
  description: {
    "&.MuiTypography-root": {
      color: "var(--gray)",
      fontWeight: 400,
      lineHeight: "18.23px",
      fontSize: "14px",
      marginTop: 4,
    },
  },
  input: {
    "&.css-qv3mbf-MuiFormControl-root-MuiTextField-root .MuiInputBase-root .MuiInputBase-input":
      {
        position: "relative",
        fontSize: 14,
      },
    "& .MuiInputBase-root": {
      "& .Mui-disabled": {
        WebkitTextFillColor: "#202024 !important",
      },
      "& .MuiInputBase-input": {
        padding: "8px 0px",
        width: "700px",
        gap: "8px",
      },
    },
  },

  button_groups: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#F6F6FD",
    padding: "20px 25px",
    gap: 10,
    marginLeft: "-25px",
    marginRight: "-25px",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "15px",
      gap: 10,
      marginLeft: "-15px",
      marginRight: "-15px",
    },
  },

  button: {
    "&.MuiButtonBase-root": {
      color: "var(--white)",
      padding: "10px 25px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& span.icon": {
        marginLeft: 8,
        display: "inline-block",
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .loader": {
        "& .MuiCircularProgress-root": {
          height: "16px !important",
          width: "16px !important",
        },
      },
      "&.link": {
        background: "var(--white)",
        border: "1px solid var(--Tertiary)",
        color: "var(--Tertiary)",
        "& .linkIcon": {
          height: "16px",
          width: "16px",
          display: "inline-block",
          marginRight: 10,
        },
      },
    },
  },
  downloadCsvButton: {
    "&.MuiButtonBase-root": {
      background: "var(--Tertiary)",
    },
    "&:hover": {
      backgroundColor: `var(--Tertiary) !important`,
    },
  },
  uploadOut: {
    height: 108,
    width: 108,
    borderRadius: 10,
    background: "#F6F6FD80",
    cursor: "pointer",
    position: "relative",
    flex: "0 0 auto",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: 80,
      width: 80,
    },
    "& .MuiBox-root": {
      minHeight: "0",
    },
  },
  closeIcon: {
    position: "absolute",
    bottom: -10,
    right: -10,
    height: 28,
    width: 28,
    boxShadow: "5px 5px 15px rgba(69, 97, 101, 0.10)",
    background: "var(--white)",
    borderRadius: "100%",
    zIndex: 2,
  },
  editIcon: {
    position: "absolute",
    top: -10,
    right: -10,
    height: 28,
    width: 28,
    boxShadow: "5px 5px 15px rgba(69, 97, 101, 0.10)",
    background: "var(--white)",
    borderRadius: "100%",
    "& path": {
      fill: "var(--primary)",
    },
  },
  uploadText: {
    "&.MuiTypography-root": {
      fontSize: 14,
      lineHeight: "28px",
      color: "var(--gray)",
      [theme.breakpoints.down("md")]: {
        lineHeight: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        marginTop: 20,
      },
    },
  },
  inputUpload: {
    "&.MuiFormControl-root": {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      top: "-40px",
      "& .MuiInputBase-root": {
        height: "100%",
        "& .MuiInputBase-input": {
          padding: 0,
          height: "100%",
          cursor: "pointer",
        },
      },
    },
  },
  uplodLabel: {
    "&.MuiFormLabel-root": {
      position: "absolute",
      inset: 0,
      cursor: "pointer",
    },
  },
  uploadWrapper: {
    borderRadius: 5,
    padding: 6,
    zIndex: 2,
    backgroundColor: "#DFE1F84D",
    justifyContent: "center",
    "&:after": {
      position: "absolute",
      content: "''",
      inset: 0,
      borderRadius: 10,
      border: "6px solid #fff",
      zIndex: -1,
    },
  },

  divider: {
    position: "relative",
    margin: "0 18px",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0",
    },
    "&:before": {
      position: "absolute",
      content: "''",
      height: "100%",
      width: 2,
      background:
        "linear-gradient(180deg, rgba(85, 95, 221, 0.00) 0%, rgba(85, 95, 221, 0.10) 48.44%, rgba(85, 95, 221, 0.00) 100%)",
      zIndex: 0,
      [theme.breakpoints.down("sm")]: {
        height: 2,
        width: "100%",
        background:
          "linear-gradient(90deg, rgba(85, 95, 221, 0.00) 0%, rgba(85, 95, 221, 0.10) 48.44%, rgba(85, 95, 221, 0.00) 100%)",
      },
    },
    "& span": {
      color: "#86650A",
      height: 33,
      width: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      backgroundColor: "#F6F6FD",
      position: "relative",
      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        height: 41,
        width: 41,
      },
    },
  },
  UnderlineDropdown: {
    "& div": {
      "&.MuiInputBase-root": {
        paddingLeft: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderRadius: 0,
        borderColor: "#EAEDF0",
      },
    },
  },
  positionDivider: {
    position: "absolute",
    left: "50%",
    top: 0,
    bottom: 0,
    transform: "translateX(-50%)",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      left: "unset",
      right: "unset",
      top: "unset",
      transform: "unset",
    },
  },
  selectWrapperMain: {
    "&.MuiStack-root": {
      [theme.breakpoints.down("sm")]: {
        gap: 8,
      },
      [theme.breakpoints.down(500)]: {
        alignItems: "start",
        flexDirection: "column",
      },
    },
  },
  selectLabel: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      fontSize: "16px",
    },
  },
}));

const InviteMembersModal = () => {
  const classes = useStyles();
  const {
    formPath,
    inviteMembersData,
    handleChange,
    loaders,
    buttonsContainer: buttons,
    highlighted,
    onDragEnter,
    onDragLeave,
    onInputChange,
    onEmailSelect,
    downloadLocalFile,
    handelSelectBoxChange,
    removeUploadedFile,
    inviteDepartments = [],
    role,
    inviteLocation = [],
  } = inviteMembersModalContainer({});
  const { formErrors } = formContainer({ formPath });
  const inviteMemberAttribute = {
    label: "Invite Members",
    data: [
      {
        isOrSplit: true,
        value: [
          {
            control: "input",
            type: "file",
            name: "csv_file",
            value: "",
            placeholder: "Upload your Team Photo",
            errorMessage: ERROR_MESSAGE?.photo,
            fullWidth: true,
          },
        ],
        subdescription: "Drop CSV files here or click to upload",
      },
      {
        question: "Invite Members",
        description:
          "Add people to your organization by typing their email address.",
        value: [
          {
            control: "input",
            type: "text",
            name: "emails",
            value: "",
            validateAs: "name",
            placeholder:
              "Example anamika@gmail.com, jekson234@taglineinfotech.com",
            errorMessage: "email is required",
            fullWidth: true,
          },
        ],
      },
    ],
  };
  return (
    <Box>
      <Stack direction={{ xsm: "column", sm: "row" }} gap={2} mt={1.5}>
        {organization_admin_name?.includes(role) && (
          <Box
            direction="row"
            alignItems="center"
            className={classes.selectWrapperMain}
            sx={{ borderBottom: "0.5px solid #EAEDF0" }}
            width={{ xsm: "100%", sm: "50%" }}
          >
            <VSNTypography className={classes.selectLabel}>
              Location
            </VSNTypography>
            <VSNSelect
              onChange={handelSelectBoxChange}
              options={inviteLocation}
              value={inviteMembersData?.location || 0}
              placeHolder="Location"
              name="location"
              fullWidth
              sx={{
                paddingLeft: "12px",
                "& .MuiSelect-select": {
                  fontWeight: 400,
                },
              }}
            />
          </Box>
        )}
        {organization_location_admin?.includes(role) && (
          <Box
            sx={{ borderBottom: "0.5px solid #EAEDF0" }}
            className={classes.selectWrapperMain}
            width={{ xsm: "100%", sm: "50%" }}
          >
            <VSNTypography component="p" className={classes.selectLabel}>
              Department
            </VSNTypography>
            <VSNSelect
              onChange={handelSelectBoxChange}
              options={
                inviteDepartments?.length
                  ? inviteDepartments
                  : [{ key: "No Department found.", value: "" }]
              }
              value={inviteMembersData?.department || 0}
              fullWidth
              placeHolder="Department"
              name="department"
              sx={{
                paddingLeft: "12px",
                "& .MuiSelect-select": {
                  fontWeight: 400,
                },
              }}
            />
          </Box>
        )}
      </Stack>

      <Stack
        direction={{ sm: "row" }}
        position="relative"
        gap={{ sm: 10, xs: 1 }}
      >
        <Hidden smDown>
          <Stack
            alignItems="center"
            justifyContent="center"
            className={`${classes.divider} ${classes.positionDivider}`}
          >
            <span>OR</span>
          </Stack>
        </Hidden>
        {inviteMemberAttribute?.data?.map(
          (
            {
              question,
              description,
              value,
              subtitle,
              subdescription,
              isRequired,
              isOrSplit,
            },
            index
          ) => {
            return (
              <React.Fragment key={index}>
                <Box mt={2.5} mb={{ md: 3.8, xs: 2 }} key={index} width="100%">
                  {!question ? (
                    <>
                      <VSNTypography className={classes.question} variant="h4">
                        {isRequired && <VSNLabel required />} Upload CSV
                      </VSNTypography>
                      <VSNTypography className={classes.uploadText}>
                        {!inviteMembersData?.csv_file_data?.name
                          ? subdescription
                          : ""}
                      </VSNTypography>
                    </>
                  ) : (
                    <>
                      <VSNTypography className={classes.question} variant="h4">
                        {isRequired && <VSNLabel required />} {question}
                      </VSNTypography>

                      <VSNTypography
                        className={classes.description}
                        variant="h6"
                        mb={2}
                      >
                        {description}
                      </VSNTypography>
                    </>
                  )}
                  {value?.map(
                    (
                      { isRequired, label, errorMessage, name, ...attribute },

                      index
                    ) => {
                      if (!question) {
                        return (
                          <React.Fragment key={index}>
                            <Stack
                              direction={{ sm: "row", xs: "column" }}
                              alignItems={{ sm: "center", xs: "start" }}
                              mt={1}
                              mb={2}
                              position="relative"
                              border={
                                highlighted
                                  ? "1px dashed var(--primary)"
                                  : "1px dashed var(--Tertiary)"
                              }
                              onDragEnter={onDragEnter}
                              onDragLeave={onDragLeave}
                              key={index}
                              className={classes.uploadWrapper}
                            >
                              <Stack
                                alignItems="center"
                                justifyContent="center"
                                color="var(--primary)"
                                className={`${classes.uploadOut}`}
                              >
                                <VSNTypography
                                  className={classes.questionTitle}
                                  variant="h6"
                                >
                                  {subtitle}
                                </VSNTypography>
                                <UploadCsvIcon />
                              </Stack>

                              <ControlForm
                                {...{
                                  ...attribute,
                                  onChange: handleChange,
                                  name,
                                }}
                                className={classes.inputUpload}
                              />
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {/* ------------ */}
                              <VSNTypography className={classes.uploadText}>
                                {inviteMembersData?.csv_file_data?.name ?? ""}
                              </VSNTypography>

                              {inviteMembersData?.csv_file_data && (
                                <IconButton
                                  onClick={() => removeUploadedFile()}
                                  color="primary"
                                  aria-label="remove file"
                                  component="span"
                                  style={{ marginLeft: "8px" }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Box>
                            <VSNErrorMessage
                              {...{
                                formError: formErrors?.[name],
                                errorMessage,
                                className: classes.csvError,
                              }}
                            />
                            <VSNButton
                              className={`${classes.button} ${classes.downloadCsvButton}`}
                              handleClick={downloadLocalFile}
                            >
                              <FileIcon />
                              &nbsp; Download CSV Template
                            </VSNButton>
                          </React.Fragment>
                        );
                      }

                      return (
                        <EmailAutoComplete
                          key={index}
                          className={classes.UnderlineDropdown}
                          options={inviteMembersData?.emailOptions || []}
                          onChange={onEmailSelect}
                          onInputChange={onInputChange}
                          placeholder="Select or enter email"
                          value={inviteMembersData?.emails || []}
                          inputValue={
                            inviteMembersData?.emails_input_value || ""
                          }
                          filterSelectedOptions
                          autoHighlight
                          slotProps={{
                            popper: {
                              sx: {
                                overflow: "auto",
                                "& ul": {
                                  width: "max-content",
                                  minWidth: "100%",
                                  "& .MuiAutocomplete-option": {
                                    width: "100%",
                                    overflow: "visible",
                                  },
                                },
                              },
                            },
                          }}
                          sx={{
                            "& .MuiChip-label": {
                              maxWidth: 200,
                            },
                            "& .MuiAutocomplete-inputRoot": {
                              "& .MuiAutocomplete-input": {
                                minWidth: "max-content",
                              },
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black",
                            },
                            "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#EAEDF0",
                              },
                            "&:focus-within .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EAEDF0 !important",
                              borderRadius: "8px 8px 0 0",
                            },
                            "&:focus-within .MuiOutlinedInput-notchedOutline:hover":
                              {
                                borderColor: "#EAEDF0",
                              },
                          }}
                        />
                      );
                    }
                  )}
                </Box>
                {isOrSplit && (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    className={`${classes.divider} ${classes.positionDivider}`}
                  >
                    <span>OR</span>
                  </Stack>
                )}
              </React.Fragment>
            );
          }
        )}
      </Stack>
      <Box className={classes.button_groups}>
        {buttons &&
          buttons.map(
            (
              {
                label,
                handleClick,
                hasLoader,
                icon,
                className,
                link,
                disabled,
              },
              index
            ) => (
              <VSNButton
                isLoading={hasLoader && loaders?.inviteMembers}
                className={`${classes.button} ${className}`}
                key={index}
                handleClick={() => handleClick(label)}
                disabled={disabled}
              >
                <span className="linkIcon">{link}</span>
                {label}
                <span className="icon">{icon}</span>
              </VSNButton>
            )
          )}
      </Box>
    </Box>
  );
};

export default InviteMembersModal;
