import { makeStyles } from "@mui/styles";
import crown from "../../assets/organizationAdmin/svg/crown.svg";

export const ParticipantsModalStyle = makeStyles((theme) => ({
  stepColor: {
    "&.MuiTypography-root": {
      background: "var(--primaryLighter)",
      width: "98px",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18.23px",
      padding: "5px 10px",
      borderRadius: "5px",
      margin: "15px 0",
      color: "var(--gray)",
    },
  },
  question: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      lineHeight: "23.44px",
      fontSize: "18px",
      color: "#2D2F39",
    },
  },
  description: {
    "&.MuiTypography-root": {
      color: "var(--gray)",
      fontWeight: 400,
      lineHeight: "18.23px",
      fontSize: "14px",
      marginTop: 4,
    },
  },
  input: {
    "&.css-qv3mbf-MuiFormControl-root-MuiTextField-root .MuiInputBase-root .MuiInputBase-input":
      {
        position: "relative",
        fontSize: 14,
      },
    "& .MuiInputBase-root": {
      "& .Mui-disabled": {
        WebkitTextFillColor: "#202024 !important",
      },
      "& .MuiInputBase-input": {
        padding: "8px 0px",
        width: "700px",
        gap: "8px",
      },
    },
  },
  filterOut: {
    "& .filterWrapper": {
      marginTop: 0,
      marginBottom: 0,
      "& .search": {
        "& .MuiFormControl-root": {
          "& .MuiInputBase-root": {
            height: 40,
          },
        },
      },
    },
  },

  button_groups: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#F6F6FD",
    padding: "20px 25px",
    gap: 10,
    marginLeft: "-25px",
    marginRight: "-25px",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "15px",
      gap: 10,
      marginLeft: "-15px",
      marginRight: "-15px",
    },
  },

  button: {
    "&.MuiButtonBase-root": {
      color: "var(--white)",
      marginLeft: 10,
      padding: "10px 16px",
      "& span.icon": {
        marginLeft: 8,
        display: "inline-block",
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .loader": {
        "& .MuiCircularProgress-root": {
          height: "16px !important",
          width: "16px !important",
        },
      },
      "&.link": {
        background: "var(--white)",
        border: "1px solid var(--Tertiary)",
        color: "var(--Tertiary)",
        "& .linkIcon": {
          height: "16px",
          width: "16px",
          display: "inline-block",
          marginRight: 10,
        },
      },
    },
  },
  search: {
    maxWidth: 322,
    width: "100%",
    "& .MuiFormControl-root": {
      background: "#F5F5F5",
      width: "100%",
      borderRadius: 5,
      "& .MuiInputBase-root": {
        height: 40,
        paddingLeft: 5,
        [theme.breakpoints.down("md")]: {
          height: 42,
        },
        "& input": {
          paddingLeft: 15,
          fontSize: 16,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  sortFilter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 10,
    },
    "& h6": {
      color: "var(--gray)",
    },
    "& p": {
      fontSize: 12,
      marginBottom: 8,
      fontWeight: 500,
      lineHeight: "15px",
    },
    "& .MuiStack-root": {
      overflow: "unset",
      paddingTop: 0,
    },

    "& .MuiFormControl-root": {
      minWidth: 181,
      [theme.breakpoints.down("400")]: {
        minWidth: 110,
      },

      "& .MuiInputBase-root": {
        borderRadius: 8,
        height: 38,
        backgroundColor: "#555FDD0D",
        border: "none",
        "& svg": {
          pointerEvents: "none",
          cursor: "pointer",
          position: "absolute",
          right: "7px",
          color: "var(--gray)",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #000",
          },
          "& svg": {
            transform: "rotate(180deg)",
          },
        },
        "& .MuiSelect-select": {
          minWidth: 112,
          display: "flex",
          padding: [[0, 32, 0, 12]],
          fontWeight: 500,
          fontSize: 14,
          minHeight: 0,
          boxSizing: "border-box",
          color: "var(--blackGray)",
          "& span": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 14,
            color: "var(--Tertiary) !important",
            "& img": {
              height: 28,
              width: 36,
            },
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #EAEDF0",
        },
      },
    },
  },
  avtarOut: {
    "& .MuiAvatar-root.MuiAvatar-circular": { border: "2px solid #fff" },
  },
  smHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: 700,
      textTransform: "capitalize",
      color: "var(--blackGray)",
    },
  },
  desc: {
    "&.MuiTypography-root": {
      fontSize: 14,
      lineHeight: "18px",
      color: "var(--gray)",
    },
  },
  btnDelete: {
    padding: "8px",
    borderRadius: 5,
    color: "#F54346",
    width: "fit-content",
    marginLeft: "auto",
    cursor: "pointer",
    "& svg": {
      height: 18,
      width: 18,
    },
  },
  btnPromote: {
    padding: "8px",
    borderRadius: 5,
    color: "var(--gray)",
    width: "fit-content",
    marginLeft: "auto",
    cursor: "pointer",
    "& svg": {
      height: 18,
      width: 18,
    },
  },
  memberList: {
    "& .MuiTableRow-root": {
      padding: 0,
      "& .MuiTableCell-root": {
        padding: "14px 2px",
        borderBottom: 0,
      },
      "& + .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          borderTop: "1px dashed #EAEDF0",
        },
      },
    },
  },
  paginationFooter: {
    margin: "0 -25px",
    background: "#DFE1F84D",
    padding: 15,
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      margin: "0 -15px",
    },
    "& .MuiPagination-ul": {
      justifyContent: "center",
      "& .MuiButtonBase-root": {
        border: "none",
        background: "var(--white)",
        color: "#42445080",
        "&.Mui-selected": {
          background: "var(--Tertiary)",
          color: "var(--white)",
        },
        [theme.breakpoints.down("sm")]: {
          margin: 0,
        },
        [theme.breakpoints.down("425")]: {
          height: 26,
          minWidth: 26,
        },
      },
    },
  },
  jellyFish: {
    position: "absolute",
    top: 0,
    left: "70%",
    transform: "translateX(-50%)",
    zIndex: -1,
  },
  zigZag: {
    position: "absolute",
    top: "50%",
    left: "74%",
    transform: "translate(-50%,-50%)",
    zIndex: -1,
  },
  filterStack: {
    "&.MuiStack-root": {
      flexDirection: "column-reverse",
    },
  },
  filterStacksearch: {
    maxWidth: "100%",
  },
  participantImage: {
    "& img": {
      maxWidth: 75,
      width: "100%",
      height: 30,
      objectFit: "contain",
      objectPosition: "left",
      [theme.breakpoints.down("sm")]: {
        maxWidth: 57,
        height: 23,
      },
    },
  },
  filterDropDown: {
    "& .MuiPaper-root": {
      maxHeight: 250,
      "& ul": {
        "& li": {
          display: "flex",
          justifyContent: "start",
          padding: "6px 16px",
          "& img": {
            height: 28,
            width: "auto",
          },
        },
      },
    },
  },
  tableHeadRow: {
    "& .MuiTableCell-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  admin: {
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "''",
      top: -8,
      right: -2,
      background: `url(${crown})`,
      backgroundSize: "100%",
      height: 20,
      width: 20,
      zIndex: 2,
    },
  },
}));
