import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import VSNBreadcrumbs from "../../../shared/VSNBreadcrumbs";
import VSNButton from "../../../shared/VSNButton";
import { AssessmentForAdminStyle } from "./AssessmentForAdminStyle";
import {
  FlashIcon,
  LockIcon,
  OptionalIcon,
  RightRoundIcon,
} from "../../../shared/icon";
import VSNImage from "../../../shared/VSNImage";
import VSNTypography from "../../../shared/VSNTypography";
import { VSNSwiper } from "../../../shared/VSNSwiper";
import { colorFromResults } from "../../../shared/CustomFunc";
import { assessmentIds } from "../../../utils/constant";
import halfRound from "../../../assets/Teams/svg/half-round.svg";
import lightBlueLeaf from "../../../assets/Teams/svg/lightBlue-leaf.svg";
import cloud from "../../../assets/Teams/svg/cloud.svg";
import greenCloud from "../../../assets/Teams/svg/green-cloud.svg";
import VSNLoader from "../../../shared/VSNLoader";
import adminAssessment from "../../../container/org-admin/adminAssessment.container";
import { formatName } from "../../../utils/javascript";

const AssessmentForAdmin = () => {
  const classes = AssessmentForAdminStyle();
  const navigate = useNavigate();
  const {
    AssessmentResult,
    AssessmentReportLoader,
    locationId,
    userId,
    releaseAllAssessment,
    releaseAllLoader,
    assessmentUserInfo,
    permissions,
  } = adminAssessment();
  const [showReleaseButton, setShowReleaseButton] = useState(false);

  const [activeSlide, setActiveSlide] = useState(0);

  const handlePrev = (e) => {
    e.stopPropagation();
    const prevIndex =
      activeSlide === 0
        ? AssessmentResult?.allResults?.length - 1
        : activeSlide - 1;
    setActiveSlide(prevIndex);
  };

  const handleNext = (e) => {
    e.stopPropagation();
    const nextIndex =
      activeSlide === AssessmentResult?.allResults?.length - 1
        ? 0
        : activeSlide + 1;
    setActiveSlide(nextIndex);
  };

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper?.activeIndex);
  };

  const breadcrumbsRoutes = [
    {
      path: "/org-admin",
      label: `Org Admin`,
    },
    {
      // path: `/org-admin/${locationId}/${userId}`,
      label: assessmentUserInfo?.first_name
        ? `${formatName(assessmentUserInfo?.first_name ?? "")} ${formatName(
            assessmentUserInfo?.last_name ?? ""
          )}`
        : assessmentUserInfo?.email ?? "Username",
    },
  ];

  const statusBtn = ({ status, isRequired, classes, disable }) => {
    if (status === "COMPLETED") {
      return (
        <VSNButton
          className={classes.btnComplete}
          sx={{ cursor: disable ? "not-allowed" : "pointer" }}
        >
          <RightRoundIcon />
          Completed
        </VSNButton>
      );
    }
    if (isRequired) {
      return (
        <VSNButton
          className={classes.btnRequired}
          sx={{ cursor: disable ? "not-allowed" : "pointer" }}
        >
          <FlashIcon />
          Required
        </VSNButton>
      );
    }
    if (!isRequired) {
      return (
        <VSNButton
          className={classes.btnRequired}
          sx={{ cursor: disable ? "not-allowed" : "pointer" }}
        >
          <OptionalIcon />
          Optional
        </VSNButton>
      );
    }
    return "";
  };

  const buttonTextForStatus = (status) => {
    if (status === "COMPLETED") {
      return "Read More";
    }
    if (status === "IGNORED") {
      return "Take Assessment";
    }
    return "";
  };

  const splitArray = (str) => {
    const data = str?.map((obj) =>
      obj?.includes(" ") ? splitArray(obj?.split(" ")) : obj
    );
    return data;
  };

  const breakpointCols = {
    default: 4,
    1440: 3,
    1200: 2,
    750: 1,
  };

  useEffect(() => {
    if (AssessmentResult) {
      setShowReleaseButton(
        AssessmentResult?.find(({ is_locked }) => is_locked)
      );
    } else {
      setShowReleaseButton(false);
    }
  }, [AssessmentResult]);
  return (
    <div className={classes.mainLayout}>
      {AssessmentReportLoader ? (
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <VSNLoader variant="fullPage" />
        </Grid>
      ) : (
        <>
          <Box
            // mt={-2}
            className={classes.headingButton}
          >
            <Stack>
              <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
              <VSNTypography className={classes.heading}>
                {assessmentUserInfo
                  ? assessmentUserInfo?.first_name
                    ? `${formatName(
                        assessmentUserInfo?.first_name ?? ""
                      )} ${formatName(assessmentUserInfo?.last_name ?? "")}`
                    : assessmentUserInfo?.email ?? "Username"
                  : "User’s Name"}
                : Assessment Reports
              </VSNTypography>
            </Stack>
            {showReleaseButton &&
              permissions?.user_assessments?.includes("release_all") && (
                <VSNButton
                  handleClick={releaseAllAssessment}
                  isLoading={releaseAllLoader}
                  className={classes?.releaseButton}
                >
                  Release All
                </VSNButton>
              )}
          </Box>
          <Masonry
            breakpointCols={breakpointCols}
            className={classes.masonryGrid}
            columnClassName="my-masonry-grid_column"
          >
            {AssessmentResult?.length ? (
              AssessmentResult?.map(
                (
                  {
                    name,
                    status,
                    is_required: isRequired,
                    result,
                    // handleButtonClick,
                    assessment_id: assessmentId,
                    type,
                    retakeAssessment,
                    userRoleData,
                    assessment_taken: assessmentTaken,
                    all_results: allResults,
                    is_locked,
                  },
                  index
                ) => {
                  return (
                    <Stack
                      key={index}
                      className={classes.assessmentCard}
                      sx={{
                        cursor: "pointer",
                        "&:before": {
                          position: "absolute",
                          content: "''",
                          inset: -2,
                          background: `linear-gradient(to right,${colorFromResults(
                            result
                          )} 50%,#F8DEFF 80%)`,
                          borderRadius: "11px",
                          zIndex: -1,
                          opacity: 0,
                        },
                      }}
                      onClick={() =>
                        assessmentId === 1
                          ? null
                          : navigate(
                              `/org-admin/${locationId}/${userId}/${assessmentId}`
                            )
                      }
                      // handleClick={(e) => {
                      //   e.stopPropagation();
                      //   handleButtonClick(
                      //     name,
                      //     assessmentId,
                      //     type,
                      //     assessmentTaken
                      //   );
                      // }}
                    >
                      {index % 2 === 0 ? (
                        <>
                          <VSNImage
                            src={halfRound}
                            alt="halfRound"
                            className={classes.halfRound}
                          />
                          <VSNImage
                            src={cloud}
                            alt="cloud"
                            className={classes.cloud}
                          />
                        </>
                      ) : (
                        <>
                          <VSNImage
                            src={greenCloud}
                            alt="greenCloud"
                            className={classes.greenCloud}
                          />
                          <VSNImage
                            src={lightBlueLeaf}
                            alt="lightBlueLeaf"
                            className={classes.lightBlueLeaf}
                          />
                        </>
                      )}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <VSNTypography
                          variant="h3"
                          className={classes.mdHeading}
                          mb="0"
                        >
                          {name}
                        </VSNTypography>
                        {statusBtn({
                          status,
                          isRequired,
                          classes,
                          disable: assessmentTaken,
                        })}
                      </Stack>
                      <Divider sx={{ marginTop: 2, borderColor: "#EAEDF0" }} />
                      {allResults?.length > 1 ? (
                        <VSNSwiper
                          className={classes.assessmentCardSlider}
                          pagination={{
                            type: "fraction",
                          }}
                          navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                            disabledClass: "swiper-button-disabled",
                          }}
                          onSlideChange={handleSlideChange}
                          initialSlide={activeSlide}
                        >
                          {allResults?.length > 1 && (
                            <>
                              <Stack
                                className="swiper-button-next"
                                onClick={handleNext}
                              />
                              <Stack
                                className="swiper-button-prev"
                                onClick={handlePrev}
                              />
                            </>
                          )}
                          {allResults?.map((el, ind) => {
                            return (
                              <SwiperSlide key={ind}>
                                <VSNTypography
                                  fontSize={{ sm: 18, xs: 16 }}
                                  fontWeight={500}
                                  color="var(--primary)"
                                  mt={2}
                                  pr={7}
                                >
                                  {el?.category}
                                </VSNTypography>
                                <Stack className={classes.imgWrapper} mb={2}>
                                  <VSNImage src={el?.image} alt="assessment1" />
                                </Stack>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: el?.result,
                                  }}
                                />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: el?.trait_description,
                                  }}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </VSNSwiper>
                      ) : (
                        <>
                          <VSNTypography
                            fontSize={{ sm: 18, xs: 16 }}
                            fontWeight={500}
                            color="var(--primary)"
                            mt={2}
                            pr={7}
                          >
                            {allResults?.[0]?.category}
                          </VSNTypography>
                          <Stack className={classes.imgWrapper} mb={2}>
                            <VSNImage
                              src={allResults?.[0]?.image}
                              alt="assessment1"
                            />
                          </Stack>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: allResults?.[0]?.result,
                            }}
                          />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: allResults?.[0]?.trait_description,
                            }}
                          />
                        </>
                      )}
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing="20px"
                      >
                        {status &&
                        (Object?.values(assessmentIds)?.includes(
                          assessmentId?.toString()
                        ) ||
                          status !== "COMPLETED") ? (
                          assessmentId !== 1 ? (
                            <VSNButton
                              variant="outlined"
                              className={classes.outLinedBtn}
                              // handleClick={(e) => {
                              //   e.stopPropagation();
                              //   handleButtonClick(
                              //     name,
                              //     assessmentId,
                              //     type,
                              //     assessmentTaken
                              //   );
                              // }}
                              startIcon={is_locked && <LockIcon />}
                            >
                              {buttonTextForStatus(status)}
                            </VSNButton>
                          ) : (
                            ""
                          )
                        ) : null}

                        {assessmentTaken && userRoleData?.is_demo_user ? (
                          <VSNButton
                            className={classes.outLinedBtn}
                            handleClick={(e) => {
                              e.stopPropagation();
                              retakeAssessment(assessmentId, type);
                            }}
                          >
                            Retake Assessment
                          </VSNButton>
                        ) : null}
                      </Stack>
                    </Stack>
                  );
                }
              )
            ) : (
              <Stack>No assessments found</Stack>
            )}
          </Masonry>
        </>
      )}
    </div>
  );
};

export default AssessmentForAdmin;
