import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import {
  headerTermsConditionDescription,
  termsConditionDescription,
} from "../../description/terms-condition.description";
import SEO from "../../shared/SEO";
import VSNTypography from "../../shared/VSNTypography";
import CommonHeader from "./CommonHeader";
import PageBgImg from "./PageBgImg";
import { ListMainPoint } from "../../shared/icon";
import BulletPoint from "../../assets/privacypolicy/png/BulletPoint1.png";
import VSNImage from "../../shared/VSNImage";
import FooterSlider from "../landing/FooterSlider";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    padding: "40px 10% 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "48px 16px 40px",
    },
    "& img": {
      objectFit: "contain",
      marginTop: 2,
    },
    "& p": {
      fontSize: 16,
      lineHeight: "21px",
      color: "var(--gray)",
      marginBottom: 20,
      marginTop: 16,
      marginLeft: 32,
      "& a": {
        color: "var(--primary)",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 20,
      },
    },
  },
  mainWrap: {
    marginBottom: 36,
    "& h6": {
      fontSize: 24,
      lineHeight: "31px",
      color: "#2D2F39",
      marginLeft: "12px",
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        lineHeight: "26px",
      },
    },
  },
  listBox: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginLeft: 32,
    "& > p": {
      margin: [[8, 0, 12]],
    },
    "& .MuiTypography-span": {
      fontSize: 16,
      lineHeight: "25px",
      color: "var(--gray)",
      marginLeft: 8,
    },
    "& .MuiStack-root": {
      marginBottom: 8,
    },
  },
}));

const TermsCondition = () => {
  const classes = useStyles();
  return (
    <>
      <SEO contentKey="termsConditions" />
      <CommonHeader
        headerDescription={headerTermsConditionDescription}
        isPrivacyPage={false}
      />
      <Box className={classes.boxWrap} position="relative">
        <PageBgImg isPrivacyPage />
        <Box className={classes.mainBox}>
          {termsConditionDescription.map(
            ({ title, hasPoint, description, color }, i) => (
              <Box className={classes.mainWrap} key={i}>
                <Stack direction="row" alignItems="flex-start">
                  <ListMainPoint color={color} />
                  <VSNTypography variant="h6" component="h6">
                    {title}
                  </VSNTypography>
                </Stack>
                <>
                  {hasPoint
                    ? description?.map(({ mainPoint, innerPoints }, index) => (
                        <Box className={classes.listBox} key={index}>
                          <VSNTypography>{mainPoint}</VSNTypography>
                          {innerPoints?.map((points, i) => (
                            <Stack
                              direction="row"
                              alignItems="flex-start"
                              key={i}
                            >
                              <VSNImage src={BulletPoint} alt="BulletPoint" />
                              <VSNTypography
                                variant="span"
                                sx={{
                                  mx: "2",
                                  "& span": {
                                    fontWeight: 600,
                                  },
                                }}
                              >
                                {points}
                              </VSNTypography>
                            </Stack>
                          ))}
                        </Box>
                      ))
                    : description.map((points, index) => (
                        <VSNTypography key={index}>{points}</VSNTypography>
                      ))}
                </>
              </Box>
            )
          )}
        </Box>
      </Box>
      <FooterSlider />
    </>
  );
};

export default TermsCondition;
