import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VSNTypography from "../../shared/VSNTypography";
import VSNPaper from "../../shared/VSNPaper";
import { ReactComponent as GiftOpen } from "../../assets/assesments/svg/gift-open.svg";
import VSNLoader from "../../shared/VSNLoader";
import VSNImage from "../../shared/VSNImage";
import VSNButton from "../../shared/VSNButton";

const useStyles = makeStyles((theme) => ({
  assesmentInfo: {
    maxWidth: 890,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    minHeight: 576,
    padding: [[45, 70, 40, 70]],
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: [[24, 16, 30, 16]],
      minHeight: "496px",
    },

    "& h5": {
      fontSize: 20,
      color: "var(--black)",
      fontWeight: 700,
      marginBottom: 20,
      [theme.breakpoints.down("370")]: {
        fontSize: 17,
      },
    },
    "& p": {
      fontSize: 14,
      color: "var(--gray)",
      marginBottom: 30,
      lineHeight: "18px",
    },
  },
  openGift: {
    marginBottom: 25,
    height: 102,
    [theme.breakpoints.down("sm")]: {
      height: 90,
    },
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: 130,
      },
    },
  },
  innerMainContent: {
    maxWidth: 472,
    width: "100%",
  },
  emailAdd: {
    marginBottom: 52,
    [theme.breakpoints.down("md")]: {
      marginBottom: 36,
    },
    "& .MuiStack-root": {
      "& label": {
        textAlign: "left",
        color: "var(--gray)",
        fontSize: 14,
        marginBottom: 0,
      },
      "& .MuiFormControl-root": {
        "& .MuiInputBase-root": {
          "& input": {
            fontSize: 18,
            color: "#202024",
            fontWeight: 700,
          },
        },
      },
    },
  },
  otpButton: {
    "&.MuiButtonBase-root.MuiButton-root": {
      maxWidth: 293,
      width: "100%",
      marginBottom: 30,
    },
  },
  socialMedia: {
    maxWidth: 293,
    width: "100%",
    margin: "auto",
  },
  orContent: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginBottom: 0,
      padding: [[0, 10]],
      color: "#86650A",
      fontSize: 14,
      fontWeight: 700,
    },
  },
  line: {
    borderTop: "1px solid  #EAEDF0",
    width: "100%",
  },
  socialMediaIcon: {
    position: "static",
    "&.MuiStack-root": {
      [theme.breakpoints.down("sm")]: {
        marginTop: 20,
        gap: 30,
      },
      "& button": {
        padding: 0,
        "& span": {
          "& img": {
            width: 30,
          },
        },
      },
    },
  },
  OTPContainer: {
    marginTop: 50,
  },
  redText: {
    "&.MuiTypography-root": {
      color: "red ",
      margin: 0,
      textAlign: "left",
      marginTop: 3,
    },
  },
  completetionLoader: {
    "& .MuiBox-root": {
      position: "static",
    },
  },
  mainBox: {
    "&.MuiStack-root": {
      maxWidth: 750,
      margin: "0 auto",
      "& img": {
        margin: "0 auto 32px",
        display: "block",
      },
      "& .MuiTypography-root": {
        textAlign: "center",
      },
      "& .MuiTypography-h5": {
        fontSize: 34,
        lineHeight: "44px",
        fontWeight: "700",
        marginBottom: 8,
        color: "var(--blackGray)",
        [theme.breakpoints.down("smd")]: {
          fontSize: 24,
          lineHeight: "31px",
        },
      },
      "& .MuiTypography-body1": {
        fontSize: 24,
        lineHeight: "31px",
        [theme.breakpoints.down("smd")]: {
          fontSize: 18,
          lineHeight: "24px",
        },
      },
      "& .MuiButtonBase-root": {
        margin: "16px auto 0",
        padding: "12.5px 16px",
      },
    },
  },
}));
const AssessmentCompletion = () => {
  const currentAssessmentId = localStorage.getItem("currentAssessmentId");
  const classes = useStyles();
  const assessmentList = useSelector((state) => state?.api?.assessmentList);
  const findAssessment = assessmentList?.find(
    (ele) => String(ele?.assessment_id) === currentAssessmentId
  );

  const navigate = useNavigate();

  if (["10", "11", "12"]?.includes(currentAssessmentId?.toString())) {
    return (
      <Stack
        padding={{ xs: "0 16px", smd: 0 }}
        alignItems="center"
        className={classes.mainBox}
      >
        <Box className={classes.content}>
          <VSNImage src={findAssessment?.thanks_note?.image} />
          <VSNTypography variant="h5" component="h5">
            {findAssessment?.thanks_note?.quote}
          </VSNTypography>
          <VSNTypography variant="body1" component="p">
            {findAssessment?.thanks_note?.description}
          </VSNTypography>
          <VSNButton handleClick={() => navigate("/assessments")}>
            Return to Dashboard
          </VSNButton>
        </Box>
      </Stack>
    );
  }
  return (
    <Stack padding={2.5} alignItems="center">
      <VSNPaper className={classes.assesmentInfo}>
        <Box className={classes.innerMainContent}>
          <Box className={classes.openGift}>
            <GiftOpen />
          </Box>
          <VSNTypography variant="h5" component="h5">
            Congratulations on completing the Assessment!
          </VSNTypography>
          <VSNTypography variant="body1" component="p">
            We&apos;re almost done! Your results will ready in just a few
            seconds.
          </VSNTypography>

          <Box mt={8} className={classes.completetionLoader}>
            <VSNLoader variant="inline" />
          </Box>
        </Box>
      </VSNPaper>
    </Stack>
  );
};

export default AssessmentCompletion;
