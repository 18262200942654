import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Stack } from "@mui/material";
import VSNModel from "../../shared/VSNModel";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import { ReactComponent as Attention } from "../../assets/common/svg/attention.svg";

import VSNTypography from "../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  leavingModel: {
    "&.MuiModal-root": {
      "& .MuiPaper-root": {
        padding: [[54, 30]],
        maxWidth: 770,
        [theme.breakpoints.down("md")]: {
          padding: [[48, 16, 30]],
        },
        "& .css-ezjfjs": {
          top: -34,
        },
      },
    },
  },
  container: {
    position: "static",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },

    "& svg": {
      marginBottom: 27,
      [theme.breakpoints.down("md")]: {
        marginBottom: 12,
        width: 250,
      },
    },
    "& h4": {
      fontWeight: 600,
      fontSize: 30,
      lineHeight: "26px",
      marginBottom: 19,
      color: "var(--blackGray)",
      [theme.breakpoints.down("md")]: {
        marginBottom: 12,
        fontSize: 22,
      },
    },
    "& p": {
      fontSize: 16,
      lineHeight: "21px",
      color: "var(--blackGray)",
      marginBottom: 45,
      [theme.breakpoints.down("md")]: {
        marginBottom: 30,
      },
    },
  },
  leaveButton: {
    "&.MuiButtonBase-root": {
      background: "transparent",
      color: "var(--primary)",
      margin: 0,
    },
  },
  attentionButton: {
    "& .MuiButtonBase-root": {
      padding: [[8, 54]],
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        padding: [[8, 40]],
      },
    },
  },
}));

const LeaveAssessment = ({ onCancel, onConfirm }) => {
  const classes = useStyles();
  return (
    <div>
      <VSNModel
        onClose={onCancel}
        open
        className={classes.leavingModel}
        mobileDrawer
      >
        <div className={classes.container}>
          <Box align="center">
            <Attention />
          </Box>
          <VSNTypography variant="h4" component="h4">
            Leaving the page?
          </VSNTypography>
          <VSNTypography variant="body1" component="p">
            The progress you made so far will not be saved.
          </VSNTypography>
          <Stack
            direction="row"
            gap={{ md: 3, xs: 2 }}
            className={classes.attentionButton}
            justifyContent="center"
          >
            <VSNButton
              type="button"
              handleClick={onConfirm}
              className={classes.leaveButton}
            >
              Leave
            </VSNButton>
            <VSNButton type="submit" handleClick={onCancel}>
              Stay
            </VSNButton>
          </Stack>
        </div>
      </VSNModel>
    </div>
  );
};

export default LeaveAssessment;
