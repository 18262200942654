import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import VSNButton from "../../shared/VSNButton";
import VSNForm from "../../shared/VSNForm";
import VSNTypography from "../../shared/VSNTypography";
import ControlForm from "../../shared/form/ControlForm";
import VSNLabel from "../../shared/VSNLabel";
import { formContainer } from "../../container/form.container";
import { adminLoginHeadingText } from "../../description/logIn.description";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import { logInContainer } from "../../container/logIn.container";
import SEO from "../../shared/SEO";

const useStyles = makeStyles((theme) => ({
  authTitle: {
    "&.MuiTypography-h3": {
      fontWeight: 500,
      fontSize: 22,
      lineHeight: "25px",
      color: "var(--blackGray)",
      marginBottom: 40,
      padding: [[16, 0]],
      [theme.breakpoints.down("md")]: {
        marginBottom: 32,
        padding: 0,
        fontSize: 18,
      },
    },
  },
  formGroup: {
    marginBottom: 15,
  },
  formInput: {
    marginTop: "-5px !important",
  },
}));

const AdminLogIn = () => {
  const classes = useStyles();
  const {
    logInAttribute,
    handleChange,
    formPath,
    onSubmit,
    loginLoader,
    isDisable,
  } = logInContainer();
  const { formData, formErrors } = formContainer({ formPath });

  return (
    <>
      <SEO contentKey="login" />
      <VSNTypography variant="h3" className={classes.authTitle}>
        {adminLoginHeadingText}
      </VSNTypography>
      <VSNForm>
        <Grid container>
          <Grid item xs={12}>
            {logInAttribute?.map(
              (
                { isRequired, label, errorMessage, name, ...attribute },
                index
              ) => {
                return (
                  <Box className={classes.formGroup} key={index}>
                    <VSNLabel required={isRequired} label={label} />
                    <ControlForm
                      {...{
                        ...attribute,
                        onChange: handleChange,
                        name,
                        value: formData?.[name],
                        className: classes.formInput,
                      }}
                    />

                    <VSNErrorMessage
                      {...{ formError: formErrors?.[name], errorMessage }}
                    />
                  </Box>
                );
              }
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mt={{ md: 5, xs: 3 }} display="flex" justifyContent="center">
              <VSNButton
                type="submit"
                handleClick={onSubmit}
                isLoading={loginLoader}
                disabled={isDisable}
              >
                Next
              </VSNButton>
            </Box>
          </Grid>
        </Grid>
      </VSNForm>
    </>
  );
};

export default AdminLogIn;
