import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import VSNSelect from "../../../shared/VSNSelect";
import VSNTypography from "../../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  information: {
    position: "relative",
    alignItems: "center",
    maxWidth: 830,
    width: "100%",
    margin: "0 auto 48px",
    padding: "0 15px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: 15,
    },
    "& h4": {
      fontSize: 22,
      lineHeight: "38.21px",
      fontWeight: 600,
      color: "var(--darkGray)",
      marginBottom: "8px",
      textAlign: "center",
      flex: 1,
      [theme.breakpoints.down("lg")]: {
        textAlign: "center",
      },
      [theme.breakpoints.down("md")]: {
        lineHeight: "31px",
        marginBottom: 10,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        padding: [[0]],
        marginBottom: 10,
      },
    },
    "& .MuiFormControl-root": {
      // maxWidth: 330,
      width: "100%",
      "& .MuiInputBase-root": {
        borderRadius: "4px",
        boxShadow: " 0px 9px 20px 0px #968FB01A",
        "& .MuiSelect-outlined": {
          paddingLeft: 13,
          padding: "16px 32px !important",
          fontSize: 18,
          lineHeight: "26.83px",
          fontWeight: 500,
          color: "var(--gray)",
        },
      },
      "& fieldset": {
        borderColor: "#EAEDF0",
      },
    },
  },
}));

const VSNSelectDropDown = ({ question, onChange, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.information}>
      <VSNTypography variant="h4" component="h4">
        {`${question?.order}. ${question?.phrase}`}
      </VSNTypography>
      <VSNSelect
        onChange={(e) => {
          const selectedValue = e.target.value;
          const newValue = question.choices.find(
            (item) => item?.result === selectedValue
          );
          onChange(newValue, question);
        }}
        value={value?.value?.result || 0}
        options={question.choices.map((item) => ({
          key: item?.phrase,
          value: item?.result,
        }))}
      />
    </Box>
  );
};

export default VSNSelectDropDown;
