import React from "react";
import SelectBox from "./QuestionComponent/SelectBox";
import VSNTextField from "../../shared/VSNTextField";
import VSNSelect from "../../shared/VSNSelect";
import DoNotAllowReSubmissionSelect from "./QuestionComponent/DoNotAllowReSubmissionSelect";
import DoNotAllowReSubmissionChoice from "./QuestionComponent/DoNotAllowReSubmissionChoice";
import MultiSelectComponent from "./QuestionComponent/MultiSelectComponent";
import VSNDropDown from "./QuestionComponent/VSNDropDown";
import InputField from "./QuestionComponent/InputField";
import SingleLineSelect from "./QuestionComponent/SingleLineSelect";
import VSNSelectDropDown from "./QuestionComponent/VSNSelectDropDown";
import StaticQuestion from "./QuestionComponent/StaticQuestion";
import DateInputField from "./QuestionComponent/DateInputField";
import SingleLineAnswer from "./QuestionComponent/SingleLineAnswer";

const CustomQuestion = ({
  question,
  onChange,
  value,
  handelNextQuestion,
  currentQuestionIndex,
  allow_resubmission,
  onChangeMultiChoice,
}) => {
  switch (question?.answer_type) {
    case "text":
    case "number":
      return (
        <InputField
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
          }}
        />
      );
    case "date":
      return (
        <DateInputField
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
          }}
        />
      );
    case "single-option":
      return (
        <DoNotAllowReSubmissionSelect
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
          }}
        />
      );
    case "single-choice":
      if (!allow_resubmission) {
        return (
          <DoNotAllowReSubmissionChoice
            {...{
              question,
              onChange,
              value,
              handelNextQuestion,
              currentQuestionIndex,
              allow_resubmission,
            }}
          />
        );
      }
      return (
        <SelectBox
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
          }}
        />
      );
    case "single-line-choice":
      return (
        <SingleLineSelect
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
          }}
        />
      );
    case "single-line-answer":
      return (
        <SingleLineAnswer
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
          }}
        />
      );
    case "multiple-choice":
      return (
        <MultiSelectComponent
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
            onChangeMultiChoice,
          }}
        />
      );
    case "dropdown":
      return (
        <VSNDropDown
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
            onChangeMultiChoice,
          }}
        />
      );
    case "select-dropdown":
      return (
        <VSNSelectDropDown
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
            onChangeMultiChoice,
          }}
        />
      );
    case "static-json":
      return (
        <StaticQuestion
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
          }}
        />
      );
    default:
      return (
        <SingleLineSelect
          {...{
            question,
            onChange,
            value,
            handelNextQuestion,
            currentQuestionIndex,
            allow_resubmission,
          }}
        />
      );
  }
};

export default CustomQuestion;
