import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box, Menu } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { makeStyles } from "@mui/styles";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import VSNTypography from "./VSNTypography";

const useStyles = makeStyles((theme) => ({
  dateField: {
    "& div": {
      display: "flex",
      alignItems: "center",
      marginTop: 0,
    },
    "& p": {
      marginBottom: "0 !important",
      paddingLeft: 4,
      "& span": {
        color: "rgb(198, 198, 205)",
        fontSize: "14px",
        marginBottom: 0,
      },
      fontSize: "14px !important",
    },
    border: "1px solid #EAEDF0",
    height: "40px",
    borderRadius: 8,
    fontWeight: "500 !important",
    padding: "8px 8px !important",
    minWidth: "181px",
    "& .dateIcon": {
      color: "#c6c6cd",
    },
    "& .dateIconPrimary": {
      color: "var(--Tertiary)",
    },
  },
}));

const VSNDatePicker = ({ name, onFilterChange, value: dateValue }) => {
  const classes = useStyles();
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const inputRef = React.useRef();
  const [toggle, setToggle] = useState(null);
  const [date, setDate] = useState({});
  const [focus, setFocus] = useState([0, 1]);

  const handleChange = (val) => {
    const isFuture =
      dayjs(val?.selection?.endDate).valueOf() > dayjs().valueOf();
    setValue({
      startDate: val?.selection?.startDate,
      endDate: isFuture ? new Date() : val?.selection?.endDate,
      key: "selection",
    });
    const startDate = dayjs(val?.selection?.startDate).format("YYYY-MM-DD");
    const endDate = isFuture
      ? dayjs().format("YYYY-MM-DD")
      : dayjs(val?.selection?.endDate).format("YYYY-MM-DD");
    setDate({ startDate, endDate });
  };

  useEffect(() => {
    if (!toggle) {
      onFilterChange({
        target: { name, value: { ...date } },
      });
    }
  }, [toggle]);

  useEffect(() => {
    if (focus?.every((val) => !val)) {
      setToggle(null);
    }
  }, [focus]);

  useEffect(() => {
    const elements = document.getElementsByClassName("rdrStaticRange");
    for (let index = 0; index < elements.length; index += 1) {
      elements[index]?.addEventListener("click", () => setToggle(null));
    }
  }, [{ ...value }]);

  useEffect(() => {
    setDate({
      startDate: dateValue?.startDate,
      endDate: dateValue?.endDate,
    });
    setValue({
      startDate: new Date(dayjs(dateValue?.startDate).toDate()),
      endDate: new Date(dayjs(dateValue?.endDate).toDate()),
      key: "selection",
    });
  }, [dateValue]);

  return (
    <>
      <Box
        className={classes?.dateField}
        ref={inputRef}
        onClick={() => setToggle(inputRef?.current)}
      >
        <Box>
          <DateRangeIcon
            className={date?.startDate ? "dateIconPrimary" : "dateIcon"}
          />
          <VSNTypography>
            {date?.startDate ? (
              `${dayjs(date?.startDate).format("ll")}-${dayjs(
                date?.endDate
              ).format("ll")}`
            ) : (
              <VSNTypography variant="span" component="span">
                Select the date
              </VSNTypography>
            )}
          </VSNTypography>
        </Box>
      </Box>
      <Menu
        id="lock-menu"
        anchorEl={toggle}
        open={!!toggle}
        onClose={() => setToggle(null)}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <DateRangePicker
          maxDate={new Date()}
          ranges={[value]}
          onChange={handleChange}
          inputRanges={[]}
          focusedRange={focus}
          onRangeFocusChange={(focus) => setFocus(focus)}
          initialFocusedRange={[0, 1]}
        >
          {" "}
        </DateRangePicker>
      </Menu>
    </>
  );
};

export default VSNDatePicker;
