import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import cpet1 from "../../../../assets/assesments/physical/cpet/cpet-1.svg";
import cpet2 from "../../../../assets/assesments/physical/cpet/cpet-2.svg";
import cpet3 from "../../../../assets/assesments/physical/cpet/cpet-3.svg";
import cpet4 from "../../../../assets/assesments/physical/cpet/cpet-4.svg";

export const keyInsight = [
  {
    heading: "EKG Summary:",
    description: "Normal at rest and throughout exercise",
    icon: "Download EKG Summary",
  },
  {
    heading: "Interpretation:",
    description:
      "Normal cardiopulmonary test. Improved physiological condition compared to previous study",
  },
  {
    heading: "ACSM Aerobic Fitness Classification for Age:",
    description: "Good",
  },
];
export const optionOne = {
  series: [
    {
      type: "gauge",
      startAngle: 225,
      endAngle: -45,
      min: 0,
      max: 100.0,
      splitNumber: 10,
      axisLine: {
        lineStyle: {
          width: 10,
          color: [
            [0.5, "#FDE9C8"],
            [0.8, "#C3EFD1"],
            [1, "#34A259"],
          ],
        },
      },
      pointer: {
        width: 5,
        length: "60%",
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        distance: -2,
        fontSize: 16,
        formatter: (value) => {
          if (value === 0) return "6.2";
          if (value === 100) return "60.0";
          return "";
        },
      },
      detail: {
        fontSize: 20,
        fontWeight: "700",
        formatter: "{value}",
        offsetCenter: [0, "80%"],
      },
      data: [{ value: 48.1 }],
    },
  ],
};

export const optionTwo = {
  series: [
    {
      type: "gauge",
      startAngle: 225,
      endAngle: -45,
      min: 0,
      max: 100.0,
      splitNumber: 10,
      axisLine: {
        lineStyle: {
          width: 10,
          color: [
            [0.6, "#FDE9C8"],
            [0.7, "#C3EFD1"],
            [1, "#34A259"],
          ],
        },
      },
      pointer: {
        width: 5,
        length: "60%",
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        distance: -2,
        fontSize: 16,
        formatter: (value) => {
          if (value === 0) return "0";
          if (value === 100) return "450";
          return "";
        },
      },
      detail: {
        fontSize: 20,
        fontWeight: "700",
        formatter: "{value}",
        offsetCenter: [0, "80%"],
      },
      data: [{ value: 320 }],
    },
  ],
};

export const optionThree = {
  series: [
    {
      type: "gauge",
      startAngle: 225,
      endAngle: -45,
      min: 0,
      max: 100.0,
      splitNumber: 10,
      axisLine: {
        lineStyle: {
          width: 10,
          color: [
            [0.6, "#FDE9C8"],
            [0.8, "#C3EFD1"],
            [1, "#34A259"],
          ],
        },
      },
      pointer: {
        width: 5,
        length: "60%",
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        distance: -2,
        fontSize: 16,
        formatter: (value) => {
          if (value === 0) return "0.0";
          if (value === 100) return "30.0";
          return "";
        },
      },
      detail: {
        fontSize: 20,
        fontWeight: "700",
        formatter: "{value}",
        offsetCenter: [0, "80%"],
      },
      data: [{ value: 21.1 }],
    },
  ],
};

export const chartOfCPET = [
  {
    option: {
      tooltip: {
        trigger: "none", // Show tooltip for line chart and scatter points
      },

      xAxis: {
        type: "category",
        data: ["0", "5", "10", "15", "20", "25", "30"], // Categorical time labels
        name: "Time (min)",
        nameLocation: "middle",
        nameGap: 30,
        axisLabel: {
          rotate: 0,
        },
      },

      yAxis: [
        {
          type: "value",
          name: "VO2 and VCO2",
          nameLocation: "middle",
          nameRotate: 90,
          nameGap: 40,
        },
        {
          type: "value",
          name: "Watts",
          position: "right",
          nameLocation: "middle",
          nameRotate: 90,
          nameGap: 40,
          splitLine: {
            show: false,
          },
        },
      ],

      series: [
        {
          data: [
            ["0", 150],
            ["5", 230],
            ["10", 224],
            ["15", 218],
            ["20", 135],
            ["25", 147],
            ["30", 260],
          ],
          type: "line",
          name: "Primary Line",
          yAxisIndex: 0,
          itemStyle: {
            color: "#6B7EE9",
          },
        },
        {
          data: [
            ["0", 10],
            ["5", 20],
            ["10", 20],
            ["15", 30],
            ["20", 25],
            ["25", 35],
            ["30", 25],
          ],
          type: "line",
          name: "Secondary Line",
          yAxisIndex: 1,
          itemStyle: {
            color: "#F54346",
          },
        },
      ],
    },
    header: "Exercise Capacity",
    description:
      "Exercise capacity reflects the maximum exertion an individual can sustain, measured through key metrics like VO2 max, VCO2 and workload (watts). Higher values indicate better fitness and oxygen utilization during exercise.",
    note: "*VO2L/min represents the highest amount of oxygen uptake during exercise",
    legend: [
      {
        color: "#F54346",
        label: "VCO2 (L/min)",
      },
      {
        color: "#6B7EE9",
        label: "VO2 L/min",
      },
      {
        color: "#3A3A40",
        label: "Watts",
      },
    ],
  },
  {
    option: {
      tooltip: {
        trigger: "none", // Disable the tooltip
      },

      xAxis: {
        min: 0,
        max: 20,
        splitLine: false,
        axisLine: {
          show: false, // Hides the xAxis line
        },
        axisTick: {
          show: false, // Hides the ticks on xAxis
        },
        name: "VO2 (L/min)", // Set the label for xAxis
        nameLocation: "middle", // Place the label in the middle of the xAxis
        nameGap: 30, // Adjust the gap between the xAxis and the label
      },

      yAxis: [
        {
          min: 0,
          max: 15,
          axisLine: {
            show: false, // Hides the yAxis line on the left
          },
          axisTick: {
            show: false, // Hides the ticks on yAxis
          },
          position: "left", // Position the first yAxis on the left
          name: "O2 - Pulse (ml/beat)", // Add label for the first yAxis
          nameLocation: "middle",
          nameGap: 30, // Adjust gap for the yAxis label on the left
        },
        {
          min: 0,
          max: 15,
          axisLine: {
            show: false, // Hides the yAxis line on the right
          },
          axisTick: {
            show: false, // Hides the ticks on yAxis
          },
          position: "right", // Position the second yAxis on the right
          name: "Watts", // Add label for the second yAxis
          nameLocation: "middle",
          nameGap: 30, // Adjust gap for the yAxis label on the right
        },
      ],

      series: [
        {
          name: "O2 - Pulse (ml/beat)", // Name of the first data type
          type: "scatter",
          xAxisIndex: 0,
          yAxisIndex: 0, // Link to the primary (left) yAxis
          data: [
            [10.0, 8.04],
            [8.0, 6.95],
            [13.0, 7.58],
            [9.0, 8.81],
            [11.0, 8.33],
          ], // Data for Type 1
          itemStyle: {
            color: "#AAC1F7", // Color for Type 1 dots
          },
          markLine: {
            animation: false,
            lineStyle: {
              type: "solid",
            },
            data: [
              [
                {
                  coord: [0, 3],
                  symbol: "none",
                },
                {
                  coord: [20, 13],
                  symbol: "none",
                },
              ],
            ],
          },
        },
        {
          name: "Watts", // Name of the second data type
          type: "scatter",
          xAxisIndex: 0,
          yAxisIndex: 1, // Link to the secondary (right) yAxis
          data: [
            [14.0, 9.96],
            [6.0, 7.24],
            [4.0, 4.26],
            [12.0, 10.84],
            [7.0, 4.82],
            [5.0, 5.68],
          ], // Data for Type 2
          itemStyle: {
            color: "#FFA2A4", // Color for Type 2 dots
          },
        },
      ],
    },
    header: "Cardiac Output vs Oxygen Uptake",
    description:
      "Cardiac Output vs. Oxygen Uptake shows how well the heart pumps blood and supports oxygen use during exercise. A strong correlation indicates efficient cardiovascular and oxygen transport.",
    note: "Synchronization of heart rate and stroke volume to oxygen uptake (VO2)",
    legend: [
      {
        color: "#3A3A40",
        label: "VCO2 (L/min)",
      },
      {
        color: "#AAC1F7",
        label: "O2 - Pulse",
      },
      {
        color: "#FFA2A4",
        label: "HR",
      },
    ],
  },
  {
    option: {
      tooltip: {
        trigger: "none", // Show tooltip for line chart and scatter points
      },
      xAxis: {
        type: "category",
        data: ["0", "5", "10", "15", "20", "25", "30"], // Categorical time labels
        name: "Time (sec)",
        nameLocation: "middle",
        nameGap: 30,
        axisLabel: {
          rotate: 0,
        },
      },

      yAxis: [
        {
          type: "value",
          name: "Heart Rate",
          nameLocation: "middle",
          nameRotate: 90,
          nameGap: 40,
        },
        {
          type: "value",
          name: "O2 - Pulse",
          position: "right",
          nameLocation: "middle",
          nameRotate: 90,
          nameGap: 40,
          splitLine: {
            show: false,
          },
        },
      ],

      series: [
        {
          data: [
            ["0", 150],
            ["5", 230],
            ["10", 224],
            ["15", 218],
            ["20", 135],
            ["25", 147],
            ["30", 260],
          ],
          type: "line",
          name: "Primary Line",
          yAxisIndex: 0,
          itemStyle: {
            color: "#6B7EE9",
          },
        },
        {
          data: [
            ["0", 10],
            ["5", 20],
            ["10", 20],
            ["15", 30],
            ["20", 25],
            ["25", 35],
            ["30", 25],
          ],
          type: "line",
          name: "Secondary Line",
          yAxisIndex: 1,
          itemStyle: {
            color: "#F54346",
          },
        },
      ],
    },
    header: "Cardiac Output (HRxSV)",
    description:
      "Cardiac Output (HR x SV) signifies the volume of blood the heart pumps per minute, calculated by multiplying heart rate (HR) by stroke volume (SV). It reflects the heart's ability to supply oxygen-rich blood to the muscles during exercise.",
    note: "*Cardiac output (Heart Rate & Stroke Volume) increases oxygen uptake (VO2) during exercise. Shaded areas show normal ranges.",
    legend: [
      {
        color: "#F54346",
        label: "HR (bpm)",
      },
      {
        color: "#6B7EE9",
        label: "O2 - Pulse",
      },
    ],
  },
  {
    option: {
      tooltip: {
        trigger: "none",
      },
      grid: {
        show: false, // Hides the grid lines
      },
      xAxis: {
        type: "value",
        name: "VO₂(L/min)",
        nameLocation: "middle", // Moves the name to the middle of the axis
        nameGap: 30, // Adds gap between the name and the axis (adjust as needed)
        min: 0,
        max: 300,
        interval: 50,
        axisLine: {
          show: false, // Hides the xAxis line
        },
        axisTick: {
          show: false, // Hides the ticks on xAxis
        },
        splitLine: false,
        lineStyle: { show: false },
        axisLabel: {
          show: true, // Displays the labels
          formatter: (value) => {
            return value; // Customize label format if needed
          },
        },
      },
      yAxis: {
        type: "value",
        name: "Watts(Watts)",
        nameLocation: "middle", // Moves the name to the middle of the axis
        nameGap: 30, // Adds gap between the name and the axis (adjust as needed)
        min: 12,
        max: 22,
        interval: 2,
        axisLine: {
          show: false, // Hides the yAxis line
        },
        axisTick: {
          show: false, // Hides the ticks on yAxis
        },
        axisLabel: {
          show: true, // Displays the labels
          formatter: (value) => {
            return value; // Customize label format if needed
          },
        },
      },
      series: [
        {
          name: "Normal Slope",
          type: "line",
          symbol: "none",
          lineStyle: {
            type: "solid",
            color: "#c4c4c4",
          },
          data: [
            [0, 13],
            [50, 14],
            [100, 15],
            [150, 16],
            [200, 17],
            [250, 18],
          ],
        },
        {
          name: "VO₂ / Workrate",
          type: "line",
          symbol: "circle",
          symbolSize: 6,
          itemStyle: {
            color: "#e74c3c",
          },
          lineStyle: {
            color: "#e74c3c",
          },
          data: [
            [0, 13],
            [25, 15],
            [75, 16],
            [125, 17],
            [175, 18],
            [225, 20],
            [275, 21],
            [300, 22],
          ],
        },
      ],
    },
    header: "Oxygen Transport/Utilization",
    description:
      "Exercise capacity reflects the maximum exertion an individual can sustain, measured through key metrics like VO2 max, VCO2 and workload (watts). Higher values indicate better fitness and oxygen utilization during exercise.",
    note: "VO2 /Workrate slope represent both side the transport of oxygen and it’s utilization by the working muscle tissue",
    legend: [
      {
        color: "#CBCBCF",
        label: "Normal Slope",
      },
      {
        color: "#F54346",
        label: "VO2 /Workrate",
      },
    ],
  },
];
export const chartOfLung = [
  {
    option: {
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false }, // Hide x-axis labels
      },
      yAxis: {
        type: "value",
        min: -4,
        max: 10,
        interval: 2, // Set interval to 2 for even spacing of measurements
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: {
          lineStyle: {
            color: "#e0e0e0",
          },
        },
        name: "VO2 and VCO2", // Name of the Y-axis
        nameLocation: "middle", // Position the Y-axis name in the middle
        nameGap: 30, // Adjust the gap to position the name in the middle
        nameTextStyle: {
          fontSize: 12, // Adjust the font size
          color: "#333", // Set the text color
        },
      },
      grid: {
        top: "10%",
        bottom: "10%",
        left: "10%",
        right: "10%",
      },
      series: [
        {
          name: "VO2 and VCO2",
          data: [3, 4, 5, 7, 9, 10, 8, 7, 6, 5],
          type: "line",
          smooth: false, // Straight lines
          symbol: "circle",
          symbolSize: 6,
          lineStyle: {
            color: "#ffa500",
            width: 2,
          },
          itemStyle: {
            color: "#ffa500",
          },
        },
        {
          name: "Series 2",
          data: [0, -0.5, -1.2, -2.5, -3, -2.8, -2.3, -2, -1.8, -1.5],
          type: "line",
          smooth: false, // Straight lines
          symbol: "circle",
          symbolSize: 6,
          lineStyle: {
            color: "#00b050",
            width: 2,
          },
          itemStyle: {
            color: "#00b050",
          },
        },
      ],
    },
    header: "Oxygen Transport/Utilization",
    description:
      "Exercise capacity reflects the maximum exertion an individual can sustain, measured through key metrics like VO2 max, VCO2 and workload (watts). Higher values indicate better fitness and oxygen utilization during exercise.",
    note: "VO2 /Workrate slope represent both side the transport of oxygen and it’s utilization by the working muscle tissue",
    legend: [
      {
        color: "#CBCBCF",
        label: "Normal Slope",
      },
      {
        color: "#F54346",
        label: "VO2 /Workrate",
      },
    ],
  },
  {
    option: {
      xAxis: {
        type: "category",
        data: ["0", "100", "200", "300", "400", "500", "600", "700", "800"], // Dummy data for time in minutes
        name: "Time (Min)", // x-axis label
        nameLocation: "middle",
        nameGap: 30,
        axisLine: { show: true },
        axisTick: { show: true },
        axisLabel: { color: "#666" },
      },
      yAxis: [
        {
          type: "value",
          min: 0, // Start from 0
          max: 60,
          interval: 10,
          name: "Heart Rate",
          nameLocation: "middle",
          nameGap: 40,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { color: "#666" },
          splitLine: { lineStyle: { color: "#e0e0e0" } },
        },
        {
          type: "value",
          min: 0, // Start from 0
          max: 6,
          interval: 1,
          name: "L/min",
          nameLocation: "middle",
          nameGap: 40,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { color: "#666" },
          splitLine: { show: false }, // Disable split line on the second y-axis
        },
      ],
      grid: {
        top: "10%",
        bottom: "15%",
        left: "10%",
        right: "10%",
      },
      series: [
        {
          name: "Heart Rate",
          data: [20, 30, 40, 55, 40, 55, 45, 30, 20], // Modified data for Heart Rate
          type: "line",
          smooth: false,
          symbol: "circle",
          symbolSize: 6,
          lineStyle: {
            color: "#00b050", // Green color for Heart Rate line
            width: 2,
          },
          itemStyle: {
            color: "#00b050",
          },
          markArea: {
            data: [
              [
                {
                  yAxis: 40, // Start of shaded region
                  itemStyle: { color: "rgba(0, 176, 80, 0.1)" }, // Green shaded area
                },
                {
                  yAxis: 50, // End of shaded region
                },
              ],
            ],
          },
        },
        {
          name: "L/min",
          yAxisIndex: 1,
          data: [2, 1.5, 0.7, 2, 0.7, 2, 1.5, 2, 1.5], // Modified data for L/min
          type: "line",
          smooth: false,
          symbol: "circle",
          symbolSize: 6,
          lineStyle: {
            color: "#ffa500", // Orange color for L/min line
            width: 2,
          },
          itemStyle: {
            color: "#ffa500",
          },
        },
      ],
    },
    header: "Oxygen Transport/Utilization",
    description:
      "Exercise capacity reflects the maximum exertion an individual can sustain, measured through key metrics like VO2 max, VCO2 and workload (watts). Higher values indicate better fitness and oxygen utilization during exercise.",
    note: "VO2 /Workrate slope represent both side the transport of oxygen and it’s utilization by the working muscle tissue",
    legend: [
      {
        color: "#CBCBCF",
        label: "Normal Slope",
      },
      {
        color: "#F54346",
        label: "VO2 /Workrate",
      },
    ],
  },
  {
    option: {
      xAxis: {
        type: "value",
        name: "VE (L/min)",
        nameLocation: "middle",
        nameTextStyle: {
          padding: [20, 0, 0, 0],
          fontSize: 12,
          color: "#999",
        },
        axisLabel: {
          fontSize: 12,
          color: "#666",
        },
        axisLine: { show: false },
        axisTick: { show: false },
        min: 0,
        max: 200,
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        name: "Tidal Volume (L/min)",
        nameLocation: "middle",
        nameTextStyle: {
          padding: [0, 0, 20, 0],
          fontSize: 12,
          color: "#999",
        },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          fontSize: 12,
          color: "#666",
        },
        min: 12,
        max: 24,
        splitLine: {
          show: true,
          lineStyle: {
            type: "solid",
            color: "#eee",
          },
        },
      },
      series: [
        {
          type: "scatter",
          data: [
            [35, 13.5],
            [40, 13.7],
            [45, 14.1],
            [50, 14.4],
            [55, 14.9],
            [60, 15.3],
            [65, 15.7],
            [70, 16.1],
            [75, 16.4],
            [80, 16.8],
            [85, 17.2],
            [90, 17.6],
            [95, 18.0],
            [100, 18.3],
            [105, 18.7],
            [110, 19.0],
            [115, 19.3],
            [120, 19.7],
            [125, 20.0],
            [130, 20.3],
            [135, 20.6],
            [140, 21.0],
            [145, 21.2],
            [150, 21.5],
            [155, 21.8],
            [160, 22.0],
            [165, 22.3],
            [170, 22.6],
            [175, 22.8],
            [180, 23.1],
            [185, 23.3],
            [190, 23.6],
            [195, 23.8],
          ],
          symbolSize: 15,
          itemStyle: {
            color: "rgba(255, 99, 132, 0.5)",
          },
        },
      ],
      lineStyle: {
        show: false, // Ensures no line is drawn
      },
    },
    header: "Oxygen Transport/Utilization",
    description:
      "Exercise capacity reflects the maximum exertion an individual can sustain, measured through key metrics like VO2 max, VCO2 and workload (watts). Higher values indicate better fitness and oxygen utilization during exercise.",
    note: "VO2 /Workrate slope represent both side the transport of oxygen and it’s utilization by the working muscle tissue",
    legend: [
      {
        color: "#CBCBCF",
        label: "Normal Slope",
      },
      {
        color: "#F54346",
        label: "VO2 /Workrate",
      },
    ],
  },
  {
    option: {
      tooltip: {
        trigger: "none",
      },
      grid: {
        show: false, // Hides the grid lines
      },
      xAxis: {
        type: "value",
        name: "VO₂(L/min)",
        nameLocation: "middle", // Moves the name to the middle of the axis
        nameGap: 30, // Adds gap between the name and the axis (adjust as needed)
        min: 0,
        max: 300,
        interval: 50,
        axisLine: {
          show: false, // Hides the xAxis line
        },
        axisTick: {
          show: false, // Hides the ticks on xAxis
        },
        splitLine: false,
        lineStyle: { show: false },
        axisLabel: {
          show: true, // Displays the labels
          formatter: (value) => {
            return value; // Customize label format if needed
          },
        },
      },
      yAxis: {
        type: "value",
        name: "Watts(Watts)",
        nameLocation: "middle", // Moves the name to the middle of the axis
        nameGap: 30, // Adds gap between the name and the axis (adjust as needed)
        min: 12,
        max: 22,
        interval: 2,
        axisLine: {
          show: false, // Hides the yAxis line
        },
        axisTick: {
          show: false, // Hides the ticks on yAxis
        },
        axisLabel: {
          show: true, // Displays the labels
          formatter: (value) => {
            return value; // Customize label format if needed
          },
        },
      },
      series: [
        {
          name: "Normal Slope",
          type: "line",
          symbol: "none",
          lineStyle: {
            type: "solid",
            color: "#c4c4c4",
          },
          data: [
            [0, 13],
            [50, 14],
            [100, 15],
            [150, 16],
            [200, 17],
            [250, 18],
          ],
        },
        {
          name: "VO₂ / Workrate",
          type: "line",
          symbol: "circle",
          symbolSize: 6,
          itemStyle: {
            color: "#e74c3c",
          },
          lineStyle: {
            color: "#e74c3c",
          },
          data: [
            [0, 13],
            [25, 15],
            [75, 16],
            [125, 17],
            [175, 18],
            [225, 20],
            [275, 21],
            [300, 22],
          ],
        },
      ],
    },
    header: "Oxygen Transport/Utilization",
    description:
      "Exercise capacity reflects the maximum exertion an individual can sustain, measured through key metrics like VO2 max, VCO2 and workload (watts). Higher values indicate better fitness and oxygen utilization during exercise.",
    note: "VO2 /Workrate slope represent both side the transport of oxygen and it’s utilization by the working muscle tissue",
    legend: [
      {
        color: "#CBCBCF",
        label: "Normal Slope",
      },
      {
        color: "#F54346",
        label: "VO2 /Workrate",
      },
    ],
  },
];

export const zones = [
  {
    label: "ZONE 2",
    backgroundColor: "#EFF5FE",
    hoverColor: "#EFF5FE",
    color: "#6B7EE9",
    icon: <AccessTimeIcon fontSize="large" sx={{ color: "#8B3F9E" }} />,
    intensity: "Light Intensity",
    details: "56% VO2max",
    hrBike: "HR 110-125",
    wattsBike: "160 Watts",
    hrRun: "HR 121-137",
  },
  {
    label: "ZONE 3",
    backgroundColor: "#F2FBF5",
    hoverColor: "#F2FBF5",
    color: "#2D8F4E",
    icon: <DirectionsBikeIcon fontSize="large" sx={{ color: "#8B3F9E" }} />,
    intensity: "Moderate Intensity",
    details: "70% VO2max",
    hrBike: "HR 125-145",
    wattsBike: "217 Watts",
    hrRun: "HR 137-160",
  },
  {
    label: "ZONE 4",
    backgroundColor: "#FFF8EB",
    hoverColor: "#FFF8EB",
    color: "#F07610",
    icon: <DirectionsRunIcon fontSize="large" sx={{ color: "#8B3F9E" }} />,
    intensity: "Vigorous Intensity",
    details: "83% VO2max",
    hrBike: "HR 146-166",
    wattsBike: "217 Watts",
    hrRun: "HR 160-182",
  },
  {
    label: "ZONE 5",
    backgroundColor: "#FFE1E2",
    hoverColor: "#FFE1E2",
    color: "#9D1719",
    icon: <AccessTimeIcon fontSize="large" sx={{ color: "#8B3F9E" }} />,
    intensity: "Maximal",
    details: "100% VO2max",
    hrBike: "HR 166-184",
    wattsBike: "320 Watts",
    hrRun: "HR 182-202",
  },
];

export const xray_faqs = [
  {
    description:
      "Undertake moderate intensity (zone 3) aerobic exercise for a minimum of 30 minutes on five days per week or vigorous intensity (zone 4)aerobic exercise for a minimum of 20 minutes on three days per week.",
  },
  {
    description:
      "Undertake activities that maintain or increase muscular strength and endurance performed a minimum of two days per week.",
  },
  {
    description:
      "Zone 2 training may feel unproductive because of its low intensity, however, it improves your body's ability to utilize fat during exercise, improving overall performance and recovery.",
  },
  {
    description:
      "Adaptations from Zone 2 training include: improved work capacity, delayed onset of fatigue, increased stroke volume, O2 transport and VO2max.",
  },
];

export const swiperData = [
  {
    image: cpet1,
    title: "Do More Low-Intensity (Zone 2) Workouts",
    description:
      "Spend more time doing easy exercises, like walking or light jogging. These workouts improve how your body uses oxygen and help build endurance over time.",
  },
  {
    image: cpet2,
    title: "Boost Your Overall Fitness",
    description:
      "Regular cardio exercises like running, swimming, or cycling can improve your ability to handle physical tasks, helping your heart and lungs work more efficiently.",
  },
  {
    image: cpet3,
    title: "Improve Your Recovery After Exercise",
    description:
      "After intense activities, your heart should return to normal quickly. To improve this, try doing short bursts of high-intensity exercise followed by rest.",
  },
  {
    image: cpet4,
    title: "Train Your Heart to Handle Stress",
    description:
      "Regular endurance training, like long-distance running or biking, helps your heart adapt to physical challenges.",
  },
];
