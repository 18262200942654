import { makeStyles } from "@mui/styles";
import { colorPalatte } from "../../description/type.description";

export const TypeShowStyle = makeStyles((theme) => ({
  banner: {
    position: "relative",
    height: 394,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      height: 148,
    },
    "&::after": {
      position: "absolute",
      content: "''",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: (props) =>
        `linear-gradient(125deg, ${props?.gradient2} 0%, ${props?.gradient1} 84.07%)`,
      zIndex: -1,
    },
  },
  bannerBgImg: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  bannerText: {
    textAlign: "center",
    zIndex: 1,
    "& img": {
      width: 139,
      height: 70,
      marginTop: -17,
      [theme.breakpoints.down("md")]: {
        height: 25,
        width: "15%",
        lineHeight: "25px",
        marginTop: -5,
        paddingBbottom: "10px",
      },
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 60,
      lineHeight: "104%",
      color: "#2D2F39",
      fontWeight: "700",
      paddingRight: 10,
      [theme.breakpoints.down("md")]: {
        paddingRight: 3,
        fontSize: 22,
        lineHeight: "25px",
      },
    },
  },
  desc: {
    "&.MuiTypography-root": {
      fontWeight: "700",
      fontSize: 26,
      lineHeight: "34px",
      color: "var(--gray)",
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
  },
  contentBox: {
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
  container: {
    "&.MuiContainer-root": {
      maxWidth: 1440,
    },
  },
  mdDesc: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "185%",
      color: "var(--gray)",
      paddingTop: 25,
      display: "inline-block",
      [theme.breakpoints.down("md")]: {
        paddingTop: 12,
      },
      "& span": {
        color: (props) => props?.dark,
      },
    },
  },
  whyVasana: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "33px",
      color: "var(--gray)",
      paddingTop: 25,
      display: "inline-block",
      [theme.breakpoints.down("md")]: {
        padding: [[0, 0, 10]],
        fontSize: 16,
      },
      "& span": {
        color: "black",
      },
    },
  },

  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 34,
      lineHeight: "104%",
      color: "#2D2F39",
      fontWeight: "700",
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
    },
  },
  imgWrapper: {
    maxWidth: 581,
    paddingLeft: 50,
    marginLeft: "auto",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      margin: "0px auto 50px",
      paddingLeft: 0,
    },
  },
  textWrapper: {
    display: "flex",
    position: "absolute",
    left: 32,
    bottom: 5,
    padding: "12px 26px",
    borderRadius: 12,
    backgroundColor: "var(--white)",
    width: 128,
    height: 83,
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      left: "50%",
      bottom: 0,
      width: 78,
      height: 56,
      padding: "11px 16px",
      transform: "translate(-50%, 50%)",
      boxShadow: "0px 7px 31px 0px rgba(174, 142, 183, 0.12)",
    },
  },
  WorkplaceTable: {
    "&.MuiTable-root": {
      "& .MuiTableHead-root": {
        "& .MuiTableCell-root": {
          backgroundColor: (props) => props?.light,
          fontSize: 18,
          fontWeight: 700,
          padding: "12px 50px",
          width: "33.33%",
          color: (props) => props?.dark,
        },
      },
      "& .MuiTableBody-root": {
        "& .MuiTableRow-hover": {
          backgroundColor: "var(--white)",

          "& .MuiTableCell-root": {
            fontSize: 16,
            padding: "12px 50px",
            width: "33.33%",
            color: "var(--gray)",
            borderBottomColor: "#CDD0E1",
          },
        },
      },
    },
  },
  skillBoxGroup: {
    [theme.breakpoints.down("lg")]: {
      "&:nth-child(even)": {
        "& > .MuiBox-root": {
          "&:before": {
            display: "none",
          },
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      "& > .MuiBox-root": {
        "&:before": {
          display: "none",
        },
      },
    },
  },
  skillBox: {
    "&.MuiBox-root": {
      position: "relative",
      textAlign: "center",
      [theme.breakpoints.down("lg")]: {
        marginBottom: 40,
      },
      "&:before": {
        position: "absolute",
        content: "''",
        width: 2,
        height: "100%",
        background:
          "radial-gradient(50% 50.00% at 50% 50.00%, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%);",
        right: 0,
      },
      "& .MuiTypography-root": {
        "&.MuiTypography-h4": {
          fontSize: 16,
          fontWeight: 700,
          color: "#2D2F39",
          lineHeight: "24px",
          marginBottom: 6,
        },
        fontSize: 16,
        color: "var(--gray)",
        lineHeight: "normal",
      },
    },
  },
  skillImgWrapper: {
    height: 165,
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: 17,
    },
  },
  otherSection: {
    padding: "50px 0",
    position: "relative",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      padding: "30px 0 0",
    },
  },
  cardDescription: {
    display: "flex",
    alignItems: "start",
    "& .image-out": {
      height: "auto",
      width: 13,
      flex: "0 0 auto",
      display: "block",
      marginTop: 6,
      "& img": {
        width: "100%",
      },
    },
  },
  card: {
    backgroundColor: "var(--white)",
    padding: 20,
    borderRadius: 12,
    position: "relative",
    marginTop: 40,
    zIndex: 2,
    height: "calc(100% - 40px)",
    [theme.breakpoints.down("md")]: {
      maxWidth: 350,
      margin: "40px auto",
    },
    "&::before": {
      position: "absolute",
      content: "''",
      top: 51,
      bottom: 27,
      right: "100%",
      width: 10,
      borderRadius: "12px 0 0 12px",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      top: 51,
      bottom: 27,
      left: "100%",
      width: 10,
      borderRadius: "0 12px 12px 0",
    },
  },
  mdCardText: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "29px",
      color: "var(--gray)",
      display: "inline-block",
      textAlign: "start",
      paddingLeft: 9,
      "& span": {
        fontWeight: 700,
      },
    },
  },
  sa: {
    "&::before": {
      backgroundColor: colorPalatte?.sa?.light,
    },
    "&::after": {
      backgroundColor: colorPalatte?.sa?.light,
    },
  },
  re: {
    "&::before": {
      backgroundColor: colorPalatte?.re?.light,
    },
    "&::after": {
      backgroundColor: colorPalatte?.re?.light,
    },
  },
  ga: {
    "&::before": {
      backgroundColor: colorPalatte?.ga?.icon,
    },
    "&::after": {
      backgroundColor: colorPalatte?.ga?.icon,
    },
  },
  ma: {
    "&::before": {
      backgroundColor: colorPalatte?.ma?.icon,
    },
    "&::after": {
      backgroundColor: colorPalatte?.ma?.icon,
    },
  },
  TypeSectionImg: {
    "&::after": {
      content: "''",
      position: "absolute",
      borderLeft: (props) => `14px solid ${props?.light}`,
      borderBottom: (props) => `15px solid ${props?.light}`,
      borderTop: "none",
      borderRight: "none",
      borderRadius: [[0, 120]],
      zIndex: 0,
      left: -14,
      top: 54,
      right: "18%",
      bottom: -10,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginBottom: 30,
    },
  },
  contentWrapper: {
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 40px)",
    "& .MuiButton-root": {
      width: "100%",
      fontWeight: "500",
      marginTop: "auto",
      "& img": {
        marginLeft: 10,
      },
    },
  },
  headLogoOut: {
    position: "absolute",
    left: 33,
    right: 33,
    top: -30,
    backgroundColor: "var(--white)",
    borderRadius: 5,
    padding: 12,
    textAlign: "center",
    boxShadow:
      "0px 0px 0px 0px rgba(227, 203, 231, 0.10), 0px 7px 14px 0px rgba(227, 203, 231, 0.10), 0px 26px 26px 0px rgba(227, 203, 231, 0.09);",
    "& img": {
      height: 30,
      width: "auto",
    },
  },
  whyLearnSection: {
    "& .MuiButton-root": {
      padding: "12px 20px",
      fontWeight: 500,
      marginTop: 12,
      "& img": {
        marginLeft: 10,
      },
    },
  },
  rainbow: {
    position: "absolute",
    top: 0,
    right: "calc(100% + 30px)",
  },
  halfRound: {
    position: "absolute",
    bottom: -30,
    left: 0,
    [theme.breakpoints.down("md")]: {
      left: -30,
      bottom: -30,
      height: 13,
    },
  },
  flower: {
    position: "absolute",
    top: 67,
    right: "14%",
  },
  halfRound2: {
    position: "absolute",
    top: "40%",
    left: "6%",
    transform: "rotate(90deg)",
  },
  spring: {
    position: "absolute",
    right: "16%",
    top: "58%",
    transform: "translateY(-50%)",
  },
  leafLeft: {
    position: "absolute",
    top: 0,
    left: 0,
    [theme.breakpoints.down("900")]: {
      display: "none",
    },
  },
  leafRight: {
    position: "absolute",
    bottom: 0,
    right: 0,
    rotate: "180deg",
    [theme.breakpoints.down("900")]: {
      display: "none",
    },
  },
  otherRainBow: {
    position: "absolute",
    bottom: 0,
    left: "15%",
  },
  tableBox: {
    marginBottom: 20,
    border: "1px solid #CDD0E1;",
    "& h6": {
      background: (props) => props?.light,
      padding: [[11, 13]],
      color: (props) => props?.dark,
      fontSize: 20,
      lineHeight: "185%",
    },
    "& p": {
      padding: [[22, 13]],
      borderBottom: "1px solid #CDD0E1",
      color: "var(--gray)",
      fontSize: 16,
      lineHeight: 1,
      "&:last-child": {
        borderBottom: 0,
      },
    },
  },
  mainImage: {
    zIndex: 1,
    border: "17px solid #ffffff",
    borderRadius: [[120, 0]],
    position: "relative",
    [theme.breakpoints.down("md")]: {
      border: 0,
      borderRadius: 5,
    },
  },
  whyImage: {
    zIndex: 1,
    borderLeft: "17px solid #ffffff",
    borderBottom: "17px solid #ffffff",
    borderRadius: [[0, 120]],
    position: "relative",
    [theme.breakpoints.down("md")]: {
      border: 0,
      borderRadius: 5,
    },
  },
  individual: {
    display: "flex",
    alignItems: "center",
    "&::after": {
      content: "''",
      position: "absolute",
      borderRight: (props) => `14px solid ${props?.light}`,
      borderBottom: (props) => `15px solid ${props?.light}`,
      borderTop: "none",
      borderLeft: "none",
      borderRadius: [[120, 0]],
      zIndex: 0,
      left: 63,
      top: 54,
      right: -17,
      bottom: -15,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
  raysImage: {
    position: "absolute",
    top: "20%",
    right: 0,
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  whyRaysImage: {
    position: "absolute",
    top: "20%",
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  headingImage: {
    position: "relative",
    bottom: -5,
    [theme.breakpoints.down("md")]: {
      bottom: -2,
    },
  },
}));
