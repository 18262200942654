import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Stack } from "@mui/system";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import VSNImage from "../../shared/VSNImage";
import SocialLogin from "../signUp/SocialLogin";
import ShapeOne from "../../assets/common/svg/shape-1.svg";
import ShapeTwo from "../../assets/common/svg/shape-2.svg";
import ShapeThree from "../../assets/common/svg/shape-3.svg";
import VSNTypography from "../../shared/VSNTypography";
import { getBannerInfo } from "../../description/common.description";
import BannerLayout from "./BannerLayout";

const useStyles = makeStyles((theme) => ({
  mainAuth: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      alignItems: "start",
    },
  },
  authDetail: {
    width: "100%",
    maxWidth: 595,
    margin: "0 auto",
    paddingRight: 32,
    [theme.breakpoints.down("md")]: {
      padding: [[0, 16, 0]],
      marginBottom: 47,
    },
  },
  orDivider: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "23px",
    color: "#86650a",
    position: "relative",
    display: "block",
    textAlign: "center",
    marginBottom: 60,
    "&::after,&::before": {
      position: "absolute",
      content: '""',
      width: "39%",
      top: "50%",
      transform: "translateY(-50%)",
      height: 1,
      backgroundColor: "#fbcebd",
      [theme.breakpoints.down("sm")]: {
        width: "32%",
      },
    },
    "&::after": {
      left: 0,
    },
    "&::before": {
      right: 0,
    },
  },
  shape: {
    position: "absolute",
  },
  shapeOne: {
    top: 80,
    left: -65,
    opacity: 0.4,
    zIndex: -1,
  },
  shapeTwo: {
    right: 0,
    top: "30%",
    transform: "translateY(-30%)",
    opacity: 0.9,
    zIndex: -1,
  },
  shapeThree: {
    left: 0,
    bottom: 40,
    opacity: 0.5,
    zIndex: -1,
  },
  redirectText: {
    "&.MuiTypography-root": {
      textAlign: "center",
      color: "var(--blackGray)",
      fontSize: 16,
      marginTop: 12,
      "& a": {
        fontWeight: 700,
        color: "var(--primary)",
      },
    },
  },
  socialMediaIcon: {
    position: "static",
    "&.MuiStack-root": {
      gap: "48px",
      marginTop: 52,
      [theme.breakpoints.down("md")]: {
        marginTop: 26,
        gap: "30px",
      },
      "& button": {
        padding: 0,
        "& span": {
          "& img": {
            [theme.breakpoints.down("md")]: {
              width: 30,
            },
          },
        },
      },
    },
  },
  usercontainer: {
    height: "calc(100vh - 75px)",
    overflowY: "auto",
    width: "100%",
    paddingBottom: 20,
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  subMain: {
    height: "calc(100vh - 96px)",
  },
}));

const SignUpLayout = () => {
  const classes = useStyles();
  const location = useLocation();
  const {
    social,
    linkName,
    navLink,
    redirectText,
    alternativeText,
    bannerClassName,
  } = getBannerInfo[location.pathname];

  return (
    <Stack pt={4}>
      <Grid
        container
        {...(location?.pathname === "/edit-profile" && {
          alignItems: "flex-start",
        })}
        spacing={{ md: 4, xs: 3 }}
      >
        <Grid
          item
          xs={12}
          md={5}
          lg={4.4}
          xl={3.5}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <BannerLayout {...getBannerInfo[location?.pathname]} />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          lg={7.6}
          xl={8.5}
          position="relative"
          className={classes.mainAuth}
        >
          <Box
            className={classNames({
              [classes.usercontainer]: location?.pathname === "/edit-profile",
            })}
          >
            <Box className={classes.authDetail}>
              <Outlet />

              <VSNImage
                src={ShapeOne}
                className={`${classes.shape} ${classes.shapeOne}`}
                alt="ShapeOne"
              />
              <VSNImage
                src={ShapeTwo}
                className={`${classes.shape} ${classes.shapeTwo}`}
                alt="ShapeTwo"
              />
              <VSNImage
                src={ShapeThree}
                className={`${classes.shape} ${classes.shapeThree}`}
                alt="ShapeThree"
              />
              {/* {social && (
                <>
                  <VSNTypography variant="body1" className={classes.orDivider}>
                    {alternativeText}
                  </VSNTypography>

                  <SocialLogin className={classes.socialMediaIcon} />
                </>
              )} */}

              {redirectText && (
                <VSNTypography className={classes.redirectText}>
                  {redirectText}
                  <Link to={navLink}> {linkName}</Link>
                </VSNTypography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SignUpLayout;
