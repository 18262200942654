import { makeStyles } from "@mui/styles";
import bgQuick from "../../../assets/common/png/quick-guide.png";
import bgShapes from "../../../assets/Teams/svg/back-shape-card.svg";
import crown from "../../../assets/organizationAdmin/svg/crown.svg";
import oneLine from "../../../assets/Teams/svg/online-image-background.svg";
import personalitiesBg from "../../../assets/Teams/png/personalitiesBg.png";

export const TeamDashboardStyle = makeStyles((theme) => ({
  heading: {
    "&.MuiTypography-root": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: 30,
      lineHeight: "40px",
      fontWeight: 700,
      color: "#2D2F39",
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
        lineHeight: "28px",
      },
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 22,
      lineHeight: "28px",
      fontWeight: 700,
      color: "var(--blackGray)",
      position: "relative",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 3,
        bottom: 3,
        left: -20,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: "var(--primary)",
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 20,
        lineHeight: "26px",
      },
      [theme.breakpoints.down("360")]: {
        fontSize: 17,
        lineHeight: "23px",
      },
    },
  },
  text: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "23px",
      color: "var(--gray)",
    },
  },
  mdText: {
    "&.MuiTypography-root": {
      fontSize: 18,
      lineHeight: "23px",
      color: "var(--gray)",
      fontWeight: 500,
      "& span": {
        fontWeight: 700,
      },
    },
  },
  innerWrapper: {
    padding: "0 23px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  teamViewCard: {
    padding: 20,
    borderRadius: 10,
    background: "#FCFCFD",
    border: "2px solid #fff",
    boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.07)",
    position: "relative",
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
    "&.mobileCard": {
      [theme.breakpoints.down("sm")]: {
        padding: 10,
      },
      "& .MuiTypography-root": {
        "&:before": {
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
      },
    },
  },
  saregamaCard: {
    padding: "25px 12px",
    position: "relative",
    background: "var(--white)",
    overflow: "hidden",
    zIndex: 2,
    height: "100%",
    cursor: "pointer",
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
    "&.disabled": {
      opacity: 0.7,
    },
  },
  curculerOut: {
    position: "relative",
    lineHeight: 0,
    "& .MuiCircularProgress-root": {
      position: "relative",
      height: "60px !important",
      width: "60px !important",
      borderRadius: "100%",
      boxShadow: "inset 0 0 0 4px #fff",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 8,
        right: 8,
        bottom: 8,
        left: 8,
        borderRadius: "100%",
        background: "var(--white)",
        border: "2px solid transparent",
      },
      "& .MuiCircularProgress-circle": {
        strokeWidth: "2px",
      },
    },
    "& .value": {
      fontSize: 20,
      fontWeight: "700",
      color: "#4F4F4F",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
  },
  dropdownIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    height: 25,
    width: 25,
    backgroundColor: "var(--white)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      width: 18,
    },
  },
  cardOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    borderRadius: 10,
  },
  btn: {
    "&.MuiButtonBase-root": {
      padding: "9px 23px",
      border: "1px solid var(--primary)",
      color: "var(--white)",
      flex: "0 0 auto",
      [theme.breakpoints.down("sm")]: {
        padding: "9px",
      },
      "& .content": {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        fontWeight: "400",
        gap: 5,
        color: "var(--white)",
        "& svg": {
          height: "23px",
          width: "auto",
          [theme.breakpoints.down("sm")]: {
            height: "18px",
          },
        },
      },
      "& span": {
        lineHeight: "16px",
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
      },
    },
  },
  btnOutline: {
    "&.MuiButtonBase-root": {
      padding: "9px 12px",
      background: "var(--primaryLighter)",
      [theme.breakpoints.down("sm")]: {
        padding: "9px",
      },
      "& .content": {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        fontWeight: "400",
        gap: 5,
        color: "var(--primary)",
        "& svg": {
          height: "auto",
          width: "12px",
        },
      },
      "& span": {
        lineHeight: "18px",
        fontSize: 14,
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
      },
      "&.exitButton": {
        backgroundColor: "#F543460D",
        "&:hover": {
          backgroundColor: "#555fdd0a",
        },
        "& span": {
          color: "#F54346",
        },
      },
      "&.remove": {
        display: "inline-block",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      "&.add": {
        display: "none",
        [theme.breakpoints.down("md")]: {
          display: "block",
          marginTop: 16,
          padding: "13px ",
        },
      },
    },
  },
  sortFilter: {
    "& p": {
      fontSize: 12,
      marginBottom: 8,
      fontWeight: 500,
      lineHeight: "15px",
    },
    "& .MuiStack-root": {
      overflow: "unset",
      paddingTop: 0,
    },

    "& .MuiFormControl-root": {
      minWidth: 100,
      "& .MuiInputBase-root": {
        borderRadius: 8,
        height: 38,
        backgroundColor: "#555FDD0D",
        border: "none",
        "& svg": {
          pointerEvents: "none",
          cursor: "pointer",
          position: "absolute",
          right: "7px",
          color: "var(--gray)",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          display: "none",
        },
        "& .MuiSelect-select": {
          minWidth: "auto",
          padding: [[0, 32, 0, 12]],
          fontWeight: 500,
          fontSize: 14,
          minHeight: 0,
          color: "var(--blackGray)",
          display: "flex",
          alignItems: "center",
          "& span": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 14,
            color: "var(--Tertiary) !important",
          },
          "& img": {
            height: 28,
            width: 36,
          },
        },
      },
    },
  },
  memberList: {
    "& .MuiTableRow-root": {
      padding: 0,
      "& .MuiTableCell-root": {
        padding: "14px 2px 14px",
        borderBottom: 0,
        [theme.breakpoints.down("sm")]: {
          "&:nth-child(2)": {
            display: "none",
          },
        },
      },
      "& + .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          borderTop: "1px solid #A298CD1A",
        },
      },
    },
  },
  smHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: 700,
      color: "var(--blackGray)",
      textTransform: "capitalize",
    },
  },
  desc: {
    "&.MuiTypography-root": {
      fontSize: 14,
      lineHeight: "18px",
      color: "var(--gray)",
    },
  },
  videosBox: {
    padding: [[16, 18, 24]],
    border: "none",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      padding: [[0, 0, 24]],
    },
  },
  video: {
    width: "100%",
    height: 150,
    position: "relative",
  },
  videoImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: 8,
  },
  contentDescription: {
    textAlign: "left",
    marginTop: 16,
    "& h6": {
      color: "#2D2F39",
      fontSize: 16,
      lineHeight: "21px",
      fontWeight: 700,
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& span": {
      fontSize: 12,
      color: "var(--gray)",
      fontWeight: 500,
      lineHeight: "15px",
    },
    "& p": {
      fontSize: 14,
      color: "var(--gray)",
      lineHeight: "18px",
      marginTop: 5,
    },
  },
  videoOut: {
    position: "relative",
    borderRadius: 16,
    overflow: "hidden",
    minHeight: 140,
    "& .MuiStack-root": {
      position: "absolute",
      left: 13,
      right: 13,
      bottom: 13,
      zIndex: 3,
      "& .MuiAvatar-root": {
        height: 32,
        width: 32,
      },
      "& .MuiTypography-root": {
        fontSize: 14,
        fontWeight: 700,
        color: "var(--white)",
        padding: "5px 10px",
        borderRadius: 5,
        background: "rgba(255, 255, 255, 0.20)",
      },
    },
  },
  videoTimingRow: {
    "&.MuiList-root": {
      padding: 5,
      marginTop: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      listStyle: "disc",
      "& .MuiListItem-root": {
        padding: 0,
        fontSize: 14,
        lineHeight: "18px",
        fontWeight: 500,
        color: "var(--gray)",
        "& + .MuiListItem-root": {
          paddingLeft: 12,
          position: "relative",
          "&:before": {
            position: "absolute",
            content: "''",
            height: 4,
            width: 4,
            backgroundColor: "var(--gray)",
            top: "50%",
            transform: "translateY(-50%)",
            left: 0,
            borderRadius: "100%",
          },
        },
      },
    },
  },
  imgOut: {
    height: 60,
    width: 60,
    [theme.breakpoints.down("lg")]: {
      height: 45,
      width: 45,
    },
    "& img": {
      width: "100%",
    },
  },
  avtarOut: {
    "& .MuiAvatar-root.MuiAvatar-circular": { border: "2px solid #fff" },
  },
  imageBox: {
    alignItems: "center",
    "&>.MuiBox-root": {
      minWidth: 75,
      height: 30,
      "& img": {
        height: "100%",
        width: "auto",
      },
    },
  },
  filterDropDown: {
    "& .MuiPaper-root": {
      boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.13)",
      borderRadius: 5,
      "& .MuiList-root": {
        padding: "10px 5px",
        "& .MuiMenuItem-root": {
          borderRadius: 5,
          fontSize: 14,
          color: "var(--gray)",
          fontWeight: 500,
          "& img": {
            height: 28,
            width: "auto",
          },
          "&:hover": {
            backgroundColor: "#F4F6FD",
          },
        },
      },
    },
  },
  admin: {
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "''",
      top: -8,
      right: -2,
      background: `url(${crown})`,
      backgroundSize: "100%",
      height: 20,
      width: 20,
      zIndex: 2,
    },
  },
  yourPersonalitySliderMain: {
    width: "100%",
    "& .swiper": {
      "& .swiper-slide": {
        height: "auto",
      },
      "& .swiper-pagination": {
        bottom: 0,
        "& .swiper-pagination-bullet": {
          background: "#DFE1F84D",
          opacity: 1,
          width: 24,
          height: 4,
          transition: "all 0.4s ease-in-out",
          borderRadius: 4,
        },
        "& .swiper-pagination-bullet-active": {
          background: "var(--primary)",
          width: 11,
        },
      },
    },
  },
  personalityMain: {
    borderRadius: 10,
    background: "var(--white)",
    "& .swiper-wrapper": {
      "& .swiper-slide": {
        "&:after": {
          position: "absolute",
          content: "''",
          top: 0,
          bottom: 0,
          right: -10,
          width: 2,
          background:
            "linear-gradient(180deg, rgba(85, 95, 221, 0.00) 0%, rgba(85, 95, 221, 0.15) 48.44%, rgba(85, 95, 221, 0.00) 100%)",
        },
        "&:last-child": {
          "&:after": {
            display: "none",
          },
        },
      },
    },
  },
  personalityBoxMain: {
    maxWidth: 450,
    "& .videosBox": {
      maxWidth: 450,
    },
    "& h5": {
      fontSize: 18,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 16,
      textAlign: "left",
      color: "var(--blackGray)",
      "& img, .icon-wrapper": {
        marginRight: 10,
        objectFit: "none",
        height: 32,
        width: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& p": {
      fontSize: 16,
      color: "var(--gray)",
      lineHeight: "21px",
    },
  },
  quickGuideCard: {
    padding: 15,
    borderRadius: 10,
    border: "1px solid #EAEDF0",
    background: "var(--white)",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 16px 0",
      margin: "15px 0 0 15px",
    },
  },
  quickGuideRow: {
    backgroundPosition: "right !important",
    backgroundRepeat: "no-repeat !important",
    padding: 15,
    borderRadius: 10,
    position: "relative",
    zIndex: 2,
    "&:before": {
      position: "absolute",
      content: "''",
      right: 0,
      top: 0,
      bottom: 0,
      background: `url(${bgQuick})`,
      opacity: 0.2,
      backgroundRepeat: "no-repeat",
      width: 239,
      borderRadius: 10,
      zIndex: -1,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
    "&.saType": {
      background: `linear-gradient(90deg, #FCF0FF 11.61%, #F2FAFF 72.11%)`,
    },
    "&.reType": {
      background: `linear-gradient(94deg, #F2FAFF 15.57%, #FFF7ED 100.63%)`,
    },
    "&.gaType": {
      background: `linear-gradient(93deg, #FFF7ED -2.17%, #E9FDF0 120.1%)`,
    },
    "&.maType": {
      background: `linear-gradient(93deg, #F0FEF5 -15.69%, #FCF0FF 110.14%)`,
    },
  },
  quickGuideSlide: {
    "&:before": {
      position: "absolute",
      content: "''",
      left: 0,
      width: 1,
      height: "100%",
      background:
        "radial-gradient(406875.8% 64.2% at 50.62% 42.02%, #EAEDF0 0%, rgba(255, 255, 255, 0.00) 100%)",
    },
  },
  quickQuideContainer: {
    marginTop: "16px !important",
    "& .MuiGrid-item": {
      "&:first-child": {
        "& .MuiBox-root": {
          "&:before": {
            display: "none",
          },
        },
      },
      [theme.breakpoints.down("xl")]: {
        "&:last-child": {
          "& .MuiBox-root": {
            "&:before": {
              display: "none",
            },
          },
        },
      },
      [theme.breakpoints.down("lg")]: {
        "&:nth-child(2)": {
          "& .MuiBox-root": {
            "&:before": {
              display: "none",
            },
          },
        },
      },
    },
  },
  closeOut: {
    borderRadius: "100px",
    color: "var(--blackGray)",
    position: "relative",
    background: "var(--white)",
    zIndex: 2,
    cursor: "pointer",
    flex: "0 0 auto",
    height: "30px",
    width: "30px",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: 10,
      right: 10,
    },
  },
  vasanaTypesSlider: {
    "& .swiper": {
      width: "100%",
      marginTop: 14,
      position: "relative",
      "& .swiper-slide": {
        height: "unset",
      },
      "&:before": {
        position: "absolute",
        content: "''",
        top: 0,
        right: 0,
        bottom: 0,
        width: "10%",
        background:
          "linear-gradient(270deg, #FCFCFD 10%, rgba(252, 252, 253, 0.00) 93.2%)",
        zIndex: 2,
        [theme.breakpoints.down(475)]: {
          display: "none",
        },
      },
      "& .swiper-pagination": {
        position: "unset",
        "& .swiper-pagination-bullet": {
          background: "#DFE1F84D",
          opacity: 1,
          width: 24,
          height: 4,
          transition: "all 0.4s ease-in-out",
          borderRadius: 4,
        },
        "& .swiper-pagination-bullet-active": {
          background: "var(--primary)",
          width: 11,
        },
      },
    },
  },
  slide: {
    marginBottom: 21,
    minHeight: 378,
    padding: 20,
    paddingBottom: 30,
    borderRadius: "12px",
    border: "1px solid #EAEDF0",
    background: `url(${bgShapes}), #fff`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    position: "relative",
    height: "calc(100% - 21px)",
    [theme.breakpoints.down("xl")]: {
      minHeight: 328,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
    },
    "&:before": {
      position: "absolute",
      content: "''",
      background:
        "linear-gradient(186deg, #FDFDFE 24.74%, rgba(253, 253, 254, 0.00) 125.42%)",
      inset: 0,
      borderRadius: "12px",
    },
    "& img": {
      height: 120,
      width: "auto",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: 100,
      },
    },
    "& .MuiButton-root": {
      background: "transparent",
      padding: "4px 0px",
      color: "var(--primary)",
      marginTop: "auto",
      marginBottom: -10,
      width: "fit-content",
      "& .content": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      height: "calc(100% - 15px)",
      minHeight: 304,
    },
  },
  customArrow: {
    "& .swiper-button": {
      cursor: "pointer",
      backgroundColor: "#DFE1F84D",
      borderRadius: "50%",
      color: "var(--gray)",
      height: 28,
      width: 28,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  popupTeamView: {
    position: "absolute",
    backgroundColor: "#8D90B61A",
    zIndex: 9,
    inset: 0,
    backdropFilter: "blur(4px)",
    [theme.breakpoints.down("lg")]: {
      padding: 12,
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      padding: 0,
      border: "1px solid #EAEDF0",
    },
    "& .innerContent": {
      height: "100%",
      borderRadius: 8,
      padding: 16,
      background: `url(${oneLine}) no-repeat,#fff`,
      backgroundSize: "cover",
    },
    "& .desc": {
      fontSize: 14,
      lineHeight: "18px",
      color: "var(--gray)",
    },
  },
  blueHeading: {
    "&.MuiTypography-root": {
      fontSize: 18,
      fontWeight: 700,
      color: "var(--Tertiary)",
    },
  },
  closeIcon: {
    height: 25,
    width: 25,
    borderRadius: 50,
    background: "var(--primaryLighter)",
    color: "var(--primary)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "0 0 auto",
    cursor: "pointer",
  },
  oneLine: {
    maxWidth: 100,
    [theme.breakpoints.between("md", "lg")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    "& .line": {
      flex: "0 0 auto",
      [theme.breakpoints.between("md", "lg")]: {
        order: 2,
      },
      [theme.breakpoints.down("sm")]: {
        order: 2,
        marginLeft: 8,
      },
    },
    "& .dot": {
      height: 10,
      width: 10,
      borderRadius: "100%",
      flex: "0 0 auto",
      [theme.breakpoints.between("md", "lg")]: {
        order: 3,
      },
      [theme.breakpoints.down("sm")]: {
        order: 3,
        height: 8,
        width: 8,
        marginLeft: 4,
      },
    },
    "& .typeImg": {
      height: 24,
    },
    "& .MuiTypography-root": {
      fontSize: 14,
      lineHeight: "18px",
      color: "var(--Tertiary)",
      fontWeight: 700,
      [theme.breakpoints.between("md", "lg")]: {
        order: 5,
      },
      [theme.breakpoints.down("sm")]: {
        order: 5,
      },
    },
  },
  typeImgWrapper: {
    flex: "0 0 auto",
    [theme.breakpoints.between("md", "lg")]: {
      order: 1,
      maxWidth: 50,
      width: "100%",
      marginRight: 8,
    },
    [theme.breakpoints.down("sm")]: {
      order: 1,
      maxWidth: 50,
      width: "100%",
      marginLeft: "0 !important",
    },
  },
  imgCartoonWrapper: {
    flex: "0 0 auto",
    [theme.breakpoints.between("md", "lg")]: {
      order: 4,
      maxWidth: 48,
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      order: 4,
      maxWidth: 64,
      width: "100%",
    },
  },
  baseMapList: {
    "&.MuiList-root": {
      marginLeft: -24,
      "& > .MuiListItem-root": {
        padding: 0,
        display: "block",
      },
    },
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
      padding: [[10, 24]],
      borderLeft: "3px solid transparent",
      cursor: "pointer",
      [theme.breakpoints.down("lg")]: {
        padding: [[10, 15]],
      },
      "& svg": {
        marginLeft: "auto",
        paddingLeft: 2,
      },
    },
  },
  active: {
    "&.MuiTypography-root": {
      backgroundColor: "rgba(223, 225, 248, 0.30)",
      borderLeftColor: "var(--Tertiary)",
      "& svg": {
        transform: "rotate(180deg)",
      },
    },
  },
  baseList: {
    maxHeight: 120,
    overflowY: "auto",
    "&.MuiList-root": {
      paddingLeft: 20,
    },
    "& .MuiListItem-root": {
      color: "var(--gray)",
      cursor: "pointer",
    },
  },
  activeMap: {
    "&.MuiListItem-root": {
      color: "var(--Tertiary)",
    },
  },
  personalities: {
    border: "1px solid #E3F1F9",
    borderRadius: 10,
    padding: 15,
    backgroundImage: `url(${personalitiesBg})`,
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  personalitiesDesc: {
    padding: 20,
    borderRadius: 10,
    backgroundColor: "var(--white)",
    maxHeight: 388,
    display: "flex",
    flexDirection: "column",
    boxShadow:
      "0px 5px 11px 0px rgba(216, 202, 202, 0.10), 0px 20px 20px 0px rgba(216, 202, 202, 0.09)",
    height: "100%",
    "& .MuiTypography-h6": {
      color: "#86650A",
    },
    "& p": {
      flex: 1,
      overflowY: "auto",
    },
  },
  personalitiesType: {
    "&.MuiBox-root": {
      display: "flex",
      alignItems: "center",
      bottom: 0,
      marginTop: 15,
      [theme.breakpoints.down("xl")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: 10,
        width: "100%",
      },
      "& p.MuiTypography-root": {
        padding: "0 8px",
        height: 33,
        textAlign: "center",
        marginRight: 5,
        lineHeight: "33px",
        fontFamily: "Playfair Display",
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
          marginTop: 5,
          width: "auto",
        },
      },
      "& span": {
        marginTop: 5,
        wordBreak: "break-all",
        lineHeight: "normal",
        paddingRight: 5,
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
      },
    },
  },
  optionInfo: {
    "&.MuiButtonBase-root": {
      pointerEvents: "none",
      background: "#F7F3FB",
      color: "var(--darkGray)",
      margin: [[0]],
      lineHeight: "23px",
      padding: [[26, 66]],
      position: "relative",
      width: 602,
      maxWidth: "100%",
      minHeight: 100,
      fontSize: 16,
      marginBottom: "10px",
      [theme.breakpoints.down("lg")]: {
        minHeight: 122,
      },
      [theme.breakpoints.down("md")]: {
        padding: [[16, 18]],
        fontSize: 16,
        minHeight: 80,
      },
      "&:hover": {
        background: "#EAEBFB",
      },
      "&:active": {
        background: "#EAEBFB",
        transition: "auto",
        WebkitTransition: "auto",
      },
      "&:after": {
        position: "absolute",
        content: '""',
        borderRadius: 8,
        width: "100%",
        height: "100%",
        border: "1px solid #CCCBFF",
        top: 10,
        left: 10,
        zIndex: -1,
      },
      "& .content": {
        fontSize: 16,
      },
    },
  },
  btnWrapper: {
    "& .MuiButtonBase-root": {
      position: "static",
      height: "100%",
      width: 43,
      margin: 0,
      backgroundColor: "#DFE1F8",
      border: "1px solid var(--Tertiary)",
      borderRadius: 8,
      "&:hover": {
        backgroundColor: "#ced2ff",
      },
      "& .MuiSvgIcon-root": {
        color: "var(--Tertiary)",
      },
    },
  },
}));
