import { Box, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import VSNSearchBox from "../../shared/VSNSearchBox";
import { ReactComponent as Jelly } from "../../assets/companyAdminModel/jelly.svg";
import { ReactComponent as Umbrella } from "../../assets/companyAdminModel/umbrella.svg";
import VSNTypography from "../../shared/VSNTypography";
import VSNPagination from "../../shared/VSNPagination";
import { teamListModalContainer } from "../../container/teamListModal.container";

export const useStyles = makeStyles((theme) => ({
  jellyImg: {
    position: "absolute",
    top: "50%",
    left: "57%",
  },
  umbrellaImg: {
    position: "absolute",
    top: -60,
    left: "50%",
    zIndex: 4,
  },
  search: {
    width: "100%",
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
    "& .MuiFormControl-root": {
      background: "#F5F5F5",
      width: "100%",
      borderRadius: 5,
      "& .MuiInputBase-root": {
        height: 40,
        paddingLeft: 5,
        [theme.breakpoints.down("md")]: {
          height: 42,
        },
        "& input": {
          paddingLeft: 15,
          fontSize: 16,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      color: "var(--blackGray)",
      textTransform: "capitalize",
    },
  },
  modelListWrapper: {
    marginBottom: 20,
  },
  modelLgHeading: {
    "& .MuiTypography-root": {
      color: "var(--gray)",
    },
  },
  modelImageWrapper: {
    maxWidth: 94,
    minHeight: 73,
    maxHeight: 73,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 60,
      maxWidth: 77,
    },
    "& img": {
      width: "100%",
      height: "100%",
      border: "1px solid #EAEDF0",
      objectFit: "contain",
      borderRadius: 5,
    },
  },
  paragraphTxt: {
    "&.MuiTypography-root": {
      color: "var(--gray)",
      fontSize: 14,
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  roleWrapper: {
    height: 34,
    maxWidth: 90,
    width: "100%",
    backgroundColor: "#f9f9f9",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      height: 28,
      maxWidth: 68,
      marginTop: 5,
    },
  },
  defaultTxt: {
    "&.MuiTypography-root": {
      fontSize: 14,
      textTransform: "capitalize",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
  },
  userTxt: {
    "&.MuiTypography-root": {
      color: "var(--blackGray)",
    },
  },
  adminTxt: {
    "&.MuiTypography-root": {
      color: "var(--Tertiary)",
    },
  },
  paginationFooter: {
    margin: "0 -25px",
    background: "#DFE1F84D",
    padding: 15,
    borderRadius: 5,
    marginTop: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0 -15px",
    },
    "& .MuiPagination-ul": {
      justifyContent: "center",
      "& .MuiButtonBase-root": {
        border: "none",
        background: "var(--white)",
        color: "#42445080",
        "&.Mui-selected": {
          background: "var(--Tertiary)",
          color: "var(--white)",
        },
        [theme.breakpoints.down("425")]: {
          height: 26,
          minWidth: 26,
        },
      },
      "& .MuiPaginationItem-ellipsis": {
        height: 32,
        minWidth: 32,
        backgroundColor: "var(--white)",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        padding: [[6, 0]],
        ["@media screen and (max-width: 424px)"]: {
          height: 26,
          minWidth: 26,
        },
      },
    },
  },
}));

const TeamListModal = ({ teamsOfMember }) => {
  const classes = useStyles();

  const { filters, loader, searchRef, onSearchChange, onPageChange } =
    teamListModalContainer();

  // Small Screen Query
  const isLargeScreen = useMediaQuery("(min-width:601px)");

  return (
    <Stack
      sx={{
        overflowY: teamsOfMember?.data?.length ? "unset" : "hidden",
      }}
    >
      {isLargeScreen && (
        <>
          <Jelly
            className={classes.jellyImg}
            sx={{
              display: teamsOfMember?.data?.length ? "none" : "inline-block",
            }}
          />
          <Umbrella className={classes.umbrellaImg} />
        </>
      )}
      <Box className={`${classes.search} ${classes.filterStacksearch}`}>
        <VSNSearchBox
          onChange={onSearchChange}
          name="keyword"
          inputRef={searchRef}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        margin="15px 0"
        marginRight="30px"
        className={classes.modelLgHeading}
      >
        <VSNTypography>Teams Name</VSNTypography>
        {isLargeScreen && <VSNTypography>Role</VSNTypography>}
      </Stack>
      <Stack
        className={classes.modelListWrapper}
        spacing={{ xs: "15px", sm: 2.5 }}
      >
        {loader ? (
          <Stack alignItems="center">
            <CircularProgress size={24} />
          </Stack>
        ) : (
          <>
            {teamsOfMember?.data?.length ? (
              teamsOfMember?.data?.map((team, listIndex) => {
                return (
                  <Stack
                    direction="row"
                    spacing={{ xs: 1.25, sm: "15px" }}
                    key={listIndex}
                  >
                    <Box className={classes.modelImageWrapper}>
                      <img src={team?.profile_picture} alt="Team" />
                    </Box>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent={{ sm: "space-between" }}
                      spacing={1}
                      alignItems={{ xs: "start", sm: "center" }}
                      width="100%"
                    >
                      <Stack
                        spacing="5px"
                        style={{ padding: "0px 0" }}
                        maxWidth="400px"
                        width="100%"
                      >
                        <VSNTypography className={classes.mdHeading}>
                          {team?.name || ""}
                        </VSNTypography>
                        {isLargeScreen && (
                          <VSNTypography className={classes.paragraphTxt}>
                            {team?.goal || ""}
                          </VSNTypography>
                        )}
                      </Stack>
                      <Stack className={classes.roleWrapper}>
                        <VSNTypography
                          className={`${classes.defaultTxt} ${
                            team?.role === "admin"
                              ? classes.adminTxt
                              : classes.userTxt
                          }`}
                        >
                          {team?.role || ""}
                        </VSNTypography>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })
            ) : (
              <VSNTypography textAlign="center">No data found</VSNTypography>
            )}
          </>
        )}
      </Stack>
      {filters?.pages ? (
        <Stack spacing={2} className={classes.paginationFooter}>
          <Stack spacing={2}>
            <VSNPagination
              count={filters?.pages}
              variant="outlined"
              shape="rounded"
              onChange={onPageChange}
              page={filters?.current_page || 1}
              siblingCount={0}
              boundaryCount={1}
            />
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default TeamListModal;
