import { Divider, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
import cloud from "../../assets/Teams/svg/cloud.svg";
import greenCloud from "../../assets/Teams/svg/green-cloud.svg";
import halfRound from "../../assets/Teams/svg/half-round.svg";
import lightBlueLeaf from "../../assets/Teams/svg/lightBlue-leaf.svg";
import { colorFromResults } from "../../shared/CustomFunc";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import arrow from "../../assets/quickView/svg/swiper-arrow.svg";
import VSNTypography from "../../shared/VSNTypography";
import {
  FlashIcon,
  LockIcon,
  OptionalIcon,
  RightRoundIcon,
} from "../../shared/icon";
import { assessmentIds } from "../../utils/constant";
import { VSNSwiper } from "../../shared/VSNSwiper";

const useStyles = makeStyles((theme) => ({
  assessmentCard: {
    padding: "15px 20px",
    borderRadius: 10,
    background: "var(--white)",
    border: "1px solid #EAEDF0",
    position: "relative",
    marginBottom: 25,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
    "&:hover": {
      borderColor: "transparent",
      "&:before": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
    "& .text": {
      fontWeight: 500,
      marginTop: 8,
      marginBottom: 24,
      "& span": {
        fontWeight: 700,
      },
    },
    "& .cardHeading": {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: "23px",
      "& span": {
        fontSize: 22,
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
          fontSize: 18,
        },
      },
    },
    "& .imgLineWrapper": {
      display: "flex",
      alignItems: "center",
      marginBottom: 4,
      fontWeight: 500,
      "& p": {
        fontSize: 18,
      },
      "& img": {
        marginTop: -5,
      },
    },
  },
  halfRound: {
    position: "absolute",
    top: "20%",
    left: 0,
  },
  greenCloud: {
    position: "absolute",
    top: "25%",
    left: "5%",
  },
  cloud: {
    position: "absolute",
    bottom: 20,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lightBlueLeaf: {
    position: "absolute",
    bottom: 5,
    right: "2%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
      fontSize: 22,
      lineHeight: "28px",
      color: "var(--blackGray)",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        lineHeight: "20px",
      },
    },
  },
  imgWrapper: {
    height: 163,
    width: 183,
    margin: "16px auto 0 auto",
    "& img": {
      height: "100%",
    },
  },
  meanText: {
    "&.MuiTypography-root": {
      color: "var(--gray)",
      lineHeight: "20px",
      fontWeight: "700",
      minHeight: 43,
      [theme.breakpoints.down("md")]: {
        minHeight: 0,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "18px",
      },
      "& span": {
        color: "#86650A",
      },
    },
  },
  truncateText: {
    "&.MuiTypography-root": {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "21px",
      },
    },
  },
  outLinedBtn: {
    "&.MuiButton-root": {
      width: "100%",
      fontWeight: 500,
      padding: "12px",
      maxWidth: 175,
      height: "100%",
      [theme.breakpoints.between("sm", "md")]: {
        padding: "12px 6px",
      },
      "&:hover": {
        background:
          "linear-gradient(132.61deg, var(--primaryDarker) 32.09%, var(--primaryGradientLighter) 163.78%)",
        color: "var(--white)",
      },
      "& .content": {
        fontSize: 14,
        lineHeight: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
  },
  outLinedlockBtn: {
    "&.MuiButtonBase-root.MuiButton-outlined": {
      fontWeight: 500,
      padding: "12px",
      height: "100%",
      width: "fit-content",
      border: `1px solid var(--gray)`,
      color: "var(--gray)",
      [theme.breakpoints.between("sm", "md")]: {
        padding: "12px ",
      },
      "& .content": {
        fontSize: 14,
        lineHeight: "16px",
      },
      "& .MuiButton-startIcon": {
        height: 16,
        marginRight: 6,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  btnComplete: {
    "&.MuiButton-root": {
      padding: "10px 15px",
      background: "#2D8F4E",
      fontWeight: 500,
      width: "fit-content",
      flex: "0 0 auto",
      [theme.breakpoints.down("sm")]: {
        padding: "7px 10px",
      },
      "& .content": {
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        lineHeight: "16px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
        "& svg": {
          height: 15,
          width: 15,
          marginRight: 8,
        },
      },
      "&:hover": {
        background: "#2D8F4E",
      },
    },
  },
  btnRequired: {
    "&.MuiButton-root": {
      padding: "10px 15px",
      background: "#555FDD0D",
      fontWeight: 500,
      width: "fit-content",
      color: "var(--Tertiary)",
      flex: "0 0 auto",
      [theme.breakpoints.down("sm")]: {
        padding: "7px 10px",
      },
      "& .content": {
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        lineHeight: "16px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
        "& svg": {
          height: 18,
          width: 15,
          marginRight: 8,
        },
      },
      "&:hover": {
        background: "#555FDD0D",
      },
    },
  },
  assessmentCardSlider: {
    width: "100%",
    "& .swiper-pagination": {
      position: "absolute",
      top: 15,
      right: 0,
      bottom: "auto",
      left: "auto",
      width: "fit-content",
      padding: "6px 10px",
      fontSize: 14,
      borderRadius: 5,
      background: "var(--primaryLighter)",
      minWidth: 50,
      color: "var(--gray)",
      "& .swiper-pagination-current": {
        color: "var(--primary)",
      },
    },
    "& .swiper-button-prev, .swiper-button-next": {
      top: 130,
      "&:after": {
        background: `url(${arrow})`,
        fontSize: 0,
        height: 27,
        width: 27,
        backgroundSize: "contain",
      },
    },
    "& .swiper-button-next": {
      transform: "rotate(180deg)",
    },
    "& .swiper-button-disabled": {
      pointerEvents: "all",
    },
  },
}));

const statusBtn = ({ status, isRequired, classes, disable }) => {
  if (status === "COMPLETED") {
    return (
      <VSNButton
        className={classes.btnComplete}
        sx={{ cursor: disable ? "not-allowed" : "pointer" }}
      >
        <RightRoundIcon />
        Completed
      </VSNButton>
    );
  }
  if (isRequired) {
    return (
      <VSNButton
        className={classes.btnRequired}
        sx={{ cursor: disable ? "not-allowed" : "pointer" }}
      >
        <FlashIcon />
        Required
      </VSNButton>
    );
  }
  if (!isRequired) {
    return (
      <VSNButton
        className={classes.btnRequired}
        sx={{ cursor: disable ? "not-allowed" : "pointer" }}
      >
        <OptionalIcon />
        Optional
      </VSNButton>
    );
  }
  return "";
};

const buttonTextForStatus = (status) => {
  if (status === "COMPLETED") {
    return "Read More";
  }
  if (status === "IGNORED") {
    return "Take Assessment";
  }
  return "";
};

const splitArray = (str) => {
  const data = str?.map((obj) =>
    obj?.includes(" ") ? splitArray(obj?.split(" ")) : obj
  );
  return data;
};

const AssessmentCard = ({
  name,
  status,
  is_required: isRequired,
  result,
  handleButtonClick,
  assessment_id: assessmentId,
  type,
  retakeAssessment,
  userRoleData,
  index,
  assessment_taken: assessmentTaken,
  all_results: allResults,
  is_locked,
}) => {
  const classes = useStyles();
  const [activeSlide, setActiveSlide] = useState(0);

  const handlePrev = (e) => {
    e.stopPropagation();
    const prevIndex =
      activeSlide === 0 ? allResults?.length - 1 : activeSlide - 1;
    setActiveSlide(prevIndex);
  };

  const handleNext = (e) => {
    e.stopPropagation();
    const nextIndex =
      activeSlide === allResults?.length - 1 ? 0 : activeSlide + 1;
    setActiveSlide(nextIndex);
  };

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper?.activeIndex);
  };

  // const isDisable =
  //   ![
  //     assessmentIds?.vasanaType,
  //     assessmentIds?.introExtro,
  //     assessmentIds?.isi,
  //     assessmentIds?.anxiety,
  //     assessmentIds?.audit,
  //   ]?.includes(assessmentId?.toString()) && assessmentTaken;

  const isDisable = !Object?.values(assessmentIds)?.includes(
    assessmentId.toString()
  );

  return (
    <>
      <Stack
        key={index}
        className={classes.assessmentCard}
        sx={{
          cursor: isDisable ? "not-allowed" : "pointer",
          "&:before": {
            position: "absolute",
            content: "''",
            inset: -2,
            background: `linear-gradient(to right,${colorFromResults(
              result
            )} 50%,#F8DEFF 80%)`,
            borderRadius: "11px",
            zIndex: -1,
            opacity: 0,
          },
        }}
        onClick={() =>
          isDisable || is_locked
            ? null
            : handleButtonClick(name, assessmentId, type, assessmentTaken)
        }
      >
        {index % 2 === 0 ? (
          <>
            <VSNImage
              src={halfRound}
              alt="halfRound"
              className={classes.halfRound}
            />
            <VSNImage src={cloud} alt="cloud" className={classes.cloud} />
          </>
        ) : (
          <>
            <VSNImage
              src={greenCloud}
              alt="greenCloud"
              className={classes.greenCloud}
            />
            <VSNImage
              src={lightBlueLeaf}
              alt="lightBlueLeaf"
              className={classes.lightBlueLeaf}
            />
          </>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <VSNTypography variant="h3" className={classes.mdHeading} mb="0">
            {name}
          </VSNTypography>
          {statusBtn({
            status,
            isRequired,
            classes,
            disable: isDisable && assessmentTaken,
          })}
        </Stack>
        <Divider sx={{ marginTop: 2, borderColor: "#EAEDF0" }} />
        {allResults?.length > 1 ? (
          <VSNSwiper
            className={classes.assessmentCardSlider}
            pagination={{
              type: "fraction",
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              disabledClass: "swiper-button-disabled",
            }}
            onSlideChange={handleSlideChange}
            initialSlide={activeSlide}
          >
            {allResults?.length > 1 && (
              <>
                <Stack className="swiper-button-next" onClick={handleNext} />
                <Stack className="swiper-button-prev" onClick={handlePrev} />
              </>
            )}
            {allResults?.map((el, ind) => {
              return (
                <SwiperSlide key={ind}>
                  <VSNTypography
                    fontSize={{ sm: 18, xs: 16 }}
                    fontWeight={500}
                    color="var(--primary)"
                    mt={2}
                    pr={7}
                  >
                    {el?.category}
                  </VSNTypography>
                  <Stack className={classes.imgWrapper} mb={2}>
                    <VSNImage src={el?.image} alt="assessment1" />
                  </Stack>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: el?.result,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: el?.trait_description,
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </VSNSwiper>
        ) : (
          <>
            <VSNTypography
              fontSize={{ sm: 18, xs: 16 }}
              fontWeight={500}
              color="var(--primary)"
              mt={2}
              pr={7}
            >
              {allResults?.[0]?.category}
            </VSNTypography>
            <Stack className={classes.imgWrapper} mb={2}>
              <VSNImage src={allResults?.[0]?.image} alt="assessment1" />
            </Stack>
            <div
              dangerouslySetInnerHTML={{
                __html: allResults?.[0]?.result,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: allResults?.[0]?.trait_description,
              }}
            />
          </>
        )}
        <Stack direction={{ xs: "column", sm: "row" }} spacing="20px">
          {/* {status &&
          (Object?.values(assessmentIds)?.includes(assessmentId?.toString()) ||
            status !== "COMPLETED") ? (
            <VSNButton
              variant="outlined"
              className={classes.outLinedBtn}
              handleClick={(e) => {
                e.stopPropagation();
                handleButtonClick(name, assessmentId, type, assessmentTaken);
              }}
            >
              {buttonTextForStatus(status)}
            </VSNButton>
          ) : null} */}

          {(status &&
            (Object.values(assessmentIds)?.includes(assessmentId?.toString()) ||
              status !== "COMPLETED")) ||
          is_locked ? (
            <VSNButton
              variant="outlined"
              startIcon={is_locked && <LockIcon />}
              className={
                is_locked ? classes.outLinedlockBtn : classes.outLinedBtn
              }
              handleClick={(e) => {
                e.stopPropagation();
                handleButtonClick(name, assessmentId, type, assessmentTaken);
              }}
              disabled={is_locked}
            >
              {is_locked ? (
                <>Results Unlocking Soon</>
              ) : (
                buttonTextForStatus(status)
              )}
            </VSNButton>
          ) : null}
          {assessmentTaken && userRoleData?.is_demo_user ? (
            <VSNButton
              className={classes.outLinedBtn}
              handleClick={(e) => {
                e.stopPropagation();
                retakeAssessment(assessmentId, type);
              }}
            >
              Retake Assessment
            </VSNButton>
          ) : null}
        </Stack>
      </Stack>
    </>
  );
};

export default AssessmentCard;
