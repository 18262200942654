import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { signinDescription } from "../../description/signUp.description";
import VSNButton from "../../shared/VSNButton";
import VSNForm from "../../shared/VSNForm";
import VSNTypography from "../../shared/VSNTypography";
import ControlForm from "../../shared/form/ControlForm";
import VSNLabel from "../../shared/VSNLabel";
import { formContainer } from "../../container/form.container";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import SEO from "../../shared/SEO";

const useStyles = makeStyles((theme) => ({
  authTitle: {
    "&.MuiTypography-h4": {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "23px",
      maxWidth: 564,
      color: "var(--blackGray)",
      padding: [[16, 0]],
      marginBottom: 40,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        marginBottom: 32,
        padding: 0,
      },
    },
  },
  formGroup: {
    marginBottom: 36,
    [theme.breakpoints.down("md")]: {
      marginBottom: 30,
    },
  },
  verifyContainer: {
    margin: [[52, 0]],
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
      marginBottom: 20,
    },
    "& button": {
      marginBottom: 0,
    },
  },
  greeting: {
    "&.MuiTypography-root": {
      fontSize: "40px",
      fontWeight: "700",
      [theme.breakpoints.down("md")]: {
        marginBottom: 48,
        fontSize: 28,
      },
    },
  },
}));

const SignUpContainer = ({
  formAttribute,
  onSubmit,
  formPath,
  handleChange,
  signupLoader,
}) => {
  const classes = useStyles();

  const { formData, formErrors } = formContainer({ formPath });

  return (
    <>
      <SEO contentKey="signup" />
      <VSNTypography className={classes.greeting}>Welcome !</VSNTypography>
      <VSNTypography variant="h4" className={classes.authTitle}>
        {signinDescription}
      </VSNTypography>
      <VSNForm>
        <Grid container>
          <Grid item xs={12} lg={12}>
            {formAttribute?.map(
              (
                { isRequired, label, errorMessage, name, ...attribute },
                index
              ) => {
                return (
                  <Box className={classes.formGroup} key={index}>
                    <VSNLabel required={isRequired} label={label} />
                    <ControlForm
                      {...{
                        ...attribute,
                        onChange: handleChange,
                        name,
                        value: formData?.[name],
                      }}
                    />
                    <VSNErrorMessage
                      {...{ formError: formErrors?.[name], errorMessage }}
                    />
                  </Box>
                );
              }
            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              mt={5}
              mb={2}
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
            >
              <VSNButton
                type="submit"
                component="button"
                handleClick={onSubmit}
                isLoading={signupLoader}
              >
                Continue
              </VSNButton>
            </Box>
          </Grid>
        </Grid>
      </VSNForm>
    </>
  );
};

export default SignUpContainer;
