/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/media-has-caption */
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  useMediaQuery,
} from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ScrollAnimation from "react-animate-on-scroll";
import { SwiperSlide } from "swiper/react";
import approachImg1 from "../../assets/landing/png/approach-img1.webp";
import Genome from "../../assets/landing/png/Genome.webm";
import GenomeMP4 from "../../assets/landing/png/GenomeMP4.mp4";
import healthImg1 from "../../assets/landing/png/health-img1.webp";
import healthImg2 from "../../assets/landing/png/health-img2.webp";
import healthImg3 from "../../assets/landing/png/health-img3.webp";
import healthImg4 from "../../assets/landing/png/health-img4.webp";
import healthImg5 from "../../assets/landing/png/health-img5.webp";
import individuals from "../../assets/landing/png/individuals.webp";
import organizations from "../../assets/landing/png/organizations.webp";
import section1Bg1 from "../../assets/landing/png/section-1-bg-1.png";
import section1Bg2 from "../../assets/landing/png/section-1-bg-2.png";
import section1Bg3 from "../../assets/landing/png/section-1-bg-3.png";
import section1Bg4 from "../../assets/landing/png/section-1-bg-4.png";
import section1Bg5 from "../../assets/landing/png/section-1-bg-5.png";
import section2Bg1 from "../../assets/landing/png/section-2-bg-1.png";
import shape6 from "../../assets/landing/png/shape6.png";
import shape7 from "../../assets/landing/png/shape7.png";
import welcomeImage from "../../assets/landing/png/welcome-image.webp";
import worker from "../../assets/landing/png/worker.webp";
import { getSlug, navigateAfterLogin } from "../../shared/CustomFunc";
import SEO from "../../shared/SEO";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import VSNTypography from "../../shared/VSNTypography";
import {
  GenomeShape1,
  GenomeShape2,
  GenomeShape3,
  GenomeShape4,
  GenomeShape5,
  GenomeShapeArrow,
  RightArrow,
} from "../../shared/icon";
import VasanaTypeResult from "../assessment/VasanaTypeResult";
import AchieveLogo from "./AchieveLogo";
import FooterSlider from "./FooterSlider";
import { LandingDashboardStyles } from "./LandingContainerStyle";
import { VSNSwiper } from "../../shared/VSNSwiper";

export const LandingContainer = () => {
  const classes = LandingDashboardStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userRoleData = useSelector((state) => state.app?.userData);
  const { user_profile } = userRoleData;
  const { assessmentList } = useSelector((state) => state.api);
  const isUser = localStorage?.getItem("token");
  const isLargeScreen = useMediaQuery("(min-width:900px)");
  const signup_flow_completed =
    userRoleData?.user_profile?.signup_flow_completed;

  useEffect(() => {
    if (userRoleData?.user_profile) {
      navigateAfterLogin(navigate, userRoleData, pathname);
    }
  }, [userRoleData]);

  useEffect(() => {
    if (isUser && assessmentList?.length && user_profile) {
      const remainingAssessment =
        assessmentList?.length && !signup_flow_completed
          ? assessmentList.find(
              (assessment) =>
                assessment.is_required_for_signup &&
                !assessment.assessment_taken
            )
          : null;
      if (remainingAssessment && !signup_flow_completed) {
        localStorage.setItem(
          "currentAssessmentId",
          remainingAssessment?.assessment_id
        );
        const slug = getSlug(
          assessmentList,
          remainingAssessment?.assessment_id
        );
        navigate(`/get-started/${slug}`);
      }
    }
  }, [isUser, assessmentList, user_profile]);

  if (isUser) {
    return <VasanaTypeResult />;
  }

  const healthList = [
    {
      image: healthImg1,
      title: "Understanding Ourselves",
      desc: `Understanding our triggers and typical behavior under pressure is crucial. At Vasana, we help you gain this self-awareness, enabling you to identify and manage stressors effectively. Our goal is to help you learn how to lower pressure in your environment so that your biological responses are not triggered, allowing your psychological strengths to guide your actions.`,
      bgColor: "linear-gradient(96.45deg, #FFFFFF 0%, #F2E7F5 100%)",
    },
    {
      image: healthImg2,
      title: "Changing the Way We Interact",
      desc: `The way people interact with each other can bring tremendous joy and happiness but also cause immense pain and sadness. We decode each person's unique behavioral genome using advanced AI, Deep Learning, Game Theory, and NLP to offer insights into their interactions and emotional well-being. This allows us to create adaptive applications that support personal growth and enhance connections. We're currently developing the Vasana Relationship Index, a tool to evaluate and improve interpersonal relationships.`,
      bgColor: "linear-gradient(98.84deg, #FFFFFF 0%, #E3F1F9 100%)",
    },
    {
      image: healthImg3,
      title: "Unveiling Personalized Whole Health Insights with Technology",
      desc: `We use voice and video AI along with NLP to detect Vasana types and behavioral markers and create a person’s behavioral genome. We leverage AI and data analytics to analyze physical and behavioral health indicators for a comprehensive well-being assessment, enabling adaptive applications and therapeutic actions.`,
      bgColor: "linear-gradient(96.28deg, #FFFFFF -18.04%, #FFF7EC 100%)",
    },
    {
      image: healthImg4,
      title: "Personalized Self-service Therapeutics",
      desc: `By identifying your unique psychometric behavioral style, we recommend tailored therapeutic solutions. These include training through a comprehensive video library, instructor-led sessions, and personalized coaching by HUMA, your Vasana co-pilot.`,
      bgColor: "linear-gradient(96.29deg, #FFFFFF -20.09%, #F0F9F2 100%)",
    },
    {
      image: healthImg5,
      title: "Evidence-based Approach",
      desc: `Vasana’s proprietary tools use behavioral science and neuroscience, and a transformative methodology comprising 45+ frameworks. We use these frameworks, advanced AI, neuroscience and behavioral-based nudges to help people enhance relationships, develop empathy and communicate effectively.`,
      bgColor: "linear-gradient(96.5deg, #FFFFFF 0%, #FBCEBD 137.64%)",
    },
  ];

  const uniqueNeeds = [
    {
      image: worker,
      title: "Public Safety",
      desc: "Revolutionize mental healthcare through advanced diagnostics, whole health navigation, and personalized self-service therapeutics, enhancing care and outcomes for public safety professionals, empowering them to thrive.",
      buttonText: "Learn More",
      bgColor: "#F0F9F2",
      className: classes.purpleBtn,
      link: "/public-safety",
    },
    {
      image: organizations,
      title: "Teams",
      desc: "Transform your workplace with our proprietary behavioral assessments and  other mental health markers and tailored therapeutics. Empower team members to understand their behavior, build trust, reduce friction, and boost productivity.",
      buttonText: "Learn More",
      bgColor: "#FFF7EC",
      className: classes.purpleBtn,
      link: "/for-teams",
    },
    {
      image: individuals,
      title: "Individuals",
      desc: "Discover your unique behavioral genome through our self-facilitated Vasana platform. Improve your behavioral and mental health, fostering personal and professional growth.",
      buttonText: "Get Started",
      bgColor: "#F8F0FA",
      link: "/login",
    },
  ];

  const circleBg = [
    {
      bg: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 70%, #F2E7F5 100%)",
      duration: 0.2,
    },
    {
      bg: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 70%, #E3F1F9 100%)",
      duration: 0.4,
    },
    {
      bg: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 70%, #FFF7EC 100%)",
      duration: 0.6,
    },
    {
      bg: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 50%, #F0F9F2 100%)",
      duration: 0.8,
    },
  ];

  const bannerText = [
    `<span className="orangeText">Enhancing Well-Being</span> with advanced diagnostics and therapeutics`,
    `<span className="orangeText">Fostering Happiness</span> by helping you understand yourself and others`,
    `<span className="orangeText">Improving Lives</span> by reducing stress, anxiety, and suicidal Ideation`,
  ];

  const features = [
    {
      title: "Understand",
      subtitle: "Yourself",
      description:
        "Develop a deep understanding of your biological imprint that shapes your current behavior.",
    },
    {
      title: "Understand",
      subtitle: "Others",
      description:
        "Learn how to override your biology with your psychology in everyday breakdowns and situations.",
    },
    {
      title: "Thrive",
      subtitle: "Together",
      description:
        "Imagine waking up each day, no longer driven by your biology and instinct, but by a deep self awareness backed by science and analytics.",
    },
  ];

  return (
    <>
      <SEO contentKey="landing" />
      <Stack className={`${classes.heroSection}`}>
        <Container maxWidth="xl" sx={{ position: "relative" }}>
          <Grid container justifyContent="center">
            <Grid item xl={8} lg={9} md={9} smd={9} xs={12}>
              <Box textAlign="center">
                <h1>
                  <VSNTypography
                    variant="h1"
                    component="span"
                    className={classes.lgHeading}
                  >
                    Mental Healthcare for You, Powered by
                    <span className="orangeText"> Science and AI.</span>
                  </VSNTypography>
                </h1>
                <VSNSwiper
                  effect="fade"
                  className={classes.bannerTextSlider}
                  autoplay={{
                    delay: 3500,
                  }}
                  loop
                  slidesPerView={1}
                >
                  {bannerText?.map((item, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <VSNTypography
                          dangerouslySetInnerHTML={{
                            __html: item,
                          }}
                          className="desc"
                        />
                      </SwiperSlide>
                    );
                  })}
                </VSNSwiper>
                <Box className={classes.arrowDown}>
                  <HashLink off to="#uniqueSection">
                    <ArrowDownwardIcon />
                  </HashLink>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Stack>
      <Stack
        className={classNames(classes.spacing, classes.uniqueSection)}
        position="relative"
        id="uniqueSection"
      >
        <Container maxWidth="xl" sx={{ position: "relative" }}>
          <Grid container spacing={{ md: 7, sm: 5, xs: 3 }}>
            {features.map((data, i) => (
              <Grid item md={4} xs={12} key={i}>
                <VSNTypography
                  fontSize={{ md: 32, sm: 28, xs: 24 }}
                  textAlign="center"
                  color="secondary.grey"
                  mb={{ sm: 2, xs: 1 }}
                >
                  {data.title}
                  <VSNTypography
                    component="span"
                    fontSize={{ md: 32, sm: 28, xs: 24 }}
                    fontWeight="bold"
                    ml={1}
                  >
                    {data.subtitle}
                  </VSNTypography>
                </VSNTypography>
                <VSNTypography
                  textAlign="center"
                  fontSize={{ lg: 24, xsm: 20, xs: 16 }}
                  color="secondary.grey"
                >
                  {data.description}
                </VSNTypography>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Stack>
      <Stack position="relative" pb={{ slg: 12, xs: 6 }}>
        <Container maxWidth="xl">
          <VSNTypography
            color="secondary.darkGrey"
            variant="h4"
            textAlign="center"
            fontWeight={700}
            fontSize={{ xlg: 40, lg: 32, xs: 26 }}
          >
            Tailored to Your Unique Needs
          </VSNTypography>
          <Grid container columnSpacing={3}>
            {uniqueNeeds?.map((data, i) => {
              return (
                <Grid item md={4} key={i}>
                  <Box
                    className={classes.uniqueNeeds}
                    sx={{ backgroundColor: data.bgColor }}
                  >
                    <Box className={classes.uniqueNeedsContent}>
                      <VSNTypography component="h5">{data.title}</VSNTypography>
                      <VSNTypography>{data.desc}</VSNTypography>
                      <Box mt="auto">
                        <VSNButton
                          variant="contained"
                          className={data.className}
                          endIcon={<RightArrow />}
                          handleClick={() => navigate(`${data.link}`)}
                        >
                          {data.buttonText}
                        </VSNButton>
                      </Box>
                    </Box>
                    <VSNImage src={data.image} alt="image" />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Container>

        <VSNImage
          src={section1Bg4}
          alt="shape"
          className={`${classes.sectionBg2} ${classes.sectionShape}`}
        />
        <VSNImage
          src={section1Bg2}
          alt="shape"
          className={`${classes.sectionBg3} ${classes.sectionShape}`}
        />
        <VSNImage
          src={section2Bg1}
          alt="shape"
          className={`${classes.sectionBg4} ${classes.sectionShape}`}
        />
      </Stack>

      <Stack className={`${classes.welcomeSection} ${classes.spacing}`}>
        <Container maxWidth="xl">
          <VSNTypography
            color="secondary.darkGrey"
            variant="h4"
            textAlign="center"
            mb={5}
            fontWeight={700}
            fontSize={{ xlg: 40, lg: 32, xs: 26 }}
          >
            Welcome to Vasana
          </VSNTypography>
          <Grid
            container
            columnSpacing={2}
            alignItems="center"
            flexDirection="row-reverse"
          >
            <Grid item md={6} xs={12}>
              <Box className={classes.circleBg}>
                <VSNImage
                  src={welcomeImage}
                  className={classes.welcomeImage}
                  alt="welcomeImage"
                />
                {circleBg?.map((item, i) => {
                  return (
                    <ScrollAnimation
                      className="circle"
                      animateIn="zoomIn"
                      duration={item.duration}
                      style={{
                        background: item.bg,
                      }}
                      key={i}
                    />
                  );
                })}
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <VSNTypography color="secondary.grey">
                In Sanskrit,{" "}
                <VSNTypography color="var(--primary)" component="span">
                  Vāsanā /[vaa-suh-naa]/
                </VSNTypography>
                , refers to the deep-seated behavioral tendencies or karmic
                imprints that shape a person&apos;s current actions.
              </VSNTypography>
              <VSNTypography color="secondary.grey">
                Under pressure, people often default to their biological
                instincts. Understanding these instincts is the first step
                toward meaningful change.
              </VSNTypography>
              <VSNButton
                handleClick={() => navigate(`who-we-are`)}
                endIcon={<RightArrow />}
                sx={{
                  padding: { xs: "8px 24px", sm: "12px 40px" },
                  marginTop: 1.5,
                }}
              >
                Learn More About Us
              </VSNButton>
            </Grid>
          </Grid>
        </Container>
        <VSNImage
          src={section2Bg1}
          alt="shape"
          className={classNames(classes.section2Bg1, classes.sectionShape)}
        />
        <VSNImage
          src={section1Bg5}
          alt="shape"
          className={`${classes.sectionBg5} ${classes.sectionShape}`}
        />
      </Stack>
      <Stack className={classes.spacing} position="relative">
        <Container maxWidth="xl">
          <Stack className={classNames(classes.vasanaApproachSection)}>
            <Grid container spacing={3} mb={3}>
              <Grid item md={3} xs={12}>
                <VSNTypography
                  color="secondary.darkGrey"
                  variant="h4"
                  mb={{ lg: 4 }}
                  fontWeight={600}
                  fontSize={{ xlg: 40, lg: 32, xs: 26 }}
                  textAlign={{ md: "left", xs: "center" }}
                >
                  The Vāsanā Approach
                </VSNTypography>
              </Grid>
              <Grid item md={9} xs={12}>
                <VSNTypography
                  color="secondary.grey"
                  textAlign={{ md: "left", xs: "center" }}
                  fontSize={{ lg: 24, sm: 20, xs: 16 }}
                >
                  Our approach integrates key components shaping behavior:
                  biological traits, psychological makeup, wellness markers, and
                  physical health.
                </VSNTypography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row-reverse"
            >
              <Grid item md={4.5} xs={12}>
                <VSNImage
                  src={approachImg1}
                  className={classes.opportunityImage}
                  alt="approach-img"
                />
              </Grid>
              <Grid item md={7} xs={12}>
                <VSNTypography>
                  These elements are deeply intertwined, shaping your behavior,
                  actions, and overall state of being. Understanding these
                  elements provides a comprehensive picture, guiding your
                  journey towards intentional and authentic living.
                </VSNTypography>
                <List disablePadding>
                  <ListItem>
                    Begin your journey with our proprietary Vasana Type
                    Assessment; the heart of the Vāsanā behavioral genome. The
                    Vasana framework identifies four biological types—
                    <VSNTypography component="span" color="#8B3F9E">
                      Sa
                    </VSNTypography>
                    ,{" "}
                    <VSNTypography component="span" color="#1780C6">
                      Re
                    </VSNTypography>
                    ,{" "}
                    <VSNTypography component="span" color="#FFB142">
                      Ga
                    </VSNTypography>
                    , and{" "}
                    <VSNTypography component="span" color="#2D8F4E">
                      Ma
                    </VSNTypography>
                    —impacting individual behaviors and interactions.
                  </ListItem>
                  <ListItem>
                    Enhance your individual behavioral genome with additional
                    behavioral, wellness, and neurodiversity markers.
                  </ListItem>
                  <ListItem>
                    Extend your genome to include physical health attributes and
                    markers
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Stack>
        </Container>
        <VSNImage
          src={section1Bg1}
          alt="shape"
          className={`${classes.section3Bg1} ${classes.sectionShape}`}
        />
        <VSNImage
          src={shape6}
          alt="shape"
          className={classNames(classes.section3Bg2, classes.sectionShape)}
        />
        <VSNImage
          src={section1Bg4}
          alt="shape"
          className={`${classes.section3Bg3} ${classes.sectionShape}`}
        />
      </Stack>
      <Stack
        className={classNames(classes.spacing, classes.genomeSection)}
        position="relative"
      >
        <Container maxWidth="xl">
          <VSNTypography
            color="secondary.darkGrey"
            variant="h4"
            textAlign="center"
            fontWeight={700}
            fontSize={{ xlg: 40, lg: 32, xs: 26 }}
          >
            The Vasana Behavioural Genome
          </VSNTypography>
          <VSNTypography
            color="var(--primary)"
            variant="h6"
            textAlign="center"
            mb={3}
            fontSize={{ lg: 24, md: 20, xs: 16 }}
          >
            A conceptual view
          </VSNTypography>
          <Stack direction={{ md: "row" }} justifyContent="space-between">
            <Stack flex={1} maxWidth={{ xlg: 340, lg: 250, mlg: 200, md: 180 }}>
              <Box className={classes.diagnosticInputs}>
                <VSNTypography fontSize={{ xlg: 32, lg: 26, xs: 20 }}>
                  Diagnostic Inputs
                </VSNTypography>
                <List>
                  <ListItem>
                    <VSNTypography>Vasana Assessment</VSNTypography>
                    <GenomeShape1 />
                  </ListItem>
                  <ListItem>
                    <VSNTypography>Behavioral Assessments</VSNTypography>
                    <GenomeShape2 />
                  </ListItem>
                  <ListItem>
                    <VSNTypography>Wellness Assessments</VSNTypography>
                    <GenomeShape2 />
                  </ListItem>
                  <ListItem>
                    <VSNTypography>Physical Diagnostics</VSNTypography>
                    <GenomeShape1 />
                  </ListItem>
                </List>
              </Box>
            </Stack>
            <Stack minWidth={{ xlg: 650, lg: 620, mlg: 560, md: 450 }}>
              <Stack height="100%" justifyContent="center">
                <Box className={classes.mobileArrow}>
                  <GenomeShape5 />
                </Box>
                <Box className={classes.conceptualView}>
                  {/* <video loop muted autoPlay playsInline>
                    <source src={GenomeMP4} type="video/mp4" />
                    <source src={Genome} type="video/webm" />
                  </video> */}
                  <VSNImage
                    src={welcomeImage}
                    className={classes.vasanaGenome}
                    alt="shape"
                  />
                </Box>
                <Box className={classes.mobileArrow}>
                  <GenomeShape5 />
                </Box>
              </Stack>
            </Stack>
            <Stack flex={1}>
              <Box className={classes.therapeuticsList}>
                <VSNTypography>Therapeutics</VSNTypography>
                <List>
                  <ListItem disablePadding>
                    <Box>
                      <VSNTypography>Dashboard</VSNTypography>
                      <List disablePadding>
                        <ListItem disablePadding>
                          Live dashboard and status
                        </ListItem>
                        <ListItem disablePadding>
                          Prompts, Notifications and recommendations
                        </ListItem>
                      </List>
                    </Box>
                    <GenomeShape3 />
                  </ListItem>
                  <ListItem disablePadding>
                    <Box>
                      <VSNTypography>Training</VSNTypography>
                      <List disablePadding>
                        <ListItem disablePadding>
                          Adaptive online video library
                        </ListItem>
                        <ListItem disablePadding>
                          Instructor led training
                        </ListItem>
                        <ListItem disablePadding>
                          Adaptive online content
                        </ListItem>
                      </List>
                    </Box>
                    <GenomeShape4 />
                  </ListItem>
                  <ListItem disablePadding>
                    <Box>
                      <VSNTypography>Real time coaching</VSNTypography>
                      <List disablePadding>
                        <ListItem disablePadding>
                          <VSNTypography component="span">Huma</VSNTypography> A
                          Digital Assistant
                        </ListItem>
                        <ListItem disablePadding>
                          Integration (Plugins, APIs)
                        </ListItem>
                      </List>
                    </Box>
                    <GenomeShape3 />
                  </ListItem>
                </List>
              </Box>
            </Stack>
          </Stack>
        </Container>
        <VSNImage
          src={shape7}
          alt="shape"
          className={classNames(classes.section4Bg1, classes.sectionShape)}
        />
        <VSNImage
          src={section1Bg1}
          alt="shape"
          className={classNames(classes.section4Bg2, classes.sectionShape)}
        />
        <VSNImage
          src={section1Bg5}
          alt="shape"
          className={classNames(classes.section4Bg3, classes.sectionShape)}
        />
        <VSNImage
          src={section1Bg2}
          alt="shape"
          className={classNames(classes.section4Bg4, classes.sectionShape)}
        />
        <VSNImage
          src={shape7}
          alt="shape"
          className={classNames(classes.section4Bg5, classes.sectionShape)}
        />
      </Stack>
      <Stack
        className={classNames(classes.spacing, classes.magicSection)}
        position="relative"
      >
        <Container maxWidth="xl">
          <VSNTypography
            color="var(--primary)"
            variant="h6"
            textAlign="center"
            mb={2}
            fontSize={{ lg: 24, md: 20, xs: 16 }}
          >
            THE VASANA MAGIC
          </VSNTypography>
          <VSNTypography
            color="secondary.darkGrey"
            variant="h4"
            textAlign="center"
            fontWeight={700}
            fontSize={{ xlg: 40, lg: 32, xs: 26 }}
          >
            Pioneering New Paths in Behavioral Health
          </VSNTypography>
          <Grid container justifyContent="center">
            <Grid item lg={9} xs={12}>
              {healthList?.map((data, i) => {
                return (
                  <Box
                    className={classes.healthPathList}
                    key={i}
                    sx={{ background: data.bgColor }}
                  >
                    <Stack className={classes.healthPathListImage}>
                      <VSNImage src={data.image} alt={data.title} />
                    </Stack>
                    <Box>
                      <VSNTypography variant="h6">{data.title}</VSNTypography>
                      <VSNTypography>{data.desc}</VSNTypography>
                    </Box>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </Container>
        <VSNImage
          src={section1Bg1}
          alt="shape"
          className={classNames(classes.section5Bg1, classes.sectionShape)}
        />
        <VSNImage
          src={shape6}
          alt="shape"
          className={classNames(classes.section5Bg2, classes.sectionShape)}
        />
        <VSNImage
          src={shape7}
          alt="shape"
          className={classNames(classes.section5Bg3, classes.sectionShape)}
        />
        <VSNImage
          src={section1Bg5}
          alt="shape"
          className={classNames(classes.section5Bg4, classes.sectionShape)}
        />
        <VSNImage
          src={shape7}
          alt="shape"
          className={classNames(classes.section5Bg5, classes.sectionShape)}
        />
        <VSNImage
          src={section1Bg1}
          alt="shape"
          className={classNames(classes.section5Bg6, classes.sectionShape)}
        />
        <VSNImage
          src={section1Bg4}
          alt="shape"
          className={classNames(classes.section5Bg7, classes.sectionShape)}
        />
        <VSNImage
          src={shape6}
          alt="shape"
          className={classNames(classes.section5Bg8, classes.sectionShape)}
        />
        <VSNImage
          src={section1Bg5}
          alt="shape"
          className={classNames(classes.section5Bg9, classes.sectionShape)}
        />
        <VSNImage
          src={shape7}
          alt="shape"
          className={classNames(classes.section5Bg10, classes.sectionShape)}
        />
      </Stack>
      <AchieveLogo title="Working with the Best to Achieve the Amazing" />
      <FooterSlider />
    </>
  );
};
