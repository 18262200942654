import { makeStyles } from "@mui/styles";
import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import VSNImage from "../../shared/VSNImage";
import VSNTypography from "../../shared/VSNTypography";
import headerbgOne from "../../assets/termsCondition/svg/headerbgOne.svg";
import headerbgTwo from "../../assets/termsCondition/svg/headerbgTwo.svg";
import headerbgThree from "../../assets/termsCondition/svg/headerbgThree.svg";
import headerbgFour from "../../assets/termsCondition/svg/headerbgFour.svg";
import headerbgFifth from "../../assets/termsCondition/svg/headerbgFifth.svg";

const useStyles = makeStyles((theme) => ({
  TermsHeader: {
    backgroundImage: "linear-gradient(180deg, #FEFEFE 5.58%, #E6F1FA 100%)",
    padding: [[61, 87, 44]],
    [theme.breakpoints.down("lg")]: {
      padding: [[40, 16]],
    },
    [theme.breakpoints.down("md")]: {
      padding: [[24, 16]],
    },
    "&.privacyHeader": {
      padding: [[121, 87, 68]],
      [theme.breakpoints.down("lg")]: {
        padding: [[40, 16]],
      },
      [theme.breakpoints.down("md")]: {
        padding: [[24, 16]],
      },
    },
  },
  TermsTopheading: {
    "& h6": {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: "15px",
      [theme.breakpoints.down("lg")]: {
        fontSize: 32,
        lineHeight: "39px",
        marginBottom: "14px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        lineHeight: "31px",
        marginBottom: "13px",
      },
    },
    "& p": {
      fontSize: 20,
      fontWeight: 700,
      color: "var(--gray)",
      lineHeight: "26px",
      "& a": {
        color: "var(--primary)",
        textDecoration: "underline !important",
        margin: "0 5px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 18,
        lineHeight: "26px",
        fontWeight: 500,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "21px",
        fontWeight: 400,
      },
    },
  },
  TopImg: {
    "& svg": {
      [theme.breakpoints.down("md")]: {
        maxWidth: 174,
        maxHeight: 162,
        marginTop: "40px",
      },
    },
  },
  headerbg: {
    position: "absolute",
    zIndex: -1,
  },
  headerbgOne: {
    bottom: 50,
    left: "55%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  headerbgTwo: {
    top: "62px",
    right: "50%",
    opacity: "0.7",
    [theme.breakpoints.down("md")]: {
      top: 10,
      right: "30%",
      opacity: 0.7,
      maxWidth: "12%",
    },
  },
  headerbgThree: {
    top: "30px",
    left: 0,
    opacity: "0.08",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  headerbgFour: {
    bottom: 0,
    left: 0,
    opacity: 0.4,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  headerbgFifth: {
    bottom: 0,
    left: 0,
  },
}));

const CommonHeader = ({ headerDescription, isPrivacyPage }) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.TermsHeader} ${isPrivacyPage && `privacyHeader`}`}
      position="relative"
      zIndex="0"
    >
      <VSNImage
        src={headerbgOne}
        className={`${classes.headerbg} ${classes.headerbgOne}`}
        alt="headerbgOne"
      />
      <VSNImage
        src={headerbgTwo}
        className={`${classes.headerbg} ${classes.headerbgTwo}`}
        alt="headerbgTwo"
      />
      <VSNImage
        src={headerbgThree}
        className={`${classes.headerbg} ${classes.headerbgThree}`}
        alt="headerbgThree"
      />
      <VSNImage
        src={headerbgFour}
        className={`${classes.headerbg} ${classes.headerbgFour}`}
        alt="headerbgFour"
      />
      <VSNImage
        src={headerbgFifth}
        className={`${classes.headerbg} ${classes.headerbgFifth}`}
        alt="headerbgFifth"
      />
      {headerDescription.map(({ description, heading, image }, i) => (
        <Grid container spacing={2} alignItems="center" key={i}>
          <Grid item xs={12} sm={image ? 8 : 12}>
            <Stack direction="column" className={classes.TermsTopheading}>
              <VSNTypography variant="h6" component="h6">
                {heading}
              </VSNTypography>
              <VSNTypography variant="body2" className={classes.TermsTopdesc}>
                {description}
              </VSNTypography>
            </Stack>
          </Grid>
          {image && (
            <Grid
              item
              xs={12}
              sm={4}
              textAlign="center"
              className={classes.TopImg}
            >
              {image}
            </Grid>
          )}
        </Grid>
      ))}
    </Box>
  );
};

export default CommonHeader;
