import { Box, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch } from "react-redux";
import bg from "../../assets/Teams/png/exit-team-bg.png";
import blueSquare from "../../assets/Teams/svg/blue-square-flower.svg";
import noteams from "../../assets/Teams/svg/no-teams-found.svg";
import greenFlower from "../../assets/Teams/svg/green-flower.svg";
import lightPink from "../../assets/Teams/svg/light-pink-flower.svg";
import halfOrange from "../../assets/Teams/svg/orange-half-flower.svg";
import shape4 from "../../assets/Teams/svg/shape-4.svg";
import yellow from "../../assets/Teams/svg/yellow-flower.svg";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import VSNTypography from "../../shared/VSNTypography";
import { UserIcon } from "../../shared/icon";
import { toggleModelShow } from "../../shared/CustomFunc";

const useStyles = makeStyles((theme) => ({
  bgFlower: {
    position: "absolute",
    top: 0,
    left: -25,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      left: -15,
    },
  },
  shape4: {
    position: "absolute",
    top: 30,
    right: "16%",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  yellow: {
    position: "absolute",
    top: 22,
    left: "10%",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  halfOrange: {
    position: "absolute",
    bottom: "18%",
    right: -25,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      right: -15,
    },
  },
  blueSquare: {
    position: "absolute",
    bottom: 40,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lightPink: {
    position: "absolute",
    top: -10,
    left: "60%",
    transform: "translateX(-50%)",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  greenFlower: {
    position: "absolute",
    bottom: 20,
    left: "15%",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonRow: {
    "& .MuiButton-root": {
      maxWidth: 172,
      width: "100%",
      padding: 10,
      "& .content": {
        fontWeight: 500,
        fontSize: 16,
      },
    },
  },
}));

export default function NoTeamToJoinModal({ teamsToJoin, myTeams }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Box position="relative">
      <VSNImage src={bg} alt="bg" className={classes.bgFlower} />
      <VSNImage src={shape4} alt="shape4" className={classes.shape4} />
      <VSNImage
        src={blueSquare}
        alt="blueSquare"
        className={classes.blueSquare}
      />
      <VSNImage src={yellow} alt="yellow" className={classes.yellow} />
      <VSNImage
        src={halfOrange}
        alt="halfOrange"
        className={classes.halfOrange}
      />
      <VSNImage src={lightPink} alt="lightPink" className={classes.lightPink} />
      <VSNImage
        src={greenFlower}
        alt="greenFlower"
        className={classes.greenFlower}
      />
      <Grid
        container
        columnSpacing={2}
        rowSpacing={{ md: 0, xs: 4 }}
        pb={{ sm: 10, xs: 3 }}
        pt={{ sm: 10, xs: 6 }}
        alignItems="center"
      >
        <Grid
          item
          md={4.5}
          sm={5}
          xs={12}
          textAlign={{ sm: "right", xs: "center" }}
        >
          <VSNImage src={noteams} alt="noteams" />
        </Grid>
        <Grid item md={7.5} sm={7} xs={12}>
          <VSNTypography
            textAlign={{ sm: "start", xs: "center" }}
            variant="h4"
            color="var(--blackGray)"
            fontSize={{ sm: "28px", xs: "26px" }}
            fontWeight="700"
            lineHeight={{ sm: "36px", xs: "30px" }}
          >
            <span style={{ color: "var(--primary)" }}>No more</span> teams
            found!
          </VSNTypography>
          <VSNTypography
            textAlign={{ sm: "start", xs: "center" }}
            color="var(--blackGray)"
            fontSize={{ sm: "20px", xs: "16px" }}
            fontWeight="700"
            lineHeight={{ sm: "24px", xs: "20px" }}
            my={{ sm: 2.4, xs: 1.2 }}
          >
            {teamsToJoin?.length === 0 && myTeams?.length > 0
              ? "You are already a member of every team within the organization."
              : "No team was found within the organization that you can join."}
          </VSNTypography>
          <VSNTypography
            textAlign={{ sm: "start", xs: "center" }}
            color="var(--gray)"
            fontSize={{ sm: "16px", xs: "14px" }}
            fontWeight="500"
            lineHeight={{ sm: "20px", xs: "18px" }}
            my={{ sm: 2.4, xs: 1.2 }}
            pr={{ sm: 3, xs: 0 }}
          >
            Take the lead and forge your own path. Create a team and shape a
            collaborative environment that reflects your vision.
          </VSNTypography>
          <Stack
            direction="row"
            className={classes.buttonRow}
            justifyContent={{ sm: "start", xs: "center" }}
            spacing={2}
            mt={{ sm: 0, xs: 2.5 }}
          >
            <VSNButton
              variant="contained"
              handleClick={() => dispatch(toggleModelShow("createTeam"))}
              startIcon={<UserIcon />}
            >
              Create a Team
            </VSNButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
