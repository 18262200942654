import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import styled from "styled-components";
import businessImg from "../assets/Teams/png/business-team-img.png";
import { ReactComponent as Send } from "../assets/admin/assessmentResult/svg/Send.svg";
import { ReactComponent as RemoveAdmin } from "../assets/organizationAdmin/svg/RemoveAdminIcon.svg";
import {
  activeStatusOption,
  changeLocationAdminOption,
  VSNTypes,
} from "../description/common.description";
import {
  LOADING_CHANGE,
  ON_FORM_CHANGE,
  ON_MODEL_STATE_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../redux/constants";
import { ApiContainer } from "../utils/api";
import {
  copyToClipboard,
  equal,
  formatName,
  getAvatarName,
  getName,
  keys,
  length,
  upperCase,
} from "../utils/javascript";
import VSNConfirmBox from "./VSNConfirmBox";
import VSNImage from "./VSNImage";
import VSNMembersAvatar from "./VSNMembersAvatar";
import VSNMembersAvatarGroup from "./VSNMembersAvatarGroup";
import VSNSelect from "./VSNSelect";
import VSNToolTip from "./VSNToolTip";
import VSNTypography from "./VSNTypography";
import { DeleteIcon, EditCustomIcon, PlusIcon } from "./icon";
import {
  all_admin,
  assessmentIds,
  assessmentNames,
  clinician,
  department_admin,
  department_admin_name,
  head_clinician,
  IdToRole,
  location_admin,
  location_admin_name,
  nameOfRole,
  organization_admin_name,
  organization_location_admin,
} from "../utils/constant";
import crown from "../assets/organizationAdmin/svg/crown.svg";
import VSNButton from "./VSNButton";

const useStyles = makeStyles((theme) => ({
  memberName: {
    "& .MuiAvatar-root": {
      borderColor: "var(--white)",
      outline: "2px solid #EAEDF0",
      boxSizing: "border-box",
      fontSize: 16,
      color: "var(--white)",
      backgroundColor: "#1F396D",
      height: 36,
      width: 36,
      [theme.breakpoints.down("sm")]: {
        height: 30,
        width: 30,
        marginLeft: -3,
        fontSize: 12,
      },
      [theme.breakpoints.down("359")]: {
        height: 24,
        width: 24,
      },
    },
    "& .admin": {
      position: "relative",
      "&:before": {
        position: "absolute",
        content: "''",
        top: -6,
        right: -8,
        background: `url(${crown})`,
        backgroundSize: "100%",
        height: 20,
        width: 20,
        zIndex: 2,
      },
    },
    "& .MuiTypography-root": {
      color: "var(--gray)",
      paddingLeft: 10,
    },
  },
  avtarGroup: {
    "&.MuiAvatarGroup-root": {
      justifyContent: "start",
    },
  },
  curculerOut: {
    position: "relative",
    width: "fit-content",
    marginRight: 10,
    "& .MuiCircularProgress-root": {
      position: "relative",
      borderRadius: "100%",
      boxShadow: "inset 0 0 0 2px #F2E7F5",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 8,
        right: 8,
        bottom: 8,
        left: 8,
        borderRadius: "100%",
        background: "var(--white)",
        border: "2px solid transparent",
      },
      "& .MuiCircularProgress-circle": {
        strokeWidth: "2px",
      },
    },
    "& .value": {
      fontSize: 14,
      fontWeight: "700",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
  },
  tblSelect: {
    width: 95,
    height: 34,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAEDF0",
    },
    "& .MuiInputBase-input": {
      fontWeight: 400,
      padding: "8px 32px 8px 10px",
      fontSize: 14,
    },
    "&.user": {
      "& .MuiInputBase-input": {
        color: "var(--gray)",
      },
    },
    "&.admin": {
      "& .MuiInputBase-input": {
        color: "var(--Tertiary)",
      },
    },
  },
  activeSelect: {
    "& .MuiSelect-select": {
      color: "#2D8F4E",
    },
  },
  roleSelect: {
    width: 145,
    "& .MuiSelect-select": {
      color: "#8B3F9E",
    },
  },
  changeDepartment: {
    width: 170,
  },

  status: {
    padding: "9px 10px",
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 500,
    width: "fit-content",
  },
  role: {
    padding: "9px 10px",
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 500,
    width: "fit-content",
  },
  teamTableImage: {
    height: 92,
    width: 216,
    minWidth: 216,
    objectFit: "contain",
    borderRadius: 5,
    background: "#F5F5F5",
    [theme.breakpoints.down("md")]: {
      width: 93,
      minWidth: 93,
      height: 93,
    },
  },
  teamTableHeading: {
    "&.MuiTypography-root": {
      marginBottom: 5,
      fontSize: 20,
      lineHeight: 1.3,
      color: "var(--blackGray)",
      fontWeight: 700,
      whiteSpace: "initial",
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
  },
  teamTableContent: {
    "&.MuiTypography-root": {
      whiteSpace: "initial",
      fontSize: 14,
      width: "100%",
    },
  },
  iconWrapper: {
    borderRadius: 6,
    margin: "0 5px",
    height: 33,
    width: 33,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
    "& svg": {
      fontSize: "1.4rem",
    },
  },
  badge: {
    position: "absolute",
    top: "-10px",
    right: "-5px",
    background: "#f55045",
    color: "var(--white)",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: 600,
    borderRadius: 99,
    padding: 4,
  },
  disabledCheckbox: {
    opacity: 0.4,
    cursor: "not-allowed",
    "& .MuiSvgIcon-root": {
      cursor: "not-allowed",
    },
  },
  admin: {
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "''",
      top: -8,
      right: -2,
      background: `url(${crown})`,
      backgroundSize: "100%",
      height: 20,
      width: 20,
      zIndex: 2,
    },
  },
  btnResendInv: {
    "&.MuiButtonBase-root": {
      padding: "9px 12px",
      background: "var(--primaryLighter)",
      [theme.breakpoints.down("sm")]: {
        padding: "9px",
      },
      "& .content": {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        fontWeight: "400",
        gap: 5,
        color: "var(--primary)",
        "& svg": {
          height: "auto",
          width: "12px",
        },
      },
      "& span": {
        lineHeight: "18px",
        fontSize: 14,
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
      },
      "&.remove": {
        display: "inline-block",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      "&.add": {
        display: "none",
        [theme.breakpoints.down("md")]: {
          display: "block",
          marginTop: 16,
          padding: "13px ",
        },
      },
    },
  },
  inviteButton: {
    "&.MuiButtonBase-root": {
      color: "var(--white)",
      padding: "8.5px 10px",
      borderRadius: 5,
      border: 0,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& svg": {
        height: 18,
        width: 18,
      },
      "& span.icon": {
        marginLeft: 8,
        display: "inline-block",
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      },
      "& .loader": {
        "& .MuiCircularProgress-root": {
          height: "16px !important",
          width: "16px !important",
        },
      },
      "&.link": {
        background: "#555FDD0D",
        color: "var(--Tertiary)",
        "& .linkIcon": {
          height: "20px",
          width: "20px",
          display: "inline-block",
        },
      },
      "&.width": {
        minWidth: "133px",
      },
    },
  },
  eventScheduleButton: {
    backgroundColor: "#2D8F4E0D",
    "&.MuiButtonBase-root": {
      color: "#2D8F4E !important",
    },
    "&.link": {
      background: "#2D8F4E0D !important",
    },
  },
  eventScheduleCreateButton: {
    backgroundColor: "#2D8F4E0D",
    "&.MuiButtonBase-root": {
      color: "#FF743C !important",
    },
    "&.link": {
      background: "#F7A23B1A !important",
    },
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 18,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "var(--white)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        background: "var(--Tertiary)",
      },
    },
    "& + .MuiSwitch-track": {
      background: "#00000040",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 9,
    height: 9,
    borderRadius: 6,
    transition: "all 0.4s ease-in-out",
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    opacity: 1,
    boxSizing: "border-box",
  },
}));

const AvatarWithAdmin = ({ row, onClickParticipants }) => {
  const classes = useStyles();
  return (
    <VSNMembersAvatarGroup max={5} onClick={(e) => onClickParticipants(row)}>
      {row?.admins?.map((member, index) => (
        <Box
          key={`${member?.user_id} -${index}`}
          className={`${classes.admin}`}
        >
          <VSNMembersAvatar
            alt={member?.first_name?.toUpperCase()}
            vasanaType={member?.primary_marker}
            title={getAvatarName(member)}
          />
        </Box>
      ))}
      {row?.members?.map((member, index) => (
        <Box
          key={`${member?.user_id} -${index}`}
          // className={`${classes.admin}`}
        >
          <VSNMembersAvatar
            alt={member?.first_name?.toUpperCase()}
            vasanaType={member?.primary_marker}
            title={getAvatarName(member)}
          />
        </Box>
      ))}
    </VSNMembersAvatarGroup>
  );
};

export const clearFormData = (formName) => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: ON_FORM_CHANGE,
    payload: {
      formData: {
        ...state?.form?.formData,
        [formName]: {},
      },
    },
  });
  dispatch({
    type: SET_ERROR,
    payload: {
      formErrors: {
        ...state?.form?.formErrors,
        [formName]: {},
      },
    },
  });
};

export const toggleModelShow =
  (model, nestedOpen) => async (dispatch, getState) => {
    const { modelStates } = getState();
    const newModelState = { ...modelStates };
    if (!keys(modelStates)?.includes(model)) {
      newModelState[model] = false;
    }
    keys(newModelState)?.forEach((key) => {
      if (equal(model, key)) {
        newModelState[key] = !newModelState[key];
      } else {
        newModelState[key] = nestedOpen ? newModelState[key] : false;
      }
    });
    dispatch({ type: ON_MODEL_STATE_CHANGE, payload: newModelState });
  };

export const clearAllModal = () => async (dispatch, getState) => {
  const { modelStates } = getState();
  const newModelState = { ...modelStates };
  keys(modelStates)?.forEach((key) => {
    newModelState[key] = false;
  });
  dispatch({ type: ON_MODEL_STATE_CHANGE, payload: newModelState });
};

export const renderActions = (
  action,
  handleAction,
  row,
  apiLoaders,
  switchStates,
  activeTab,
  currentPermission,
  allPermission
) => {
  const {
    onEditClick,
    onDeleteClick,
    downloadReport,
    setSelectedRows = () => {},
    selectedRows = [],
    handleAddMembers = () => {},
    handleSwitchChange = () => {},
    resendInvitation = () => {},
    onClickParticipants = () => {},
    handelChangeRole,
    onCopyClick,
    getMemberAssessmentDetail,
    onDeleteOrgClick,
    onChangeDepartment,
  } = handleAction;
  const { pathname } = useLocation();
  const selectKey = action?.includes("team") ? "id" : "user_id";
  const isSelected = !!selectedRows.find(
    (selectedRow) => selectedRow[selectKey] === row[selectKey]
  );
  const { changingRole: loaderChangeRole } = useSelector(
    (state) => state?.api?.loader
  );
  const {
    sub: currentUserId,
    organization_name: orgName,
    role,
  } = useSelector((state) => state.app?.userData?.user_profile);
  const [dialogBox, setDialogBox] = useState(false);
  const handleCheckboxChange = (e, value) => {
    // Row selection for members table
    setSelectedRows((prev = []) => {
      const selectedRowIndex = prev.findIndex(
        (selectedRow) => selectedRow[selectKey] === row[selectKey]
      );
      if (~selectedRowIndex) {
        // Remove if already exists
        const clonedRows = [...prev];
        clonedRows.splice(selectedRowIndex, 1);
        return clonedRows;
      }

      return [...prev, row];
    });
  };

  useEffect(() => {
    // Clear row selection
    return () => setSelectedRows([]);
  }, []);

  const classes = useStyles();

  return (
    action?.length > 0 && (
      <Stack alignItems="center" direction="row">
        {action?.map((val, i) => (
          <Fragment key={i}>
            {val === "edit" &&
              ((currentPermission ?? false) &&
              !currentPermission.includes("edit") ? (
                <> </>
              ) : (
                <VSNToolTip title="Edit">
                  <span
                    className={classes.iconWrapper}
                    style={{ background: "#2D8F4E0D" }}
                    onClick={() => onEditClick(row)}
                    aria-hidden
                  >
                    <EditCustomIcon disabled style={{ cursor: "pointer" }} />
                  </span>
                </VSNToolTip>
              ))}
            {val === "changeDepartment" &&
              organization_location_admin.includes(role) &&
              row?.department_original_id && (
                <VSNToolTip title="Change Department">
                  <span
                    className={classes.iconWrapper}
                    style={{ background: "#2D8F4E0D" }}
                    onClick={() => onChangeDepartment(row)}
                    aria-hidden
                  >
                    <EditCustomIcon disabled style={{ cursor: "pointer" }} />
                  </span>
                </VSNToolTip>
              )}
            {val === "showList" ? (
              <VSNToolTip title="Assessment List">
                <span
                  className={classes.iconWrapper}
                  style={{ background: "#F0F9F2", color: "#2D8F4E" }}
                  onClick={() => getMemberAssessmentDetail(row)}
                  aria-hidden
                >
                  <DescriptionOutlinedIcon />
                  {row?.pending_assessments?.length > 0 && (
                    <div className={classes.badge}>
                      {row?.pending_assessments?.length}
                    </div>
                  )}
                </span>
              </VSNToolTip>
            ) : null}
            {val === "enabled" ? (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={
                  row?.nonChangeable
                    ? { cursor: "not-allowed", paddingLeft: 1 }
                    : { paddingLeft: 1 }
                }
              >
                <AntSwitch
                  sx={row?.nonChangeable ? { pointerEvents: "none" } : {}}
                  disabled={row?.nonChangeable}
                  checked={row?.enabled || false}
                  onChange={(event) =>
                    !row?.nonChangeable ? handleSwitchChange(event, row) : null
                  }
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Stack>
            ) : null}
            {val === "delete" &&
              currentUserId !== row.user_id &&
              ((
                activeTab === "members" && pathname === "/org-admin"
                  ? orgName !== "Guest"
                  : true
              ) ? (
                <VSNToolTip title="Delete">
                  <span
                    className={classes.iconWrapper}
                    style={{ background: "#F543460D", color: "#F54346" }}
                    onClick={() => onDeleteClick(row)}
                    aria-hidden
                  >
                    <DeleteIcon />
                  </span>
                </VSNToolTip>
              ) : (
                <span
                  className={classes.iconWrapper}
                  style={{ background: "#F4F6FD", color: "gray" }}
                  aria-hidden
                >
                  <DeleteIcon />
                </span>
              ))}
            {val === "deleteOrg" && row?.id !== 79 && (
              <VSNToolTip title="Delete">
                <span
                  className={classes.iconWrapper}
                  style={{ background: "#F543460D", color: "#F54346" }}
                  onClick={() => onDeleteOrgClick(row)}
                  aria-hidden
                >
                  <DeleteIcon />
                </span>
              </VSNToolTip>
            )}
            {val === "deleteUser" &&
              allPermission?.member?.includes("delete") &&
              (currentUserId !== row.user_id &&
              (activeTab === "members" && pathname === "/org-admin"
                ? orgName !== "Guest"
                : true) ? (
                <VSNToolTip title="Delete">
                  <span
                    className={classes.iconWrapper}
                    style={{ background: "#F543460D", color: "#F54346" }}
                    onClick={() => onDeleteClick(row)}
                    aria-hidden
                  >
                    <DeleteIcon />
                  </span>
                </VSNToolTip>
              ) : (
                <span
                  className={classes.iconWrapper}
                  style={{ background: "#F4F6FD", color: "gray" }}
                  aria-hidden
                >
                  <DeleteIcon />
                </span>
              ))}
            {val === "selectUser" && (
              <Checkbox
                sx={{ cursor: "pointer" }}
                className={
                  currentUserId === row?.user_id ? classes.disabledCheckbox : ""
                }
                checked={currentUserId === row?.user_id ? false : isSelected}
                onChange={handleCheckboxChange}
                disabled={currentUserId === row?.user_id}
              />
            )}
            {val === "manageMembers" && (
              <VSNToolTip title="Manage Members">
                <span
                  className={classes.iconWrapper}
                  style={{ background: "#555FDD0D" }}
                >
                  <ManageAccountsOutlinedIcon
                    onClick={() =>
                      row?.members?.length
                        ? onClickParticipants(row)
                        : handleAddMembers(row)
                    }
                    color="var(--Tertiary)"
                  />
                </span>
              </VSNToolTip>
            )}
            {val === "changeRole" &&
              currentPermission &&
              currentPermission?.includes("remove") && (
                <>
                  {currentUserId === row?.user_id ? (
                    <VSNToolTip
                      title={`Remove as an  ${formatName(activeTab)}`}
                    >
                      <RemoveAdmin color="gray" />
                    </VSNToolTip>
                  ) : (
                    <VSNToolTip
                      title={`Remove as an  ${formatName(activeTab)}`}
                    >
                      <RemoveAdmin
                        onClick={() => setDialogBox(true)}
                        color="#F54346"
                      />
                    </VSNToolTip>
                  )}
                  {dialogBox && (
                    <VSNConfirmBox
                      isOpen={dialogBox}
                      title="Are You Sure?"
                      handelChangeRole={handelChangeRole}
                      // handleOk={() => handelChangeRole(row, setDialogBox)}
                      handleCancel={() => setDialogBox(false)}
                      setDialogBox={setDialogBox}
                      row={row}
                      okBtnName="Yes, Update"
                      cancelBtnName="Cancel"
                      isLoading={loaderChangeRole}
                      className={classes.conformModel}
                      msg={
                        <>
                          You are about to remove{" "}
                          <span>
                            {row?.first_name} {row?.last_name}
                          </span>
                          {`as an ${formatName(
                            activeTab
                          )}. This action cannot be undone.`}
                        </>
                      }
                    />
                  )}
                </>
              )}
            {val === "copyLink" && (
              <VSNToolTip title="Copy Invitation link">
                <span
                  className={classes.iconWrapper}
                  style={{ background: "#555FDD0D" }}
                  onClick={() => onCopyClick(row?.invitation_link)}
                  aria-hidden
                >
                  <ContentCopyIcon
                    sx={{ color: "var(--Tertiary)" }}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </VSNToolTip>
            )}
            {val === "resendEmail" && (
              <VSNButton
                handleClick={() => resendInvitation(row)}
                className={classes.btnResendInv}
              >
                Resend Invitation
              </VSNButton>
            )}
            {val === "download" ? (
              row?.assessment_given ? (
                apiLoaders?.[row?.user_id] ? (
                  <CircularProgress
                    sx={{ marginTop: "3px" }}
                    size={20}
                    color="inherit"
                  />
                ) : (
                  <VSNToolTip title="Download assessment report">
                    <CloudDownloadOutlinedIcon
                      sx={{ margin: "0 5px" }}
                      onClick={() => downloadReport(row)}
                    />
                  </VSNToolTip>
                )
              ) : (
                <Box sx={{ width: 30 }}> </Box>
              )
            ) : (
              ""
            )}
          </Fragment>
        ))}
      </Stack>
    )
  );
};

export const sortMembers = (data) => {
  const adminMembers = data?.members.filter((member) =>
    data?.admins?.includes(member.user_id)
  );
  const nonAdminMembers = data?.members.filter(
    (member) => !data?.admins?.includes(member.user_id)
  );
  const sortedMembers = [...adminMembers, ...nonAdminMembers];
  return sortedMembers || [];
};

const GetCustom = ({
  value,
  head,
  handleAction,
  row,
  apiLoaders,
  open,
  switchStates,
}) => {
  const tabsPath = "org-summary";
  const { permission } = head;
  const allPermission = useSelector(
    (state) => state?.app?.userData?.user_profile?.permissions
  );
  const currentPermission = allPermission?.[permission];

  const activeTab = useSelector((state) => state?.api?.tabs?.[tabsPath]);

  const loaders = useSelector((state) => {
    return {
      changeMemberRole: state.api?.loader?.changeMemberRole,
    };
  });
  const currentUserId = useSelector(
    (state) => state.app?.userData?.user_profile?.sub
  );

  const { api } = ApiContainer();
  const [changeRole, setChangeRole] = useState("");
  const [newDepartment, setNewDepartment] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogForActive, setShowDialogForActive] = useState(false);
  const organization_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_id
  );
  const departmentTypes =
    useSelector((state) => state?.api?.departmentType) ?? [];
  const dispatch = useDispatch();
  const handleRoleChange = async (e) => {
    const { name, value } = e.target;
    setShowDialog(true);
    setChangeRole(value);
  };
  const handleStatusChange = async (e) => {
    const { name, value } = e.target;
    setShowDialogForActive(true);
    setChangeRole(value);
  };
  const handleDepartmentChange = async (e) => {
    const { name, value } = e.target;
    setShowDialog(true);
    setNewDepartment(value);
  };
  const filters = useSelector((state) => state.form.formData?.[activeTab]);
  const departmentList = useSelector((state) => state?.api?.departmentList);
  const handleRoleChangeConfirm = async () => {
    dispatch({
      type: LOADING_CHANGE,
      payload: { changeMemberRole: true },
    });
    const response = await api({
      method: "POST",
      endPoint: `organization/${organization_id}/members/${row?.user_id}/roles`,
      data: {
        role: changeRole,
      },
      showToastMessage: true,
      urlencoded: false,
    });

    if (!response?.data?.success) {
      setShowDialog(false);
    } else {
      dispatch({
        type: LOADING_CHANGE,
        payload: { changeMemberRole: false },
      });
    }
    const reportsData = await api({
      method: "GET",
      endPoint: `organization/${organization_id}/${activeTab}?page=${
        filters?.page
      }&limit=${filters?.rowsPerPage}${
        filters?.date_filter?.startDate
          ? `&start_date=${filters?.date_filter?.startDate}`
          : ``
      }${
        filters?.date_filter?.endDate
          ? `&end_date=${filters?.date_filter?.endDate}`
          : ``
      }${
        filters?.sortyByStatus ? `&member_status=${filters?.sortyByStatus}` : ``
      }${
        filters?.sortByVasanaType
          ? `&vasana_type=${filters?.sortByVasanaType}`
          : ``
      }${filters?.sortByRole ? `&member_role=${filters?.sortByRole}` : ``}${
        filters?.keyword ? `&keyword=${filters?.keyword}` : ``
      }`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: activeTab,
    });

    if (reportsData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          [activeTab]: reportsData.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          [activeTab]: [],
        },
      });
    }
  };
  const classes = useStyles();
  const { type, action, isMember } = head;
  const {
    onSendClick,
    onClickParticipants,
    onClickTeamList,
    onExpandRow,
    adminView,
    getMemberAssessmentDetail,
    changeLocationAdminRole,
    changeDepartment,
    changeStatus,
    handelEventScheduleClick,
  } = handleAction;
  const memberStatus = ({ color, background, text }) => (
    <VSNTypography color={color} sx={{ background }} className={classes.status}>
      {text}
    </VSNTypography>
  );
  const navigate = useNavigate();
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);

  const roleStatus = ({ color, background, text }) => (
    <VSNTypography
      color="#BA68C8"
      sx={{ backgroundColor: "#8B3F9E1A" }}
      className={classes.role}
    >
      {text}
    </VSNTypography>
  );

  const getStatus = () => {
    if (!row?.status) {
      return "- ";
    }

    switch (row.status) {
      case "invited":
        return memberStatus({
          color: "#F7A23F",
          background: "#F7A23B1A",
          text: "Invited",
        });

      case "joined":
        return memberStatus({
          color: "var(--Tertiary)",
          background: "#555FDD1A",
          text: "Joined",
        });

      case "assessment_completed":
        return memberStatus({
          color: "#2D8F4E",
          background: "#2D8F4E1A",
          text: "Assessment Taken",
        });

      default:
        return null;
    }
  };

  const getLocationStatus = () => {
    if (!row?.role) {
      return "- ";
    }

    switch (row.role) {
      case location_admin:
        return roleStatus({
          text: "Location Admin",
        });

      case clinician:
        return roleStatus({
          text: "Clinician",
        });

      case head_clinician:
        return roleStatus({
          text: "Head Clinician",
        });

      default:
        return null;
    }
  };

  return (
    <>
      {type === "link" ? (
        <>
          {value && (
            <Link
              to={value}
              onClick={(event) => {
                event.preventDefault();
                copyToClipboard(value);
              }}
            >
              {value}
            </Link>
          )}
          <Send
            style={{
              cursor: "pointer",
              marginLeft: "12px",
              verticalAlign: "middle",
            }}
            onClick={() => onSendClick(row)}
          />
        </>
      ) : type === "changeDepartment" ? (
        row?.department_original_id ? (
          role === department_admin ? (
            row?.department_name
          ) : (
            <>
              <VSNSelect
                onChange={handleDepartmentChange}
                value={row?.department_original_id}
                options={departmentList?.map(
                  ({ department_name, department_original_id }) => ({
                    key: `${department_name} (${department_original_id})`,
                    value: department_original_id,
                  })
                )}
                className={`${classes.tblSelect} ${classes.changeDepartment}`}
              />
              {showDialog && (
                <VSNConfirmBox
                  isOpen={showDialog}
                  title="Are You Sure?"
                  msg="Are you sure you want to change department?"
                  handleOk={() =>
                    changeDepartment(row, newDepartment, setShowDialog)
                  }
                  handleCancel={() => setShowDialog(false)}
                  handleClose={() => setShowDialog(false)}
                  okBtnName="Yes"
                  cancelBtnName="Cancel"
                  isLoading={loaders?.changeMemberRole}
                  // className={classes.conformModel}
                />
              )}
            </>
          )
        ) : (
          <Stack textAlign="center">-</Stack>
        )
      ) : type === "createEventSchedule" ? (
        <>
          {allPermission?.event_schedule?.includes(
            row?.is_event_scheduled ? "edit" : "create"
          ) ? (
            <VSNButton
              className={`${classes.inviteButton} ${
                row?.is_event_scheduled
                  ? classes?.eventScheduleButton
                  : classes?.eventScheduleCreateButton
              } link width`}
              startIcon={
                row?.is_event_scheduled ? <EditCustomIcon /> : <AddIcon />
              }
              handleClick={() => handelEventScheduleClick(row)}
            >
              {row?.is_event_scheduled ? "View Event" : "Create Event"}
            </VSNButton>
          ) : (
            "-"
          )}
        </>
      ) : type === "viewReport" ? (
        <VSNButton className={`${classes.inviteButton} link`}>
          View Report
        </VSNButton>
      ) : type === "viewAssessment" ? (
        <VSNButton
          className={`${classes.inviteButton} link`}
          handleClick={() =>
            navigate(`/org-admin/${row?.location_original_id}/${row?.user_id}`)
          }
        >
          View Reports
        </VSNButton>
      ) : type === "collapsible" ? (
        all_admin?.includes(role) ? (
          row?.department_original_id && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onExpandRow(row)}
            >
              {row?.expanded ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )
        ) : (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => onExpandRow(row)}
          >
            {row?.expanded ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        )
      ) : type === "date" ? (
        <>{value ? new Date(value).toLocaleDateString("en-US") : "-"}</>
      ) : type === "time" ? (
        <>
          {value
            ? `${new Date(value).toLocaleDateString("en-US")} - ${new Date(
                value
              ).toLocaleTimeString("en-US", { hour12: false })}`
            : "-"}
        </>
      ) : type === "percentage" ? (
        <>{`${value}%`}</>
      ) : type === "actions" ? (
        <>
          {renderActions(
            action,
            handleAction,
            row,
            apiLoaders,
            switchStates,
            activeTab,
            currentPermission,
            allPermission
          )}
        </>
      ) : type === "VasanaTypeIcon" ? (
        value ? (
          <VSNImage src={VSNTypes[value?.toLowerCase()]} alt="VasanaTypeIcon" />
        ) : (
          "- "
        )
      ) : type === "organization" ? (
        !row?.name && !row?.original_id ? (
          "- "
        ) : (
          <>
            {row?.name}{" "}
            {row?.original_id
              ? !row?.name
                ? row?.original_id
                : `(${row?.original_id})`
              : ""}
          </>
        )
      ) : type === "organization_type" ? (
        !row?.organization_type ? (
          "- "
        ) : (
          <>{row?.organization_type} </>
        )
      ) : type === "status" ? (
        !row?.status ? (
          "- "
        ) : (
          getStatus()
        )
      ) : type === "locationType" ? (
        !row?.status ? (
          "- "
        ) : (
          getLocationStatus()
        )
      ) : type === "member_name" ? (
        !row?.first_name && !row?.last_name ? (
          "- "
        ) : (
          <>
            {row?.first_name} {row?.last_name}
          </>
        )
      ) : type === "member_name_icon" ? (
        !row?.first_name && !row?.last_name ? (
          "- "
        ) : (
          <>
            <Stack
              direction="row"
              alignItems="center"
              className={classes.memberName}
            >
              <Box>
                <VSNMembersAvatar
                  alt={row?.first_name}
                  src={row?.profile_picture}
                  vasanaType={row?.primary_marker}
                  title={getAvatarName(row)}
                  sx={{ border: "2px solid #fff" }}
                  defaultColor="var(--white)"
                  defaultPrimaryBackgroundColor="#1F396D"
                  defaultSecondaryBackgroundColor="#1F396D"
                />
              </Box>
              <VSNTypography>
                {" "}
                {row?.first_name} {row?.last_name}
              </VSNTypography>
            </Stack>
          </>
        )
      ) : type === "role" ? (
        !row?.role_in_organization ? (
          "- "
        ) : (
          <>
            <VSNSelect
              onChange={handleRoleChange}
              value={row?.role_in_organization}
              options={[
                { key: "User", value: "user" },
                { key: "Admin", value: "admin" },
              ]}
              className={`${classes.tblSelect} ${
                row?.role_in_organization !== "admin" &&
                row?.role_in_organization !== undefined
                  ? "user"
                  : "admin"
              }`}
              disabled={isMember && currentUserId === row?.user_id}
            />
            {showDialog && (
              <VSNConfirmBox
                isOpen={showDialog}
                msg="Are you sure you want to change member Role?"
                title="Are you sure?"
                handleOk={handleRoleChangeConfirm}
                handleCancel={() => setShowDialog(false)}
                okBtnName="Yes"
                cancelBtnName="Cancel"
                isLoading={loaders?.changeMemberRole}
                className={classes.conformModel}
              />
            )}
          </>
        )
      ) : type === "locationRole" ? (
        !row?.role_id ? (
          "- "
        ) : (
          <>
            <VSNSelect
              onChange={handleRoleChange}
              value={row?.role_id}
              options={changeLocationAdminOption}
              className={`${classes.tblSelect} ${classes.roleSelect}`}
              disabled={currentUserId === row?.user_id || row?.is_primary_admin}
            />
            {showDialog && (
              <VSNConfirmBox
                isOpen={showDialog}
                title="Are You Sure?"
                msg={`You are about to change ${getName(row)}’s Role from ${
                  nameOfRole?.[row?.role]
                } to ${nameOfRole?.[IdToRole?.[changeRole]]}.`}
                handleOk={() =>
                  changeLocationAdminRole(row, changeRole, setShowDialog)
                }
                handleCancel={() => setShowDialog(false)}
                okBtnName="Yes, Change"
                cancelBtnName="Cancel"
                isLoading={loaders?.changeMemberRole}
                className={classes.conformModel}
              />
            )}
          </>
        )
      ) : type === "changeStatus" ? (
        !row?.role_id ? (
          "- "
        ) : (
          <>
            <VSNSelect
              onChange={handleStatusChange}
              value={row?.is_active}
              options={activeStatusOption}
              className={`${classes.tblSelect} ${
                row?.is_active ? classes.activeSelect : ""
              } `}
              disabled={currentUserId === row?.user_id || row?.is_primary_admin}
            />
            {showDialogForActive && (
              <VSNConfirmBox
                isOpen={showDialogForActive}
                title="Are You Sure?"
                handleOk={() =>
                  changeStatus(row, changeRole, setShowDialogForActive)
                }
                handleCancel={() => setShowDialogForActive(false)}
                okBtnName={`Yes, ${row?.is_active ? "Inactive" : "Active"}`}
                cancelBtnName="Cancel"
                isLoading={loaders?.changeMemberRole}
                className={classes.conformModel}
                msg={
                  <>
                    You are about to {row?.is_active ? "Inactive" : "Active"}{" "}
                    <span>
                      {row?.first_name ?? row?.email} {row?.last_name}
                    </span>
                    {`as an ${formatName(activeTab)}.`}
                  </>
                }
              />
            )}
          </>
        )
      ) : type === "VasanaTypePercentage" ? (
        <>
          {row?.primary_marker ? (
            <Stack direction="row" alignItems="center">
              {/* <Box className={`${classes.curculerOut} `}>
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: `${vasanaTypeColor[row?.primary_marker]?.color}`,
                  }}
                  value={row?.vasana_score ? Number(row?.vasana_score) || 0 : 0}
                />
                <VSNTypography
                  className="value"
                  sx={{
                    color: `${vasanaTypeColor[row?.primary_marker]?.color}`,
                  }}
                >
                  {row?.vasana_score}%
                </VSNTypography>
              </Box> */}
              <VSNImage
                src={VSNTypes[value?.toLowerCase()]}
                height="32px"
                alt="VasanaTypePercentage"
              />
            </Stack>
          ) : (
            "- "
          )}
        </>
      ) : type === "team_name" ? (
        !row?.teams?.length ? (
          "- "
        ) : (
          <>
            <Stack
              direction="row"
              alignItems="center"
              pb={1.5}
              onClick={() => onClickTeamList(row)}
            >
              <VSNMembersAvatarGroup max={7}>
                {row?.teams.map((team, index) => (
                  <VSNMembersAvatar
                    key={index}
                    alt={team?.name}
                    src={team?.profile_picture}
                    vasanaType={team?.primary_marker}
                  />
                ))}
              </VSNMembersAvatarGroup>
            </Stack>
          </>
        )
      ) : type === "showAdmin" ? (
        !row?.admins?.length ? (
          "- "
        ) : (
          <>
            <Stack
              direction="row"
              alignItems="center"
              pb={1.5}
              onClick={() => adminView(row)}
            >
              <VSNMembersAvatarGroup max={3}>
                {row?.admins?.map((admin, index) => (
                  <VSNMembersAvatar
                    key={index}
                    alt={admin?.first_name}
                    src={admin?.profile_picture}
                    title={getAvatarName(admin)}
                  />
                ))}
              </VSNMembersAvatarGroup>
            </Stack>
          </>
        )
      ) : type === "address" ? (
        !row?.address_line_1 && !row?.address_line_1 ? (
          "- "
        ) : (
          <>
            <VSNToolTip
              title={`${row?.address_line_1} , ${row?.address_line_2} , ${
                row?.city ?? ""
              } , ${row?.zip_code ?? ""}`}
            >
              <Stack>{`${row?.city ?? ""} , ${row?.zip_code ?? ""}`}</Stack>
            </VSNToolTip>
          </>
        )
      ) : type === "participants" ? (
        <>
          <Stack direction="row" alignItems="center" pb={1.5}>
            <AvatarWithAdmin
              row={row}
              onClickParticipants={onClickParticipants}
            />
          </Stack>
        </>
      ) : type === "teams_team_name" ? (
        <>
          <Stack direction="row" alignItems="center">
            <Box>
              <img
                src={row?.profile_picture ? row?.profile_picture : businessImg}
                alt="business"
                className={classes.teamTableImage}
              />
            </Box>
            <Box pl={1.9} flex={1}>
              <VSNTypography
                variant="body1"
                component="p"
                className={classes.teamTableHeading}
              >
                {row?.name}
              </VSNTypography>
              <VSNTypography
                variant="body1"
                component="p"
                className={classes.teamTableContent}
              >
                {row?.goal}
              </VSNTypography>
            </Box>
          </Stack>
        </>
      ) : (
        value || "- "
      )}
    </>
  );
};

export const setErrors =
  ({ errors, value, name, formPath, isClear }) =>
  async (dispatch, getState) => {
    const { form } = getState();
    if (isClear) {
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: {},
          },
        },
      });
    } else if (value) {
      errors = { ...errors, [name]: "" };
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: { ...errors },
          },
        },
      });
    }
  };

export const getAssessmentState = ({ assessments, id }) => {
  return (
    assessments?.find(
      (el) => el?.assessment_id?.toString() === id?.toString()
    ) || null
  );
};

export const getSlug = (assessments, id) => {
  const current =
    assessments?.find(
      (el) => el?.assessment_id?.toString() === id?.toString()
    ) || null;
  return (
    current?.name?.toLowerCase()?.replaceAll(" ", "-") ||
    current?.assessment_name?.toLowerCase()?.replaceAll(" ", "-")
  );
};

export const getIdFromSlug = (assessments, slug) => {
  const current =
    assessments?.find((el) => {
      return (
        el?.name?.toLowerCase()?.replaceAll(" ", "-") === slug ||
        el?.assessment_name?.toLowerCase()?.replaceAll(" ", "-") === slug
      );
    }) || null;
  return current?.assessment_id?.toString();
};

export const handleNavigation = (navigate, userRoleData) => {
  const token = localStorage.getItem("token");
  if (token && userRoleData && length(keys(userRoleData))) {
    if (userRoleData?.profile_updated) {
      localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
      const vasanaTypeAssessment = getAssessmentState({
        assessments: userRoleData?.assessments,
        id: assessmentIds?.vasanaType,
      });
      if (vasanaTypeAssessment?.result) {
        navigate("/");
      } else {
        navigate(`/get-started/${assessmentNames[assessmentIds?.vasanaType]}`);
      }
    } else {
      navigate("/edit-profile", { state: { onBoarding: true } });
    }
  }
};
export const navigateAfterLogin = (navigate, userRoleData, pathname) => {
  const token = localStorage.getItem("token");
  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });
  if (token) {
    if (userRoleData?.user_profile) {
      if (vasanaTypeAssessment?.result) {
        if (pathname !== "/") navigate("/");
      } else {
        navigate(`/get-started/${assessmentNames[assessmentIds?.vasanaType]}`);
      }
    } else {
      navigate("/edit-profile");
    }
  } else {
    navigate("/login");
  }
};

export const changeSaReGaMaStyle = (str) => {
  if (str) {
    return str.replace(
      new RegExp(`\\b(${["sa", "re", "ga", "ma"].join("|")})\\b`, "gi"),
      '<span style="font-family: Playfair Display;font-weight:bold">$1</span>'
    );
  }
  return "";
};
export default GetCustom;

const Checkbox = ({ sx, checked, onChange, disabled, className }) => {
  return (
    <Box className={className}>
      {checked ? (
        <CheckBoxIcon
          sx={{ margin: "0 5px", ...sx }}
          onClick={(e) => (!disabled ? onChange(e) : null)}
        />
      ) : (
        <CheckBoxOutlineBlankIcon
          sx={{ margin: "0 5px", ...sx }}
          onClick={(e) => (!disabled ? onChange(e) : null)}
        />
      )}
    </Box>
  );
};

export const renderTypesWithImage = ({
  type,
  height,
  isBlackImage,
  className,
}) => {
  if (type)
    return (
      <VSNImage
        className={className}
        src={
          isBlackImage
            ? `${
                process.env.REACT_APP_MEDIA_URL
              }${type?.toLowerCase()}/black-logo.svg`
            : `${
                process.env.REACT_APP_MEDIA_URL
              }${type?.toLowerCase()}/colored-logo.svg`
        }
        alt={`${type} Image`}
        {...(height ? { height } : {})}
      />
    );
  return <></>;
};

export const colorFromResults = (type) => {
  switch (type) {
    case "Sa":
    case "sa":
      return "#8b3f9e";
    case "Ga":
    case "ga":
      return "#ffb142";
    case "Ma":
    case "ma":
      return "#2d8f4e";
    case "Re":
    case "re":
      return "#1780c6";
    case "Introvert":
    case "Extrovert":
      return "var(--Tertiary)";
    default:
      return "#c6c6c6";
  }
};

export const getEndpoint = ({
  organization_original_id,
  location_id,
  department_id,
  role,
  endPoint,
}) => {
  if (organization_admin_name?.includes(role)) {
    return `organization/${organization_original_id}/${endPoint}`;
  }
  if (location_admin_name?.includes(role)) {
    return `organization/${organization_original_id}/locations/${location_id}/${endPoint}`;
  }
  if (department_admin_name?.includes(role)) {
    return `organization/${organization_original_id}/locations/${location_id}/departments/${department_id}/${endPoint}`;
  }
  return null;
};
