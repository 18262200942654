import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { TestLayoutStyles } from "./TestLayoutStyles";
import VSNImage from "../../shared/VSNImage";
import unlock1 from "../../assets/assesments/svg/assesment-1.svg";
import unlock2 from "../../assets/assesments/svg/assesment-2.svg";
import unlock3 from "../../assets/assesments/svg/assesment-3.svg";
import unlock4 from "../../assets/assesments/svg/quick-shape-4.svg";
import unlock5 from "../../assets/assesments/svg/quick-shape-3.svg";
import unlock6 from "../../assets/assesments/svg/snake.svg";
import { assessmentDataContainer } from "../../container/assessmentData.container";
import { SET_API_DATA } from "../../redux/constants";
import { keys, length } from "../../utils/javascript";
import {
  getAssessmentState,
  getIdFromSlug,
  getSlug,
} from "../../shared/CustomFunc";
import { assessmentIds, assessmentNames } from "../../utils/constant";
import VSNLoader from "../../shared/VSNLoader";

const TestLayout = () => {
  const classes = TestLayoutStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentName } = useParams();
  const dispatch = useDispatch();
  const userRoleData = useSelector((state) => state?.app?.userData);
  const { assessmentList } = useSelector((state) => state?.api);
  const signup_flow_completed =
    userRoleData?.user_profile?.signup_flow_completed;

  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });
  const currentAssessmentId = localStorage.getItem("currentAssessmentId");
  const currentAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: currentAssessmentId,
  });

  const { loading } = assessmentDataContainer({
    currentAssessment,
    location,
    assessmentName,
  });

  useEffect(() => {
    const handlePopState = () => {
      const slug = getSlug(assessmentList, currentAssessmentId);
      navigate(`/get-started/${slug}`);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [currentAssessmentId, navigate]);

  useEffect(() => {
    if (
      userRoleData?.role &&
      assessmentList?.length &&
      !signup_flow_completed
    ) {
      const remainingAssessment = assessmentList?.length
        ? assessmentList.find(
            (assessment) =>
              assessment.is_required_for_signup && !assessment.assessment_taken
          )
        : null;

      if (remainingAssessment) {
        localStorage.setItem(
          "currentAssessmentId",
          remainingAssessment?.assessment_id
        );
        const slug = getSlug(
          assessmentList,
          remainingAssessment?.assessment_id
        );
        navigate(`/get-started/${slug}`);
        return;
      }
      if (
        !vasanaTypeAssessment?.result &&
        getIdFromSlug(assessmentList, assessmentName) !==
          assessmentIds?.vasanaType
      ) {
        localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
        navigate(`/get-started/${assessmentNames[assessmentIds.vasanaType]}`);
        return;
      }
      const currentTest = getAssessmentState({
        assessments: userRoleData?.assessments,
        id: getIdFromSlug(assessmentList, assessmentName),
      });
      if (!userRoleData?.is_demo_user && currentTest?.result) {
        if (
          getIdFromSlug(assessmentList, assessmentName) ===
          assessmentIds?.vasanaType
        ) {
          navigate(`/`);
        } else {
          localStorage.setItem(
            "currentAssessmentId",
            assessmentIds?.vasanaType
          );
          navigate(`/assessments`);
        }
        return;
      }

      if (
        !location?.pathname?.includes(
          getSlug(assessmentList, currentAssessmentId)
        )
      ) {
        const slug = getSlug(
          assessmentList,
          getIdFromSlug(assessmentList, assessmentName)
        );
        if (slug) {
          localStorage.setItem(
            "currentAssessmentId",
            getIdFromSlug(assessmentList, assessmentName)
          );
          navigate(`/get-started/${slug}`);
        } else {
          navigate(`/assessments`);
        }
      }
    }
  }, [userRoleData, assessmentList]);

  useEffect(() => {
    if (userRoleData?.is_demo_user) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          downloadVasanaTypeReport: null,
        },
      });
    } else if (
      userRoleData &&
      length(keys(userRoleData)) &&
      !userRoleData?.profile_updated
    ) {
      navigate("/edit-profile", {
        state: { onBoarding: true },
      });
    }
  }, [location, userRoleData]);
  if (loading) return <VSNLoader variant="fullPage" />;

  return (
    <Box className={classes.mainWrapper}>
      <Outlet />

      <VSNImage
        src={unlock1}
        className={`${classes.shape} ${classes.shapeOne}`}
        alt="unlock1"
      />
      <VSNImage
        src={unlock2}
        className={`${classes.shape} ${classes.shapeTwo}`}
        alt="unlock2"
      />
      <VSNImage
        src={unlock3}
        className={`${classes.shape} ${classes.shapeThree}`}
        alt="unlock3"
      />
      <VSNImage
        src={unlock4}
        className={`${classes.shape} ${classes.shapeFour}`}
        alt="unlock4"
      />
      <VSNImage
        src={unlock6}
        className={`${classes.shape} ${classes.shapeSix}`}
        alt="unlock6"
      />
    </Box>
  );
};

export default TestLayout;
