import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_API_DATA } from "../redux/constants";
import { ApiContainer } from "../utils/api";
import { equal, keys, objToArray, values } from "../utils/javascript";
import { assessmentIds, endPoints } from "../utils/constant";
import { getIdFromSlug, getSlug } from "../shared/CustomFunc";

export const assessmentDataContainer = ({
  currentAssessment,
  location,
  assessmentName,
}) => {
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assessmentList = useSelector((state) => state?.api?.assessmentList);
  const currentAssessmentId = localStorage.getItem("currentAssessmentId");
  const userData = useSelector((state) => state?.app?.userData);
  const assessmentStoredData = useSelector(
    (state) => state.api?.assessmentData
  );
  const [assessmentData, setAssessData] = useState({
    ...assessmentStoredData,
  });
  const [loading, setLoading] = useState(true);

  const getQuestionSetData = async () => {
    setLoading(true);
    const getData = async (endPoint, key) => {
      const response = await api({
        method: "GET",
        endPoint,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: key,
      });
      if (response?.status) {
        if (key === "questions") {
          response.data.sections = values(response.data.sections);
        }
        if (key === "tieBreaker") {
          response.data.sections = values(response.data.sections);
        }
        setAssessData((prevData) => {
          const returnData = {
            ...prevData,
            [key]:
              equal(key, "questions") || equal(key, "tieBreaker")
                ? response?.data
                : response?.data,
          };
          dispatch({
            type: SET_API_DATA,
            payload: {
              assessmentData: returnData,
            },
          });
          return returnData;
        });
      }
    };

    const apiCalls = [
      getData(
        `assessment/${currentAssessment?.assessment_id}/get-question`,
        "questions"
      ),
    ];
    if (
      ["/assessment", "/quick-note"]?.some((path) =>
        location?.pathname?.includes(path)
      )
    ) {
      apiCalls?.push([getData(endPoints?.generateSubmissionId, "subId")]);
    }
    if (currentAssessment?.has_tie_breaker_questions) {
      apiCalls?.push([
        getData(
          `assessment/${currentAssessment?.assessment_id}/tie-breaker`,
          "tieBreaker"
        ),
      ]);
    }
    if (
      currentAssessment?.assessment_id?.toString() === assessmentIds?.vasanaType
    ) {
      apiCalls?.push([getData(endPoints?.markerDetails, "markerInfo")]);
    }
    await Promise.all(apiCalls);
    setLoading(false);
  };

  useEffect(() => {
    if (currentAssessment?.assessment_id) getQuestionSetData();
  }, [currentAssessment?.assessment_id]);

  useEffect(() => {
    if (assessmentList && userData?.user_profile) {
      const getAssessment = assessmentList.find(
        (el) =>
          String(el?.assessment_id) === currentAssessmentId &&
          !el?.assessment_taken
      );
      const canUserTakeAssessment = !!getAssessment;
      const slug = getSlug(assessmentList, currentAssessmentId);
      if (
        slug &&
        assessmentName !== slug &&
        (canUserTakeAssessment || userData?.is_demo_user)
      )
        navigate(`/get-started/${slug}`);
      else if (!slug) navigate(`/assessments`);
      else if (!userData?.is_demo_user && !canUserTakeAssessment)
        navigate(`/assessments`);
    }
  }, [assessmentList, currentAssessment?.assessment_id, userData]);

  return { assessmentData, loading };
};
