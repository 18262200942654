import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import anchorContainer from "../container/anchor.container";

const useStyles = makeStyles((theme) => ({
  MenuWrapper: {
    "&.MuiButtonBase-root": {
      backgroundColor: "#DFE1F8",
      border: `1px solid var(--Tertiary)`,
      borderRadius: 5,
      backdropFilter: "blur(4px)",
      height: 42,
      width: 42,
      "&:hover": {
        backgroundColor: "#d4d8ff",
      },
      "& .MuiSvgIcon-root": {
        color: "var(--Tertiary)",
      },
    },
  },
  ListMenuWrapper: {
    "& .MuiSvgIcon-root": {
      [theme.breakpoints.down("md")]: {
        width: 14,
      },
    },
  },
  popupIcon: {
    height: 24,
    width: 24,
    borderRadius: "100%",
    backgroundColor: "var(--white)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteIcon: {
    "& svg": {
      height: 14,
    },
  },
}));

const VNSActions = ({ btnName, menuItems }) => {
  const { open, anchorEl, setAnchorEl, handleClose } = anchorContainer();
  const classes = useStyles();

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        size="small"
        aria-controls={open ? `menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={classes.MenuWrapper}
      >
        {btnName}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          handleClose(e);
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleClose(e);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.10)",
            background: "var(--white)",
            backdropFilter: "blur(3px)",
            borderRadius: "8px",
            marginTop: "14px",
            padding: "5px",
            "& .MuiList-root ": {
              padding: 0,
              "& .MuiMenuItem-root": {
                padding: "8px",
                color: "var(--blackGray)",
                "& span": {
                  marginLeft: "8px",
                  fontSize: "14px",
                },
              },
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {menuItems?.map(({ onClick, label, show = true }, index) => (
          <Box key={index}>
            {show && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                  return onClick(e);
                }}
              >
                {label}
              </MenuItem>
            )}
          </Box>
        ))}
      </Menu>
    </>
  );
};

export default VNSActions;
