import React from "react";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/material";
import VSNTextField from "../../../shared/VSNTextField";
import VSNTypography from "../../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  information: {
    position: "relative",
    alignItems: "center",
    maxWidth: 840,
    width: "100%",
    margin: "0 auto 48px",
    padding: "0 15px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: 15,
    },
    "& h4": {
      fontSize: 22,
      lineHeight: "38.21px",
      fontWeight: 600,
      color: "var(--darkGray)",
      marginBottom: "8px",
      textAlign: "center",
      [theme.breakpoints.down("lg")]: {
        textAlign: "center",
      },
      [theme.breakpoints.down("md")]: {
        lineHeight: "31px",
        marginBottom: 10,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        padding: [[0]],
        marginBottom: 10,
      },
    },
    "& .MuiFormControl-root": {
      // maxWidth: 330,
      width: "100%",
      "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
          border: "1px solid #EAEDF0",
          borderRadius: "4px",
          boxShadow: " 0px 9px 20px 0px #968FB01A",
          "&.MuiOutlinedInput-input": {
            minHeight: "11px",
            padding: "16px 32px",
            color: "var(--gray)",
            fontSize: 18,
            lineHeight: "20.83px",
            fontWeight: 500,
          },
        },
      },
      "& fieldset": {
        borderColor: "#EAEDF0",
      },
    },
  },
}));

const DateInputField = ({
  question,
  onChange,
  value,
  handelNextQuestion,
  currentQuestionIndex,
  allow_resubmission,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.information}>
      <VSNTypography variant="h4" component="h4">
        {`${question?.order}. ${question?.phrase}`}
      </VSNTypography>
      <DesktopDatePicker
        id={question.id}
        name={question.id}
        onChange={(e) => {
          const inputValue = new Date(e).toLocaleDateString("en-US");
          onChange({ result: inputValue }, question, !inputValue?.trim());
        }}
        value={value && value.value ? dayjs(value.value.result) : null}
        renderInput={(params) => <VSNTextField {...params} />}
      />
    </Box>
  );
};

export default DateInputField;
