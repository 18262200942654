import React, { Fragment } from "react";
import { Box, CircularProgress, Collapse, Grid, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VSNTypography from "../../../shared/VSNTypography";
import VSNImage from "../../../shared/VSNImage";
import { TeamDashboardStyle } from "./TeamDashboardStyle";
import {
  colorFromResults,
  renderTypesWithImage,
} from "../../../shared/CustomFunc";
import bgCard from "../../../assets/Teams/png/saregama-bg.png";
import { CloseIcon } from "../../../shared/icon";
import { quickGuide } from "./quickGuide";

const SaregamaCard = [
  {
    id: 1,
    img: `${process.env.REACT_APP_MEDIA_URL}sa/colored-logo.svg`,
    bgColor: `url(${bgCard}),linear-gradient(90deg, #FCF0FF 11.61%, #F2FAFF 72.11%)`,
    bgRepeat: "no-repeat",
    bgCover: "cover",
    boxShadow: "0px 7px 10px rgba(102, 45, 116, 0.15)",
    key: "sa",
  },
  {
    id: 1,
    img: `${process.env.REACT_APP_MEDIA_URL}re/colored-logo.svg`,
    bgColor: `url(${bgCard}),linear-gradient(94deg, #F2FAFF 15.57%, #FFF7ED 100.63%)`,
    bgRepeat: "no-repeat",
    bgCover: "cover",
    boxShadow: "0px 7px 10px 0px rgba(51, 127, 177, 0.15)",
    key: "re",
  },
  {
    id: 1,
    img: `${process.env.REACT_APP_MEDIA_URL}ga/colored-logo.svg`,
    bgColor: `url(${bgCard}),linear-gradient(93deg, #FFF7ED -2.17%, #E9FDF0 120.1%)`,
    bgRepeat: "no-repeat",
    bgCover: "cover",
    boxShadow: "0px 7px 10px 0px rgba(129, 87, 28, 0.10)",
    key: "ga",
  },
  {
    id: 1,
    img: `${process.env.REACT_APP_MEDIA_URL}ma/colored-logo.svg`,
    bgColor: `url(${bgCard}),linear-gradient(93deg, #F0FEF5 -15.69%, #FCF0FF 110.14%)`,
    bgRepeat: "no-repeat",
    bgCover: "cover",
    boxShadow: "0px 7px 10px 0px rgba(41, 130, 71, 0.15)",
    key: "ma",
  },
];

export default function TeamComposition({
  active,
  setActive,
  teamProfile,
  teamType,
}) {
  const classes = TeamDashboardStyle();

  const guideCards = ({ classes, active, setActive, teamType }) => (
    <Box className={classes.quickGuideCard} mt={1.9}>
      <Stack
        className={`${classes.quickGuideRow} ${active ? `${active}Type` : ""}`}
        direction="row"
        justifyContent="space-between"
      >
        <Stack direction={{ sm: "row" }} alignItems={{ sm: "center" }}>
          <VSNTypography
            color="#86650A"
            fontWeight="500"
            width={{ sm: "auto", xs: "100%" }}
          >
            Quick Guide :
          </VSNTypography>
          <Stack direction="row" alignItems="center">
            <VSNTypography
              color="var(--blackGray)"
              fontWeight="500"
              sx={{ marginRight: 0.6, marginLeft: { sm: 0.6 } }}
              fontSize={{ sm: 16, xs: 14 }}
            >
              Working with Vasana Type
            </VSNTypography>
            <Box
              sx={{
                display: "inline-block",
                width: { sm: "auto", xs: "28px" },
              }}
            >
              {renderTypesWithImage({ type: active })}
            </Box>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          ml="auto"
          justifyContent="center"
          className={classes.closeOut}
          onClick={() => setActive()}
        >
          <CloseIcon />
        </Stack>
      </Stack>
      <Grid
        container
        rowSpacing={{ xl: 0, xs: 2 }}
        className={classes.quickQuideContainer}
      >
        {quickGuide?.[teamType]?.[active]?.map(
          ({ img, title, desc }, index) => (
            <Grid lg={4} md={6} item key={index}>
              <Box
                px={{ lg: 4, xs: 1.2 }}
                className={classes.quickGuideSlide}
                position="relative"
              >
                <Stack direction="row" spacing={1.4} mb={1.2}>
                  <VSNImage src={img} alt="image" />
                  <VSNTypography
                    variant="h3"
                    fontWeight="700"
                    color="#4F4F4F"
                    fontSize={{ sm: 18, xs: 16 }}
                  >
                    {title}
                  </VSNTypography>
                </Stack>
                <VSNTypography
                  color="var(--gray)"
                  lineHeight={{ sm: "21px", xs: "18px" }}
                  fontSize={{ sm: 16, xs: 14 }}
                >
                  {desc}
                </VSNTypography>
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );

  return (
    <Stack className={classes.teamViewCard}>
      <VSNTypography variant="h3" className={classes.mdHeading}>
        Team Composition
      </VSNTypography>
      <VSNTypography className={classes.text} mt={1}>
        Your team is composed of individuals with different Vasana types.
        Understanding your team composition can be the key to help improve
        communication, build trust, deflate conflicts, and increase
        productivity.
      </VSNTypography>
      <VSNTypography className={classes.text} mt={1}>
        By gaining insight into both our own and our team members´ unspoken
        concerns, we can build more powerful and mutually beneficial
        relationships with our colleagues. The key to a team’s success is to
        recognize that each Type has strengths and liabilities, and it is
        essential to comprehend how these Types work in relationship with each
        other.
      </VSNTypography>
      <Grid
        container
        spacing={{ sm: 2.5, xs: 2 }}
        sx={{ marginTop: "0px !important" }}
      >
        {SaregamaCard.map(
          ({ id, number, img, bgColor, bgRepeat, bgCover, boxShadow, key }) => {
            return (
              <Fragment key={key}>
                <Grid
                  item
                  lg={3}
                  sm={6}
                  xs={12}
                  onClick={() => setActive(key === active ? "" : key)}
                >
                  <Stack
                    direction="row"
                    className={`${classes.saregamaCard} ${
                      active === key ? "active" : active ? "disabled" : ""
                    }`}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      boxShadow: active === key ? boxShadow : "none",
                    }}
                  >
                    <Box
                      sx={{
                        background: bgColor,
                        backgroundRepeat: bgRepeat,
                        backgroundSize: bgCover,
                        border:
                          active === key
                            ? `1px solid ${colorFromResults(key)}`
                            : "none",
                      }}
                      className={classes.cardOverlay}
                    >
                      {}
                    </Box>
                    <Box className={classes.curculerOut}>
                      <CircularProgress variant="determinate" value={number} />
                      <VSNTypography className="value">
                        {Intl.NumberFormat("en", {
                          notation: "compact",
                        }).format(teamProfile?.vasana_types?.[key] || 0)}{" "}
                      </VSNTypography>
                    </Box>
                    <Box ml={1} height="25px" width="58px">
                      <VSNImage src={img} alt="image" height="100%" />
                    </Box>
                    <Box className={classes.dropdownIcon}>
                      {active === key ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </Box>
                  </Stack>
                </Grid>
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    display: { sm: "none", xs: "block" },
                  }}
                >
                  <Collapse in={active === key}>
                    {guideCards({
                      classes,
                      active,
                      setActive,
                      teamType,
                    })}
                  </Collapse>
                </Box>
              </Fragment>
            );
          }
        )}
      </Grid>
      <Box
        sx={{
          height: "auto",
          width: "100%",
          display: { sm: "block", xs: "none" },
        }}
      >
        <Collapse in={active !== ""}>
          {guideCards({ classes, active, setActive, teamType })}
        </Collapse>
      </Box>
    </Stack>
  );
}
