import React from "react";
import FormControl from "@mui/material/FormControl";
import { InputLabel } from "@mui/material";

const VSNFormControl = (props) => {
  const { inputLabel, htmlId, fullWidth, children, sx } = props;
  return (
    <FormControl fullWidth={fullWidth} sx={sx}>
      {inputLabel && <InputLabel htmlFor={htmlId}>{inputLabel}</InputLabel>}
      {children}
    </FormControl>
  );
};

export default VSNFormControl;
