import { makeStyles } from "@mui/styles";
import React, { Children } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  tabs: {
    "&.MuiTabs-root": {
      boxShadow:
        "0px 1px 4px rgba(37, 69, 105, 0.05), 0px 2px 8px rgba(37, 69, 105, 0.05)",
      borderRadius: 8,
      border: " 1px solid #EAEDF0",
      background: "var(--white)",
      "& .MuiTabs-scroller": {
        marginRight: 48,
        marginLeft: 48,
        overflow: "auto !important",
        [theme.breakpoints.down("sm")]: {
          marginLeft: 10,
          marginRight: 10,
        },
        "& .MuiTabs-flexContainer": {
          marginTop: 5,
          [theme.breakpoints.down("sm")]: {
            marginTop: 0,
          },
          "& .MuiButtonBase-root": {
            padding: [[10, 0]],
            marginRight: 50,
            fontSize: 16,
            color: "#A5A9BD",
            textTransform: "capitalize",
            fontWeight: 700,
            [theme.breakpoints.down("sm")]: {
              marginRight: 20,
            },
            [theme.breakpoints.down("375")]: {
              fontSize: 15,
            },
            "&.Mui-selected": {
              color: "var(--Tertiary)",
            },
          },
        },
      },
      "& .MuiTabs-indicator": {
        height: 4,
        background: "var(--Tertiary)",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
    },
  },
}));

const VSNTabs = ({ tabs, path, handleTabChange, permissions, activeTab }) => {
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  return (
    <Box>
      <Box>
        {/* {findTab &&
          permissions?.[findTab?.tableViewPermission]?.includes("view") &&
          children} */}

        {role === "platform_admin"
          ? tabs.map(({ value, children }, index) => (
              <React.Fragment key={index}>
                {activeTab === value && children}
              </React.Fragment>
            ))
          : tabs?.map(
              ({ value, children, tableViewPermission }, index) =>
                value === activeTab &&
                permissions?.[tableViewPermission]?.includes("view") && (
                  <React.Fragment key={index}>{children}</React.Fragment>
                )
            )}
      </Box>
    </Box>
  );
};

export default VSNTabs;
