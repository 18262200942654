import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import VSNLabel from "./VSNLabel";
import VSNTypography from "./VSNTypography";
import VSNErrorMessage from "./VSNErrorMessage";
import { CloseIcon, EditIcon, ImageIcon } from "./icon";
import VSNImage from "./VSNImage";

const useStyles = makeStyles((theme) => ({
  innerBox: {
    flex: 1,
  },

  borderBox: {
    borderRadius: 5,
    border: "1px dashed rgba(116, 119, 135, 0.30)",
    padding: 10,
    marginBottom: 10,
    flex: "1",
  },
  uploadOut: {
    "&.MuiStack-root": {
      height: "100%",
      width: "100%",
      maxHeight: 134,
      borderRadius: 3,
      background: "#F6F6FD80",
      cursor: "pointer",
      color: "#555FDD",
      position: "relative",
      flex: "0 0 auto",
      [theme.breakpoints.down("sm")]: {
        height: 134,
      },
      "& .MuiBox-root": {
        minHeight: "0",
      },
      "& img": {
        maxHeight: "100%",
      },
    },
  },
  inputUpload: {
    position: "absolute",
    inset: 0,
    opacity: 0,
    cursor: "pointer",
    zIndex: 9,
    "& .MuiInputBase-root": {
      height: "100%",
      "& .MuiInputBase-input": {
        padding: 0,
        height: "100%",
        display: "none",
      },
    },
  },

  uplodLabel: {
    "&.MuiFormLabel-root": {
      position: "absolute",
      inset: 0,
      cursor: "pointer",
    },
  },
  editIcon: {
    position: "absolute",
    bottom: -10,
    right: -10,
    height: 28,
    width: 28,
    boxShadow: "5px 5px 15px rgba(69, 97, 101, 0.10)",
    background: "white",
    borderRadius: "100%",
    "& path": {
      fill: "var(--primary)",
    },
  },

  closeIcon: {
    position: "absolute",
    top: -10,
    right: -10,
    height: 28,
    width: 28,
    boxShadow: "5px 5px 15px rgba(69, 97, 101, 0.10)",
    background: "var(--white)",
    borderRadius: "100%",
    zIndex: 99,
  },

  uploadText: {
    textAlign: "center",
    "&.MuiTypography-root": {
      fontSize: 14,
      lineHeight: "18px",
      color: "#747787",
      fontWeight: 500,
      maxWidth: 245,
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        lineHeight: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
  },
}));

const VSNUploadPhoto = ({
  value,
  handleChangePhoto,
  name,
  subdescription,
  md,
}) => {
  const classes = useStyles();
  return (
    <Stack className={classes.innerBox}>
      <Box className={classes.borderBox}>
        <Stack
          alignItems="center"
          justifyContent="center"
          color="#E87637"
          className={classes.uploadOut}
        >
          <input
            type="file"
            accept="image/*"
            id="photo_upload-undefined"
            onChange={handleChangePhoto}
            className={classes.inputUpload}
            name={name}
          />
          {value && (
            <VSNLabel
              htmlFor="photo_upload-undefined"
              className={classes.uplodLabel}
              label={
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className={classes.editIcon}
                >
                  <EditIcon />
                </Stack>
              }
            />
          )}

          <VSNTypography className={classes.questionTitle} variant="h6">
            {/* {subtitle} */}
          </VSNTypography>
          {value && (typeof value === "string" || value?.photo_url) ? (
            <Stack gap={2}>
              <VSNImage
                src={typeof value === "string" ? value : value?.photo_url}
                alt="img"
                style={{ width: "80px" }}
              />
              <Stack
                alignItems="center"
                justifyContent="center"
                className={classes.closeIcon}
                onClick={() =>
                  handleChangePhoto(
                    {
                      target: {
                        name,
                        value: null,
                        files: null,
                      },
                    },
                    true
                  )
                }
              >
                <CloseIcon />
              </Stack>
            </Stack>
          ) : (
            <>
              <ImageIcon />
              <VSNTypography variant="span" className={classes.uploadClick}>
                Click here to upload
              </VSNTypography>
            </>
          )}
        </Stack>
      </Box>
      {subdescription && (
        <VSNTypography className={classes.uploadText}>
          {subdescription}
        </VSNTypography>
      )}
      <VSNErrorMessage
      // {...{
      //   formError: formErrors?.[name],
      //   errorMessage,
      // }}
      />
    </Stack>
  );
};

export default VSNUploadPhoto;
