import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LOADING_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../redux/constants";
import { checkValid } from "../shared/validation";
import { ApiContainer } from "../utils/api";
import { clearFormData, setErrors } from "../shared/CustomFunc";
import { equal, formDataConvert, length, values } from "../utils/javascript";
import { adminPoints } from "../utils/constant";
import { showToast } from "../utils/toastService";
import { ERROR_MESSAGE } from "../description/errorMessage.description";

export const createDepartmentContainer = ({ toggleModelShow }) => {
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const formPath = { parent: "departmentInfo" };
  const { form } = useSelector((state) => state);
  const departmentInfo = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const departmentInfoError = useSelector(
    (state) => state.form.formErrors?.[formPath.parent]
  );
  const departmentToEdit = useSelector((state) => state.api?.departmentToEdit);
  const createDepartmentLoader = useSelector(
    (state) => state.api?.loader?.createDepartment
  );
  const locationFiltersLoader = useSelector(
    (state) => state.api?.loader?.locationFilters
  );
  const orgFilters = useSelector((state) => state.api?.orgFilters);
  const getLocation = useSelector((state) => state.api?.getLocation);
  const departmentType = useSelector((state) => state.api?.departmentType);
  const commonFilter = useSelector(
    (state) => state.form.formData?.commonFilter
  );

  const setFormData = (name, value) => {
    dispatch(setErrors({ errors: departmentInfoError, value, name, formPath }));
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: { ...departmentInfo, [name]: value },
        },
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(name, value);
  };

  const handleMobileChange = (mobile, name) => {
    setFormData(name, `+${mobile}`);
  };

  const apiCall = async () => {
    const { department_name, logo } = departmentInfo;
    let dataToSend = formDataConvert({
      name: department_name,
      address_line_1: departmentInfo?.address_line_1,
      address_line_2: departmentInfo?.address_line_2,
      city: departmentInfo?.city,
      zip_code: departmentInfo?.zip_code,
      type_id: departmentInfo?.type_id,
    });
    if (!departmentToEdit?.department_original_id) {
      dataToSend = formDataConvert({
        name: departmentInfo?.department_name,
        location_id: departmentInfo?.location_original_id,
        organization_id: departmentInfo?.organization_original_id,
        first_name: departmentInfo?.first_name,
        last_name: departmentInfo?.last_name,
        phone_number: departmentInfo?.phone_number,
        email: departmentInfo?.email,
        address_line_1: departmentInfo?.address_line_1,
        address_line_2: departmentInfo?.address_line_2,
        city: departmentInfo?.city,
        zip_code: departmentInfo?.zip_code,
        type_id: departmentInfo?.type_id,
      });
    }
    if (logo) {
      dataToSend.append(
        "logo",
        logo?.photo_file || (logo && typeof logo === "string" && logo)
      );
    }
    if (departmentToEdit?.department_original_id) {
      const data = await api({
        method: "PUT",
        endPoint: `platform-admin/departments/${departmentToEdit?.department_original_id}`,
        data: dataToSend,
        showToastMessage: true,
        urlencoded: false,
        needLoader: true,
        parent: "createDepartment",
        header: { "Content-Type": "multipart/form-data" },
      });
      if (data?.status) {
        toggleModelShow("editDepartment");
        dispatch(clearFormData(formPath.parent));
        dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: true } });
      }
    } else {
      const data = await api({
        method: "POST",
        endPoint: adminPoints?.createDepartments,
        data: dataToSend,
        showToastMessage: true,
        urlencoded: false,
        parent: "createDepartment",
        needLoader: true,
        header: { "Content-Type": "multipart/form-data" },
      });
      if (data?.status) {
        toggleModelShow("createDepartment");
        dispatch(clearFormData(formPath.parent));
        dispatch({
          type: SET_API_DATA,
          payload: { createdOrganization: data.data },
        });
        dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: true } });
      }
    }
  };

  const getLocationFilter = async (id) => {
    const data = await api({
      method: "GET",
      endPoint: `organization/${id}/locations`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "locationFilters",
    });
    if (data?.status) {
      let getLocation = [];
      getLocation = [
        ...getLocation,
        ...data.data.map(({ location_name, location_original_id }) => ({
          key: `${location_name} (${location_original_id})`,
          value: location_original_id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          getLocation,
        },
      });
    } else {
      showToast("No Location Found.", "error");
      dispatch({
        type: SET_API_DATA,
        payload: {
          getLocation: [],
        },
      });
    }
  };
  const handleChangePhoto = (e) => {
    const { name, value, files } = e.target;
    setFormData(
      name,
      files && files?.[0]
        ? {
            photo_url: URL.createObjectURL(files[0]),
            photo_file: files[0],
          }
        : null
    );
  };

  useEffect(() => {
    if (!departmentToEdit?.department_original_id) {
      if (departmentInfo?.organization_original_id) {
        getLocationFilter(departmentInfo?.organization_original_id);
        setFormData("location_original_id", undefined);
      }
    } else if (departmentInfo?.organization_original_id) {
      getLocationFilter(departmentInfo?.organization_original_id);
    }
  }, [departmentInfo?.organization_original_id]);

  const createDepartmentAttribute = [
    {
      label: "DEPARTMENT DETAILS",
      value: [
        {
          control: "input",
          label: "Department Name",
          type: "text",
          name: "department_name",
          value: "",
          isRequired: true,
          validateAs: "required",
          placeHolder: "Enter Department name",
          errorMessage: ERROR_MESSAGE?.department_name,
          fullWidth: true,
          md: 6,
        },
        {
          control: "select",
          label: "Department Type",
          name: "type_id",
          value: 0,
          validateAs: "selectMenu",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Select Department Type",
          errorMessage: ERROR_MESSAGE?.location,
          loading: locationFiltersLoader,
          options: departmentType ?? [],
          md: 6,
          variant: "standard",
        },
        {
          control: "select",
          label: "Organization",
          name: "organization_original_id",
          value: 0,
          validateAs: "selectMenu",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Select Organization",
          errorMessage: ERROR_MESSAGE?.organization,
          options: orgFilters?.map(({ key, value }) => ({
            key,
            value,
          })),
          md: 6,
          variant: "standard",
        },
        {
          control: "select",
          label: "Location",
          name: "location_original_id",
          value: 0,
          validateAs: "selectMenu",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Select Location",
          errorMessage: ERROR_MESSAGE?.location,
          loading: locationFiltersLoader,
          options: locationFiltersLoader
            ? [{ isLoading: true }]
            : getLocation?.map(({ key, value }) => ({
                key,
                value,
              })) || [],
          md: 6,
          variant: "standard",
        },

        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
        {
          control: "photo",
          label: "Logo",
          name: "logo",
          fullWidth: true,
          md: 6,
          subdescription:
            "Only JPEG, PNG, JPG and WEBP files with max size 15 MB.",
        },
      ],
    },
    {
      label: "DEPARTMENT ADMIN DETAILS",
      value: [
        {
          control: "input",
          label: "First Name",
          type: "text",
          name: "first_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter first name",
          errorMessage: ERROR_MESSAGE?.first_name,
          md: 6,
        },
        {
          control: "input",
          label: "Last Name",
          type: "text",
          name: "last_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter last name",
          errorMessage: ERROR_MESSAGE?.last_name,
          md: 6,
        },
        {
          control: "mobile",
          label: "Mobile Number",
          type: "tel",
          name: "phone_number",
          value: "",
          validateAs: "mobile",
          isRequired: true,
          fullWidth: true,
          placeHolder: "",
          errorMessage: ERROR_MESSAGE?.mobile_number,
          inputStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            borderRadius: "0",
            maxWidth: "100%",
            width: "100%",
          },
          buttonStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            backgroundColor: "transparent",
            borderRadius: "0",
          },
          containerStyle: {
            marginTop: 7,
          },
          dropdownStyle: {
            bottom: "100%",
          },
          md: 6,
        },
        {
          control: "input",
          label: "Email Address",
          type: "email",
          name: "email",
          value: "",
          isRequired: true,
          fullWidth: true,
          validateAs: "email",
          placeHolder: "Enter email address",
          md: 6,
          errorMessage: ERROR_MESSAGE?.email_address,
        },
      ],
    },
  ];

  const handleCreateDepartment = (e) => {
    e.preventDefault();
    let errors = {};

    createDepartmentAttribute?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value: departmentInfo?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [formPath.parent]: { ...errors },
            },
          },
        });
      });
    });

    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      const noChanges = equal(
        JSON.stringify(departmentInfo),
        JSON.stringify(departmentToEdit)
      );
      if (!noChanges) {
        apiCall();
      }
    }
  };

  useEffect(() => {
    if (!getLocation || !(getLocation?.length > 0)) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          getLocation: [{ key: "No Location Found.", value: "" }],
        },
      });
    }
  }, [getLocation]);
  useEffect(() => {
    if (!departmentToEdit?.department_original_id) {
      if (commonFilter?.organization) {
        setFormData("organization_original_id", commonFilter?.organization);
      }
    }
  }, []);

  return {
    handleMobileChange,
    handleCreateDepartment,
    handleChange,
    formPath,
    departmentInfo,
    createDepartmentLoader,
    departmentToEdit,
    createDepartmentAttribute,
    handleChangePhoto,
  };
};
