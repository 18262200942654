import React from "react";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import VSNToolTip from "../../../shared/VSNToolTip";
import VSNTypography from "../../../shared/VSNTypography";
import VSNMembersAvatar from "../../../shared/VSNMembersAvatar";
import { ChartStyle } from "./ChartStyle";
import { getAvatarName, upperCase } from "../../../utils/javascript";

const DonutShape = ({
  active,
  handleChartPieceToggle,
  exploreData,
  open,
  subTitle,
  filteredData,
  matches,
}) => {
  const classes = ChartStyle();
  const teamProfile = useSelector((state) => state?.api?.teamProfile);

  const getInitials = (name) => {
    const nameArray =
      (name?.split(" ")?.length > 1 ? name?.split(" ") : name?.split("")) || [];
    let initials = "";
    for (let i = 0; i < 2; ) {
      initials += nameArray?.[i]?.charAt(0)?.toUpperCase() || "";
      i += 1;
    }
    return initials;
  };

  const renderDataItems = (dataItems, category) => {
    return dataItems?.map((item) => (
      <VSNToolTip
        key={`${category}-${item.first_name}`}
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <VSNMembersAvatar
              alt={item.first_name}
              src={item.profile_picture}
              vasanaType={item?.primary_marker}
              title={getAvatarName(item)}
              sx={{
                width: 24,
                height: 24,
                fontSize: 12,
                border: "2px solid #fff",
              }}
              defaultColor="var(--white)"
              defaultPrimaryBackgroundColor="#1F396D"
              defaultSecondaryBackgroundColor="#1F396D"
            />
            <VSNTypography
              color={
                active === category
                  ? "var(--blackGray)"
                  : active
                  ? "var(--white)"
                  : "var(--blackGray)"
              }
            >
              {`${item.first_name} ${item.last_name}`}
            </VSNTypography>
          </Stack>
        }
        placement="top"
        arrow
        slotProps={{
          popper: {
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor:
                  active === category
                    ? "var(--white)"
                    : active
                    ? "var(--blackGray)"
                    : "var(--white)",
                boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                color: "var(--blackGray)",
                py: 1,
                px: 1.5,
                borderRadius: 3,
                "& .MuiTooltip-arrow": {
                  color:
                    active === category
                      ? "var(--white)"
                      : active
                      ? "var(--blackGray)"
                      : "var(--white)",
                },
              },
            },
          },
        }}
      >
        <Box style={{ bottom: item.bottom, left: item.left }} />
      </VSNToolTip>
    ));
  };

  return (
    <>
      <div className={classes.donutContainer}>
        <div className={classes.donut}>
          {exploreData?.map((item, index) => {
            return open === "baseMap" ? (
              <div key={index}>
                {item?.oneWordDesc?.map((category, index) => {
                  return (
                    <div
                      key={`segment-${index}`}
                      style={{
                        cursor: open === "baseMap" ? "auto" : "pointer",
                      }}
                      className={`${classes.segment} ${
                        classes[`segment${index + 1}`]
                      } ${
                        active === category?.name
                          ? classes.active
                          : active
                          ? classes.inActive
                          : ""
                      }`}
                    >
                      <div className={classes.dot}>
                        <span className={classes[`label${index + 1}`]}>
                          {upperCase(category?.name)}
                        </span>
                        <div className={classes.dotWrapper}>
                          {renderDataItems(
                            index === 0
                              ? filteredData?.re
                              : index === 1
                              ? filteredData?.ga
                              : index === 2
                              ? filteredData?.ma
                              : filteredData?.sa,
                            category?.name
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div key={index}>
                {item?.points?.map((point, pointIndex) =>
                  point?.key === subTitle ? (
                    <div key={pointIndex}>
                      {point?.oneWordDesc?.map((category, index) => {
                        return (
                          <div
                            key={`segment-${index}`}
                            style={{
                              cursor: open === "baseMap" ? "auto" : "pointer",
                            }}
                            className={`${classes.segment} ${
                              classes[`segment${index + 1}`]
                            } ${
                              active === category?.name
                                ? classes.active
                                : active
                                ? classes.inActive
                                : ""
                            }`}
                            onClick={() =>
                              handleChartPieceToggle(category?.name)
                            }
                            aria-hidden="true"
                          >
                            <div className={classes.dot}>
                              {!matches ? (
                                <span className={classes[`label${index + 1}`]}>
                                  {category?.name}
                                </span>
                              ) : null}
                              <div className={classes.dotWrapper}>
                                {renderDataItems(
                                  index === 0
                                    ? filteredData?.re
                                    : index === 1
                                    ? filteredData?.ga
                                    : index === 2
                                    ? filteredData?.ma
                                    : filteredData?.sa,
                                  category?.name
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null
                )}
              </div>
            );
          })}
          <div className={classes.circle}>
            <span>{getInitials(teamProfile?.name || "")}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonutShape;
