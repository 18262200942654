import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  useMediaQuery,
} from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import doctorDiscussion from "../../assets/landing/png/doctor-discussion.webp";
import frontLineImg from "../../assets/landing/png/front-line-logo.png";
import halfOrange from "../../assets/landing/png/half-orange-round.png";
import bannerShape1 from "../../assets/landing/png/bannerShape1.png";
import bannerShape2 from "../../assets/landing/png/bannerShape2.png";
import bannerShape3 from "../../assets/landing/png/bannerShape3.png";
import healthcareHeroImg1 from "../../assets/landing/png/healthcare-hero-img1.webp";
import healthcareHeroImg2 from "../../assets/landing/png/healthcare-hero-img2.webp";
import healthcareHeroImg3 from "../../assets/landing/png/healthcare-hero-img3.webp";
import humanMind from "../../assets/landing/png/human-mind.webp";
import manWithLaptop from "../../assets/landing/png/man-with-laptop.webp";
import market from "../../assets/landing/png/marketImage.webp";
import panShape from "../../assets/landing/png/pan-shape.png";
import sapSidi from "../../assets/landing/png/sap-sidi-line.png";
import sliderImg2 from "../../assets/landing/png/slider-image-2.webp";
import sliderImg from "../../assets/landing/png/slider-img-1.webp";
import sergeryImage from "../../assets/landing/png/surgery-image.webp";
import roundVasanaImage from "../../assets/landing/png/vasana-rounds.webp";
import flowerBlue from "../../assets/landing/svg/flower-blue-square.svg";
import halfRoundBlue from "../../assets/landing/svg/half-round-blue.svg";
import orangeFlower from "../../assets/landing/svg/orange-flower.svg";
import rainbowBlue from "../../assets/landing/svg/rainbow-blue.svg";
import { ReactComponent as ListPoint } from "../../assets/vasanaForTeam/svg/ListPoint.svg";
import welcomeImage from "../../assets/landing/png/welcome-image.webp";

import SEO from "../../shared/SEO";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import { VSNSwiper } from "../../shared/VSNSwiper";
import VSNTypography from "../../shared/VSNTypography";
import { PlayIcon, RightArrow } from "../../shared/icon";
import AchieveLogo from "./AchieveLogo";
import FooterSlider from "./FooterSlider";
import { VasanaHealthCareStyle } from "./VasanaHealthCareStyle";
import Quote from "../../assets/landing/svg/quote.svg";

export default function VasanaHealthCare() {
  const [toggle, setToggle] = useState(0);

  const logoSectionData = [
    "Improved Member Experience",
    "Preventative Health Care",
    "Behavioral Health Nudges",
    "Enhanced Intelligence",
    "Diagnostics Management and Reporting",
    "Whole Health Focus",
    "Data Security and Privacy",
  ];

  const intelligenceData = [
    {
      borderColor: "#B77EC5",
      background: `url(${humanMind})`,
      heading: "Enhanced Well-being through Behavioral Genome Integration",
      desc: "Integrating our Behavioral Genome significantly enhances member well-being by identifying the interplay between physical health and behavioral indicators, providing insights that empower individuals to thrive.",
    },
    {
      borderColor: "#53A3D8",
      background: `url(${market})`,
      heading: "A Member's Hub for Holistic Diagnostics",
      desc: "We’re building and enhancing a centralized hub for holistic diagnostics, merging physical and behavioral health data to provide accurate and comprehensive diagnostics.",
    },
    {
      borderColor: "#FFC87B",
      background: `url(${manWithLaptop})`,
      heading: "Personalized Therapeutic Options",
      desc: "Our self-service therapeutics, enables members to access personalized behavioral health treatment plans and resources conveniently, empowering them to actively manage their health.",
    },
    {
      borderColor: "#82C799",
      background: `url(${doctorDiscussion})`,
      heading: "Regular Prompts  and Check-Ins",
      desc: "We provide regular prompts and check-ins to keep members on track with their health goals and ensure that they are at the help of and in charge of their overall health.",
    },
  ];
  const listDataTable = [
    {
      listHead: "Physical Attributes",
      list: [
        "Comprehensive assessment of physical attributes and markets.",
        "Analysis of physical examinations, blood tests, CPET, VO2, and more.",
      ],
    },
    {
      listHead: "Mental and Behavioral Attributes",
      list: [
        "Individual Vasana behavioral type discovery",
        "Discovery and analysis of multiple behavioral markers, including mindset, neurodiversity, personality dimensions, and more",
      ],
    },
  ];

  const sliderSection = [
    {
      image: sliderImg,
      head: "Harmonizing Mind and Body",
      desc: `Recognizing the strong link between mind and body, we focus on the intersection of these aspects to harmonize physical well-being with comprehensive wellness.`,
    },
    {
      image: sliderImg2,
      head: "Transforming Data into Action",
      desc: `By building vast personalized datasets through its diagnostic components, particularly in social cognition and cognitive science, we turn data into actionable therapeutics, enabling individuals to thrive.`,
    },
  ];
  const circleBg = [
    {
      bg: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 70%, #F2E7F5 100%)",
      duration: 0.2,
    },
    {
      bg: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 70%, #E3F1F9 100%)",
      duration: 0.4,
    },
    {
      bg: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 70%, #FFF7EC 100%)",
      duration: 0.6,
    },
    {
      bg: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 50%, #F0F9F2 100%)",
      duration: 0.8,
    },
  ];

  const classes = VasanaHealthCareStyle();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(min-width:901px)");
  return (
    <>
      <SEO contentKey="publicSafety" />
      <Box>
        <Box className={classes.bannerBg}>
          <Box className={classes.heroSection}>
            <VSNImage
              src={halfOrange}
              alt="cloud-purple"
              className={classes.halfOrange}
            />
            <Grid
              container
              spacing={4}
              alignItems="center"
              flexDirection="row-reverse"
            >
              <Grid item xs={12} md={6} xl={5}>
                <Box className={classes.heroCapsule}>
                  <VSNImage
                    src={bannerShape1}
                    alt="bannerShape1"
                    className={classes.bannerShape1}
                  />
                  <VSNImage
                    src={bannerShape2}
                    alt="bannerShape2"
                    className={classes.bannerShape2}
                  />
                  <VSNImage
                    src={bannerShape3}
                    alt="bannerShape3"
                    className={classes.bannerShape3}
                  />
                  <Box className={classes.heroCapsuleImage}>
                    <ScrollAnimation
                      className="capsule-img"
                      animateIn="fadeInUp"
                      duration="0.2"
                      offset={-100}
                    >
                      <VSNImage
                        src={healthcareHeroImg1}
                        alt="enterprise-hero-img"
                      />
                    </ScrollAnimation>
                    <ScrollAnimation
                      className="capsule-img"
                      animateIn="fadeInUp"
                      duration="0.3"
                      offset={-100}
                    >
                      <VSNImage
                        src={healthcareHeroImg2}
                        alt="enterprise-hero-img"
                      />
                    </ScrollAnimation>
                    <ScrollAnimation
                      className="capsule-img"
                      animateIn="fadeInUp"
                      duration="0.4"
                      offset={-100}
                    >
                      <VSNImage
                        src={healthcareHeroImg3}
                        alt="enterprise-hero-img"
                      />
                    </ScrollAnimation>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} xl={5.5} sx={{ mx: "auto" }}>
                <Stack>
                  <VSNTypography variant="h1" className={classes.lgHeading}>
                    Helping Deliver{" "}
                    <span className="orangeText">
                      Meaningful Holistic Care to Those Who Help Us
                    </span>
                  </VSNTypography>
                  <VSNTypography className="desc">
                    Revolutionizing healthcare for public safety professionals
                    by integrating advanced physical, behavioral and mental
                    health diagnostics and therapeutics. We empower first
                    responders with access to personalized, holistic care,
                    ensuring their mental and physical well-being are always
                    prioritized and nurtured.
                  </VSNTypography>
                  <VSNButton
                    sx={{
                      width: "fit-content",
                      padding: "12px 32px",
                    }}
                    handleClick={() =>
                      navigate(`/contact-us?interested=frontlines`)
                    }
                    endIcon={<RightArrow />}
                  >
                    Partner with Us
                  </VSNButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box my={2}>
          <FooterSlider isBlackSlider={false} />
        </Box>
        <Stack className={`${classes.spacing} ${classes.therapySection}`}>
          <VSNImage
            src={halfRoundBlue}
            alt="halfRoundBlue"
            className={classes.halfRoundBlue}
          />
          <VSNImage
            src={flowerBlue}
            alt="flowerBlue"
            className={classes.flowerBlue}
          />
          <VSNImage
            src={halfRoundBlue}
            alt="halfRoundBlue"
            className={classes.halfRoundBlue2}
          />
          <VSNImage
            src={orangeFlower}
            alt="orange-flower"
            className={classes.orangeFlower}
          />
          <VSNImage
            src={rainbowBlue}
            alt="rainbow-blue"
            className={classes.rainbowBlue}
          />
          <VSNImage src={sapSidi} alt="sapSidi" className={classes.sapSidi} />
          <VSNImage
            src={panShape}
            alt="panShape"
            className={classes.panShape}
          />
          <Container maxWidth="xl">
            <Grid container mb={4} spacing={{ md: 4, xs: 2 }}>
              <Grid item md={6} xs={12}>
                <VSNTypography
                  variant="h3"
                  className={classes.mdHeading}
                  sx={{ textAlign: { md: "left", xs: "center" } }}
                >
                  The Multifaceted Approach of Vasana for Public Safety
                  Professionals: Integrating Physical, Mental and Behavioural
                  Health
                </VSNTypography>
              </Grid>
              <Grid item md={6} xs={12}>
                <VSNTypography className={classes.description}>
                  Working in high-intensity environments, first responders and
                  frontline workers face unique challenges that can affect their
                  mental and physical wellbeing if left unchecked. At Vasana, we
                  take a holistic view of health, bridging the disconnect
                  between primary care systems and behavioral and mental health
                  systems.
                </VSNTypography>
              </Grid>
            </Grid>
            <Stack sx={{ textAlign: "center" }}>
              <Box className={classes.listContentMainBox}>
                <Grid container columnSpacing={{ xs: 4, sm: 0 }}>
                  {listDataTable.map((listData, listIndex) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        smd={6}
                        key={listIndex}
                        className={classes.singleListBox}
                      >
                        <Box className="listBox">
                          <Stack
                            className={classes.headMain}
                            alignItems="center"
                          >
                            <VSNTypography
                              variant="h6"
                              className={classes.mdText}
                            >
                              {listData?.listHead}
                            </VSNTypography>
                          </Stack>
                          <Stack
                            className={`${classes.singleList} tblSingleList`}
                          >
                            <List>
                              {listData?.list?.map(
                                (listLable, listLableIndex) => {
                                  return (
                                    <ListItem key={listLableIndex}>
                                      <Stack mt={0.7}>
                                        <ListPoint />
                                      </Stack>
                                      <VSNTypography>{listLable}</VSNTypography>
                                    </ListItem>
                                  );
                                }
                              )}
                            </List>
                          </Stack>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Stack>
            <Box className={classes.healthSliderWrapper}>
              <VSNTypography
                className={classes.description}
                my={{ xs: 3.5, sm: 6, md: 5 }}
              >
                The Vasana Behavioral Genome leverages the power of AI, data and
                neuroscience to integrate physical health markers with
                behavioral health indicators, providing a comprehensive
                assessment of overall well-being.
              </VSNTypography>
              <Box position="relative">
                <Stack
                  direction="row"
                  spacing={1.4}
                  className={classes.customArrow}
                >
                  <div className="swiper-button image-swiper-button-next">
                    <ArrowBackIcon />
                  </div>
                  <div className="swiper-button image-swiper-button-prev">
                    <ArrowForwardIcon />
                  </div>
                </Stack>
                <VSNSwiper
                  className={classes.personalityMain}
                  pagination
                  navigation={{
                    prevEl: ".image-swiper-button-next",
                    nextEl: ".image-swiper-button-prev",
                    disabledClass: "swiper-button-disabled",
                  }}
                  spaceBetween={20}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    767: {
                      slidesPerView: 1.1,
                    },
                  }}
                >
                  {sliderSection?.map((el, i) => (
                    <SwiperSlide key={i}>
                      <Stack
                        direction={{ smd: "row" }}
                        alignItems="center"
                        spacing={{ lg: 8, md: 5, xs: 3 }}
                      >
                        <Box className={classes.sliderImgWrapper}>
                          <img src={el?.image} alt="i" />
                        </Box>
                        <Box textAlign="start" sx={{ px: { sm: 0, xs: 7 } }}>
                          <VSNTypography
                            component="h4"
                            fontSize={{ md: 32, sm: 26, xs: 20 }}
                            fontWeight="bold"
                          >
                            {el?.head}
                          </VSNTypography>
                          <VSNTypography
                            mt={{ sm: 3, xs: 2 }}
                            color="secondary.grey"
                            fontSize={{ lg: 24, md: 20, sm: 18, xs: 16 }}
                          >
                            {el?.desc}
                          </VSNTypography>
                        </Box>
                      </Stack>
                    </SwiperSlide>
                  ))}
                </VSNSwiper>
              </Box>
            </Box>
          </Container>
        </Stack>
        <Stack position="relative" mt={8}>
          <VSNImage
            src={flowerBlue}
            alt="flowerBlue"
            className={classes.flowerBlue2}
          />
          <Container maxWidth="xl">
            <VSNTypography
              component="h4"
              className={classes.mdHeading}
              textAlign="center"
            >
              Better Care for Frontline Workers Through Improved Intelligence
            </VSNTypography>
            <VSNTypography
              className={classes.description}
              sx={{
                maxWidth: 1030,
                margin: "20px auto 40px",
                textAlign: "center",
              }}
            >
              Our innovative approach to holistic health has one goal;
              empowering frontline workers to find the{" "}
              <i>right care at the right time.</i>
            </VSNTypography>
            {isLargeScreen ? (
              <Stack direction="row" spacing={3} sx={{ minHeight: 500 }}>
                {intelligenceData.map((item, i) => {
                  return (
                    <Stack
                      key={i}
                      onClick={() => setToggle(i)}
                      className={classNames(
                        [`intelligence${i}`],
                        classes.intelligenceCard,
                        i === toggle ? "active" : ""
                      )}
                      style={{
                        borderColor: item?.borderColor,
                        backgroundImage: item?.background,
                      }}
                    >
                      <VSNTypography component="h4">
                        {item?.heading}
                      </VSNTypography>
                      <VSNTypography>{item?.desc}</VSNTypography>
                    </Stack>
                  );
                })}
              </Stack>
            ) : (
              <VSNSwiper
                spaceBetween={20}
                slidesPerView={1.1}
                className={classes.intelligenceSlider}
              >
                {intelligenceData.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <Stack
                        style={{
                          borderColor: item?.borderColor,
                          backgroundImage: item?.background,
                        }}
                        className={classNames(
                          [`intelligence${i}`],
                          classes.intelligenceCard
                        )}
                      >
                        <VSNTypography component="h4">
                          {item?.heading}
                        </VSNTypography>
                        <VSNTypography>{item?.desc}</VSNTypography>
                      </Stack>
                    </SwiperSlide>
                  );
                })}
              </VSNSwiper>
            )}
          </Container>
          <VSNImage
            src={rainbowBlue}
            alt="rainbow-blue"
            className={classes.rainbowBlue2}
          />
          <VSNImage
            src={halfRoundBlue}
            alt="half-round-blue"
            className={classes.halfRoundBlue3}
          />
        </Stack>

        <Box className={classes.integratesSection}>
          <VSNImage
            src={halfRoundBlue}
            alt="half-round-blue"
            className={classes.halfRoundBlue4}
          />
          <Container maxWidth="xl">
            <VSNTypography
              component="h4"
              className={classes.mdHeading}
              textAlign="center"
              mb={4}
            >
              Vasana works with public safety departments integrating smoothly
              into their ecosystem to give members what they need most
            </VSNTypography>
          </Container>

          <Box className={classes.circleBg}>
            <VSNImage
              src={welcomeImage}
              className={classes.welcomeImage}
              alt="welcomeImage"
            />
            {circleBg?.map((item, i) => {
              return (
                <ScrollAnimation
                  className="circle"
                  animateIn="fadeIn"
                  duration={item.duration}
                  key={i}
                >
                  <Box
                    sx={{ opacity: 0.7 }}
                    style={{
                      background: item.bg,
                      width: "100%",
                      height: "100%",
                      borderRadius: "100%",
                    }}
                  />
                </ScrollAnimation>
              );
            })}
            <Box className={classes.circleBgWrapper}>
              {logoSectionData.map((item, i) => {
                return (
                  <VSNTypography
                    className={classNames(
                      [`integrates${i}`],
                      classes.integrates
                    )}
                    key={i}
                  >
                    <ScrollAnimation
                      className={classes.integratesText}
                      animateIn="zoomIn"
                      duration={0.2}
                    >
                      {item}
                    </ScrollAnimation>
                  </VSNTypography>
                );
              })}
            </Box>
          </Box>
        </Box>

        <Stack
          className={classes.spacing}
          position="relative"
          mt={{ md: 10, xs: 5 }}
        >
          <VSNImage
            src={rainbowBlue}
            alt="rainbow-blue"
            className={classes.rainbowBlue3}
          />
          <VSNImage
            src={halfRoundBlue}
            alt="half-round-blue4"
            className={classes.halfRoundBlue5}
          />
          <Container maxWidth="xl">
            <Box textAlign="center" mb={5}>
              <VSNTypography
                color="var(--primary)"
                fontWeight={500}
                fontSize={{ lg: 24, md: 20, xs: 18 }}
                mb={1}
              >
                PARTNER IN FOCUS
              </VSNTypography>
              <VSNTypography component="h4" className={classes.mdHeading}>
                Revolutionizing Healthcare for Public Safety Professionals with
                Front Line
              </VSNTypography>
            </Box>
            <Grid container spacing={4} sx={{ alignItems: "center" }}>
              <Grid item md={6} lg={7}>
                <Box>
                  <VSNImage src={frontLineImg} alt="frontLineImg" />
                </Box>
                <VSNTypography className={classes.description} my={3}>
                  Sharing core values of a human-first approach, kindness and a
                  focus on holistic health, we are super-powering Front
                  Line&lsquo;s expertise in physical health for public safety
                  professionals with Vasana&lsquo;s behavioral health genome,
                  generating analytics and intelligence across both physical and
                  behavioral dimensions.
                </VSNTypography>
                <VSNTypography className={classes.description}>
                  We enable the delivery of on-demand behavioral health
                  coaching, skill-building resources, and video-based learning
                  tailored to each member’s unique needs, enhancing the holistic
                  health of fire, law enforcement, and emergency medical
                  services. Leveraging AI and advanced technology, we are
                  building a scalable platform to impact the wellbeing of
                  millions by transcending physical locations and delivering
                  scalable, comprehensive health solutions.
                </VSNTypography>
              </Grid>
              <Grid item md={6} lg={5}>
                <Stack className={classes.reviewWrapper}>
                  <Box>
                    <VSNTypography variant="h3">
                      We are thrilled to partner with Vasana, a collaboration
                      that perfectly aligns with our commitment to prioritizing
                      the holistic health of public safety professionals. By
                      integrating Vasana’s cutting-edge behavioral health genome
                      with our expertise, we are creating a groundbreaking
                      platform for personalized, comprehensive health solutions.
                      This partnership is a significant step forward in
                      enhancing the well-being of those who serve and protect
                      our communities, ensuring they thrive both physically and
                      mentally.
                    </VSNTypography>
                    <img src={Quote} alt="quote icon" />
                  </Box>
                  <VSNTypography variant="h6">- Russ Burnham</VSNTypography>
                  <VSNTypography>CEO, Front Line Mobile Health</VSNTypography>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Stack>

        <FooterSlider />
      </Box>
    </>
  );
}
