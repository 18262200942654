import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateEditContainer from "../../container/org-admin/createEdit.container";
import VSNModel from "../../shared/VSNModel";
import { SET_API_DATA, ON_FORM_CHANGE } from "../../redux/constants";
import FormInModel from "./FormInModel";
import { clearFormData } from "../../shared/CustomFunc";

const ODLModel = ({
  modelState,
  formPath,
  formAttribute,
  toggleModelShow,
  modelName,
  loaderState,
  submitButtonName,
  titleOfModel,
  SubmitFunc,
  staticValue,
  clearApiState,
}) => {
  const dispatch = useDispatch();
  const {
    handleChange,
    handleMobileChange,
    loader,
    handleDateChange,
    handleChangePhoto,
  } = CreateEditContainer({
    formPath,
    loaderState,
  });
  useEffect(() => {
    return () => {
      dispatch({
        type: SET_API_DATA,
        payload: {
          [clearApiState]: {},
        },
      });
      dispatch(clearFormData(formPath?.parent));
    };
  }, []);

  return (
    <>
      <VSNModel
        disableEnforceFocus
        open={modelState}
        onClose={() => dispatch(toggleModelShow(modelName))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        mobileDrawer
      >
        <FormInModel
          handleMobileChange={handleMobileChange}
          handleChange={handleChange}
          submit={SubmitFunc}
          submitButtonName={submitButtonName}
          formPath={formPath}
          toggleModelShow={toggleModelShow}
          loaderState={loader}
          titleOfModel={titleOfModel}
          formAttribute={formAttribute}
          modelName={modelName}
          staticValue={staticValue}
          handleDateChange={handleDateChange}
          handleChangePhoto={handleChangePhoto}
        />
      </VSNModel>
    </>
  );
};

export default ODLModel;
