import React from "react";
import { Box, Grid, List, ListItem, Stack } from "@mui/material";
import { PhysicalOnePagerResultStyle } from "./PhysicalOnePagerResultStyle";
import VSNTypography from "../../../shared/VSNTypography";
import xray1 from "../../../assets/assesments/physical/xray/xray-1.svg";
import xray2 from "../../../assets/assesments/physical/xray/xray-2.svg";
import xray3 from "../../../assets/assesments/physical/xray/xray-3.svg";
import xray4 from "../../../assets/assesments/physical/xray/xray-4.svg";
import VSNLoader from "../../../shared/VSNLoader";
import VSNBreadcrumbs from "../../../shared/VSNBreadcrumbs";
import onePagerResultContainer from "../../../container/onePager.container";
import SwiperCardComponent from "../../assessment/SwiperCardComponent";
import {
  BulletIcon,
  ComparisonIcon,
  HistoryIcon,
  PurpalFlowerBullet,
} from "../../../shared/icon";

const xray_faqs = [
  {
    icon: "https://vasana-static.s3.amazonaws.com/images/insomnia/icon-2.svg",
    title: "What is a chest X-ray evaluating?",
    description:
      "A chest X-ray is a diagnostic tool used to examine the heart, lungs, airways, bones of the chest and spine, and smaller surrounding structures. It is routinely used to help diagnose shortness of breath, persistent cough, fever, chest pain, or injuries. Chest x-rays can also reveal signs of fluid in or around the lungs.",
  },
  {
    icon: "https://vasana-static.s3.amazonaws.com/images/insomnia/icon-2.svg",
    title: "Why are regular chest X-rays important for overall health?",
    description:
      "Regular chest X-rays are crucial for early detection of respiratory issues, lung conditions, or heart problems. Early detection allows for timely intervention and treatment, helping to maintain better health outcomes.",
  },
];

const swiperData = [
  {
    image: xray1,
    title: "Practice Controlled Breathing Techniques",
    description:
      "Practice diaphragmatic breathing to strengthen lungs, improve respiratory efficiency, and aid recovery after smoke exposure.",
  },
  {
    image: xray2,
    title: "Stay Fit and Active",
    description:
      "Regular cardiovascular exercise, such as running, swimming, or cycling, improves overall lung function and resilience.",
  },
  {
    image: xray3,
    title: "Avoid Smoking and Additional Smoke Exposure",
    description:
      "Avoiding tobacco and minimizing additional smoke exposure can reduce the compounded risk of respiratory and cardiovascular diseases.",
  },
  {
    image: xray4,
    title: "Limit Exposure to Hazardous Chemicals",
    description:
      "Be mindful of potential chemical exposures both on and off duty. Use personal protective equipment (PPE) and ensure proper ventilation when dealing with hazardous materials.",
  },
];

export default function XRayDashboardResult({
  endPointToCall,
  showBreadCrumbs = true,
  breadCrumbAndButton,
  releaseButton,
}) {
  const classes = PhysicalOnePagerResultStyle();
  const { onePagerAssessmentResult, loader, currentAssessment } =
    onePagerResultContainer({ endPointToCall });

  const breadcrumbsRoutes = [
    {
      path: "/assessments",
      label: "Assessments",
    },
    {
      path: `/assessments/${currentAssessment?.assessment_id}`,
      label: `X-ray`,
    },
  ];

  // if (loader?.assessmentResult || !onePagerAssessmentResult) {
  //   return <VSNLoader variant="fullPage" />;
  // }

  return (
    <div className={classes.mainLayout}>
      {showBreadCrumbs ? (
        <Box mt={2.5} mb={1.8}>
          <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
        </Box>
      ) : (
        <Box className={classes.headingButton}>
          <Box>{breadCrumbAndButton}</Box>
          <Box>{onePagerAssessmentResult?.is_locked ? releaseButton : ""}</Box>
        </Box>
      )}

      <VSNTypography
        fontSize={{ xs: 24, xsm: 34 }}
        sx={{ fontWeight: 700 }}
        mb={1}
      >
        Your Chest X-Ray, Explained
      </VSNTypography>

      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Stack className={classes.assessmentReportCard}>
            <Box sx={{ display: "block" }}>
              <VSNTypography
                className={classes.mdHeadingMain}
                dangerouslySetInnerHTML={{
                  __html: "Clinical Summary of Your X-Ray",
                }}
              />
            </Box>

            <Stack direction={{ md: "row" }} mt={1} spacing={2}>
              <Box flex={1}>
                <VSNTypography component="p" className={classes.desc}>
                  There is no radiographic evidence of active cardiopulmonary
                  disease.
                </VSNTypography>
              </Box>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xl={6} lg={6} xs={12}>
          <Stack className={classes.assessmentReportCard}>
            <VSNTypography
              variant="h3"
              component="h3"
              className={classes.mdHeadingprimary}
              mb={1}
            >
              {`Doctor's Take: Key Insights from Your X-Ray`}
            </VSNTypography>
            <Stack
              direction="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box py={1} display="flex" width={{ lg: "100%", xlg: "76%" }}>
                <Box sx={{ height: 12, width: 12 }} pr={3}>
                  <BulletIcon />
                </Box>
                <VSNTypography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#3A3A40",
                    lineHeight: "24px",
                  }}
                >
                  Portable PA radiograph of the chest demonstrates a normal
                  cardiomediastinal silhouette and pulmonary vasculature. There
                  are adequate lung volumes without effusion or discrete
                  consolidation. There is no pneumothorax or other parenchymal
                  lesion noted. The bones of the thorax and shoulders, as well
                  as the viewed abdominal soft tissues, are unremarkable.
                </VSNTypography>
              </Box>

              <Box sx={{ display: { sm: "flex", xs: "block" } }} gap={2}>
                <Box className={classes.notesListMulti} gap={2}>
                  <HistoryIcon />
                  <VSNTypography component="p">
                    History
                    <br /> <span>TEST DRIVE (Hx)</span>
                  </VSNTypography>
                </Box>
                <Box className={classes.notesListMulti} gap={2}>
                  <ComparisonIcon />
                  <VSNTypography component="p">
                    Comparisons <br /> <span>None</span>
                  </VSNTypography>
                </Box>
              </Box>

              <VSNTypography
                sx={{ fontSize: "12px", fontWeight: 500, color: "var(--gray)" }}
                pt={2}
              >
                Report electronically signed by: TILTON, MD, DOUGLAS M. on
                11/30/2023 12:37:11 PM
              </VSNTypography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xl={6} lg={6} xs={12}>
          <Stack className={classes.assessmentReportCardWhite}>
            <VSNTypography
              variant="h3"
              component="h3"
              className={classes.mdHeading}
              mb={1}
            >
              Chest X-Ray: FAQs
            </VSNTypography>
            <VSNTypography component="p" className={classes.desc}>
              Learn why you underwent a chest X-ray and why it’s essential for
              monitoring your health at work.
            </VSNTypography>
            <List className={classes.dailyFunctioning}>
              {xray_faqs?.map((el, index) => (
                <ListItem key={index}>
                  <Box mt={0.5}>
                    <PurpalFlowerBullet />
                  </Box>
                  <VSNTypography color="var(--blackGray)">
                    <span style={{ color: "#86650A", fontWeight: 500 }}>
                      {el?.title ?? ""}{" "}
                    </span>
                    <br />
                    {el?.description}
                  </VSNTypography>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Grid>

        <SwiperCardComponent
          title="Be Proactive: Recommendations for Healthier Cardiopulmonary Functioning"
          description="To continue managing your anxiety effectively and to maintain your current state of mental health, consider the following tips:"
          data={swiperData ?? []}
        />
      </Grid>
    </div>
  );
}
