import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  ListItem,
  Stack,
} from "@mui/material";
import Masonry from "react-masonry-css";
import VSNTypography from "../../shared/VSNTypography";
import {
  FlashIcon,
  MenuIcon,
  OptionalIcon,
  RightRoundIcon,
  SadIcon,
} from "../../shared/icon";
import { assessmentsViewContainer } from "../../container/assessmentsView.container";
import AssessmentCard from "./assessmentCard";
import VSNSelect from "../../shared/VSNSelect";
import { ReactComponent as PreviewLayerIcon } from "../../assets/landing/svg/previewLayer.svg";
import MRF from "../../assets/assesments/physical/mrf/mrf.svg";
import xray from "../../assets/assesments/physical/xray/XrayDashboard.svg";
import physical from "../../assets/assesments/physical/physical/PhysicalDashboard.svg";

const useStyles = makeStyles((theme) => ({
  assessmentContainer: {
    "&.MuiContainer-root": {
      maxWidth: 1536,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 30,
      lineHeight: "40px",
      fontWeight: 700,
      color: "#2D2F39",
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
        lineHeight: "28px",
      },
    },
  },
  mdText: {
    "&.MuiTypography-root": {
      fontSize: 18,
      lineHeight: "23px",
      color: "var(--gray)",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "18px",
        fontWeight: 400,
      },
      "& span": {
        fontWeight: 700,
        color: "#8B3F9E",
      },
    },
  },
  headingWrapper: {
    maxWidth: 608,
    margin: "0 auto",
    textAlign: "center",
    padding: "56px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0 0 0",
    },
  },
  innerTabsWrapper: {
    maxWidth: 1002,
    width: "100%",
    margin: "65px auto 30px",
    background: "var(--white)",
    borderRadius: 8,
    position: "relative",
    zIndex: 2,
    overflowX: "auto",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "30px -16px 10px -16px",
      paddingBottom: 10,
      width: "unset",
      borderRadius: 0,
    },
  },
  listitemTab: {
    "&.MuiListItem-root": {
      color: "var(--gray)",
      opacity: 1,
      minWidth: 0,
      display: "flex",
      alignItems: "center",
      width: "fit-content",
      padding: [[21, 12]],
      margin: [[0, 24]],
      position: "relative",
      cursor: "pointer",
      flexDirection: "row",
      flex: "0 0 auto",
      [theme.breakpoints.down("md")]: {
        margin: "0 12px",
      },
      [theme.breakpoints.down("767")]: {
        margin: 0,
        padding: 16,
      },
      [theme.breakpoints.down("sm")]: {
        background: "var(--white)",
      },
      "& span": {
        display: "inline-block",
        marginLeft: 8,
      },
      "&::after": {
        position: "absolute",
        content: '""',
        height: 4,
        width: "100%",
        background: "var(--Tertiary)",
        borderRadius: "4px 4px 0px 0px",
        left: 0,
        opacity: 0,
        bottom: 0,
        transition: "all 0.3s ease-in-out",
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
          zIndex: 1,
        },
      },
      "&.active, &:hover": {
        color: "var(--Tertiary)",
        "&::after": {
          opacity: 1,
        },
      },
      "& svg": {
        flex: "0 0 auto",
      },
    },
  },
  masonryGrid: {
    display: "flex",
    gap: 25,
    width: "calc(100% - 50px)",
    [theme.breakpoints.down("1024")]: {
      width: "calc(100% - 25px)",
    },
    [theme.breakpoints.down("md")]: {
      gap: 16,
      width: "calc(100% - 16px)",
    },
    [theme.breakpoints.down("650")]: {
      width: "100%",
    },
  },
  sortFilter: {
    margin: [[0, 24]],
    padding: [[21, 12]],
    "& .MuiFormControl-root": {
      "& .MuiSvgIcon-root": {
        display: "none",
      },
      "& span, .MuiSelect-select": {
        verticalAlign: "middle",
        color: `var(--gray) !important`,
        fontSize: 16,
        fontFamily: "DM Sans",
        fontWeight: 400,
      },
      "& .MuiOutlinedInput-input": {
        padding: "0 !important",
      },
      "& fieldset": {
        display: "none",
      },
    },
  },
  filterDropDown: {
    "& .MuiPaper-root": {
      maxHeight: 250,
      padding: [[0, 5]],
      "& ul": {
        "& li": {
          display: "flex",
          justifyContent: "start",
          padding: "6px 16px",
          "&.MuiButtonBase-root": {
            borderRadius: 5,
            "&.Mui-selected": {
              color: "var(--Tertiary)",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            },
          },
          "& img": {
            height: 28,
            width: "auto",
          },
        },
      },
    },
  },
}));

const innertab = [
  {
    id: 1,
    icon: <MenuIcon />,
    name: "All",
    value: "all",
  },
  {
    id: 2,
    icon: <FlashIcon />,
    name: "Required",
    value: "required",
  },
  {
    id: 3,
    icon: <OptionalIcon />,
    name: "Optional",
    value: "optional",
  },
  {
    id: 4,
    icon: <RightRoundIcon />,
    name: "Completed",
    value: "completed",
  },
  // {
  //   id: 5,
  //   icon: <SadIcon />,
  //   name: "Ignored",
  //   value: "ignored",
  // },
];

const sortBy = {
  name: "anotherFilter",
  placeHolder: "Assessment Filter",
  defaultValue: 0,
  options: [
    {
      key: "All",
      value: "all",
    },
    {
      key: "Wellness Assessments",
      value: "wellness",
    },
    {
      key: "Behavioral Assessments",
      value: "behavioral",
    },
    {
      key: "Physical Assessments",
      value: "physical",
    },
  ],
};

const staticCards = [
  {
    all_results: [
      {
        category: null,
        image: MRF,
        result:
          "<p  class='cardHeading'>Your Medical Recommendations Are Here </p>",
        trait_description:
          " <p class='text' style='color: #747787'>Your provider has completed your Medical Recommendation Form, offering tailored guidance for your health. Click to view these in the dashboard.</p>",
      },
    ],
    assessment_id: 13,
    assessment_taken: true,
    assessment_type: "Behavioral",
    get_started: {
      description:
        "Answer the following questions and help us curate an amazing learning experience for you. We promise it won't take more than 5 minutes",
      image:
        "https://vasana-static.s3.amazonaws.com/images/vasana/get-started.svg",
      quote: "See you on the other side!",
      subtitle: "Unlock Your Strengths",
    },
    has_tie_breaker_questions: true,
    is_locked: false,
    is_required: true,
    is_required_for_signup: true,
    is_result_available: true,
    name: "MRF",
    quick_note: {
      quick_note_content:
        "<p class='desc' style='color: #424450; font-weight: 400'> It will take approximately <span style='color: #555fdd; font-weight: 700'>5 minutes </span>to complete the Vasana Type Assessment.</p>",
      quick_note_recommendation:
        "We recommend completing the assessment in one go. Refreshing the browser before submitting the assessment, will need you to restart the assessment from the beginning.",
    },
    shuffle_questions: true,
    status: "COMPLETED",
    thanks_note: {},
    type: "Options",
  },
  {
    all_results: [
      {
        category: null,
        image: xray,
        result: "<p  class='cardHeading'>Your Chest X-ray Insights </p>",
        trait_description:
          " <p class='text' style='color: #747787'>Your chest X-ray offers a detailed look at the health of your lungs and heart. These insights help you better understand your current health status, and include personalized recommendations to guide your wellness journey.</p>",
      },
    ],
    assessment_id: 14,
    assessment_taken: true,
    assessment_type: "Behavioral",
    get_started: {
      description:
        "Answer the following questions and help us curate an amazing learning experience for you. We promise it won't take more than 5 minutes",
      image:
        "https://vasana-static.s3.amazonaws.com/images/vasana/get-started.svg",
      quote: "See you on the other side!",
      subtitle: "Unlock Your Strengths",
    },
    has_tie_breaker_questions: true,
    is_locked: false,
    is_required: true,
    is_required_for_signup: true,
    is_result_available: true,
    name: "X-ray",
    quick_note: {
      quick_note_content:
        "<p class='desc' style='color: #424450; font-weight: 400'> It will take approximately <span style='color: #555fdd; font-weight: 700'>5 minutes </span>to complete the Vasana Type Assessment.</p>",
      quick_note_recommendation:
        "We recommend completing the assessment in one go. Refreshing the browser before submitting the assessment, will need you to restart the assessment from the beginning.",
    },
    shuffle_questions: true,
    status: "COMPLETED",
    thanks_note: {},
    type: "Options",
  },
  {
    all_results: [
      {
        category: null,
        image: physical,
        result: "<p  class='cardHeading'>Explore Your Fitness Insights</p>",
        trait_description:
          " <p class='text' style='color: #747787'>Find out what your physical exam reveals about your health and discover the next steps your provider recommends for your well-being. Click to explore the details in your dashboard!</p>",
      },
    ],
    assessment_id: 15,
    assessment_taken: true,
    assessment_type: "Behavioral",
    get_started: {
      description:
        "Answer the following questions and help us curate an amazing learning experience for you. We promise it won't take more than 5 minutes",
      image:
        "https://vasana-static.s3.amazonaws.com/images/vasana/get-started.svg",
      quote: "See you on the other side!",
      subtitle: "Unlock Your Strengths",
    },
    has_tie_breaker_questions: true,
    is_locked: false,
    is_required: true,
    is_required_for_signup: true,
    is_result_available: true,
    name: "Physical Exam",
    quick_note: {
      quick_note_content:
        "<p class='desc' style='color: #424450; font-weight: 400'> It will take approximately <span style='color: #555fdd; font-weight: 700'>5 minutes </span>to complete the Vasana Type Assessment.</p>",
      quick_note_recommendation:
        "We recommend completing the assessment in one go. Refreshing the browser before submitting the assessment, will need you to restart the assessment from the beginning.",
    },
    shuffle_questions: true,
    status: "COMPLETED",
    thanks_note: {},
    type: "Options",
  },
];

export default function AssessmentsView() {
  const {
    assessmentTab,
    assessmentFilterTab,
    assessmentList,
    assessmentListLoader,
    handleTabChange,
    resultInfo,
    handleButtonClick,
    retakeAssessment,
    userRoleData,
    onFilterChange,
  } = assessmentsViewContainer();
  const classes = useStyles();
  const breakpointCols = {
    default: 3,
    1200: 3,
    1024: 2,
    650: 1,
  };

  return (
    <Container maxWidth="xl" className={classes.assessmentContainer}>
      <Box className={classes.headingWrapper}>
        <VSNTypography variant="h2" className={classes.heading}>
          Assessments
        </VSNTypography>
        <VSNTypography className={classes.mdText}>
          Discover your unique personality traits through our assessments to
          enhance teamwork and boost efficiency.
        </VSNTypography>
      </Box>
      <Stack
        direction="row"
        justifyContent={{ xsm: "center", xs: "start" }}
        className={classes.innerTabsWrapper}
      >
        {innertab.map((data) => {
          return (
            <ListItem
              key={data?.id}
              className={`${assessmentTab === data?.value ? "active" : ""} ${
                classes.listitemTab
              }`}
              onClick={() =>
                !assessmentListLoader ? handleTabChange(data?.value) : null
              }
              disabled={assessmentListLoader}
            >
              {data?.icon}
              <span>{data?.name}</span>
            </ListItem>
          );
        })}
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          className={classes.sortFilter}
        >
          <PreviewLayerIcon />
          <VSNSelect
            {...{
              ...sortBy,
              onChange: onFilterChange,
              value: sortBy?.options
                ?.map((el) => el?.value)
                ?.includes(assessmentFilterTab)
                ? assessmentFilterTab
                : 0,
              classes: { popover: classes.filterDropDown },
              disabled: assessmentListLoader,
            }}
          />
        </Stack>
      </Stack>
      <>
        {assessmentListLoader ? (
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={24} color="inherit" />
          </Grid>
        ) : (
          <Masonry
            breakpointCols={breakpointCols}
            className={classes.masonryGrid}
            columnClassName="my-masonry-grid_column"
          >
            {assessmentList?.length ? (
              assessmentList?.map((data, i) => {
                return (
                  <AssessmentCard
                    {...{
                      ...data,
                      resultInfo,
                      handleButtonClick,
                      retakeAssessment,
                      userRoleData,
                      index: i,
                    }}
                    key={i}
                  />
                );
              })
            ) : (
              <Stack>No assessments found</Stack>
            )}
            {/* {staticCards?.map((data, i) => {
              return (
                <AssessmentCard
                  {...{
                    ...data,
                    resultInfo,
                    handleButtonClick,
                    retakeAssessment,
                    userRoleData,
                    index: i,
                  }}
                  key={i}
                />
              );
            })} */}
          </Masonry>
        )}
      </>
    </Container>
  );
}
