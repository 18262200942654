import { makeStyles } from "@mui/styles";
import bannerBg from "../../assets/landing/png/bannerBg.png";
import integratesSectionBg from "../../assets/landing/png/integratesSection-bg.webp";

export const VasanaHealthCareStyle = makeStyles((theme) => ({
  heroSection: {
    width: "100%",
    padding: "56px 48px",
    background: `url(${bannerBg})`,
    borderRadius: 24,
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    [theme.breakpoints.down("lg")]: {
      padding: "46px 38px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "36px 28px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "36px 15px 25px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      background: "linear-gradient(94.43deg, #FEFEFE 14.47%, #E6F1FA 105%)",
      bottom: 0,
      zIndex: -1,
      borderRadius: 24,
    },
  },
  lgHeading: {
    "&.MuiTypography-root": {
      fontSize: 64,
      lineHeight: "70px",
      color: "#2D2F39",
      maxWidth: 700,
      [theme.breakpoints.down("xl")]: {
        fontSize: 58,
        lineHeight: "60px",
        maxWidth: 640,
      },
      [theme.breakpoints.down("xlg")]: {
        fontSize: 50,
        lineHeight: "50px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 40,
        lineHeight: "40px",
        maxWidth: 440,
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 34,
        lineHeight: "38px",
      },
      "& .orangeText": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
  },
  bannerBg: {
    position: "relative",
    display: "flex",
    margin: "76px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      margin: "76px 0 0 0",
    },
    "& .desc": {
      fontSize: 20,
      color: "var(--gray)",
      marginBottom: 32,
      marginTop: 24,
      fontWeight: 400,
      [theme.breakpoints.down("lg")]: {
        fontSize: 18,
        marginBottom: 15,
        marginTop: 15,
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 16,
      },
      "& span": {
        color: "var(--primary)",
      },
    },
    "& .MuiButtonBase-root": {
      padding: "12px 32px",
      borderRadius: "10px",
      background:
        "linear-gradient(132.61deg, var(--primaryDarker) 32.09%, var(--primaryGradientLighter) 163.78%)",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 22px",
      },
    },
  },
  heroCapsule: {
    position: "relative",
  },
  heroCapsuleImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "0 -10px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "0 -5px",
    },
    "& .capsule-img": {
      width: "33.33%",
      "&:first-child": {
        marginTop: 50,
      },
      "&:last-child": {
        marginTop: 100,
      },
      "& img": {
        borderRadius: "500px",
        padding: "0 10px",
        [theme.breakpoints.down("sm")]: {
          padding: "0 5px",
        },
      },
    },
  },
  bannerShape1: {
    position: "absolute",
    right: "28%",
    top: "4%",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
    [theme.breakpoints.down("sm")]: {
      width: 40,
    },
  },
  bannerShape2: {
    position: "absolute",
    left: "26%",
    bottom: "24%",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      width: 40,
    },
    [theme.breakpoints.down("sm")]: {
      width: 30,
    },
  },
  bannerShape3: {
    position: "absolute",
    right: "25%",
    bottom: "8%",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
    [theme.breakpoints.down("sm")]: {
      width: 50,
    },
  },
  halfOrange: {
    position: "absolute",
    bottom: 0,
    left: "45%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("lg")]: {
      width: 120,
    },
    [theme.breakpoints.down("md")]: {
      width: 100,
    },
  },
  imageWrapper: {
    maxWidth: 640,
    width: "100%",
    padding: 12,
    borderRadius: 15,
    [theme.breakpoints.down("lg")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    "& img": {
      height: "auto",
      width: "100%",
      borderRadius: 10,
      minHeight: 540,
      objectFit: "cover",
      [theme.breakpoints.down("lg")]: {
        minHeight: "auto",
      },
    },

    "&:before": {
      position: "absolute",
      content: "''",
      height: "50%",
      width: "100%",
      maxWidth: "60%",
      top: -10,
      right: -10,
      borderRadius: 15,
      background: "var(--white)",
      backgroundSize: "contain",
      zIndex: -1,
    },
    "&:after": {
      position: "absolute",
      content: "''",
      height: "50%",
      width: "100%",
      maxWidth: "60%",
      bottom: -10,
      left: -10,
      borderRadius: 15,
      background: "var(--white)",
      backgroundSize: "contain",
      zIndex: -1,
    },
    "& .innerWrapper": {
      position: "relative",
      overflow: "unset",
      lineHeight: 0,
      "&:after": {
        position: "absolute",
        content: "''",
        top: -10,
        left: -10,
        bottom: -10,
        right: -10,
        borderRadius: 15,
        background: "linear-gradient(#F4F8FC, #E8F2F8)",
      },
      "& img": {
        position: "relative",
        zIndex: 2,
      },
    },
    "&.sectionImg": {
      position: "relative",
      "& img": {
        minHeight: 416,
        objectFit: "cover",
        objectPosition: "100%",
        [theme.breakpoints.down("md")]: {
          minHeight: "auto",
        },
      },
    },
  },
  missionSection: {
    paddingTop: 50,
    "& .desc": {
      maxWidth: 1060,
      margin: "0 auto",
      textAlign: "center",
    },
  },
  description: {
    "&.MuiTypography-root": {
      color: "var(--gray)",
      fontSize: 24,
      fontWeight: 400,
      [theme.breakpoints.down("lg")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
      "& i": {
        fontWeight: 500,
      },
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 40,
      lineHeight: "46px",
      fontWeight: "bold",
      color: "#2D2F39",
      [theme.breakpoints.down("xl")]: {
        fontSize: 36,
        lineHeight: "40px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 32,
        lineHeight: "36px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 26,
        lineHeight: "30px",
      },
      "& span": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
  },
  spacing: {
    padding: "80px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0",
    },
  },
  smHeading: {
    "&.MuiTypography-root": {
      fontSize: 24,
      lineHeight: "44px",
      color: "#2D2F39",
      fontWeight: 700,
      marginBottom: 20,
      [theme.breakpoints.down("md")]: {
        marginBottom: 6,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        lineHeight: "28px",
      },
    },
  },
  therapySection: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 30,
    },
  },
  listContentMainBox: {
    backgroundColor: "var(--white)",
    border: "1px solid #CDD0E1",
    borderRadius: 6,
    overflow: "hidden",
    [theme.breakpoints.down("smd")]: {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    "& .MuiGrid-item": {
      "&:last-child": {
        [theme.breakpoints.down("smd")]: {
          marginTop: 16,
        },
        "& .listBox": {
          "&:after": {
            content: "normal",
          },
        },
      },
    },
  },
  headMain: {
    width: "100%",
    backgroundColor: "#F9F7FF",
    padding: "15px 0",
    borderBottom: `1px solid #CDD0E1`,
    borderRadius: "0 0 0 5px",
    [theme.breakpoints.down("smd")]: {
      padding: "10px 0",
      borderRadius: 5,
    },
    "& .MuiTypography-root": {
      fontSize: 20,
    },
  },
  singleListBox: {
    "&.MuiGrid-item": {
      "&:nth-child(2)": {
        "& $headMain": {
          borderRadius: "0 0 5px 0",
          [theme.breakpoints.down("smd")]: {
            borderRadius: 5,
          },
        },
        "& $singleList": {
          "&::after": {
            display: "none",
          },
        },
      },
    },
    "& .listBox": {
      backgroundColor: "var(--white)",
      borderRadius: 5,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      [theme.breakpoints.down("smd")]: {
        border: "1px solid #CDD0E1",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        top: 80,
        right: 1,
        bottom: 0,
        width: 2,
        background: `radial-gradient(74.41% 74.41% at 50% 38.61%, #CDD0E1 0%, rgba(205, 208, 225, 0) 100%);`,
        borderRadius: "50%",
        [theme.breakpoints.down("smd")]: {
          display: "none",
        },
      },
    },
  },
  whyChoose: {
    "& .MuiGrid-item": {
      "& img": {
        [theme.breakpoints.down("md")]: {
          maxWidth: 500,
          width: "100%",
        },
      },
    },
  },
  singleList: {
    position: "relative",
    zIndex: 3,
    padding: "40px 16px",
    maxWidth: 626,
    margin: "0 auto",
    flex: 1,
    [theme.breakpoints.down("smd")]: {
      padding: "20px 16px",
    },
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root": {
        padding: "0",
        gap: "0 15px",
        alignItems: "start",
        marginBottom: 20,
        "&:last-child": {
          marginBottom: 0,
        },
        [theme.breakpoints.down("sm")]: {
          marginBottom: 10,
        },
        "& .MuiTypography-root": {
          fontSize: 20,
          lineHeight: "25px",
          color: "var(--gray)",
          [theme.breakpoints.down("lg")]: {
            fontSize: 18,
          },
          [theme.breakpoints.down("md")]: {
            fontSize: 16,
          },
          "& span": {
            color: "#86650A",
            fontWeight: 700,
          },
        },
      },
    },
  },
  smText: {
    "&.MuiTypography-root": {
      fontSize: 24,
      lineHeight: "39px",
      color: "#2D2F39",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        lineHeight: "24px",
      },
      [theme.breakpoints.down("xts")]: {
        fontSize: 16,
        lineHeight: "22px",
      },
      "& span": {
        color: "#86650A",
        fontWeight: 700,
      },
    },
  },
  halfRoundBlue: {
    position: "absolute",
    bottom: "25%",
    left: "11%",
    transform: "rotate(-270deg)",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  flowerBlue: {
    position: "absolute",
    top: "9%",
    left: "12%",
    height: 61,
    width: 61,
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  panShape: {
    position: "absolute",
    top: "25%",
    right: "8%",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  halfRoundBlue2: {
    position: "absolute",
    top: "38%",
    left: "12%",
    transform: "rotate(-180deg)translateY(-50%)",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  orangeFlower: {
    position: "absolute",
    top: "11%",
    right: "20%",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  rainbowBlue: {
    position: "absolute",
    right: "5%",
    top: "60%",
    transform: "translateY(-50%)",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  healthSliderWrapper: {
    maxWidth: 1240,
    width: "100%",
    margin: "0 auto",
    textAlign: "center",
  },
  sapSidi: {
    position: "absolute",
    right: "17%",
    bottom: "7%",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  personalityMain: {
    paddingBottom: 60,
    marginRight: 60,
    marginLeft: 60,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 0,
    },
    "& .swiper-button-next": {
      transform: "rotate(180deg)",
    },
    "& .swiper-pagination": {
      bottom: 0,
      "& .swiper-pagination-bullet": {
        background: "#DFE1F84D",
        opacity: 1,
        width: 40,
        height: 6,
        transition: "all 0.4s ease-in-out",
        borderRadius: 4,
      },
      "& .swiper-pagination-bullet-active": {
        background: "var(--primary)",
      },
    },
  },
  intelligenceSlider: {
    height: 450,
    "& .swiper-slide-active": {
      "& .MuiStack-root": {
        height: 450,
        "& p": {
          maxHeight: "fit-content",
          visibility: "visible",
        },
      },
    },
  },
  customArrow: {
    position: "absolute",
    right: 0,
    left: 0,
    top: "43%",
    transform: "translateY(-50%)",
    justifyContent: "space-between",
    zIndex: 2,
    height: 0,
    "& .swiper-button": {
      cursor: "pointer",
      backgroundColor: "var(--primary)",
      borderRadius: 8,
      color: "var(--white)",
      height: 48,
      width: 48,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      transform: "translateY(-50%)",
      [theme.breakpoints.down("sm")]: {
        height: 38,
        width: 38,
      },
      "&.swiper-button-disabled": {
        opacity: 0.5,
      },
    },
  },
  sliderImgWrapper: {
    height: 330,
    width: 330,
    flex: "0 0 auto",
    borderRadius: "0 150px",
    overflow: "hidden",
    border: "14px solid #3498DB10",
    [theme.breakpoints.down("lg")]: {
      height: 290,
      width: 290,
    },
    [theme.breakpoints.down("md")]: {
      height: 250,
      width: 250,
      borderRadius: "0 100px",
    },
    [theme.breakpoints.down("smd")]: {
      height: 230,
      width: 230,
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  integratesSection: {
    margin: "50px 0",
    borderRadius: 24,
    padding: "80px 0",
    background: `url(${integratesSectionBg})`,
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
    "& .heading": {
      fontSize: 37,
      lineHeight: "48px",
      fontWeight: "bold",
      color: "#2D2F39",
      maxWidth: 1240,
      textAlign: "center",
      margin: "0 auto 68px",
    },
  },
  circleBgWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: 900,
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: {
      maxWidth: 820,
    },
  },
  circleBg: {
    borderRadius: " 100%",
    position: "relative",
    display: "flex",
    height: 910,
    justifyContent: "center",
    flexDirection: "column",
    margin: "0 auto -350px",
    [theme.breakpoints.down("xlg")]: {
      height: 800,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "-300px",
      height: 700,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "-250px",
      height: 600,
    },
    [theme.breakpoints.down("xsm")]: {
      marginBottom: "-200px",
      height: 450,
    },
    "& img": {
      width: "100%",
      maxWidth: 180,
      position: "relative",
      margin: "0 auto 4%",
      [theme.breakpoints.down("md")]: {
        maxWidth: 150,
      },
      [theme.breakpoints.down("smd")]: {
        marginBottom: 0,
        maxWidth: 100,
      },
      [theme.breakpoints.down("xsm")]: {
        marginBottom: 0,
        maxWidth: 70,
      },
    },
    "& .circle": {
      display: "block",
      position: "absolute",
      top: 0,
      bottom: 0,
      height: 910,
      width: 910,
      margin: "auto",
      borderRadius: "50%",
      left: "50%",
      transform: "translateX(-50%)",
      [theme.breakpoints.down("xlg")]: {
        height: 800,
        width: 800,
      },
      [theme.breakpoints.down("md")]: {
        height: 700,
        width: 700,
      },
      [theme.breakpoints.down("sm")]: {
        height: 600,
        width: 600,
      },
      [theme.breakpoints.down("xsm")]: {
        height: 450,
        width: 450,
      },
      "&:nth-child(3)": {
        height: 810,
        width: 810,
        [theme.breakpoints.down("xlg")]: {
          height: 710,
          width: 710,
        },
        [theme.breakpoints.down("md")]: {
          height: 630,
          width: 630,
        },
        [theme.breakpoints.down("sm")]: {
          height: 550,
          width: 550,
        },
        [theme.breakpoints.down("xsm")]: {
          height: 400,
          width: 400,
        },
      },
      "&:nth-child(4)": {
        height: 710,
        width: 710,
        [theme.breakpoints.down("xlg")]: {
          height: 610,
          width: 610,
        },
        [theme.breakpoints.down("md")]: {
          height: 550,
          width: 550,
        },
        [theme.breakpoints.down("sm")]: {
          height: 490,
          width: 490,
        },
        [theme.breakpoints.down("xsm")]: {
          height: 350,
          width: 350,
        },
      },
      "&:nth-child(5)": {
        height: 610,
        width: 610,
        [theme.breakpoints.down("xlg")]: {
          height: 510,
          width: 510,
        },
        [theme.breakpoints.down("md")]: {
          height: 470,
          width: 470,
        },
        [theme.breakpoints.down("sm")]: {
          height: 430,
          width: 430,
        },
        [theme.breakpoints.down("xsm")]: {
          height: 300,
          width: 300,
        },
      },
    },
  },
  integrates: {
    "&.MuiTypography-root": {
      position: "absolute",
    },
    "&.integrates0": {
      top: "34%",
      left: "-4%",
      [theme.breakpoints.down("md")]: {
        left: 10,
      },
      [theme.breakpoints.down("smd")]: {
        top: "25%",
        left: "8%",
      },
    },
    "&.integrates1": {
      top: "19%",
      left: "18%",
      [theme.breakpoints.down("smd")]: {
        top: "38%",
        left: "2%",
      },
    },
    "&.integrates2": {
      top: "3%",
      left: "44%",
      [theme.breakpoints.down("md")]: {
        left: 0,
        right: 0,
        display: "table",
        margin: "0 auto",
      },
      [theme.breakpoints.down("sm")]: {
        top: "-8px",
      },
    },
    "&.integrates3": {
      top: "25%",
      right: "14%",
      [theme.breakpoints.down("smd")]: {
        top: "33%",
        right: 10,
      },
    },
    "&.integrates4": {
      top: "40%",
      right: "-5%",
      [theme.breakpoints.down("md")]: {
        right: 10,
      },
      [theme.breakpoints.down("smd")]: {
        top: "18%",
      },
      [theme.breakpoints.down("sm")]: {
        top: "13%",
      },
    },
    "&.integrates5": {
      bottom: "38%",
      right: "13%",
      [theme.breakpoints.down("sm")]: {
        bottom: "33%",
      },
    },
    "&.integrates6": {
      top: "50%",
      left: "7%",
      [theme.breakpoints.down("smd")]: {
        top: "55%",
      },
      [theme.breakpoints.down("sm")]: {
        left: "3%",
      },
    },
  },
  integratesText: {
    backgroundColor: "var(--white)",
    boxShadow: "0px 4px 24px 0px #00000014",
    color: "#2D2F39",
    width: "fit-content",
    borderRadius: 24,
    padding: "8px 16px",
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("xsm")]: {
      fontSize: 10,
      padding: "6px 10px",
    },
  },
  intelligenceCard: {
    height: 382,
    flex: 1,
    border: "1px solid",
    borderRadius: 8,
    padding: 24,
    justifyContent: "flex-end",
    position: "relative",
    backgroundPosition: "center center",
    transition: "all 0.5s",
    backgroundSize: "cover",
    [theme.breakpoints.down("lg")]: {
      padding: 16,
    },
    "& h4": {
      fontSize: 24,
      lineHeight: "30px",
      fontWeight: "bold",
      position: "relative",
      marginBottom: 10,
      [theme.breakpoints.down("lg")]: {
        fontSize: 22,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
    },
    "& p": {
      maxHeight: 0,
      visibility: "hidden",
      position: "relative",
      transition: "maxHeight 0.5s",
      color: "var(--gray)",
      fontSize: 20,
      [theme.breakpoints.down("lg")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "&:before": {
      position: "absolute",
      content: "''",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 8,
    },
    "&.intelligence0": {
      "&:before": {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(242, 231, 245, 0.75) 50%, rgba(242, 231, 245, 0.85) 70%, #F2E7F5 85%, #F2E7F5 100%)",
      },
    },
    "&.intelligence1": {
      "&:before": {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(227, 241, 249, 0.75) 50%, rgba(227, 241, 249, 0.85) 70%, #E3F1F9 85%, #E3F1F9 100%)",
      },
    },
    "&.intelligence2": {
      "&:before": {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 251, 246, 0.7) 50%, rgba(255, 249, 241, 0.85) 70%, #FFF8EE 85%, #FFF7EC 100%)",
      },
    },
    "&.intelligence3": {
      "&:before": {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(248, 252, 249, 0.7) 50%, rgba(244, 251, 245, 0.85) 70%, #F2FAF4 85%, #F0F9F2 100%)",
      },
    },
    "&.active": {
      flex: "0 0 30%",
      width: "auto",
      height: 430,
      overflow: "hidden",
      "& p": {
        maxHeight: "fit-content",
        visibility: "visible",
      },
    },
  },
  halfRoundBlue3: {
    position: "absolute",
    right: "11%",
    bottom: "20%",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  rainbowBlue2: {
    position: "absolute",
    left: "7%",
    bottom: 0,
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  halfRoundBlue4: {
    position: "absolute",
    right: "11%",
    bottom: "20%",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  rainbowBlue3: {
    position: "absolute",
    right: "7%",
    top: -50,
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  // videoWrapper: {
  //   position: "relative",
  //   borderRadius: 16,
  //   minHeight: 460,
  //   [theme.breakpoints.down("smd")]: {
  //     minHeight: 360,
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     minHeight: 260,
  //   },
  //   "&:before": {
  //     position: "absolute",
  //     content: "''",
  //     top: 0,
  //     bottom: 0,
  //     left: 0,
  //     right: 0,
  //     borderRadius: 16,
  //     background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
  //   },
  //   "&:after": {
  //     position: "absolute",
  //     content: "''",
  //     top: -12,
  //     right: -12,
  //     borderRadius: 16,
  //     height: 162,
  //     width: 162,
  //     background: "#F0DDD380",
  //     zIndex: -1,
  //   },
  //   "& img": {
  //     width: "100%",
  //     height: "100%",
  //     minHeight: 460,
  //     objectFit: "cover",
  //     borderRadius: 16,
  //     [theme.breakpoints.down("smd")]: {
  //       minHeight: 360,
  //     },
  //     [theme.breakpoints.down("sm")]: {
  //       minHeight: 260,
  //     },
  //   },
  // },
  // playIconWrapper: {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%,-50%)",
  //   height: 72,
  //   width: 72,
  //   borderRadius: "50%",
  //   backgroundColor: "var(--primary)",
  //   color:"var(--white)",
  //   lineHeight: "98px",
  //   textAlign: "center",
  //   transition: "all 0.3s",
  //   cursor: "pointer",
  //   "&:hover": {
  //     backgroundColor:"var(--white)",
  //     color: "var(--primary)",
  //   },
  // },
  reviewWrapper: {
    "& .MuiTypography-root": {
      textAlign: "center",
    },
    "& .MuiBox-root:has( > .MuiTypography-h3)": {
      padding: "48px 32px",
      borderRadius: 16,
      backgroundColor: "var(--primaryLighter)",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        padding: [[40, 20, 20]],
      },
      "& .MuiTypography-h3": {
        fontSize: 24,
        fontWeight: 600,
        color: "var(--gray)",
        letterSpacing: 0.5,
        position: "relative",
        zIndex: 1,
        [theme.breakpoints.down("lg")]: {
          fontSize: 20,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
      "& img": {
        position: "absolute",
        top: 14,
        left: 18,
        [theme.breakpoints.down("sm")]: {
          maxWidth: 94,
        },
      },
    },
    "& .MuiTypography-h6": {
      fontSize: 24,
      lineHeight: "31px",
      fontWeight: 700,
      color: "#2D3652",
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        lineHeight: "26px",
      },
      marginTop: 14,
    },
    "& .MuiTypography-body1": {
      lineHeight: "21px",
      fontWeight: 500,
      color: "var(--gray)",
    },
  },
  halfRoundBlue5: {
    position: "absolute",
    top: "8%",
    left: "12%",
    transform: "rotate(90deg)",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  flowerBlue2: {
    position: "absolute",
    top: "11%",
    right: "9%",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
}));
