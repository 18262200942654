import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { motion, useAnimation } from "framer-motion";
import { Box, IconButton } from "@mui/material";
import Thyroid from "../../../assets/assesments/physical/ultraPolice/Thyroid.svg";
import Abdomen from "../../../assets/assesments/physical/ultraPolice/Abdomen.svg";
import Gallbladder from "../../../assets/assesments/physical/ultraPolice/Gallbladder.svg";
import Kidneys from "../../../assets/assesments/physical/ultraPolice/Kidneys.svg";
import Pancreatic from "../../../assets/assesments/physical/ultraPolice/Pancreatic_Head.svg";
import Abdominal from "../../../assets/assesments/physical/ultraPolice/Abdominal_aorta.svg";
import bladder from "../../../assets/assesments/physical/ultraPolice/Urinary_bladder.svg";
import Scrotum from "../../../assets/assesments/physical/ultraPolice/Scrotum.svg";
import Epididymal from "../../../assets/assesments/physical/ultraPolice/Epididymal_Head.svg";
import Comparisons from "../../../assets/assesments/physical/ultraPolice/History_Comparisons.svg";
import VSNImage from "../../../shared/VSNImage";
import VSNTypography from "../../../shared/VSNTypography";
import whiteArrow from "../../../assets/quickView/svg/whiteArrow.svg";

const UltrasoundData = [
  {
    svgIcon: Thyroid,
    heading: "Thyroid",
    paragraph:
      "Right lobe measures 4.6 cm in length. Leftlobe measures 3.6 cm in length. Isthmus measures 4 mm in thickness.Normal homogeneous echogenicity.No discrete thyroid lesions.",
  },
  {
    svgIcon: Abdomen,
    heading: "Abdomen",
    paragraph:
      "Liver: Increased homogeneous echogenicity. No discrete mass or lesion within the provided images. The liver is enlarged by strict size criteria, measuring 20.5 cm in greatest craniocaudal dimension.",
  },
  {
    svgIcon: Gallbladder,
    heading: "Gallbladder",
    paragraph: "No discrete abnormality identified.",
  },
  {
    svgIcon: Kidneys,
    heading: "Kidneys",
    paragraph:
      "The kidneys demonstrate normal morphology and location. There is a 2.6 cm hypoechoic lesion within the left mid kidney with coarse calcifications immediately adjacent to this lesion. The overall appearance is not significantly changed compared with the prior studies. There are no new right or left renal lesions identified. There is no hydronephrosis or proximal hydroureter. There is no perinephric free fluid.",
  },
  {
    svgIcon: Pancreatic,
    heading: "Pancreatic Head",
    paragraph:
      "No discrete abnormality identified.Spleen: No discrete abnormality identified.",
  },
  {
    svgIcon: Abdominal,
    heading: "Abdominal aorta",
    paragraph: "Tapers normally without aneurysm.",
  },
  {
    svgIcon: bladder,
    heading: "Urinary bladder",
    paragraph: "Moderately distended. No discrete abnormality identified.",
  },
  {
    svgIcon: Scrotum,
    heading: "Scrotum",
    paragraph:
      "Right testicle measures 5.2 cm x 3.0 cm x 2.5 cm  Left testicle measures 5.2 cm x 3.1 cm x 2.8 cm Normal homogeneous echotexture, without mass or lesion. Blood flow is present in the right and left testicle.",
  },
  {
    svgIcon: Epididymal,
    heading: "Epididymal Head",
    paragraph:
      "Right and left epididymal heads are without discrete lesion.No hydrocele or varicocele.",
  },
  {
    svgIcon: Comparisons,
    heading: "History & Comparisons",
    paragraph:
      "OUTINE PHYSCAL (Hx) & Screening ultrasound of 1/27/2022 and 2/2/2021.",
  },
];

const useStyles = makeStyles(() => ({
  card: {
    cursor: "pointer",
    border: "1px solid transparent",
    borderRadius: 12,
    background: "#F2E7F5",
    transition: "all 0.3s ease",
    height: 130,
    "& .paragraph": {
      display: "none",
      color: "#3A3A40",
      lineHeight: "23px",
    },
    "& img": {
      filter: "brightness(0)",
    },
  },
  activeCard: {
    width: 450,
    height: 240,
    borderColor: "#8B3F9E",
    "& .paragraph": {
      display: "block !important",
      lineHeight: "20px",
    },
    "& img": {
      transform: "scale(1.3)",
      filter: "brightness(1)",
    },
  },
  slideArrowLeft: {
    "&.MuiButtonBase-root": {
      cursor: "pointer",
      background: `url(${whiteArrow})`,
      borderRadius: "50%",
      color: "#000",
      height: 24,
      width: 24,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "10px",
      transform: "rotate(0deg)",
      position: "absolute",
      left: 0,
      top: "50%",
      zIndex: 999,
    },
  },
  slideArrowRight: {
    "&.MuiButtonBase-root": {
      cursor: "pointer",
      background: `url(${whiteArrow})`,
      borderRadius: "50%",
      color: "#000",
      height: 24,
      width: 24,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "10px",
      transform: "rotate(180deg)",
      position: "absolute",
      right: 0,
      top: "50%",
      zIndex: 999,
    },
  },
}));

const CustomSlider = () => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const controls = useAnimation();
  const [activeSlide, setActiveSlide] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleCardClick = (index) => {
    setActiveSlide(index);
    setDirection("right");
  };

  const moveCard = (direction) => {
    setActiveSlide((prev) => {
      setDirection(direction);
      if (direction === "left" && prev > 0) {
        return prev - 1;
      }
      if (direction === "right" && prev < UltrasoundData.length - 1) {
        return prev + 1;
      }
      return prev;
    });
  };

  useEffect(() => {
    const updatePosition = (direction) => {
      const elements = document.querySelectorAll('[id^="journeyStep"]');
      if (elements[activeSlide]) {
        const containerOffset =
          containerRef.current.getBoundingClientRect().left;
        const elementOffset =
          elements[activeSlide].getBoundingClientRect().left;
        const activeCardWidth = 450;

        const transformPosition = elementOffset - containerOffset;

        controls.start({
          x:
            -transformPosition +
            (direction === "right" && activeCardWidth / 2 + 50),
        });
      }
    };

    updatePosition(direction);
    window.addEventListener("resize", updatePosition);

    return () => window.removeEventListener("resize", updatePosition);
  }, [activeSlide, controls]);

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#f8f1ff",
        borderRadius: "8px",
      }}
    >
      <IconButton
        className={classes.slideArrowLeft}
        onClick={() => moveCard("left")}
      />

      <motion.div
        ref={containerRef}
        animate={controls}
        className="slider"
        style={{
          display: "flex",
          gap: "16px",
          cursor: "grab",
          alignItems: "flex-start",
        }}
        transition={{ type: "spring", stiffness: 100, damping: 30 }}
      >
        {UltrasoundData.map((item, index) => (
          <motion.div
            key={index}
            id={`journeyStep${index}`}
            onClick={() => handleCardClick(index)}
            className={`${classes.card} ${
              activeSlide === index ? classes.activeCard : ""
            }`}
            style={{
              backgroundColor: "#F2E7F5",
              borderRadius: "16px",
              boxShadow: 3,
              padding: "18px",
              minWidth: activeSlide === index ? "400px" : "130px",
              cursor: "pointer",
              transition: "min-width 0.3s ease",
            }}
          >
            <Box key={item.index} className="card">
              <VSNImage src={item.svgIcon} alt="icon" />
              <VSNTypography
                variant="subtitle1"
                component="h6"
                color="#202024"
                lineHeight="20px"
                fontWeight={700}
                my={1}
              >
                {item.heading}
              </VSNTypography>
              <VSNTypography className="paragraph">
                {item.paragraph}
              </VSNTypography>
            </Box>
          </motion.div>
        ))}
      </motion.div>

      <IconButton
        className={classes.slideArrowRight}
        onClick={() => moveCard("right")}
      />
    </Box>
  );
};

export default CustomSlider;
