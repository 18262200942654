import { makeStyles } from "@mui/styles";
import crown from "../../assets/organizationAdmin/svg/crown.svg";

export const ViewTeamsStyle = makeStyles((theme) => ({
  heading: {
    "&.MuiTypography-root": {
      fontSize: 30,
      fontWeight: "bold",
      color: "var(--blackGray)",
      paddingBottom: 18,
      borderBottom: "1px solid #74778721",
      "& span": {
        color: "var(--primary)",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 19,
        lineHeight: "26px",
      },
    },
  },
  text: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: 15,
      color: "var(--gray)",
      maxWidth: "892px",
      margin: "15px auto 0",
    },
  },
  desc: {
    "&.MuiTypography-root": {
      fontSize: 14,
      color: "var(--gray)",
      lineHeight: "18px",
      width: "100%",
      maxWidth: "fit-content",
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
    },
  },
  tabWrapper: {
    display: "flex",
    // "&.MuiTabs-root": {
    //   minHeight: "unset",
    "& .tabOut": {
      width: 40,
      height: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "var(--white)",
      color: "var(--blackGray)",
      position: "relative",
      cursor: "pointer",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 10,
        bottom: 10,
        width: 1,
        left: 0,
        background: "#42445033",
      },
      "&.active": {
        color: "var(--primary)",
      },
      "&:first-child": {
        "&:before": {
          display: "none",
        },
      },
    },
    // },
  },
  btn: {
    "&.MuiButtonBase-root": {
      padding: "9px 12px",
      background: "var(--white)",
      border: "1px solid var(--primary)",
      [theme.breakpoints.down("sm")]: {
        padding: "9px",
      },
      "& .content": {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        fontWeight: "400",
        gap: 5,
        color: "var(--primary)",
        "& svg": {
          height: "23px",
          width: "auto",
          [theme.breakpoints.down("sm")]: {
            height: "18px",
          },
        },
      },
      "& span": {
        lineHeight: "16px",
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
      },
    },
  },
  btnBlue: {
    "&.MuiButton-root": {
      borderColor: "var(--Tertiary)",
      color: "var(--Tertiary)",
      width: "max-content",
      "& .content": {
        color: "var(--Tertiary)",
      },
    },
  },
  helpCard: {
    padding: 15,
    borderRadius: 5,
    backgroundColor: "var(--white)",
    boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.07)",
    position: "relative",
    zIndex: 2,
    height: "100%",
  },
  myTeamCard: {
    cursor: "pointer",
  },
  imgWrapper: {
    borderRadius: 5,
    overflowY: "hidden",
    position: "relative",
    height: 179,
    [theme.breakpoints.down("lg")]: {
      minHeight: 100,
    },
    "& img": {
      width: "100%",
      objectFit: "cover",
      height: "100%",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: "700",
      color: "var(--blackGray)",
      marginBottom: 5,
      width: "100%",
      maxWidth: "fit-content",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        lineHeight: "20px",
      },
    },
  },
  smHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: "700",
      color: "var(--blackGray)",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
        lineHeight: "16px",
      },
    },
  },
  MenuWrapper: {
    "&.MuiButtonBase-root": {
      height: "27px",
      width: "27px",
      position: "absolute",
      top: 12,
      right: 12,
      backgroundColor: "#4244504D",
      borderRadius: 5,
      backdropFilter: "blur(4px)",
      "& .MuiSvgIcon-root": {
        color: "var(--white)",
      },
    },
  },
  popupIcon: {
    height: 24,
    width: 24,
    borderRadius: "100%",
    backgroundColor: "var(--white)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  height: {
    maxHeight: "92px",
    "& img": {
      objectPosition: "center",
    },
  },
  buttonRow: {
    "&.MuiStack-root": {
      flexDirection: "row",
      padding: "18px 0",
      [theme.breakpoints.down("359")]: {
        flexDirection: "column",
        gap: 10,
      },
      "& .MuiStack-root": {
        [theme.breakpoints.down("359")]: {
          width: "100%",
        },
        "& .MuiButton-root": {
          [theme.breakpoints.down("359")]: {
            width: "100% !important",
          },
        },
      },
    },
  },
  btnJoin: {
    "&.MuiButton-root": {
      padding: "5px 7px",
      [theme.breakpoints.down("sm")]: {
        width: "fit-content",
      },
      "& .content": {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
      },
    },
  },
  noDataBox: {
    background: "var(--white)",
    borderRadius: 10,
    minHeight: "calc(100vh - 235px)",
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "& img": {
      maxWidth: 510,
      width: "100%",
      objectFit: "contain",
      marginBottom: 22,
      height: "auto",
    },
    "& h6": {
      "&.MuiTypography-root": {
        color: "#2D3652",
        fontSize: 30,
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
          fontSize: 20,
        },
      },
    },
    "& p": {
      "&.MuiTypography-root": {
        color: "var(--gray)",
        fontSize: 18,
        fontWeight: 500,
        width: "100%",
        maxWidth: 566,
        margin: "0 auto",
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
    },
  },
  admin: {
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "''",
      top: -8,
      right: -2,
      background: `url(${crown})`,
      backgroundSize: "100%",
      height: 20,
      width: 20,
      zIndex: 2,
    },
  },
}));
