import React from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import VSNImage from "../../shared/VSNImage";
import authTopBg from "../../assets/common/png/auth-top-bg.png";
import authBottomBg from "../../assets/common/png/auth-bottom-bg.png";
import VSNTypography from "../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  BgWrap: {
    position: "relative",
    minHeight: "calc(100vh - 96px)",
    borderTopRightRadius: 30,
    overflow: "hidden",
    "&:after": {
      position: "absolute",
      width: "100%",
      height: "60%",
      content: '""',
      bottom: 0,
      left: 0,
    },
    [theme.breakpoints.up(1440)]: {
      minWidth: 564,
    },
    [theme.breakpoints.down("md")]: {
      borderTopRightRadius: 0,
    },
  },
  editProfileBgWrap: {
    height: "50%",
  },

  Bg: {
    width: "100%",
    height: "calc(100vh - 75px)",
    objectFit: "cover",
    display: "block",
    borderTopRightRadius: "30px",
    objectPosition: "top",
  },
  TopBg: {
    position: "absolute",
    top: -5,
    right: 0,
    zIndex: 1,
    opacity: 0.2,
  },
  BottomBg: {
    position: "absolute",
    bottom: -50,
    left: 10,
    zIndex: 1,
    opacity: 0.3,
  },
  BgDesc: {
    position: "absolute",
    zIndex: 1,
    bottom: "25%",
    left: 0,
    right: 0,
    margin: "0 auto",
    padding: [[0, 62]],
    "& h4.MuiTypography-root": {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: "52px",
      color: "var(--white)",
      marginBottom: 10,
      [theme.breakpoints.down("md")]: {
        fontSize: 30,
        lineHeight: "42px",
      },
    },
    "& p.MuiTypography-root": {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: "31px",
      color: "var(--white)",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "22px",
      },
    },
  },
  userInfoImg: {
    position: "relative",
    "&:after": {
      position: "absolute",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #4F4F4F 170.53%)",
      content: '""',
      top: 0,
      left: 0,
    },
  },
}));

const BannerLayout = ({
  imgSrc,
  heading,
  description,
  opacity,
  bannerClassName,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames({
        [classes.BgWrap]: true,
        // [classes.userInfoImg]: opacity,
        // [classes?.[bannerClassName]]: bannerClassName,
      })}
    >
      <VSNImage src={imgSrc} className={classes.Bg} alt="bg" />
      <div className={classes.BgDesc}>
        <VSNTypography variant="h4">{heading}</VSNTypography>
        <VSNTypography variant="body1">{description}</VSNTypography>
      </div>
    </div>
  );
};

export default BannerLayout;
