import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { ReactSortable } from "react-sortablejs";
import { useDispatch, useSelector } from "react-redux";
import { DragIcon, InfoIcon, DragDisableIcon } from "../../shared/icon";
import VSNTypography from "../../shared/VSNTypography";
import { ApiContainer } from "../../utils/api";
import VSNToolTip from "../../shared/VSNToolTip";
import { getEndpoint } from "../../shared/CustomFunc";
import VSNSelect from "../../shared/VSNSelect";
import { SET_API_DATA } from "../../redux/constants";

const useStyles = makeStyles((theme) => ({
  assessmentTableCard: {
    width: "100%",
    flex: 1,
    backgroundColor: "var(--white)",
    alignItems: "center",
    cursor: "move",
    padding: [[4, 8, 4, 20]],
    "& .MuiStack-root": {
      color: "var(--gray)",
      "&:first-child": {
        width: "20%",
      },
      "&:nth-child(2)": {
        width: "10%",
      },
      "&:nth-child(3)": {
        width: "10%",
        textAlign: "center",
      },
      "&:nth-child(4)": {
        width: "10%",
        textAlign: "center",
      },
      "&:nth-child(5)": {
        width: "10%",
        textAlign: "center",
      },
      "&:nth-child(6)": {
        width: "17%",
        textAlign: "center",
      },
      "&:nth-child(7)": {
        width: "18%",
        textAlign: "center",
      },
      "&:last-child": {
        width: "5%",
      },
    },
    "& svg": {
      flex: "none",
    },
    "&:hover": {
      backgroundColor: "#f1f0f0",
    },
  },
  assessmentTableCards: {
    minWidth: 1440,
  },
  assessmentTableCardsTitle: {
    alignItems: "center",
    "& p": {
      padding: 10,
      fontSize: 18,
      fontWeight: 600,
      "& svg": {
        verticalAlign: "middle",
      },
      "&:first-child": {
        width: "20%",
      },
      "&:nth-child(2)": {
        width: "10%",
      },
      "&:nth-child(3)": {
        width: "10%",
        textAlign: "center",
      },
      "&:nth-child(4)": {
        width: "10%",
        textAlign: "center",
      },
      "&:nth-child(5)": {
        width: "10%",
        textAlign: "center",
      },
      "&:nth-child(6)": {
        width: "17%",
        textAlign: "center",
      },
      "&:nth-child(7)": {
        width: "18%",
        textAlign: "center",
      },
      "&:last-child": {
        width: "5%",
      },
    },
  },
  assessmentList: {
    maxHeight: "400px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 8,
      padding: 4,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: "#c7c5c5",
      border: "1px solid #ffffff",
    },
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  "&.MuiSwitch-root": {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "var(--white)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          background: "var(--Tertiary)",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.3,
          background: "var(--Tertiary)",
        },
      },
      "& + .MuiSwitch-track": {
        background: "#00000040",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: "all 0.4s ease-in-out",
    },
    "& .MuiSwitch-track": {
      borderRadius: 10,
      opacity: 1,
      boxSizing: "border-box",
    },
  },
}));

let count;
const Item = ({
  item,
  draggable,
  classes,
  handleSwitchChange,
  mandatory,
  role,
  onSignUpSwitchChange,
  onLockSwitchChange,
  onSelectBoxChange,
  onDateChange,
  onForcedRetakeChange,
}) => {
  count += 1;
  const frequencyOption = useSelector((state) => state?.api?.frequencyOption);
  return (
    <Stack
      direction="row"
      sx={{
        cursor: draggable ? "move !important" : "auto !important",
      }}
      className={classes.assessmentTableCard}
    >
      <Stack>
        <VSNTypography sx={{ whiteSpace: "normal" }}>
          {item?.name || ""}
        </VSNTypography>
      </Stack>
      <Stack>
        <VSNTypography
          sx={{
            color: `${
              item?.assessment_type === "Behavioral"
                ? "#2D8F4E"
                : item?.assessment_type === "Wellness"
                ? "var(--Tertiary)"
                : "#8B3F9E"
            } !important`,
          }}
        >
          {item?.assessment_type || ""}
        </VSNTypography>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        flex="none"
        spacing={1}
        p="10px 20px 10px 10px"
        justifyContent="center"
      >
        <AntSwitch
          sx={item?.nonChangeable ? { pointerEvents: "none" } : {}}
          disabled={item?.nonChangeable || mandatory}
          checked={item?.enabled || false}
          onChange={(event) =>
            !item?.nonChangeable ? handleSwitchChange(event, item) : null
          }
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        flex="none"
        spacing={1}
        p="10px 20px 10px 10px"
        justifyContent="center"
      >
        <AntSwitch
          sx={item?.nonChangeable ? { pointerEvents: "none" } : {}}
          disabled={item?.nonChangeable || !item?.required || mandatory}
          checked={item?.signUpFlow || false}
          onChange={(event) =>
            !item?.nonChangeable ? onSignUpSwitchChange(event, item) : null
          }
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        flex="none"
        spacing={1}
        p="10px 20px 10px 10px"
        justifyContent="center"
      >
        <AntSwitch
          sx={item?.nonChangeable ? { pointerEvents: "none" } : {}}
          disabled={item?.nonChangeable || mandatory || !item?.result_exists}
          checked={mandatory ? false : item?.locked || false}
          onChange={(event) =>
            !item?.nonChangeable ? onLockSwitchChange(event, item) : null
          }
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
      <Stack
        direction="row"
        flex="none"
        spacing={1}
        p="10px 20px 10px 10px"
        alignItems="center"
      >
        <Button
          sx={{
            backgroundColor: "rgba(85, 95, 221, 0.04)",
            textTransform: "none",
            padding: 1,
          }}
          disabled={mandatory}
          onClick={() => onForcedRetakeChange(item)}
        >
          Forced Retake
        </Button>
        <VSNTypography sx={{ fontSize: "14px", fontWeight: 600 }}>
          {item?.forcedReTakeDate &&
            dayjs(item?.forcedReTakeDate)?.format("MM/DD/YYYY")}
        </VSNTypography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        flex="none"
        spacing={1}
        justifyContent="center"
      >
        {!mandatory ? (
          <>
            <VSNSelect
              disabled={mandatory}
              formControlSx={{ flex: 1 }}
              sx={{
                "& .MuiSelect-select": {
                  fontSize: "16px",
                  fontWeight: "normal",
                  paddingLeft: "12px",
                  textAlign: "start",
                },
              }}
              value={item?.frequency || 0}
              placeHolder="Frequency"
              onChange={(event) => onSelectBoxChange(event, item)}
              options={frequencyOption || []}
            />
            {item?.showDateField && (
              <DesktopDatePicker
                sx={{
                  flex: 1,
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "9.45px",
                  },
                }}
                minDate={dayjs()}
                fullWidth
                onChange={(value) => onDateChange(value, item)}
                value={dayjs(item?.frequencyDate)}
              />
            )}
          </>
        ) : (
          "-"
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        width="5%"
        flex="none"
        spacing={1}
        p="10px 20px 10px 10px"
        justifyContent="center"
      >
        {!mandatory && draggable ? (
          <DragIcon />
        ) : (
          <VSNToolTip
            placement="bottom"
            arrow
            title={
              mandatory
                ? "You can not change this assessment order."
                : "Enable toggle button to reorder assessment as required."
            }
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "var(--white)",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "var(--blackGray)",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "var(--white)",
                    },
                  },
                },
              },
            }}
          >
            <span>
              <DragDisableIcon />
            </span>
          </VSNToolTip>
        )}
      </Stack>
    </Stack>
  );
};

export default function AssessmentsTab({ data }) {
  count = 0;
  const classes = useStyles();
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const organization_original_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_original_id
  );
  const dataForAssessment = useSelector(
    (state) => state?.api?.dataForAssessment
  );
  const [isLoading, setIsLoading] = useState(true);
  const location_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.location_id
  );
  const department_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.department_id
  );
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  const frequencyOption = useSelector((state) => state?.api?.frequencyOption);
  const [assessments, setAssessments] = useState({});
  const activeTab = useSelector((state) => state?.api?.tabs?.["org-summary"]);
  const apiEndpoint =
    activeTab === "departments"
      ? `organization/${organization_original_id}/locations/${dataForAssessment?.location_original_id}/departments/${dataForAssessment?.department_original_id}`
      : `organization/${organization_original_id}/locations/${dataForAssessment?.location_original_id}/departments/${dataForAssessment?.department_original_id}/member/${dataForAssessment?.user_id}`;

  useEffect(() => {
    setAssessments(data || {});
  }, [data]);
  useEffect(() => {
    const optionData = async () => {
      const data = await api({
        method: "GET",
        endPoint: "assessment/frequency-dropdown",
        urlencoded: false,
      });
      dispatch({
        type: SET_API_DATA,
        payload: {
          frequencyOption: data?.data?.map((item) => ({
            value: item?.label,
            key: item?.label,
          })),
        },
      });
    };
    if (!frequencyOption) {
      optionData();
    }
  }, []);
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const assessmentData = api({
        method: "get",
        endPoint: `${apiEndpoint}/assessments`,
        urlencoded: false,
      });
      const signUpFlowData = api({
        method: "get",
        endPoint: `${apiEndpoint}/assessments/required-for-signup`,
        urlencoded: false,
      });
      const lockFlowData = api({
        method: "get",
        endPoint: `${apiEndpoint}/assessments/is-locked`,
        urlencoded: false,
      });
      const frequencyData = api({
        method: "GET",
        endPoint: `${apiEndpoint}/assessments/frequency`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "assessments",
      });
      const forcedReTakeData = api({
        method: "GET",
        endPoint: `${apiEndpoint}/assessments/force-retake`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "assessments",
      });
      // Prmoise.all[assessmentData];
      Promise.all([
        assessmentData,
        signUpFlowData,
        lockFlowData,
        frequencyData,
        forcedReTakeData,
      ]).then((value) => {
        const [
          assessmentData,
          signUpFlowData,
          lockFlowData,
          frequencyData,
          forcedReTakeData,
        ] = value;
        const mandatory_assessments =
          assessmentData.data?.mandatory_assessments.map((el) => {
            return {
              ...el,
              enabled: true,
              nonChangeable: true,
              signUpFlow: true,
            };
          });
        const required_assessments =
          assessmentData.data?.required_assessments.map((el) => {
            return {
              ...el,
              enabled: true,
              required: true,
              signUpFlow: Boolean(
                signUpFlowData?.data?.find((val) => el?.id === val?.id)
              ),
              locked: Boolean(
                lockFlowData?.data?.find((val) => el?.id === val?.id)
              ),
              frequency: frequencyData?.data?.find((val) => el?.id === val?.id)
                ?.frequency,
              showDateField:
                frequencyData?.data?.find((val) => el?.id === val?.id)
                  ?.frequency === "Manual",
              frequencyDate: frequencyData?.data?.find(
                (val) => el?.id === val?.id
              )?.next_retake_at,
              forcedReTakeDate: forcedReTakeData?.data?.find(
                (val) => el?.id === val?.id
              )?.next_retake_at,
            };
          });
        const optional_assessments =
          assessmentData.data?.optional_assessments.map((el) => {
            return {
              ...el,
              enabled: false,
              optional: true,
              locked: Boolean(
                lockFlowData?.data?.find((val) => el?.id === val?.id)
              ),
              frequency: frequencyData?.data?.find((val) => el?.id === val?.id)
                ?.frequency,
              showDateField:
                frequencyData?.data?.find((val) => el?.id === val?.id)
                  ?.frequency === "Manual",
              frequencyDate: frequencyData?.data?.find(
                (val) => el?.id === val?.id
              )?.next_retake_at,
              forcedReTakeDate: forcedReTakeData?.data?.find(
                (val) => el?.id === val?.id
              )?.next_retake_at,
            };
          });
        setAssessments({
          mandatory_assessments: mandatory_assessments || [],
          required_assessments: required_assessments || [],
          optional_assessments: optional_assessments || [],
        });
        setIsLoading(false);
      });
    };
    getData();
  }, []);

  const onUpdateIndex = async ({ updatedItems }) => {
    await api({
      method: "POST",
      endPoint: `${apiEndpoint}/assessments/change-order`,
      data: {
        assessments: updatedItems,
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };

  const handleSetData = (value) => {
    if (value?.oldIndex === value?.newIndex) return;
    const draggedItem = assessments?.required_assessments?.[value?.oldIndex];
    const newItems = [...(assessments?.required_assessments || [])];
    newItems.splice(value?.oldIndex, 1);
    newItems.splice(value?.newIndex, 0, draggedItem);
    const temp = { ...assessments, required_assessments: newItems };
    setAssessments(temp);
    const combinedAssessments = [
      ...temp.mandatory_assessments,
      ...temp.required_assessments,
      ...temp.optional_assessments,
    ];
    const updatedItems = combinedAssessments?.map((item, index) => ({
      assessment_id: item.id,
      index,
    }));
    onUpdateIndex({ updatedItems });
  };

  const onSwitchChange = async (event, item) => {
    let required_assessments = [...(assessments?.required_assessments || [])];
    let optional_assessments = [...(assessments?.optional_assessments || [])];
    const mandatory_assessments = [
      ...(assessments?.mandatory_assessments || []),
    ];
    if (event?.target?.checked) {
      const assessment = {
        ...item,
        required: true,
        enabled: event.target.checked,
      };
      required_assessments = [
        ...(assessments?.required_assessments || []),
        { ...assessment },
      ];
      const index = optional_assessments?.findIndex(
        (el) => el?.id === item?.id
      );
      optional_assessments.splice(index, 1);
    } else {
      const assessment = {
        ...item,
        signUpFlow: false,
        required: false,
        enabled: event.target.checked,
      };
      const index = required_assessments?.findIndex(
        (el) => el?.id === item?.id
      );
      optional_assessments = [
        { ...assessment },
        ...(assessments?.optional_assessments || []),
      ];
      required_assessments.splice(index, 1);
      const updatedStateArray = [
        ...mandatory_assessments,
        ...required_assessments,
      ]?.filter((val) => val?.signUpFlow);

      const arrayToSend = updatedStateArray?.map((val) => val?.id);

      api({
        method: "POST",
        endPoint: `${apiEndpoint}/assessments/required-for-signup`,
        data: {
          required_for_signup: arrayToSend,
        },
        showToastMessage: false,
        urlencoded: false,
      });
    }
    setAssessments({
      required_assessments: required_assessments || [],
      optional_assessments: optional_assessments || [],
      mandatory_assessments: mandatory_assessments || [],
    });
    const updatedStateArray = [
      ...mandatory_assessments,
      ...required_assessments,
    ]?.map((val) => val?.id);
    await api({
      method: "POST",
      endPoint: `${apiEndpoint}/assessments`,
      data: {
        required_assessments: updatedStateArray,
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };

  const onSignUpSwitchChange = async (event, item) => {
    let required_assessments = [...(assessments?.required_assessments || [])];
    const optional_assessments = [...(assessments?.optional_assessments || [])];
    const mandatory_assessments = [
      ...(assessments?.mandatory_assessments || []),
    ];
    if (event?.target?.checked) {
      const updatedAssessment = required_assessments?.map((el) => {
        if (el?.id === item?.id) {
          return {
            ...el,
            signUpFlow: event.target.checked,
          };
        }
        return el;
      });
      required_assessments = updatedAssessment;
    } else {
      const updatedAssessment = required_assessments?.map((el) => {
        if (el?.id === item?.id) {
          return {
            ...el,
            signUpFlow: event.target.checked,
          };
        }
        return el;
      });
      required_assessments = updatedAssessment;
    }
    setAssessments({
      required_assessments: required_assessments || [],
      optional_assessments: optional_assessments || [],
      mandatory_assessments: mandatory_assessments || [],
    });
    const updatedStateArray = [
      ...mandatory_assessments,
      ...required_assessments,
    ]?.filter((val) => val?.signUpFlow);

    const arrayToSend = updatedStateArray?.map((val) => val?.id);
    await api({
      method: "POST",
      endPoint: `${apiEndpoint}/assessments/required-for-signup`,
      data: {
        required_for_signup: arrayToSend,
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };
  const onLockSwitchChange = async (event, item) => {
    let required_assessments = [...(assessments?.required_assessments || [])];
    let optional_assessments = [...(assessments?.optional_assessments || [])];
    const mandatory_assessments = [
      ...(assessments?.mandatory_assessments || []),
    ];
    const updatedAssessment = required_assessments?.map((el) => {
      if (el?.id === item?.id) {
        return {
          ...el,
          locked: event.target.checked,
        };
      }
      return el;
    });
    required_assessments = updatedAssessment;
    const updatedOptionalAssessmentAssessment = optional_assessments?.map(
      (el) => {
        if (el?.id === item?.id) {
          return {
            ...el,
            locked: event.target.checked,
          };
        }
        return el;
      }
    );
    optional_assessments = updatedOptionalAssessmentAssessment;
    setAssessments({
      required_assessments: required_assessments || [],
      optional_assessments: optional_assessments || [],
      mandatory_assessments: mandatory_assessments || [],
    });
    await api({
      method: "POST",
      endPoint: `${apiEndpoint}/assessments/is-locked`,
      data: {
        assessment_id: item?.id,
        is_locked: event?.target?.checked,
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };
  const onForcedRetakeChange = async (item) => {
    let required_assessments = [...(assessments?.required_assessments || [])];
    let optional_assessments = [...(assessments?.optional_assessments || [])];
    const mandatory_assessments = [
      ...(assessments?.mandatory_assessments || []),
    ];
    const updatedAssessment = required_assessments?.map((el) => {
      if (el?.id === item?.id) {
        return {
          ...el,
          forcedReTakeDate: new Date().toISOString(),
        };
      }
      return el;
    });
    required_assessments = updatedAssessment;
    const updatedOptionalAssessmentAssessment = optional_assessments?.map(
      (el) => {
        if (el?.id === item?.id) {
          return {
            ...el,
            forcedReTakeDate: new Date().toISOString(),
          };
        }
        return el;
      }
    );
    optional_assessments = updatedOptionalAssessmentAssessment;
    setAssessments({
      required_assessments: required_assessments || [],
      optional_assessments: optional_assessments || [],
      mandatory_assessments: mandatory_assessments || [],
    });
    await api({
      method: "POST",
      endPoint: `${apiEndpoint}/assessments/force-retake`,
      data: {
        assessment_id: item?.id,
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };

  const onSelectBoxChange = async (event, item) => {
    let required_assessments = [...(assessments?.required_assessments || [])];
    let optional_assessments = [...(assessments?.optional_assessments || [])];
    const mandatory_assessments = [
      ...(assessments?.mandatory_assessments || []),
    ];
    const updatedAssessment = required_assessments?.map((el) => {
      if (el?.id === item?.id) {
        return {
          ...el,
          frequency: event.target.value,
          showDateField: event.target.value === "Manual",
        };
      }
      return el;
    });
    required_assessments = updatedAssessment;
    const updatedOptionalAssessmentAssessment = optional_assessments?.map(
      (el) => {
        if (el?.id === item?.id) {
          return {
            ...el,
            frequency: event.target.value,
            showDateField: event.target.value === "Manual",
          };
        }
        return el;
      }
    );
    optional_assessments = updatedOptionalAssessmentAssessment;
    setAssessments({
      required_assessments: required_assessments || [],
      optional_assessments: optional_assessments || [],
      mandatory_assessments: mandatory_assessments || [],
    });
    if (event.target.value !== "Manual") {
      await api({
        method: "POST",
        endPoint: `${apiEndpoint}/assessments/frequency`,
        data: {
          assessment_id: item?.id,
          frequency: event?.target?.value,
        },
        showToastMessage: true,
        urlencoded: false,
      });
    }
  };

  const onDateChange = async (value, item) => {
    let required_assessments = [...(assessments?.required_assessments || [])];
    let optional_assessments = [...(assessments?.optional_assessments || [])];
    const mandatory_assessments = [
      ...(assessments?.mandatory_assessments || []),
    ];
    const updatedAssessment = required_assessments?.map((el) => {
      if (el?.id === item?.id) {
        return {
          ...el,
          frequencyDate: value,
        };
      }
      return el;
    });
    required_assessments = updatedAssessment;
    const updatedOptionalAssessmentAssessment = optional_assessments?.map(
      (el) => {
        if (el?.id === item?.id) {
          return {
            ...el,
            frequencyDate: value,
          };
        }
        return el;
      }
    );
    optional_assessments = updatedOptionalAssessmentAssessment;
    setAssessments({
      required_assessments: required_assessments || [],
      optional_assessments: optional_assessments || [],
      mandatory_assessments: mandatory_assessments || [],
    });
    await api({
      method: "POST",
      endPoint: `${apiEndpoint}/assessments/frequency`,
      data: {
        assessment_id: item?.id,
        frequency: item?.frequency,
        manual_date: new Date(value)
          .toISOString()
          .replace("T", " ")
          .slice(0, 19),
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          textAlign: "center",
          height: 45,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={30} color="inherit" />
      </Box>
    );
  }

  return (
    <Box className={classes.assessmentTableCards} sx={{ marginBottom: "20px" }}>
      <Stack className={classes.assessmentTableCardsTitle} direction="row">
        <Typography variant="body1">Assessment Name</Typography>
        <Typography variant="body1">Assessment Type</Typography>
        <Typography variant="body1">
          Required
          <VSNToolTip
            placement="top"
            arrow
            title="This assessment should require for all the users. To rearrange the order of assessments, click on the six-dot icon beside each assessment and drag it to the desired position."
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "var(--white)",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "var(--blackGray)",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "var(--white)",
                    },
                  },
                },
              },
            }}
          >
            <span>
              {" "}
              <InfoIcon />
            </span>
          </VSNToolTip>
        </Typography>
        <Typography variant="body1">
          Signup Flow
          <VSNToolTip
            placement="top"
            arrow
            title="This assessment comes while user signup."
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "var(--white)",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "var(--blackGray)",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "var(--white)",
                    },
                  },
                },
              },
            }}
          >
            <span>
              {" "}
              <InfoIcon />
            </span>
          </VSNToolTip>
        </Typography>
        <Typography variant="body1">
          Lock
          <VSNToolTip
            placement="top"
            arrow
            title="User can't see this results until clinician release reports."
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "var(--white)",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "var(--blackGray)",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "var(--white)",
                    },
                  },
                },
              },
            }}
          >
            <span>
              {" "}
              <InfoIcon />
            </span>
          </VSNToolTip>
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "start !important" }}>
          Forced Retake
          <VSNToolTip
            placement="top"
            arrow
            title="Make user forced to retake the assessment."
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "var(--white)",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "var(--blackGray)",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "var(--white)",
                    },
                  },
                },
              },
            }}
          >
            <span>
              {" "}
              <InfoIcon />
            </span>
          </VSNToolTip>
        </Typography>
        <Typography variant="body1" textAlign="center">
          Frequency
          <VSNToolTip
            placement="top"
            arrow
            title=" Set user frequency to re-take assessment."
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "var(--white)",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "var(--blackGray)",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "var(--white)",
                    },
                  },
                },
              },
            }}
          >
            <span>
              {" "}
              <InfoIcon />
            </span>
          </VSNToolTip>
        </Typography>
        <Typography> </Typography>
        {/* <Typography variant="body1"> </Typography> */}
      </Stack>
      <Box className={classes.assessmentList}>
        {assessments?.mandatory_assessments?.map((item, index) => (
          <Item
            key={index}
            item={item}
            classes={classes}
            mandatory
            handleSwitchChange={(event, item) => onSwitchChange(event, item)}
            onSignUpSwitchChange={(event, item) =>
              onSignUpSwitchChange(event, item)
            }
            onLockSwitchChange={(event, item) =>
              onLockSwitchChange(event, item)
            }
            role={role}
            onSelectBoxChange={onSelectBoxChange}
            onDateChange={onDateChange}
          />
        ))}
        <ReactSortable
          filter=".addImageButtonContainer"
          dragClass="sortableDrag"
          list={assessments?.required_assessments || []}
          setList={() => {}}
          onEnd={handleSetData}
          animation="200"
          easing="ease-out"
        >
          {assessments?.required_assessments?.map((item, index) => (
            <Item
              key={index}
              item={item}
              draggable
              classes={classes}
              handleSwitchChange={(event, item) => onSwitchChange(event, item)}
              onSignUpSwitchChange={(event, item) =>
                onSignUpSwitchChange(event, item)
              }
              onLockSwitchChange={(event, item) =>
                onLockSwitchChange(event, item)
              }
              onSelectBoxChange={onSelectBoxChange}
              role={role}
              onDateChange={onDateChange}
              onForcedRetakeChange={onForcedRetakeChange}
            />
          ))}
        </ReactSortable>
        {assessments?.optional_assessments?.map((item, index) => (
          <Item
            key={index}
            item={item}
            classes={classes}
            handleSwitchChange={(event, item) => onSwitchChange(event, item)}
            onSignUpSwitchChange={(event, item) =>
              onSignUpSwitchChange(event, item)
            }
            onLockSwitchChange={(event, item) =>
              onLockSwitchChange(event, item)
            }
            onSelectBoxChange={onSelectBoxChange}
            role={role}
            onDateChange={onDateChange}
            onForcedRetakeChange={onForcedRetakeChange}
          />
        ))}
      </Box>
    </Box>
  );
}
