import { Box, Hidden, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import VSNImage from "../../shared/VSNImage";
import VSNTypography from "../../shared/VSNTypography";
import VSNButton from "../../shared/VSNButton";
import { RightArrow } from "../../shared/icon";
import { VasanaTypeResultStyle } from "./VasanaTypeResultStyle";
import greenFlower from "../../assets/quickView/svg/green-half.svg";
import halfRound from "../../assets/quickView/svg/half-round-card.svg";

const AssessmentsSection = ({
  assessmentsSection,
  downloadAllReports,
  reportLoaders,
  handleAssessmentClick,
  toggleReport,
}) => {
  const classes = VasanaTypeResultStyle();
  const navigate = useNavigate();

  const downloadButton = (value) => {
    return (
      <VSNButton
        className={classes.downloadButton}
        sx={{
          "&.MuiButtonBase-root": {
            padding: "10px",
            minHeight: "auto",
            fontWeight: 500,
            height: "fit-content",
            lineHeight: "18px",
            borderRadius: 1.5,
            minWidth: { xs: "auto", sm: 200 },
            width: { xs: "fit-content" },
            "& .content": { fontSize: { sm: 16, xs: 14 } },
          },
        }}
        isLoading={reportLoaders?.[value?.assessment_id] && value?.pdf_exists}
        handleClick={() => downloadAllReports(value)}
        disabled={reportLoaders?.[value?.assessment_id] && value?.pdf_exists}
      >
        Download Full Report
      </VSNButton>
    );
  };

  return (
    <>
      {assessmentsSection?.length ? (
        <Box mb={{ md: 2, xs: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            mb={1}
            justifyContent="space-between"
          >
            <VSNTypography
              variant="h5"
              component="h5"
              className={classes.lgHeading}
            >
              Assessments
            </VSNTypography>

            <VSNButton
              endIcon={<RightArrow />}
              disableRipple
              className={classes.textBtn}
              sx={{
                display: { sm: "none", xs: "flex" },
                justifyContent: "end",
              }}
              handleClick={() => navigate("/assessments")}
            >
              All Our Assessments
            </VSNButton>
          </Stack>
          <Box className={classes.naturalViewSlider}>
            {assessmentsSection?.map((value, index) => {
              return value?.assessment_taken === "True" ? (
                <Box
                  className={classes.quickAssessmentCard}
                  sx={{
                    marginBottom: "15px",
                  }}
                  key={index}
                >
                  <VSNImage
                    src={greenFlower}
                    alt="greenFlower"
                    className={classes.greenFlower}
                  />
                  <VSNImage
                    src={halfRound}
                    alt="halfRound"
                    className={classes.halfRound}
                  />
                  <Stack
                    direction={{ sm: "row", xs: "column" }}
                    justifyContent="space-between"
                    flexWrap={{ xl: "nowrap", xs: "wrap" }}
                    rowGap={0.6}
                    columnGap={0.6}
                    mb={{ xl: 0, xs: 0.7 }}
                  >
                    <Box>
                      <VSNTypography
                        variant="h3"
                        component="h3"
                        className={classes.mdHeading}
                        color="var(--blackGray)"
                      >
                        {value?.name}
                      </VSNTypography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: value?.result,
                        }}
                      />
                    </Box>
                    <Hidden smDown>
                      {value?.pdf_exists === "True"
                        ? downloadButton(value)
                        : null}
                    </Hidden>
                  </Stack>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: value?.trait_description,
                    }}
                  />
                  {value?.pdf_exists === "True" ? (
                    <Stack
                      direction="row"
                      justifyContent={{ sm: "start", xs: "space-between" }}
                      sx={{ marginTop: { sm: 0, xs: 1 } }}
                      flexWrap="wrap"
                      columnGap={0.6}
                      rowGap={0.8}
                    >
                      <VSNButton
                        endIcon={<RightArrow />}
                        disableRipple
                        className={classes.textBtn}
                        sx={{
                          marginTop: 1.4,
                          justifyContent: "start",
                          width: { xs: "fit-content" },
                        }}
                        handleClick={() =>
                          toggleReport({
                            assessmentId: value?.assessment_id,
                          })
                        }
                      >
                        View More
                      </VSNButton>
                      <Hidden smUp>{downloadButton(value)}</Hidden>
                    </Stack>
                  ) : null}
                </Box>
              ) : (
                <Box
                  className={`${classes.quickAssessmentCard} ${classes.quickSimpleCard}`}
                >
                  <Stack
                    direction={{ sm: "row", xs: "column" }}
                    justifyContent="space-between"
                    gap={0.6}
                    flexWrap="wrap"
                    mb={0.5}
                  >
                    <VSNTypography
                      variant="h3"
                      component="h3"
                      className={classes.mdHeading}
                      color="var(--blackGray)"
                    >
                      {value?.name}
                    </VSNTypography>
                    <VSNButton
                      variant="outlined"
                      className={classes.downloadBtn}
                      sx={{ margin: { sm: 0, xs: "8px 0" } }}
                      handleClick={() =>
                        handleAssessmentClick({
                          assessmentId: value?.assessment_id,
                          type: value?.type || "Slider",
                        })
                      }
                    >
                      Take Assessment
                    </VSNButton>
                  </Stack>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: value?.trait_description,
                    }}
                  />
                </Box>
              );
            })}
            <VSNButton
              endIcon={<RightArrow />}
              disableRipple
              className={classes.textBtn}
              sx={{
                marginTop: 1.4,
                display: { sm: "inline-flex", xs: "none" },
              }}
              handleClick={() => navigate("/assessments")}
            >
              All Our Assessments
            </VSNButton>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default AssessmentsSection;
