import React from "react";
import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import { TeamDashboardStyle } from "./TeamDashboardStyle";
import VSNTypography from "../../../shared/VSNTypography";
import VSNButton from "../../../shared/VSNButton";
import { RightArrow } from "../../../shared/icon";
import VSNSelect from "../../../shared/VSNSelect";
import VSNMembersAvatar from "../../../shared/VSNMembersAvatar";
import VSNImage from "../../../shared/VSNImage";
import { renderTypesWithImage } from "../../../shared/CustomFunc";
import crown from "../../../assets/organizationAdmin/svg/crown.svg";
import { getAvatarName, upperCase } from "../../../utils/javascript";
import { organization_location_admin } from "../../../utils/constant";

export default function TopMembers({
  teamProfile,
  onSeeAllMembers,
  isParticipantsOpen,
  loader,
  sortBy,
  onFilterChange,
  topMembers,
  currentSelectedTeam,
  handleAddMembers,
  handleExitTeam,
}) {
  const { role, organization_type_id, permissions } =
    useSelector((state) => state?.app?.userData?.user_profile) ?? {};
  const manageTeams = !(
    organization_type_id === 1 && permissions?.teams?.includes("read_only")
  );

  const classes = TeamDashboardStyle();
  return (
    <Stack
      className={`${classes.teamViewCard} imagePart`}
      minHeight={333}
      height="100%"
    >
      <Stack
        direction="row"
        rowGap={1}
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <VSNTypography variant="h3" className={classes.mdHeading} mr={1}>
          Team Members ({teamProfile?.no_of_participants || 0})
        </VSNTypography>
        <Stack direction="row">
          <VSNButton
            className={`${classes.btnOutline} remove`}
            sx={{ mr: 2 }}
            handleClick={() => onSeeAllMembers("currentSelectedTeamMembers")}
            isLoading={
              !isParticipantsOpen && loader?.currentSelectedTeamMembers
            }
          >
            <span>See All Members </span>
            <RightArrow />
          </VSNButton>
          {manageTeams && (
            <VSNButton
              className={`${classes.btnOutline} exitButton`}
              sx={{ mr: 2 }}
              handleClick={() => handleExitTeam("exitTeam")}
            >
              Exit team
            </VSNButton>
          )}
          <div className={classes.sortFilter}>
            <VSNSelect
              {...{
                ...sortBy,
                onChange: onFilterChange,
                classes: { popover: classes.filterDropDown },
              }}
            />
          </div>
        </Stack>
      </Stack>
      {loader?.topMembers ? (
        <>
          <Stack alignItems="center" justifyContent="center" height="100%">
            <CircularProgress size={24} color="inherit" />
          </Stack>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableBody className={classes.memberList}>
                {topMembers?.data?.map((participant, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Stack direction="row" alignItems="center">
                        <Box
                          className={`${classes.avtarOut} ${
                            currentSelectedTeam?.admins?.filter(
                              (admin) => admin?.user_id === participant?.user_id
                            )?.length > 0
                              ? classes.admin
                              : " "
                          }`}
                        >
                          <VSNMembersAvatar
                            alt={participant?.first_name || ""}
                            src="/static/images/avatar/1.jpg"
                            vasanaType={participant?.primary_marker}
                            title={getAvatarName(participant)}
                          />
                        </Box>
                        <Box ml={1.25} sx={{ maxWidth: 200, width: "100%" }}>
                          <VSNTypography
                            variant="h4"
                            className={classes.smHeading}
                          >
                            {participant?.first_name} {participant?.last_name}
                          </VSNTypography>
                          <VSNTypography className={classes.desc}>
                            {participant.email}
                          </VSNTypography>
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack
                        direction="row"
                        justifyContent="end"
                        spacing={3.5}
                        className={classes.imageBox}
                      >
                        <Box>
                          {renderTypesWithImage({
                            type: participant.primary_marker,
                          })}
                        </Box>
                        <Box>
                          {renderTypesWithImage({
                            type: participant.secondary_marker,
                            isBlackImage: true,
                          })}
                        </Box>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Stack mt="auto" gap={2}>
        <Stack direction="row">
          <VSNImage src={crown} alt="crown" height={16} />
          <VSNTypography sx={{ fontSize: 12 }}>
            The Crown Icon indicates that the user holds the role of Team Admin.
          </VSNTypography>
        </Stack>
        {topMembers?.data?.length <= 1 && (
          <VSNButton className={classes.optionInfo}>
            Currently, only you are a member of this team. To get the most out
            of Vasana, please{" "}
            <VSNTypography
              variant="span"
              component="span"
              sx={{
                color: "var(--Tertiary)",
                cursor: "pointer",
                pointerEvents: "auto",
              }}
              onClick={handleAddMembers}
            >
              add team members.
            </VSNTypography>
          </VSNButton>
        )}
        <VSNButton
          className={`${classes.btnOutline} add`}
          display={{ md: "none", xs: "block" }}
          handleClick={() => onSeeAllMembers("currentSelectedTeamMembers")}
          isLoading={!isParticipantsOpen && loader?.currentSelectedTeamMembers}
        >
          <span>See All Members </span>
          <RightArrow />
        </VSNButton>
      </Stack>
    </Stack>
  );
}
