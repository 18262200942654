import { makeStyles } from "@mui/styles";
import BgPattern from "../../assets/common/png/bgShapes.png";
import BgExtrovert from "../../assets/introExtro/png/bg-extrovert.png";
import BgTop from "../../assets/common/png/bg-responsive-top.png";

export const IntroExtroResultStyle = makeStyles((theme) => ({
  shape: {
    position: "absolute",
    lineHeight: 0,
  },
  greenFlower: {
    position: "absolute",
    top: 20,
    right: "40%",
  },
  halfRound: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  bgResponsiveExtrovert: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "absolute",
      top: 0,
      left: -15,
      width: "100%",
      transform: "rotate(90deg)",
    },
  },
  downloadBtn: {
    "&.MuiButton-root": {
      padding: 10,
      lineHeight: "14px",
      height: "max-content",
      width: "max-content",
      "& .content": {
        fontWeight: 500,
        fontSize: 14,
        whiteSpace: "nowrap",
        [theme.breakpoints.down("sm")]: {
          whiteSpace: "normal",
        },
      },
    },
  },
  textBtn: {
    "&.MuiButton-root": {
      padding: 0,
      lineHeight: "16px",
      height: "max-content",
      width: "max-content",
      background: "transparent",
      color: "var(--primary)",
      "& .MuiButton-endIcon": {
        marginRight: 0,
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 16,
        [theme.breakpoints.down("lg")]: {
          fontSize: 14,
        },
      },
      "&:hover": {
        background: "transparent",
      },
    },
  },
  quickAssessmentCard: {
    padding: 15,
    background: "linear-gradient(180deg, #FEFEFE 17.02%, #E6F1FA 124.07%)",
    marginBottom: 27,
    borderRadius: 5,
    border: "1px solid #EAEDF0",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      borderRadius: 8,
      padding: 10,
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: 8,
      padding: 15,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 8,
      padding: 10,
    },
  },
  quickSimpleCard: {
    background: "var(--white)",
  },
  bg1: {
    display: "none",
  },
  bg2: {
    top: "28%",
    right: "32%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bg3: {
    top: "-1%",
    left: "0%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bg4: {
    top: "-4%",
    right: "0%",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bg5: {
    bottom: "14%",
    right: "0%",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& svg": {
      width: 150,
      height: 150,
    },
  },
  bgRight: {
    position: "absolute",
    right: 0,
    top: ({ result }) => (result === "Extrovert" ? "unset" : "0"),
    bottom: 0,
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bgBottom: {
    display: "none",
    position: "absolute",
    right: 0,
    bottom: -24,
    zIndex: 0,
    width: "100%",
    transform: ({ result }) =>
      result === "Introvert" ? "rotate(0deg)" : "rotate(-90deg)",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      lineHeight: "200%",
      fontWeight: 700,
      [theme.breakpoints.down("xl")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "120%",
      },
    },
  },
  vasanatype: {
    marginBottom: 6,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
    "& h4": {
      display: "inline-block",
      marginRight: 0,
      flex: 1,
      textAlign: "start",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
  },
  lgHeading: {
    "&.MuiTypography-root": {
      fontSize: 26,
      fontWeight: "700",
      color: "#4F4F4F",
      lineHeight: "120%",
      marginBottom: 12,
      [theme.breakpoints.down("lg")]: {
        fontSize: 24,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 22,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        marginBottom: 16,
      },
    },
  },
  naturalViewSlider: {
    padding: 16,
    boxShadow:
      "0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
    backgroundColor: "var(--white)",
    borderRadius: 10,
    "& .swiper": {
      "& .swiper-slide": {
        height: "auto",
      },
      "& .swiper-pagination": {
        bottom: 0,
        "& .swiper-pagination-bullet": {
          background: "#FBCEBD",
          opacity: 3,
        },
        "& .swiper-pagination-bullet-active": {
          background: "var(--primary)",
        },
      },
    },
  },
  btnWrapper: {
    "& .MuiButtonBase-root": {
      [theme.breakpoints.down("lg")]: {
        padding: "6px 14px",
      },
    },
  },
  DownBtnWrapper: {
    "& .MuiButtonBase-root": {
      maxWidth: "max-content",
      padding: "8px 12px",
      height: "fit-content",
      "& .content": {
        fontWeight: 500,
        fontSize: 16,
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        width: "100%",
      },
    },
  },

  // Old Css

  mainLayout: {
    // overflow: "hidden",
    padding: [[0, 30, 35, 30]],
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: [[10, 16, 42, 16]],
    },
  },
  firtBox: {
    background: `linear-gradient(93deg, #F9F3FB 6.39%, #F6F6F6 59.95%, #F2F9F4 99.31%)`,
    padding: 25,
    textAlign: "left",
    position: "relative",
    boxShadow: "none",
    [theme.breakpoints.down("lg")]: {
      padding: 20,
    },
    [theme.breakpoints.down("md")]: {
      padding: 15,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
    "& h4": {
      color: "#3A3A40",
      lineHeight: "30px",
      fontSize: 34,
      fontWeight: 700,
      marginRight: 10,
      [theme.breakpoints.down("lg")]: {
        fontSize: 28,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
    },
    "& h6": {
      fontSize: 16,
      lineHeight: "20px",
      color: "var(--blackGray)",
      marginBottom: 18,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
        lineHeight: "18px",
        marginBottom: 13,
      },
    },
  },
  introExtroBox: {
    boxShadow:
      "0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
    borderRadius: 10,
    background: "var(--white)",
    padding: 20,
    textAlign: "left",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      padding: 15,
    },
  },

  // New Css

  firstBoxBg: {
    position: "absolute",
    top: 0,
    left: 0,
    transform: `translate(-36%, -50%) rotate(-40deg)`,
  },
  people1: {
    marginBottom: -25,
    maxWidth: 218,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xl")]: {
      maxWidth: 175,
      marginBottom: 0,
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: 150,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      margin: [[22, 0]],
    },
    "& svg": {
      verticalAlign: "bottom",
    },
  },
  shape1: {
    position: "absolute",
    top: 5,
    left: "57%",
  },
  shape2: {
    position: "absolute",
    bottom: 15,
    left: "67%",
  },
  shape3: {
    position: "absolute",
    bottom: 50,
    right: 25,
  },
  shape4: {
    position: "absolute",
    top: "38%",
    left: "24%",
  },
  introList: {
    "&.MuiList-root": {
      padding: 0,
      "& .MuiListItem-root": {
        alignItems: "start",
        padding: [[2.5, 5]],
        "& .MuiListItemIcon-root": {
          minWidth: "unset",
          paddingRight: 10,
          marginTop: 4,
        },
        "& .MuiListItemText-root": {
          margin: 0,
          color: "var(--gray)",
          wordBreak: "break-word",
          "& .MuiTypography-root": {
            [theme.breakpoints.down("md")]: {
              fontSize: 14,
            },
          },
        },
      },
    },
  },
  listHead: {
    "&.MuiTypography-root": {
      color: "#4f4f4f",
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "normal",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  communicationBox: {
    padding: [[12, 30]],
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      padding: [[12, 20]],
      height: "unset",
    },
    [theme.breakpoints.down("md")]: {
      padding: 12,
    },
  },
  mythsBox: {
    padding: [[18, 20]],
  },
  respIntroListBox: {
    "&.MuiList-root": {
      background: "var(--white)",
      border: "1px solid #EAEDF0",
      padding: 15,
      borderRadius: 10,
      [theme.breakpoints.down("sm")]: {
        padding: 10,
      },
    },
  },
  listHeadMain: {
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        height: 16,
      },
    },
  },
  people2Wrapper: {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  people3Wrapper: {
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  respLgHead: {
    "&.MuiTypography-root": {
      [theme.breakpoints.down("md")]: {
        marginBottom: 22,
      },
    },
  },
  stickOne: {
    height: 98,
    [theme.breakpoints.down("md")]: {
      // maxWidth: 320,
      width: "100%",
      height: 2,
      flex: "auto",
    },
  },
  stickTwo: {
    height: 247,
    [theme.breakpoints.down("md")]: {
      height: 2,
      width: 80,
    },
    [theme.breakpoints.down("sm")]: {
      width: 22,
    },
  },
  stickThree: {
    height: 56,
    [theme.breakpoints.down("md")]: {
      height: 2,
      maxWidth: 400,
      width: "100%",
      flex: "1",
    },
  },
  stickFour: {
    height: 204,
    [theme.breakpoints.down("md")]: {
      height: 2,
      width: 100,
    },
    [theme.breakpoints.down("sm")]: {
      width: 22,
    },
  },
  roundShape: {
    width: 12,
    height: 12,
    borderRadius: "100%",
    backgroundColor: "#555FDD26",
    marginBottom: 10,
    flex: "none",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  stick: {
    width: 2,
    marginBottom: 8,
    borderRadius: 4,
    flex: "none",
    background: "#555FDD26",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  positionPersonality: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      bottom: -20,
      background:
        "radial-gradient( rgba(85, 95, 221, 0.00) 0%, rgba(85, 95, 221, 0.15) 48.44%, rgba(85, 95, 221, 0.00) 100%)",
      height: 2,
      display: "none",
      margin: "0 25%",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      "&:before": {
        display: "block",
      },
    },
    "& img": {
      marginBottom: 8,
      display: "flex",
      margin: "0 auto",
    },
    "& p": {
      color: "#4f4f4f",
      fontSize: 14,
      lineHeight: "18px",
      textAlign: "center",
    },
    "& h6": {
      color: "var(--Tertiary)",
      fontSize: 18,
      lineHeight: "23px",
      fontWeight: 700,
      textAlign: "center",
      [theme.breakpoints.between("md", "lg")]: {
        fontSize: 14,
      },
    },
    "&:nth-child(even)": {
      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-end",
      },
      "& .imgOut": {
        [theme.breakpoints.down("md")]: {
          flexDirection: "row-reverse",
        },
      },
    },
    "& .imgOut": {
      flex: "0 0 auto",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        alignItems: "center",
        gap: 15,
      },
    },
    "&:last-child": {
      "&:before": {
        display: "none",
      },
    },
  },

  diffrentPersonalityBox: {
    padding: [[0, 24, 24, 24]],
    border: 0,
    background: ({ result }) =>
      result === "Extrovert"
        ? `url(${BgExtrovert}), 
           linear-gradient(270deg, #F4F6FF 12.89%, #FDF9FF 92.53%);`
        : `url(${BgPattern}), linear-gradient(93deg, #F9F3FB 6.39%, #F6F6F6 59.95%, #F2F9F4 99.31%);`,
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: ({ result }) =>
      result === "Extrovert" ? "bottom left !important" : "top left",
    boxShadow:
      "0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      padding: [[24, 24, 24, 0]],
      background: ({ result }) =>
        result === "Extrovert"
          ? "linear-gradient(270deg, #F4F6FF 12.89%, #FDF9FF 92.53%);"
          : `url(${BgTop}), linear-gradient(93deg, #F9F3FB 6.39%, #F2F9F4 99.31%);`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: ({ result }) =>
        result === "Extrovert" ? "top left !important" : "top left",
      backgroundSize: "100%",
    },
  },
  mythsDesc: {
    "&.MuiTypography-root": {
      overflow: "hidden",
      "-webkit-line-clamp": 4,
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis",
      display: "-webkit-box",
    },
  },
  vasanaTypeDesc: {
    "&.MuiTypography-root": {
      overflow: "hidden",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis",
      display: "-webkit-box",
    },
  },
  flower: {
    position: "absolute",
    left: "20%",
    bottom: "12%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  snake: {
    position: "absolute",
    right: "16.5%",
    top: "7%",
    [theme.breakpoints.down("md")]: {
      top: "82%",
    },
  },
  rainbowPurple: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%)",
    top: 0,
    [theme.breakpoints.down("md")]: {
      top: "40%",
      left: "unset",
      right: -20,
      transform: "rotate(90deg)",
    },
  },
  zigzag: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
      position: "absolute",
      top: "55%",
      left: "10%",
      transform: "translateY(-50%)",
    },
  },
  downloadButton: {
    "&.MuiButtonBase-root": {
      padding: [[12, 16]],
      borderRadius: 10,
      minWidth: 200,
      [theme.breakpoints.down("lg")]: {
        fontSize: 16,
        padding: [[10, 16]],
      },
    },
  },
  headingButton: {
    display: "flex",
    justifyContent: "space-between",
    gap: 14,
    alignItems: "center",
    marginBottom: 20,
    [theme.breakpoints.down("600")]: {
      alignItems: "end",
      "& .MuiButtonBase-root": {
        marginBottom: 10,
      },
    },
    [theme.breakpoints.down("450")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      "& .MuiBox-root": {
        width: "100%",
      },
    },
  },
}));
