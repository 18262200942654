import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";

const data = [
  [
    {
      type: "header",
      colSpan: 1,
      text: "Metrics",
    },
    {
      type: "header",
      colSpan: 1,
      text: "Pred. Max",
    },
    {
      type: "header",
      colSpan: 1,
      text: "Rest",
    },
    {
      type: "header",
      colSpan: 1,
      text: "AT",
    },
    {
      type: "header",
      colSpan: 1,
      text: "AT2",
    },
    {
      type: "header",
      colSpan: 1,
      text: "VO2 Max",
    },
    {
      type: "header",
      colSpan: 1,
      text: "WorkMax",
    },
    {
      type: "header",
      colSpan: 1,
      text: "%/Pred",
    },
  ],
  [
    {
      text: "RER",
    },
    {
      text: ">1.2",
    },
    {
      text: "0.81",
    },
    {
      text: "0.88",
    },
    {
      text: "1.06",
    },
    {
      text: "1.29",
    },
    {
      text: "1.28",
    },
    {
      text: "-",
    },
  ],
  [
    {
      text: "Works (Watts)",
    },
    {
      text: "283",
    },
    {
      text: " ",
    },
    {
      text: 159,
    },
    {
      text: 271,
    },
    {
      text: 319,
    },
    {
      text: 320,
    },
    {
      text: 113,
    },
  ],
  [
    {
      text: "METS",
    },
    {
      text: 12.4,
    },
    {
      text: 1.8,
    },
    {
      text: 7.6,
    },
    {
      text: 11.4,
    },
    {
      text: 13.7,
    },
    {
      text: 13.4,
    },
    {
      text: 111,
    },
  ],
  [
    {
      type: "header",
      colSpan: 8,
      text: "Aerobic Capacity",
      sx: { textAlign: "center" },
    },
  ],
  [
    {
      text: "VO2 L/min",
    },
    {
      text: "3.48",
    },
    {
      text: "0.50",
    },
    {
      text: 2.15,
    },
    {
      text: 3.2,
    },
    {
      text: 3.86,
    },
    {
      text: 3.76,
    },
    {
      text: 111,
    },
  ],
  [
    {
      text: "VO2 mL/kg/min",
    },
    {
      text: 43.3,
    },
    {
      text: 6.2,
    },
    {
      text: 26.7,
    },
    {
      text: 39.9,
    },
    {
      text: 48.1,
    },
    {
      text: 46.9,
    },
    {
      text: 111,
    },
  ],
  [
    {
      text: "VO2 ml/kg Lean Mass",
    },
    {
      text: "   ",
    },
    {
      text: 7.4,
    },
    {
      text: 31.6,
    },
    {
      text: 47.2,
    },
    {
      text: 56.9,
    },
    {
      text: 55.4,
    },
    {
      text: "-",
    },
  ],
  [
    {
      type: "header",
      colSpan: 8,
      text: "Cardiovascular",
      sx: { textAlign: "center" },
    },
  ],
  [
    {
      text: "HR (BPM)",
    },
    {
      text: 200,
    },
    {
      text: 66,
    },
    {
      text: 125,
    },
    {
      text: 166,
    },
    {
      text: 183,
    },
    {
      text: 183,
    },
    {
      text: 92,
    },
  ],
  [
    {
      text: "O2 - Pulse (ml/beat)",
    },
    {
      text: 17.4,
    },
    {
      text: 7.6,
    },
    {
      text: 17.2,
    },
    {
      text: 19.3,
    },
    {
      text: 21.1,
    },
    {
      text: 20.6,
    },
    {
      text: 121,
    },
  ],
  [
    {
      text: "Systolic",
    },
    {
      text: "<220",
    },
    {
      text: 118,
    },
    {
      text: 130,
    },
    {
      text: 130,
    },
    {
      text: 162,
    },
    {
      text: 162,
    },
    {
      text: "-",
    },
  ],
  [
    {
      text: "Diastolic",
    },
    {
      text: "<90",
    },
    {
      text: 64,
    },
    {
      text: 64,
    },
    {
      text: 64,
    },
    {
      text: 70,
    },
    {
      text: 70,
    },
    {
      text: "-",
    },
  ],
  [
    {
      text: "Cardiac Power",
    },
    {
      text: ">7000",
    },
    {
      text: 731,
    },
    {
      text: 3471,
    },
    {
      text: 5187,
    },
    {
      text: 7792,
    },
    {
      text: 7792,
    },
    {
      text: "-",
    },
  ],
  [
    {
      type: "header",
      colSpan: 8,
      text: "Pulmonary",
      sx: { textAlign: "center" },
    },
  ],
  [
    {
      text: "BR%",
    },
    {
      text: "15-30%",
    },
    {
      text: 92.2,
    },
    {
      text: 75.3,
    },
    {
      text: 57.5,
    },
    {
      text: 20.6,
    },
    {
      text: 24.3,
    },
    {
      text: "-",
    },
  ],
  [
    {
      text: "VtFEV1 %",
    },
    {
      text: "80%",
    },
    {
      text: 28,
    },
    {
      text: 45,
    },
    {
      text: 61,
    },
    {
      text: 71,
    },
    {
      text: 70,
    },
    {
      text: "-",
    },
  ],
  [
    {
      text: "VE_BTPS L/min",
    },
    {
      text: "209.0",
    },
    {
      text: 162.2,
    },
    {
      text: 51.5,
    },
    {
      text: 88.7,
    },
    {
      text: 165.8,
    },
    {
      text: 158.8,
    },
    {
      text: 79,
    },
  ],
];

const SwitchTableCell = ({ type, colSpan = 1, text, sx = {} }) => {
  switch (type) {
    case "header":
      return (
        <TableCell
          sx={{
            fontWeight: 700,
            backgroundColor: "#EAEDF0",
            color: "#3A3A40",
            ...sx,
            padding: "8px",
          }}
          colSpan={colSpan}
        >
          {text}
        </TableCell>
      );
    default:
      return (
        <TableCell
          sx={{
            fontWeight: 400,
            backgroundColor: "#FFFFFF",
            color: "#3A3A40",
            padding: "8px",
            ...sx,
          }}
          colSpan={colSpan}
        >
          {text}
        </TableCell>
      );
  }
};

export const DynamicTable = ({ dataOfTable = data }) => {
  return (
    <>
      <TableContainer component={Paper} sx={{ backgroundColor: "#FFFFFF" }}>
        <Table sx={{ borderCollapse: "collapse" }}>
          {dataOfTable.map((tableRow) => (
            <TableRow>
              {tableRow.map((tableCell) => (
                <SwitchTableCell {...tableCell} />
              ))}
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </>
  );
};

export default DynamicTable;
