import { FormLabel } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import VSNTypography from "./VSNTypography";

const StyledLabel = styled(FormLabel)((theme) => ({
  "&.MuiFormLabel-root": {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "18px",
    color: "var(--gray)",
    marginBottom: 4,
    display: "inline-block",
    "& span": {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "21px",
      color: "#FF5656",
      marginLeft: 2,
    },
  },
}));

const VSNLabel = ({ label, required, ...rest }) => {
  return (
    <StyledLabel {...rest}>
      {label}
      {required ? <VSNTypography component="span">*</VSNTypography> : null}
    </StyledLabel>
  );
};

export default VSNLabel;
