import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import anchorContainer from "../../container/anchor.container";
import {
  DeleteIcon,
  EditOutlinedIcon,
  ExitIcon,
  ShareLinkIcon,
} from "../../shared/icon";

const useStyles = makeStyles((theme) => ({
  MenuWrapper: {
    "&.MuiButtonBase-root": {
      position: "absolute",
      top: 12,
      right: 12,
      backgroundColor: "#4244504D",
      borderRadius: 5,
      backdropFilter: "blur(4px)",
      "& .MuiSvgIcon-root": {
        color: "var(--white)",
      },
    },
  },
  ListMenuWrapper: {
    "& .MuiSvgIcon-root": {
      [theme.breakpoints.down("md")]: {
        width: 14,
      },
    },
  },
  popupIcon: {
    height: 24,
    width: 24,
    borderRadius: "100%",
    backgroundColor: "var(--white)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteIcon: {
    "& svg": {
      height: 14,
    },
  },
}));

const TeamActions = ({
  id,
  handleExitTeam,
  handleShareLink,
  isListView,
  canEditTeam,
  handleEditTeam,
  canExitTeam,
  canDeleteTeam,
  handleDeleteTeam,
  admins,
  moduleName,
}) => {
  const { open, anchorEl, setAnchorEl, handleClose } = anchorContainer();
  const classes = useStyles();

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        size="small"
        sx={
          isListView
            ? {
                "&.MuiButtonBase-root": {
                  position: "relative",
                  top: "unset",
                  right: "unset",
                  padding: { md: "0" },
                  background: { md: "none", xs: "#7477871A" },
                  borderRadius: 1,
                  color: { md: "var(--blackGray)", xs: "var(--gray)" },
                  transform: {
                    md: "rotate(0)",
                    xs: "rotate(-90deg)",
                  },
                  height: { md: "fit-content", xs: "24px" },
                  width: { md: "fit-content", xs: "24px" },
                },
              }
            : { ml: 2 }
        }
        aria-controls={open ? `menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={isListView ? classes.ListMenuWrapper : classes.MenuWrapper}
      >
        {isListView ? <MoreVertIcon /> : <MoreHorizIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          handleClose(e);
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleClose(e);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.10)",
            background: "var(--white)",
            backdropFilter: "blur(3px)",
            borderRadius: "8px",
            padding: "5px",
            "& .MuiList-root ": {
              padding: 0,
              "& .MuiMenuItem-root": {
                padding: "8px",
                color: "var(--blackGray)",
                "& span": {
                  marginLeft: "8px",
                  fontSize: "14px",
                },
              },
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {canEditTeam && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleEditTeam();
              handleClose();
            }}
          >
            <Box className={classes.popupIcon}>
              <EditOutlinedIcon />
            </Box>
            <span>{`Edit ${moduleName ?? ""}`}</span>
          </MenuItem>
        )}
        {canExitTeam && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
              return handleExitTeam(id, admins);
            }}
          >
            <Box className={classes.popupIcon}>
              <ExitIcon />
            </Box>
            <span>{`Exit ${moduleName ?? ""}`}</span>
          </MenuItem>
        )}
        {canDeleteTeam && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
              handleDeleteTeam(id);
            }}
          >
            <Box className={`${classes.popupIcon} ${classes.deleteIcon}`}>
              <DeleteIcon />
            </Box>
            <span>{`Delete ${moduleName ?? ""}`}</span>
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
            return handleShareLink(e);
          }}
        >
          <Box className={classes.popupIcon}>
            <ShareLinkIcon />
          </Box>
          <span>Share Link</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TeamActions;
