import { Box, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import shape1 from "../../../assets/admin/png/model-shape-1.png";
import shape2 from "../../../assets/admin/png/model-shape-2.png";
import { formContainer } from "../../../container/form.container";
import VSNButton from "../../../shared/VSNButton";
import VSNErrorMessage from "../../../shared/VSNErrorMessage";
import VSNImage from "../../../shared/VSNImage";
import VSNLabel from "../../../shared/VSNLabel";
import VSNTypography from "../../../shared/VSNTypography";
import ControlForm from "../../../shared/form/ControlForm";
import { equal, upperCase } from "../../../utils/javascript";
import { DepartmentModalIcon } from "../../../shared/icon";

const useStyles = makeStyles((theme) => ({
  heading: {
    position: "sticky",
    top: 0,
    borderBottom: "1px solid var(--primary)",
    padding: "12px 25px",
    margin: "0 -25px",
    backgroundColor: "var(--white)",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 15px",
      margin: "0 -15px",
    },
    "&:after": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      height: 25,
      width: "100%",
      bottom: "100%",
      background: "var(--white)",
      zIndex: 8,
    },
    "& svg": {
      borderRadius: "50%",
      [theme.breakpoints.down("md")]: {
        width: 40,
        height: 40,
      },
      [theme.breakpoints.down("sm")]: {
        width: 30,
        height: 30,
      },
    },
    "& h5": {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "31px",
      marginLeft: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        lineHeight: "26px",
        maxWidth: "70%",
      },
    },
  },
  detailHeading: {
    "&.MuiTypography-root": {
      color: "rgba(134, 101, 10, 1)",
      fontSize: 14,
      fontWeight: 700,
      marginBottom: 24,
      [theme.breakpoints.down("md")]: {
        marginBottom: 20,
      },
    },
  },
  cancelButton: {
    "&.MuiButtonBase-root": {
      padding: [[12, 62]],
      [theme.breakpoints.down("md")]: {
        padding: [[8, 15]],
      },
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#F6F6FD",
    padding: "20px 25px",
    gap: 10,
    marginLeft: "-25px",
    marginRight: "-25px",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "15px",
      gap: 10,
      marginLeft: "-15px",
      marginRight: "-15px",
    },
    "& .MuiButtonBase-root": {
      margin: 0,
      padding: "10px 25px",
      lineHeight: "21px",
      width: "auto",
      borderRadius: 10,
      "& span": {
        fontSize: 16,
        fontWeight: 400,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  createLinkButton: {
    "&.MuiButtonBase-root": {
      color: "var(--white)",
    },
  },
  shape: {
    position: "absolute",
    zIndex: -2,
  },
  shape1: {
    top: "20%",
    right: "10%",
  },
  shape2: {
    bottom: "5%",
    left: "0%",
  },
  formGrid: {
    "& .react-tel-input": {
      "& .form-control": {
        fontSize: 18,
        fontWeight: 700,
        color: "#202024",
      },
    },
  },
}));

const CreateDepartmentContainer = ({
  handleMobileChange,
  handleCreateDepartment,
  mode,
  handleChange,
  formPath,
  departmentInfo,
  toggleModelShow,
  createDepartmentLoader,
  departmentToEdit,
  createDepartmentAttribute,
  handleChangePhoto,
}) => {
  const classes = useStyles();
  const { formData, formErrors } = formContainer({ formPath });
  return (
    <form onSubmit={handleCreateDepartment}>
      <Box>
        <VSNImage
          src={shape1}
          alt="shape"
          className={`${classes.shape} ${classes.shape1}`}
        />
        <VSNImage
          src={shape2}
          alt="shape"
          className={`${classes.shape} ${classes.shape2}`}
        />
        <Stack direction="row" alignItems="center" className={classes.heading}>
          <Stack>
            <DepartmentModalIcon />
          </Stack>
          <VSNTypography variant="h5" component="h5">
            {mode === "edit"
              ? "Edit Department Details"
              : "Create an Department"}
          </VSNTypography>
        </Stack>
        {createDepartmentAttribute?.map(({ label, value }, index) => (
          <Box my={{ md: 3.8, xs: 2 }} key={index}>
            <VSNTypography variant="body1" className={classes.detailHeading}>
              {upperCase(label)}
            </VSNTypography>
            <Grid
              container
              rowSpacing={{ xs: 0, md: 2 }}
              columnSpacing={4}
              className={classes.formGrid}
            >
              {value?.map(
                (
                  {
                    isRequired,
                    label,
                    errorMessage,
                    name,
                    value,
                    getValBy,
                    md,
                    ...attribute
                  },
                  index
                ) => (
                  <Grid item xs={12} md={md} key={index} mb={{ xs: 2, md: 0 }}>
                    <VSNLabel required={isRequired} label={label} />
                    <ControlForm
                      {...{
                        ...attribute,
                        elementId: index,
                        label,
                        name,
                        handleMobileChange,
                        onChange: handleChange,
                        // errorMessage,
                        value: departmentInfo?.[name] ?? "",
                        readOnly:
                          (mode === "edit" && name === "email") ||
                          (mode === "edit" && name === "first_name") ||
                          (mode === "edit" && name === "last_name") ||
                          (mode === "edit" &&
                            name === "organization_original_id") ||
                          (mode === "edit" &&
                            name === "location_original_id") ||
                          (mode === "edit" && name === "phone_number"),
                        handleChangePhoto,
                      }}
                    />
                    <VSNErrorMessage
                      {...{ formError: formErrors?.[name], errorMessage }}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        ))}
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={2}
          className={classes.buttons}
        >
          <VSNButton
            handleClick={() =>
              toggleModelShow(
                mode === "edit" ? "editDepartment" : "createDepartment"
              )
            }
            variant="outlined"
            className={classes.cancelButton}
          >
            Cancel
          </VSNButton>

          <VSNButton
            isLoading={createDepartmentLoader}
            className={classes.createLinkButton}
            disabled={equal(
              JSON.stringify(departmentInfo),
              JSON.stringify(departmentToEdit)
            )}
            type="submit"
          >
            {mode === "edit" ? "Save Changes" : "Create an Department"}
          </VSNButton>
        </Stack>
      </Box>
    </form>
  );
};

export default CreateDepartmentContainer;
