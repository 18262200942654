import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiContainer } from "../utils/api";
import {
  LOADING_CHANGE,
  ON_FLAG_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../redux/constants";
import { copyToClipboard, equal, values, length } from "../utils/javascript";
import {
  department_admin,
  endPoints,
  location_admin_name,
  organization_admin,
  RoleToId,
} from "../utils/constant";
import {
  clearFormData,
  getEndpoint,
  setErrors,
  toggleModelShow,
} from "../shared/CustomFunc";
import { LinkIcon } from "../shared/icon";
import { checkValid, emailValidation } from "../shared/validation";
import { showToast } from "../utils/toastService";
import { changeLocationAdminOption } from "../description/common.description";
import { ERROR_MESSAGE } from "../description/errorMessage.description";

const addMemberFormPath = { parent: "addMemberData" };
const addAdminMemberFormPath = { parent: "addMemberData" };

export const orgAssessmentReportContainer = ({
  getFrom,
  isFromInviteModal,
  columns,
}) => {
  const searchRef = useRef(null);
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [currentRow, setCurrentRow] = useState({});
  const [filterColumn, setFilterColumn] = useState([]);

  const [showDialog, setShowDialog] = useState(false);
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  const [deleteRow, setDeleteRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [addMemberModalTeam, setAddMemberModalTeam] = useState();
  const { organization_id, organization_name } = useSelector(
    (state) => state?.app?.userData?.user_profile
  );
  const organization_original_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_original_id
  );
  const assessMentUserTableValue = useSelector(
    (state) => state?.api?.assessMentList
  );
  const refetchData = useSelector((state) => state?.app?.flags?.refetchData);
  const tabsPath = "org-summary";
  const activeTab = useSelector((state) => state?.api?.tabs?.[tabsPath]);
  const location_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.location_id
  );
  const department_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.department_id
  );
  const permissions = useSelector(
    (state) => state?.app?.userData?.user_profile?.permissions
  );
  const { form, modelStates } = useSelector((state) => state);
  const { formData, formErrors } = form;
  const addMemberInfo = formData?.[addMemberFormPath.parent];
  const addMemberInfoError = formErrors?.[addMemberFormPath.parent];
  const addAdminMemberInfo = formData?.[addAdminMemberFormPath.parent];
  const addAdminMemberInfoError = formErrors?.[addAdminMemberFormPath.parent];
  const loaders = useSelector((state) => {
    return {
      [activeTab]: state.api?.loader?.[activeTab],
      currentSelectedTeam: state.api?.loader?.currentSelectedTeam,
      currentSelectedTeamMembers: state.api?.loader?.currentSelectedTeamMembers,
      outsideMembersLoader: state.api?.loader?.outsideMembersLoader,
      teamsOfMember: state.api?.loader?.teamsOfMember,
      expandRowOfMember: state.api?.loader?.expandRowOfMember,
      memberList: state.api?.loader?.memberList,
    };
  });
  const apiLoaders = useSelector((state) => state?.api?.loader);
  const membersToAdd =
    useSelector((state) => state?.api?.membersToAdd?.data) || [];
  const membersToAddForAdmin =
    useSelector((state) => state?.api?.membersToAddForAdmin) || {};
  const filters = useSelector((state) => state.form.formData?.[activeTab]);
  const assessmentReports = useSelector(
    (state) => state.api?.[activeTab]?.[getFrom] || state.api?.[activeTab]?.data
  );
  const paginationData = useSelector((state) => state.api?.[activeTab]?.page);
  const { orgFilters, currentSelectedTeamMembers, teamsOfMember } = useSelector(
    (state) => state.api
  );
  const isParticipantsOpen = useSelector(
    (state) => state.modelStates?.participants
  );
  const currentSelectedTeam = useSelector(
    (state) => state?.api?.currentSelectedTeam
  );
  const userData = useSelector((state) => state.app?.userData);
  const defaultProfilePictures =
    useSelector((state) => state.api?.defaultProfilePictures?.data) || [];
  const currentSelectedMember = useSelector(
    (state) => state?.api?.currentSelectedMember
  );
  const reportData = useSelector((state) => state.api?.[activeTab]);
  const { accessOfAssessmentsForMember } = useSelector((state) => state.api);
  const payloadForEndPoint = {
    organization_original_id,
    location_id,
    department_id,
    role,
  };

  const handlePageChange = (event, value) => {
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: { ...filters, page: value + 1 },
        },
      },
    });
  };

  const setFormData = ({ name, value }) => {
    // dispatch(
    //   setErrors({
    //     errors: addMemberInfoError,
    //     value,
    //     name,
    //     formPath: addMemberFormPath,
    //   })
    // );
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [addMemberFormPath.parent]: {
            ...addMemberInfo,
            [name]: value,
          },
        },
      },
    });
  };

  const handleAddMemberFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ name, value });
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: {
            ...filters,
            rowsPerPage: equal(value, -1)
              ? Number(paginationData?.total_count)
              : Number(value),
            page: 1,
          },
        },
      },
    });
  };

  const resetFilters = () => {
    searchRef.current.value = "";

    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: {
            organization: "",
            sortBy: "",
            page: 1,
            rowsPerPage: 10,
            keyword: "",
          },
        },
      },
    });
  };

  const adminEndpoint = getEndpoint({
    ...payloadForEndPoint,
    endPoint: "admins",
  });
  const tabsEndpoint = {
    location_admin: adminEndpoint,
    head_clinician: adminEndpoint,
    clinician: adminEndpoint,
    organization_admin: adminEndpoint,
    department_admin: adminEndpoint,
    locations: getEndpoint({
      ...payloadForEndPoint,
      endPoint: "locations",
    }),
    departments: getEndpoint({
      ...payloadForEndPoint,
      endPoint: "departments",
    }),
    members: getEndpoint({
      ...payloadForEndPoint,
      endPoint: "members",
    }),

    teams: getEndpoint({
      ...payloadForEndPoint,
      endPoint: "teams",
    }),
  };

  const getReports = async ({ formPath, needLoader }) => {
    if (activeTab === "assessments") return;
    const endPoint = tabsEndpoint[activeTab];
    // if (activeTab === "members") {
    //   if (location_admin_name?.includes(role)) {
    //     endPoint = `organization/${organization_original_id}/locations/${location_id}/members`;
    //   } else if (role === department_admin) {
    //     endPoint = `${departmentEndpoint}/members`;
    //   }
    // }
    // if (activeTab === "departments" && location_admin_name?.includes(role)) {
    //   endPoint = `organization/${organization_original_id}/locations/${location_id}/departments`;
    // }
    // if (activeTab === "teams") {
    //   const endpointRole = {
    //     organization_admin: `${organizationEndpoint}/teams`,
    //     default: `${locationEndpoint}/teams`,
    //     department_admin: `${departmentEndpoint}/teams`,
    //   };
    //   endPoint = endpointRole?.[role] ?? endpointRole?.default;
    // }

    const {
      sortyByStatus,
      sortByRole,
      sortByVasanaType,
      page,
      rowsPerPage,
      keyword,
      date_filter,
      vasana_filter,
      member_status,
      sortByValue,
      location,
      department,
    } = filters;
    const reportsData = await api({
      method: "GET",
      endPoint: `${endPoint}?page=${page}&limit=${rowsPerPage}${
        date_filter?.startDate ? `&start_date=${date_filter?.startDate}` : ""
      }${date_filter?.endDate ? `&end_date=${date_filter?.endDate}` : ""}${
        sortyByStatus ? `&member_status=${sortyByStatus}` : ""
      }${sortByVasanaType ? `&vasana_type=${sortByVasanaType}` : ""}${
        sortByRole ? `&member_role=${sortByRole}` : ""
      }${keyword ? `&keyword=${keyword}` : ""}${
        vasana_filter ? `&vasana_filter=true` : ``
      }${member_status ? `&assessment_completed=true` : ""}${
        sortByValue ? `&sort_by=${sortByValue}` : ``
      }${location ? `&location_id=${location}` : ""}${
        department ? `&department_id=${department}` : ""
      }`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: needLoader !== "false",
      parent: formPath?.parent,
      needFullResponse: true,
    });

    if (reportsData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          [formPath?.parent]: reportsData?.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          [formPath?.parent]: [],
        },
      });
    }
  };

  useEffect(() => {
    if (organization_id && activeTab && !isFromInviteModal && filters) {
      getReports({
        formPath: { parent: activeTab },
      });
    }
  }, [organization_id, activeTab, filters]);

  useEffect(() => {
    if (!filters && !isFromInviteModal) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData?.[activeTab],
            [activeTab]: {
              ...filters,
              page: 1,
              rowsPerPage: 10,
              keyword: "",
              sortyByStatus: "",
              sortByRole: "",
              sortByVasanaType: "",
            },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (searchRef.current && !isFromInviteModal)
      searchRef.current.value = filters?.keyword;
  }, [filters?.keyword]);
  useEffect(() => {
    setFilterColumn(() => {
      return columns
        ?.filter(({ showRole }) => (showRole ? showRole?.includes(role) : true))
        ?.filter(({ permissionName, permissionToFind }) =>
          permissionName
            ? permissions?.[permissionName]?.includes(permissionToFind)
            : true
        );
    });
  }, [columns, permissions]);

  const getPermittedAssessment = async () => {
    const reportsData = api({
      method: "GET",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "assessments",
      }),
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessments",
    });
    const signUpFlowData = api({
      method: "GET",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "assessments/required-for-signup",
      }),
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessments",
    });
    const lockFlowData = api({
      method: "GET",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "assessments/is-locked",
      }),
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessments",
    });
    const frequencyData = api({
      method: "GET",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "assessments/frequency",
      }),
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessments",
    });
    const forcedReTakeData = api({
      method: "GET",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "assessments/force-retake",
      }),
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessments",
    });
    Promise.all([
      reportsData,
      signUpFlowData,
      lockFlowData,
      frequencyData,
      forcedReTakeData,
    ]).then((value) => {
      const [
        reportsData,
        signUpFlowData,
        lockFlowData,
        frequencyData,
        forcedReTakeData,
      ] = value;
      if (reportsData.status && signUpFlowData.status) {
        const mandatory_assessments =
          reportsData.data?.mandatory_assessments.map((el) => {
            return {
              ...el,
              enabled: true,
              nonChangeable: true,
              signUpFlow: true,
            };
          });
        const required_assessments = reportsData.data?.required_assessments.map(
          (el) => {
            return {
              ...el,
              enabled: true,
              required: true,
              signUpFlow: Boolean(
                signUpFlowData?.data?.find((val) => el?.id === val?.id)
              ),
              locked: Boolean(
                lockFlowData?.data?.find((val) => el?.id === val?.id)
              ),
              frequency: frequencyData?.data?.find((val) => el?.id === val?.id)
                ?.frequency,
              showDateField:
                frequencyData?.data?.find((val) => el?.id === val?.id)
                  ?.frequency === "Manual",
              frequencyDate: frequencyData?.data?.find(
                (val) => el?.id === val?.id
              )?.next_retake_at,
              forcedReTakeDate: forcedReTakeData?.data?.find(
                (val) => el?.id === val?.id
              )?.next_retake_at,
            };
          }
        );
        const optional_assessments = reportsData.data?.optional_assessments.map(
          (el) => {
            return {
              ...el,
              enabled: false,
              optional: true,
              locked: Boolean(
                lockFlowData?.data?.find((val) => el?.id === val?.id)
              ),
              frequency: frequencyData?.data?.find((val) => el?.id === val?.id)
                ?.frequency,
              showDateField:
                frequencyData?.data?.find((val) => el?.id === val?.id)
                  ?.frequency === "Manual",
              frequencyDate: frequencyData?.data?.find(
                (val) => el?.id === val?.id
              )?.next_retake_at,
              forcedReTakeDate: forcedReTakeData?.data?.find(
                (val) => el?.id === val?.id
              )?.next_retake_at,
            };
          }
        );
        dispatch({
          type: SET_API_DATA,
          payload: {
            [activeTab]: {
              assessments: {
                mandatory_assessments: mandatory_assessments || [],
                required_assessments: required_assessments || [],
                optional_assessments: optional_assessments || [],
              },
            },
          },
        });
      }
    });
  };

  useEffect(() => {
    if (!isFromInviteModal && activeTab === "assessments")
      getPermittedAssessment();
  }, [activeTab]);

  const onFilterChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: ON_FORM_CHANGE,

      payload: {
        formData: {
          ...form.formData,
          [activeTab]: {
            ...filters,
            ...(equal(name, "organization_summary") && { page: 1 }),
            [name]: value,
          },
        },
      },
    });
  };

  const onSearch = (e) => {
    const { value } = e.target;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: {
            ...filters,
            page: 1,
            keyword: value,
          },
        },
      },
    });
  };
  const onSearchChange = useCallback(debounce(onSearch, 500), [filters]);

  const handleDeleteFunc = async () => {
    // const deleteAdminEndpoint = `admin/${endPoints?.[`delete${activeTab}`]}`;
    if (activeTab === "members") {
      const removeMemberEndpoint = `organization/${organization_original_id}/remove-members`;
      const membersPayload = {
        members_to_remove: deleteRow
          ? [deleteRow?.user_id] // Delete single member
          : selectedRows.map(({ user_id }) => user_id), // Delete multiple members
      };
      setIsDeleting(true);
      const data = await api({
        method: "POST",
        endPoint: removeMemberEndpoint,
        data: membersPayload,
        showToastMessage: true,
        urlencoded: false,
      });
      if (data.status) {
        getReports({
          endPoint: endPoints?.[activeTab],
          formPath: { parent: activeTab },
        });
        setShowDialog(null);
      }
    } else if (activeTab === "locations") {
      setIsDeleting(true);
      const data = await api({
        method: "DELETE",
        endPoint: `/organization/${deleteRow.organization_original_id}/locations/${deleteRow.location_original_id}`,
        showToastMessage: true,
        urlencoded: false,
      });
      if (data.status) {
        getReports({
          formPath: { parent: activeTab },
        });
        setShowDialog(null);
      }
    } else if (activeTab === "departments") {
      setIsDeleting(true);
      const data = await api({
        method: "DELETE",
        endPoint: `organization/${organization_original_id}/locations/${deleteRow.location_original_id}/departments/${deleteRow.department_original_id}`,
        showToastMessage: true,
        urlencoded: false,
      });
      if (data.status) {
        getReports({
          formPath: { parent: activeTab },
        });
        setShowDialog(null);
      }
    }
    dispatch({ type: ON_FLAG_CHANGE, payload: { reFetchSummary: true } });
    setIsDeleting(false);
  };

  const onDelete = async () => {
    const getDeleteData = (idField) => {
      const selectedItems = deleteRow
        ? [deleteRow?.[idField]]
        : selectedRows.map(({ [idField]: id }) => id);
      return selectedItems.join(",");
    };

    const selectedItemsField = activeTab === "teams" ? "id" : "user_id";
    const dynamicEndpoint =
      activeTab === "teams"
        ? `teams/team/${deleteRow?.id}`
        : `admin/${endPoints?.[`delete${activeTab}`]}`;

    const dataPayload =
      activeTab === "teams"
        ? { teams_to_delete: getDeleteData(selectedItemsField) }
        : { user_id: deleteRow?.user_id };

    if (activeTab === "teams") {
      setIsDeleting(true);
      const data = await api({
        method: "DELETE",
        endPoint: dynamicEndpoint,
        data: dataPayload,
        showToastMessage: true,
        urlencoded: false,
      });

      setIsDeleting(false);

      if (data.status) {
        getReports({
          endPoint: endPoints?.[activeTab],
          formPath: { parent: activeTab },
        });
        setShowDialog(null);
      }
    } else {
      handleDeleteFunc();
    }
  };

  const getMembers = async (teamId) => {
    const membersData = await api({
      method: "GET",
      endPoint: `teams/${teamId ?? addMemberModalTeam?.id}/members/outside`,
      showToastMessage: false,
      urlencoded: false,
      parent: "outsideMembersLoader",
      needLoader: true,
      needFullResponse: true,
    });

    if (membersData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          membersToAdd: membersData.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          membersToAdd: [],
        },
      });
    }
  };
  const onClickParticipants = async (team, isAlreadyOpen) => {
    const membersData = await api({
      method: "GET",
      endPoint: `teams/team/${team?.id}/members?page=1&limit=10`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "currentSelectedTeamMembers",
    });
    if (membersData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          currentSelectedTeamMembers: membersData?.data,
          currentSelectedTeam: team,
        },
      });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            addMemberData: {},
            participantsModal: {
              ...(isAlreadyOpen
                ? {}
                : { ...(formData?.participantsModal || {}) }),
              ...membersData?.data?.page,
            },
          },
        },
      });
      if (!isParticipantsOpen) dispatch(toggleModelShow("participants"));
    }
  };

  const getDefaultProfilePictures = async () => {
    const data = await api({
      method: "GET",
      endPoint: endPoints.defaultProfilePictures,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "defaultProfilePicturesLoader",
    });

    if (data?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          defaultProfilePictures: data.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          defaultProfilePictures: [],
        },
      });
    }
  };

  const onEditClick = async (data) => {
    if (activeTab === "teams") {
      const teamDetails = await api({
        method: "GET",
        endPoint: `teams/team/${data?.id}`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "currentSelectedTeam",
      });
      if (teamDetails?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            currentSelectedTeam: teamDetails.data,
          },
        });
        if (!defaultProfilePictures?.length) await getDefaultProfilePictures();
        dispatch(toggleModelShow("editTeam"));
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              teamData: {
                ...formData?.teamData,
                name: teamDetails?.data?.name || "",
                goal: teamDetails?.data?.goal || "",
                id: teamDetails?.data?.id,
                profile_picture: teamDetails?.data?.selected_picture_id
                  ? teamDetails?.data?.profile_picture
                  : null,
                photo_upload_url: teamDetails?.data?.selected_picture_id
                  ? null
                  : teamDetails?.data?.profile_picture,
              },
            },
          },
        });
      }
    } else if (activeTab === "locations") {
      const locationData = await api({
        method: "GET",
        endPoint: `organization/${data.organization_original_id}/locations/${data.location_original_id}`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "locationInfo",
      });
      if (locationData?.status) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              locationInfo: locationData?.data,
            },
          },
        });
      }
      dispatch({
        type: SET_API_DATA,
        payload: {
          locationToEdit: locationData?.data,
        },
      });
      dispatch(toggleModelShow("editLocation"));
    } else {
      const departmentData = await api({
        method: "GET",
        endPoint: `organization/${organization_original_id}/locations/${data.location_original_id}/departments/${data.department_original_id}`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "departmentInfo",
      });
      if (departmentData?.status) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              departmentInfo: departmentData?.data,
            },
          },
        });
        dispatch(toggleModelShow("editDepartment"));
      }
      dispatch({
        type: SET_API_DATA,
        payload: {
          departmentToEdit: departmentData?.data,
        },
      });
    }
  };
  const onChangeDepartment = async (row) => {
    dispatch({ type: SET_API_DATA, payload: { changeDepartment: row } });
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          changeDepartmentInfo: {
            department: row?.department_original_id,
            location: row?.location_original_id,
          },
        },
      },
    });
    dispatch(toggleModelShow("changeDepartment"));
  };

  const onDeleteClick = (row) => {
    setDeleteRow(row);
    setShowDialog(!showDialog);
  };

  const onCopyClick = (link) => copyToClipboard(link);

  const onInputChange = (event, value) => {
    const isValidMail =
      emailValidation(value) && !addMemberInfo?.emailOptions?.includes(value);
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [addMemberFormPath.parent]: {
            ...addMemberInfo,
            invited_members_input_value: value,
            ...(isValidMail ? { emailOptions: [value] } : {}),
          },
        },
      },
    });
  };
  const setFormAdminData = (name, value) => {
    dispatch(
      setErrors({
        errors: addAdminMemberInfoError ?? {},
        value,
        name,
        formPath: addAdminMemberFormPath,
      })
    );
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [addAdminMemberFormPath.parent]: {
            ...addAdminMemberInfo,
            [name]: value,
          },
        },
      },
    });
  };

  const handelAdminDataChange = (e) => {
    const { name, value } = e.target;
    setFormAdminData(name, value);
  };

  const onEmailSelect = (name, value) => {
    if (value) {
      const errorEmail = value.find(
        (email) => !emailValidation(email?.email || email)
      );
      if (errorEmail) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [addMemberFormPath.parent]: {
                ...addMemberInfo,
                [`${name}_input_value`]: value[value?.length - 1],
              },
            },
          },
        });
      } else {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [addMemberFormPath.parent]: {
                ...addMemberInfo,
                [name]: errorEmail ? [...(addMemberInfo?.[name] || [])] : value,
                [`${name}_input_value`]: "",
              },
            },
          },
        });
      }
    }
  };

  const handleAddMembers = (team) => {
    setAddMemberModalTeam(team);
    dispatch(toggleModelShow("addMembers", true));
    getMembers(team?.id);
  };

  const handleInviteAdmin = () => {
    dispatch(toggleModelShow("InviteAdminModel"));
  };

  const addMembers = async () => {
    dispatch({
      type: LOADING_CHANGE,
      payload: { addMembersLoader: true },
    });
    const { added_members, invited_members } =
      form?.formData?.addMemberData || {};
    const addedMembers = added_members?.map(({ user_id }) => user_id);

    const data = await api({
      method: "POST",
      endPoint: `teams/team/${addMemberModalTeam?.id}/manage-member`,

      data: {
        members: addedMembers,
        action: "add",
        invited_members,
      },
      showToastMessage: true,
      urlencoded: false,
      parent: "teamData",
      needLoader: true,
    });
    if (!data?.data?.error) {
      getReports({
        formPath: { parent: activeTab },
      });
      dispatch(toggleModelShow("addMembers", true));
      dispatch(clearFormData(addMemberFormPath.parent));
      dispatch({
        type: LOADING_CHANGE,
        payload: { addMembersLoader: false },
      });
      if (addMemberModalTeam?.members?.length) {
        onClickParticipants(addMemberModalTeam, true);
      }
    } else {
      dispatch({
        type: LOADING_CHANGE,
        payload: { addMembersLoader: false },
      });
    }
  };

  const addMemberButtons = [
    {
      label:
        !form?.formData?.addMemberData?.added_members?.length &&
        !form?.formData?.addMemberData?.invited_members?.length
          ? "Send Invite"
          : form?.formData?.addMemberData?.added_members?.length &&
            !form?.formData?.addMemberData?.invited_members?.length
          ? "Add members"
          : "Send Invite",
      handleClick: addMembers,
      hasLoader: true,
      disabled: !(
        form?.formData?.addMemberData?.added_members?.length ||
        form?.formData?.addMemberData?.invited_members?.length
      ),
    },
    {
      label: "Copy Invitation Link",
      handleClick: () =>
        copyToClipboard(addMemberModalTeam?.invitation_link || ""),
      className: "link",
      link: <LinkIcon />,
    },
  ];

  const onClickAdminList = async (row) => {
    const membersData = await api({
      method: "GET",
      endPoint: `organization/${organization_original_id}/locations/${row?.location_original_id}/departments/${row?.department_original_id}/admins?page=1&limit=10`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "currentSelectedTeamMembers",
    });
    if (membersData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          currentSelectedTeamMembers: membersData?.data,
          // currentSelectedTeam: team,
        },
      });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            addMemberData: {},
            // participantsModal: {
            //   ...(isAlreadyOpen
            //     ? {}
            //     : { ...(formData?.participantsModal || {}) }),
            //   ...membersData?.data?.page,
            // },
          },
        },
      });
      if (!isParticipantsOpen) dispatch(toggleModelShow("participants"));
    }
  };
  const sortingFunc = async (dir, value) => {
    let dirValue;
    if (dir === "ascending") {
      dirValue = "";
    } else {
      dirValue = "-";
    }
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: {
            ...filters,
            sortByValue: `${dirValue}${value?.toLowerCase()}`,
          },
        },
      },
    });
    // dispatch({
    //   type: LOADING_CHANGE,
    //   payload: { sortByValue: true },
    // });
    // const {
    //   sortyByStatus,
    //   sortByRole,
    //   sortByVasanaType,
    //   page,
    //   rowsPerPage,
    //   keyword,
    //   date_filter,
    //   vasana_filter,
    //   member_status,
    // } = filters;
    // const reportsData = await api({
    //   method: "GET",
    //   endPoint: `organization/${organization_id}/${activeTab}?page=${page}&limit=${rowsPerPage}${
    //     date_filter?.startDate ? `&start_date=${date_filter?.startDate}` : ""
    //   }${date_filter?.endDate ? `&end_date=${date_filter?.endDate}` : ""}${
    //     sortyByStatus ? `&member_status=${sortyByStatus}` : ""
    //   }${sortByVasanaType ? `&vasana_type=${sortByVasanaType}` : ""}${
    //     sortByRole ? `&member_role=${sortByRole}` : ""
    //   }${keyword ? `&keyword=${keyword}` : ""}${
    //     vasana_filter ? `&vasana_filter=true` : ``
    //   }${
    //     member_status ? `&member_status=assessment_completed` : ""
    //   }&sort_by=${dirValue}${value?.toLowerCase()}`,
    //   showToastMessage: false,
    //   urlencoded: false,
    //   needLoader: true,
    //   needFullResponse: true,
    // });
    // if (reportsData?.status) {
    //   dispatch({
    //     type: SET_API_DATA,
    //     payload: {
    //       [activeTab]: reportsData.data,
    //     },
    //   });
    //   dispatch({
    //     type: LOADING_CHANGE,
    //     payload: { sortByValue: false },
    //   });
    // } else {
    //   dispatch({
    //     type: SET_API_DATA,
    //     payload: {
    //       [activeTab]: [],
    //     },
    //   });
    // }
  };
  const labelForInvite = {
    organization_admin: {
      title: "Invite Organization Admin",
      desc: ` Enter an email ID to invite a member as an Admin for ${organization_name}.`,
    },
    location_admin: {
      title: "Invite Location Admin",
      desc: ` Enter an email ID to invite a member as an Admin for Location.`,
    },
    head_clinician: {
      title: "Invite Location Admin",
      desc: ` Enter an email ID to invite a member as an Admin for Location.`,
    },
    clinician: {
      title: "Invite Location Admin",
      desc: ` Enter an email ID to invite a member as an Admin for Location.`,
    },
    department_admin: {
      title: "Invite Department Admin",
      desc: ` Enter an email ID to invite a member as an Admin for Department.`,
    },
  };

  const addMembersFields = {
    data: [
      {
        question: "Add Members",
        description: `Start typing a email to add member to team or select members from the dropdown.`,
        isOrSplit: true,
        value: [
          {
            control: "select",
            type: "select",
            placeholder: "Search and add members",
            fullWidth: true,
            name: "added_members",
            defaultValue: "",
            options: loaders?.outsideMembersLoader
              ? [{ isLoading: true }]
              : membersToAdd || [],
            variant: "standard",
            loader: loaders?.memberList,
          },
        ],
      },
      {
        question: "Invite A Members",
        description: `Add people who aren't on Organization by typing their email address.`,
        value: [
          {
            control: "input",
            placeholder: "Select or enter email",
            fullWidth: true,
            options: addMemberInfo?.emailOptions || [],
            type: "email",
            name: "invited_members",
            validateAs: "email",
            onInputChange,
          },
        ],
      },
    ],
  };

  const addAdminFields = {
    data: [
      {
        question: labelForInvite[role]?.title,
        description: labelForInvite[role]?.desc,
        value: [
          ...(role === "location_admin"
            ? [
                {
                  control: "select",
                  label: "Role",
                  name: "role",
                  value: 0,
                  validateAs: "selectMenu",
                  isRequired: true,
                  fullWidth: true,
                  placeHolder: "Select Role",
                  errorMessage: ERROR_MESSAGE?.role,
                  options: changeLocationAdminOption,
                  md: 6,
                  variant: "standard",
                },
              ]
            : []),
          {
            control: "input",
            label: "Email Address",
            type: "email",
            name: "email",
            value: "",
            isRequired: true,
            fullWidth: true,
            validateAs: "email",
            placeHolder: "Enter email address",
            md: 6,
            errorMessage: ERROR_MESSAGE?.email_address,
          },
        ],
      },
    ],
  };
  const makeAdmin = async () => {
    let errors = {};
    addAdminFields?.data?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value: addAdminMemberInfo?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [addAdminMemberFormPath.parent]: { ...errors },
            },
          },
        });
      });
    });
    if (
      !(
        equal(errors, undefined) ||
        equal(length(values(errors)?.filter(Boolean)))
      )
    ) {
      return;
    }

    const { role: role_id, email } = addAdminMemberInfo;
    let dataToSend = {
      email,
    };
    if (location_admin_name?.includes(role)) {
      dataToSend = { ...dataToSend, role_id };
    }
    const data = await api({
      method: "POST",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "invite-admin",
      }),
      data: dataToSend,
      showToastMessage: true,
      urlencoded: false,
      parent: "addMembersLoader",
      needLoader: true,
    });

    if (!data?.data?.error) {
      getReports({
        formPath: { parent: activeTab },
      });
      dispatch(clearFormData(addMemberFormPath.parent));
      dispatch({
        type: LOADING_CHANGE,
        payload: { addMembersLoader: false },
      });
      if (addMemberModalTeam?.members?.length) {
        onClickParticipants(addMemberModalTeam, true);
      }
    } else {
      dispatch({
        type: LOADING_CHANGE,
        payload: { addMembersLoader: false },
      });
    }
    dispatch(toggleModelShow("InviteAdminModel", true));
  };

  const onCloseParticipantModal = () => {
    dispatch(toggleModelShow("participants"));
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          participantsModal: {},
        },
      },
    });
  };

  const handleButtonClick = (e, button) => {
    if (button === "createTeam") {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            teamData: null,
          },
        },
      });
      dispatch(toggleModelShow("createTeam"));
    }
  };

  const onSuccess = () => {
    getReports({
      endPoint: endPoints?.[activeTab],
      formPath: { parent: activeTab },
    });
  };

  const onClickTeamList = async (member) => {
    const response = await api({
      method: "GET",
      endPoint: `organization/${organization_id}/members/${member?.user_id}/teams?page=1&limit=10`,
      showToastMessage: false,
      urlencoded: false,
      parent: "teamsOfMember",
      needLoader: true,
      needFullResponse: true,
    });
    if (response?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          teamsOfMember: response?.data,
          currentSelectedMember: member,
        },
      });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            teamsOfMember: {
              ...(formData?.teamsOfMember
                ? { ...formData?.teamsOfMember }
                : {}),
              ...response?.data?.page,
            },
          },
        },
      });
    }
    dispatch(toggleModelShow("teamsOfMember"));
  };

  const onExpandRow = async (row) => {
    let clonedAccessOfAssessmentsForMember = [...(assessmentReports || [])];
    const objId = activeTab === "departments" ? "department_id" : "user_id";
    const currentRowIndex = clonedAccessOfAssessmentsForMember?.findIndex(
      (el) => el?.[objId] === row?.[objId]
    );
    const currentRow = clonedAccessOfAssessmentsForMember[currentRowIndex];

    if (currentRow?.expanded) {
      currentRow.expanded = false;
      clonedAccessOfAssessmentsForMember[currentRowIndex] = currentRow;
      dispatch({
        type: SET_API_DATA,
        payload: {
          [activeTab]: {
            ...reportData,
            [getFrom]: clonedAccessOfAssessmentsForMember || [],
          },
        },
      });
    } else {
      clonedAccessOfAssessmentsForMember =
        clonedAccessOfAssessmentsForMember?.map((val) => {
          if (val?.[objId] !== row?.[objId]) {
            return { ...val, expanded: false };
          }
          return { ...val, expanded: true };
        });

      dispatch({
        type: SET_API_DATA,
        payload: {
          dataForAssessment: row,
        },
      });
      dispatch({
        type: SET_API_DATA,
        payload: {
          [activeTab]: {
            ...reportData,
            [getFrom]: clonedAccessOfAssessmentsForMember || [],
          },
        },
      });

      const clonedAssessmentReports = [];

      dispatch({
        type: SET_API_DATA,
        payload: {
          accessOfAssessmentsForMember: {
            assessments: clonedAssessmentReports || [],
            organizationId: row?.organization_id,
            userId: row?.user_id,
          },
        },
      });
    }
  };

  const handleSwitchChangeForMember = async (event, rowData) => {
    const { organizationId, userId } = accessOfAssessmentsForMember;
    const clonedAssessmentReports = [
      ...(accessOfAssessmentsForMember?.assessments || []),
    ];
    const index = clonedAssessmentReports?.findIndex(
      (el) => el?.id === rowData?.id
    );
    let clonedAssessment = { ...clonedAssessmentReports?.[index] };
    clonedAssessment = {
      ...clonedAssessment,
      enabled: event.target.checked,
    };
    clonedAssessmentReports[index] = { ...clonedAssessment };
    dispatch({
      type: SET_API_DATA,
      payload: {
        accessOfAssessmentsForMember: {
          ...accessOfAssessmentsForMember,
          assessments: [...clonedAssessmentReports],
        },
      },
    });
    const updatedStateArray = clonedAssessmentReports
      ?.filter((el) => el?.enabled)
      .map((val) => val?.id);

    const data = await api({
      method: "POST",
      endPoint: `organization/${organizationId}/users/${userId}/assessments`,
      data: {
        assessment_ids: updatedStateArray,
      },
      showToastMessage: true,
      urlencoded: false,
    });

    if (!data.status) {
      clonedAssessment = {
        ...clonedAssessment,
        enabled: !event?.target?.checked,
      };
      clonedAssessmentReports[index] = { ...clonedAssessment };
      dispatch({
        type: SET_API_DATA,
        payload: {
          accessOfAssessmentsForMember: {
            ...accessOfAssessmentsForMember,
            assessments: [...clonedAssessmentReports],
          },
        },
      });
    }
  };
  const onEditLocation = async (event, rowData) => {
    const { organizationId, userId } = accessOfAssessmentsForMember;
    const clonedAssessmentReports = [
      ...(accessOfAssessmentsForMember?.assessments || []),
    ];
    const index = clonedAssessmentReports?.findIndex(
      (el) => el?.id === rowData?.id
    );
    let clonedAssessment = { ...clonedAssessmentReports?.[index] };
    clonedAssessment = {
      ...clonedAssessment,
      enabled: event.target.checked,
    };
    clonedAssessmentReports[index] = { ...clonedAssessment };
    dispatch({
      type: SET_API_DATA,
      payload: {
        accessOfAssessmentsForMember: {
          ...accessOfAssessmentsForMember,
          assessments: [...clonedAssessmentReports],
        },
      },
    });
    const updatedStateArray = clonedAssessmentReports
      ?.filter((el) => el?.enabled)
      .map((val) => val?.id);

    const data = await api({
      method: "POST",
      endPoint: `organization/${organizationId}/users/${userId}/assessments`,
      data: {
        assessment_ids: updatedStateArray,
      },
      showToastMessage: true,
      urlencoded: false,
    });

    if (!data.status) {
      clonedAssessment = {
        ...clonedAssessment,
        enabled: !event?.target?.checked,
      };
      clonedAssessmentReports[index] = { ...clonedAssessment };
      dispatch({
        type: SET_API_DATA,
        payload: {
          accessOfAssessmentsForMember: {
            ...accessOfAssessmentsForMember,
            assessments: [...clonedAssessmentReports],
          },
        },
      });
    }
  };

  useEffect(() => {
    if (refetchData) {
      // setTimeout(() => {
      getReports({
        formPath: { parent: activeTab },
      });
      // }, 20);
      dispatch({
        type: ON_FLAG_CHANGE,
        payload: { refetchData: false },
      });
    }
  }, [refetchData]);

  const handelChangeRole = async (row, setDialogBox) => {
    let dataToSend = {
      action: "degrade",
    };
    if (location_admin_name?.includes(role)) {
      dataToSend = { role_id: null };
    }
    setDialogBox(false);
    const data = await api({
      method: "POST",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: `members/${row?.user_id}/role`,
      }),
      data: dataToSend,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "changingRole",
      needFullResponse: true,
    });
    if (data?.status) {
      onSuccess();
      setDialogBox(false);
    } else {
      showToast(data?.data?.message);
    }
  };

  const adminView = (row) => {
    dispatch(toggleModelShow("showAdmin"));
    dispatch({ type: SET_API_DATA, payload: { admin_list: row?.admins } });
  };
  const getMemberAssessmentDetail = async (row) => {
    dispatch({
      type: SET_API_DATA,
      payload: {
        assessMentList: { userDetail: row },
      },
    });
    dispatch(toggleModelShow("assessmentList"));
  };

  const changeDepartment = async (row, newDepartment, setShowDialog) => {
    const data = await api({
      method: "PUT",
      endPoint: `organization/${organization_original_id}/locations/${row?.location_original_id}/users/${row?.user_id}/departments/change`,
      showToastMessage: true,
      urlencoded: false,
      data: {
        current_department_id: row?.department_original_id,
        new_department_id: newDepartment,
      },
    });
    if (data) {
      setShowDialog(false);
      getReports({
        formPath: { parent: activeTab },
      });
    }
  };
  const getDepartmentFilter = async (refetch) => {
    if (role === department_admin) return;
    if (role === organization_admin && !filters?.location) return;

    const data = await api({
      method: "GET",
      endPoint: refetch
        ? getEndpoint({
            ...payloadForEndPoint,
            endPoint: `locations/${filters?.location}/departments/list`,
          })
        : getEndpoint({
            ...payloadForEndPoint,
            endPoint: `departments/list`,
          }),
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "departmentFilters",
    });
    if (data?.status) {
      let departmentFilters = [];
      departmentFilters = [
        ...departmentFilters,
        ...data.data.map(({ department_name, department_original_id }) => ({
          key: `${department_name} (${department_original_id})`,
          value: department_original_id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          departmentFilters,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          departmentFilters: [],
        },
      });
    }
  };

  const getDepartmentList = async () => {
    if (role === department_admin) return;

    const data = await api({
      method: "GET",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: `departments/list`,
      }),
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "departmentFilters",
    });
    if (data?.status) {
      let departmentFilters = [];
      departmentFilters = [
        ...departmentFilters,
        ...data.data.map(({ department_name, department_original_id }) => ({
          key: `${department_name} (${department_original_id})`,
          value: department_original_id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          departmentList: data.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          departmentList: [],
        },
      });
    }
  };

  useEffect(() => {
    if (role !== department_admin && activeTab === "members") {
      getDepartmentFilter();
      getDepartmentList();
    }
  }, [activeTab]);
  useEffect(() => {
    if (filters?.location) {
      getDepartmentFilter(true);
    }
  }, [filters?.location]);

  const ButtonsForAdmin = [
    {
      label: "Invite Admin",
      handleClick: makeAdmin,
      hasLoader: true,
      disabled: !emailValidation(form?.formData?.addMemberData?.email ?? ""),
    },
  ];

  const changeLocationAdminRole = async (row, changeRole, setShowDialog) => {
    const dataToSend = { role_id: changeRole };
    setShowDialog(false);
    const data = await api({
      method: "POST",
      endPoint: `organization/${organization_original_id}/locations/${location_id}/members/${row?.user_id}/role`,
      data: dataToSend,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "changingRole",
      needFullResponse: true,
    });
    if (data?.status) {
      onSuccess();
      setShowDialog(false);
    } else {
      showToast(data?.data?.message);
      getReports({
        formPath: { parent: activeTab },
      });
    }
  };
  const changeStatus = async (row, status, setShowDialog) => {
    const dataToSend = { is_active: status };
    setShowDialog(false);
    const data = await api({
      method: "PUT",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: `admins/${row?.user_id}/status`,
      }),
      data: dataToSend,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "changingRole",
      needFullResponse: true,
    });
    if (data?.status) {
      onSuccess();
      setShowDialog(false);
    } else {
      showToast(data?.data?.message);
      getReports({
        formPath: { parent: activeTab },
      });
    }
  };
  const handelEventScheduleClick = async (row) => {
    if (row?.is_event_scheduled) {
      const data = await api({
        method: "GET",
        endPoint: `organization/${organization_original_id}/locations/${row?.location_original_id}/departments/${row?.department_original_id}/event-schedule`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "changingRole",
        needFullResponse: false,
      });
      if (data?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            eventScheduleToEdit: data?.data,
          },
        });
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              // [formPath.parent]: { ...formData, [name]: value },
              eventScheduleInfo: data?.data,
            },
          },
        });
      }
    }
    dispatch({
      type: SET_API_DATA,
      payload: {
        eventScheduleData: row,
      },
    });
    dispatch(toggleModelShow("createEventSchedule"));
  };

  return {
    activeTab,
    filters,
    data: assessmentReports,
    onFilterChange,
    paginationData,
    handlePageChange,
    resetFilters,
    onSearchChange,
    orgFilters,
    handleChangeRowsPerPage,
    searchRef,
    makeAdmin,
    handleAction: {
      getMemberAssessmentDetail,
      onEditLocation,
      adminView,
      onDeleteClick,
      onEditClick,
      handelChangeRole,
      onClickParticipants,
      selectedRows,
      setSelectedRows,
      handleAddMembers,
      onClickTeamList,
      onCopyClick,
      changeDepartment,
      handleSwitchChange:
        activeTab === "assessments" ? null : handleSwitchChangeForMember,
      onExpandRow,
      changeLocationAdminRole,
      changeStatus,
      handelEventScheduleClick,
      onChangeDepartment,
    },
    handleInviteAdmin,
    modelStates,
    currentRow,
    apiLoaders,
    showDialog,
    onDelete,
    loaders,
    onDeleteClick,
    isParticipantsOpen,
    currentSelectedTeamMembers,
    selectedRows,
    isDeleting,
    sortingFunc,
    addMemberModalTeam,
    addMemberFormPath,
    handleAddMemberFormChange,
    addMemberInfo,
    addMemberButtons,
    onInputChange,
    onEmailSelect,
    addMembersFields,
    getReports,
    onCloseParticipantModal,
    handleButtonClick,
    onSuccess,
    currentSelectedTeam,
    teamsOfMember,
    ButtonsForAdmin,
    currentSelectedMember,
    accessOfAssessmentsForMember,
    userData,
    pathname,
    addAdminFields,
    handelAdminDataChange,
    addAdminMemberInfo,
    addAdminMemberInfoError,
    assessMentUserTableValue,
    getMemberAssessmentDetail,
    role,
    filterColumn,
  };
};
