import { Box, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import React from "react";
import VSNButton from "../../shared/VSNButton";
import VSNForm from "../../shared/VSNForm";
import VSNTypography from "../../shared/VSNTypography";
import ControlForm from "../../shared/form/ControlForm";
import VSNLabel from "../../shared/VSNLabel";
import { formContainer } from "../../container/form.container";
import {
  adminLoginHeadingText,
  loginHeadingText,
} from "../../description/logIn.description";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import SEO from "../../shared/SEO";

const useStyles = makeStyles((theme) => ({
  greeting: {
    "&.MuiTypography-root": {
      fontSize: "40px",
      fontWeight: "700",
      [theme.breakpoints.down("md")]: {
        marginBottom: 48,
        fontSize: 28,
      },
    },
  },
  authTitle: {
    "&.MuiTypography-h3": {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "25px",
      color: "var(--blackGray)",
      marginBottom: 40,
      padding: [[16, 0]],
      [theme.breakpoints.down("md")]: {
        marginBottom: 32,
        padding: 0,
        fontSize: 18,
      },
    },
  },
  formGroup: {
    marginBottom: 36,
  },
  formInput: {
    marginBottom: 36,
  },
}));

const LogInContainer = ({
  logInAttribute,
  onSubmit,
  formPath,
  handleChange,
  loginLoader,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { formData, formErrors } = formContainer({ formPath });

  return (
    <>
      <VSNTypography className={classes.greeting}>Welcome Back!</VSNTypography>

      <SEO contentKey="login" />
      <VSNTypography variant="h3" className={classes.authTitle}>
        {location?.pathname === "/admin/login"
          ? adminLoginHeadingText
          : loginHeadingText}
      </VSNTypography>
      <VSNForm>
        <Grid container>
          <Grid item xs={12} lg={12}>
            {logInAttribute?.map(
              (
                { isRequired, label, errorMessage, name, ...attribute },
                index
              ) => {
                return (
                  <Box className={classes.formGroup} key={index}>
                    <VSNLabel required={isRequired} label={label} />
                    <ControlForm
                      {...{
                        ...attribute,
                        onChange: handleChange,
                        name,
                        value: formData?.[name],
                      }}
                    />

                    <VSNErrorMessage
                      {...{ formError: formErrors?.[name], errorMessage }}
                    />
                  </Box>
                );
              }
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack mt={5} mb={2} alignItems="center">
              <VSNButton
                type="submit"
                handleClick={onSubmit}
                isLoading={loginLoader}
              >
                Continue
              </VSNButton>
            </Stack>
          </Grid>
        </Grid>
      </VSNForm>
    </>
  );
};

export default LogInContainer;
