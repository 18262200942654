import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  IconButton,
  Menu,
  Drawer,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Union from "../../assets/common/svg/union.svg";
import VSNImage from "../../shared/VSNImage";
import VSNButton from "../../shared/VSNButton";
import VSNTypography from "../../shared/VSNTypography";
import headerContainer from "../../container/header.container";
import { ReactComponent as ExitIcon } from "../../assets/header/svg/log-out.svg";
import VSNConfirmBox from "../../shared/VSNConfirmBox";
import { signOutContainer } from "../../container/signOut.container";
import { ReactComponent as VasanaLogo } from "../../assets/header/svg/vasana_logo.svg";
import { upperCase } from "../../utils/javascript";
import {
  headerLinks,
  headerLinksExtra,
} from "../../description/header.description";
import VSNMembersAvatar from "../../shared/VSNMembersAvatar";
import { HamburgarIcon } from "../../shared/icon";
import VSNSelect from "../../shared/VSNSelect";
import {
  all_admin,
  location_admin_name,
  organization_admin_name,
} from "../../utils/constant";

const useStyles = makeStyles((theme) => ({
  logoutIcon: {
    cursor: "pointer",
    width: 24,
    height: 24,
    position: "relative",
    top: "-2px",
  },
  headerLine: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 700,
      color: "var(--gray)",
      marginRight: 14,
      [theme.breakpoints.down("mlg")]: {
        display: "none",
      },
    },
  },
  registerDetail: {
    "& p": {
      color: "var(--gray)",
      fontWeight: 700,
      lineHeight: "23px",
    },
    "& a": {
      background:
        "linear-gradient(132.61deg, var(--primaryDarker) 32.09%, var(--primaryGradientLighter) 163.78%)",
      padding: [[8, 20]],
      color: "var(--white)",
      borderRadius: 8,
      marginLeft: 15,
    },
  },
  header: {
    "&.MuiPaper-root": {
      "&.MuiAppBar-root": {
        zIndex: "1201 !important",
        backgroundColor: ({ isLandingPage, scrolled, openDrawer }) =>
          !openDrawer && isLandingPage && !scrolled
            ? "transparent"
            : "var(--white)",
      },
      "& .MuiToolbar-root": {
        [theme.breakpoints.down("sm")]: {
          minHeight: 64,
        },
      },
    },
  },
  profile: {
    lineHeight: "13px",
    marginRight: 10,
    marginLeft: 30,
    width: "max-content",
    height: 32,
    minWidth: 32,
    backgroundColor: "#1F396D",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding: 6,
    flex: "0 0 32px",
    "& .MuiTypography-root": {
      fontSize: 12,
      lineHeight: 1.5,
    },
  },
  navItemsMain: {
    "&.MuiStack-root": {
      [theme.breakpoints.down("mlg")]: {
        display: "none",
      },
    },
    "& span": {
      lineHeight: "0",
    },
  },
  navItem: {
    "& a": {
      display: "flex",
      alignItems: "center",
      padding: [[21, 10]],
      gap: 14,
      minHeight: 24,
      position: "relative",
      transition: "all 0.3s ease-in-out",
      height: "100%",
      [theme.breakpoints.down("xlg")]: {
        padding: [[21, 8]],
        gap: 8,
      },
      [theme.breakpoints.down("mlg")]: {
        padding: 10,
      },
      "&::after": {
        position: "absolute",
        content: '""',
        height: 4,
        width: "100%",
        background: "var(--Tertiary)",
        borderRadius: "4px 4px 0px 0px",
        left: 0,
        opacity: 0,
        bottom: 0,
        transition: "all 0.3s ease-in-out",
        zIndex: -1,
        [theme.breakpoints.down("lmd")]: {
          zIndex: 1,
        },
      },
      "& .MuiBox-root": {
        "&.active": {
          lineHeight: 0,
          display: "none",
        },
      },
      "&.active, &:hover": {
        "&::after": {
          opacity: ({ isLandingPage }) => (isLandingPage ? 0 : 1),
        },
        "& .MuiBox-root": {
          "&.active": {
            lineHeight: 0,
            display: "block",
          },
          display: "none",
          lineHeight: 0,
        },
        "& p": {
          color: ({ isLandingPage }) =>
            isLandingPage ? "var(--primary)" : "var(--Tertiary)",
        },
        "& span": {
          display: "none",
        },
      },
    },
    "& p": {
      color: "var(--gray)",
      lineHeight: "18px",
      fontSize: 14,
      fontWeight: 500,
      "&.landing-menu": {
        fontSize: 16,
        [theme.breakpoints.down(1199)]: {
          fontSize: 14,
        },
      },
    },
  },
  loginButtons: {
    "& .MuiButtonBase-root": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& .content": {
        fontSize: 14,
      },
      margin: 0,
      padding: [[7, 40]],
      fontSize: 14,
      height: 39,
      [theme.breakpoints.down("1199")]: {
        marginLeft: 8,
      },
      [theme.breakpoints.down("slg")]: {
        padding: [[4, 20]],
        height: 31,
        marginLeft: 0,
      },
      "& + .MuiButtonBase-root": {
        marginLeft: 16,
      },
    },
  },
  iconWrapper: {
    lineHeight: 0,
    "& svg": {
      height: 21,
      width: 21,
    },
  },

  search: {
    cursor: "pointer",
    marginRight: 12,
  },
  conformModel: {
    "& .MuiButtonBase-root": {
      margin: 0,
    },
  },
  profileText: {
    height: "32px !important",
    width: "32px !important",
    fontSize: "16px !important",
    border: "2px solid #fff !important",
  },

  drawerMain: {
    width: "100%",
    "& .MuiPaper-root": {
      top: 64,
      width: 300,
      padding: [[30, 15]],
      justifyContent: "space-between",
      height: "calc(100svh - 64px)",
      [theme.breakpoints.down("sm")]: {
        width: 200,
      },
      "& .MuiStack-root": {
        margin: "0",
        padding: "0",
      },
    },
  },
  changeOrgSelect: {
    // paddingLeft: 30,
    // paddingRight: 15,
    [theme.breakpoints.down("xlg")]: {
      paddingLeft: 0,
      paddingRight: 10,
    },
    [theme.breakpoints.down("xsm")]: {
      width: "100%",
    },
    "& p": {
      fontSize: 12,
      marginBottom: 8,
      fontWeight: 500,
      lineHeight: "15px",
    },
    "& .MuiStack-root": {
      overflow: "unset",
      paddingTop: 0,
    },

    "& .MuiFormControl-root": {
      minWidth: 181,
      [theme.breakpoints.down("400")]: {
        minWidth: 170,
      },

      "& .MuiInputBase-root": {
        borderRadius: 5,
        height: 40,
        "& svg": {
          pointerEvents: "none",
          cursor: "pointer",
          position: "absolute",
          right: "7px",
          color: "var(--gray)",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #000",
          },
          "& svg": {
            transform: "rotate(180deg)",
          },
        },
        "& .MuiSelect-select": {
          minWidth: 75,

          padding: [[8, 32, 8, 12]],
          fontWeight: 500,
          fontSize: 14,
          color: "var(--blackGray)",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #EAEDF0",
        },
      },
    },
  },
}));

const HeaderTabs = ({
  userRoleData,
  isUser,
  classes,
  direction,
  vasanaTypeAssessment,
  previousPath,
  pathname,
}) => {
  return (
    <Stack
      direction={direction}
      sx={{
        maxWidth: "max-content",
        margin: "0 auto",
        width: "100%",
      }}
      gap={direction === "column" ? { xs: 1.5 } : { xs: 0.5, xlg: 2 }}
      pl={{ xs: 2, xlg: 3 }}
      justifyContent="space-between"
    >
      {headerLinks({
        organizationName: userRoleData?.user_profile?.organization_name,
        userRole: userRoleData?.is_demo_user,
        userRoleForOrg: userRoleData?.user_profile?.role,
        isUser,
      })?.map(({ path, activeImg, label, img, disable, className }) => {
        return (
          <Stack
            direction="row"
            key={path}
            alignItems="center"
            className={classes.navItem}
            style={disable ? { cursor: "not-allowed" } : {}}
          >
            <NavLink
              to={
                path === "/teams" && !pathname?.includes("teams")
                  ? previousPath?.teamsTab
                  : path === "/assessments" &&
                    !pathname?.includes("assessments")
                  ? previousPath?.assessmentsTab
                  : path
              }
              style={disable ? { pointerEvents: "none" } : {}}
            >
              {/* <VSNTypography variant="body" sx={{ lineHeight: 0 }}>
              {" "}
              {img}
            </VSNTypography> */}
              <Box className={`${classes.iconWrapper}`}>{img}</Box>
              <Box className={`${classes.iconWrapper} active`}>
                {activeImg}{" "}
              </Box>
              <VSNTypography
                component="p"
                variant="body1"
                className={className}
              >
                {label}
              </VSNTypography>
            </NavLink>
          </Stack>
        );
      })}
    </Stack>
  );
};
const HeaderLinkExtraTabs = ({
  userRoleData,
  isUser,
  classes,
  direction,
  vasanaTypeAssessment,
  previousPath,
  pathname,
}) => {
  return (
    <Stack
      direction={direction}
      sx={{
        maxWidth: "max-content",
        margin: "0 auto",
        width: "100%",
      }}
      gap={direction === "column" ? { xs: 1.5 } : { xs: 0.5, xlg: 2 }}
      pl={{ xs: 2, xlg: 3 }}
      justifyContent="space-between"
    >
      {headerLinksExtra({ userRoleData })?.map(
        ({ path, activeImg, label, img, disable, className }) => {
          return (
            <Stack
              direction="row"
              key={path}
              alignItems="center"
              className={classes.navItem}
              style={disable ? { cursor: "not-allowed" } : {}}
            >
              <NavLink
                to={
                  path === "/teams" && !pathname?.includes("teams")
                    ? previousPath?.teamsTab
                    : path === "/assessments" &&
                      !pathname?.includes("assessments")
                    ? previousPath?.assessmentsTab
                    : path
                }
                style={disable ? { pointerEvents: "none" } : {}}
              >
                {/* <VSNTypography variant="body" sx={{ lineHeight: 0 }}>
              {" "}
              {img}
            </VSNTypography> */}
                <Box className={`${classes.iconWrapper}`}>{img}</Box>
                <Box className={`${classes.iconWrapper} active`}>
                  {activeImg}{" "}
                </Box>
                <VSNTypography
                  component="p"
                  variant="body1"
                  className={className}
                >
                  {label}
                </VSNTypography>
              </NavLink>
            </Stack>
          );
        }
      )}
    </Stack>
  );
};

const Header = () => {
  const { isLoggingOut, toggleSignOut, handleLogOut } = signOutContainer();
  const {
    handleLoginClick,
    handleRegisterClick,
    noLoginLink,
    userRoleData,
    isUser,
    pathname,
    state,
    navigateUser,
    vasanaTypeAssessment,
    previousPath,
    openDrawer,
    toggleDrawer,
    userInfo,
    allOrganization,
    handelChangeOption,
    changeOrgModel,
    handelCancelModel,
    handelChangeOrg,
    changeOrgLoader,
    role,
  } = headerContainer();
  const isLandingPage =
    [
      "/",
      "/for-teams",
      "/public-safety",
      "/who-we-are",
      "/contact-us",
    ].includes(pathname) && !isUser;
  const [scrolled, setScrolled] = useState(false);
  const classes = useStyles({ isLandingPage, scrolled, openDrawer });
  const showText = ["/get-started", "/quick-note"]?.some((path) =>
    pathname?.includes(path)
  );
  const isSmallScreen = useMediaQuery("(max-width:599px)");
  const isMdScreen = useMediaQuery("(max-width:992px)");

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > (isMdScreen ? 20 : 10);
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
      window.addEventListener("scroll", handleScroll);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);
  const ShowTab =
    isUser &&
    location?.pathname !== "/admin" &&
    !["get-started", "quick-note"]?.some((path) => pathname?.includes(path)) &&
    !(
      pathname?.includes("assessment") && !pathname?.includes("/assessments")
    ) &&
    vasanaTypeAssessment?.result;

  return (
    <header>
      <AppBar
        component="nav"
        className={classes.header}
        color="inherit"
        sx={{
          boxShadow:
            !openDrawer && isLandingPage && !scrolled
              ? "none"
              : "6px 7px 20px rgba(0, 0, 0, 0.08)",
        }}
        scrolled={String(scrolled)}
      >
        <Toolbar
          sx={{
            minHeight: 64,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack direction="row">
            <IconButton
              style={{ background: "none", padding: 0, marginRight: 8 }}
              disableRipple
              onClick={() => navigateUser()}
            >
              <VasanaLogo />
            </IconButton>
            <Stack direction="row" display={{ mlg: "flex", xs: "none" }}>
              {ShowTab && (
                <HeaderTabs
                  {...{
                    userRoleData,
                    isUser,
                    classes,
                    direction: "row",
                    vasanaTypeAssessment,
                    previousPath,
                    pathname,
                  }}
                />
              )}
            </Stack>
          </Stack>

          {ShowTab ? (
            <>
              <Drawer
                open={openDrawer}
                onClose={toggleDrawer(false)}
                className={classes.drawerMain}
              >
                <Box
                  sx={{ width: "100%", maxWidth: 250 }}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                >
                  <HeaderTabs
                    {...{
                      userRoleData,
                      isUser,
                      classes,
                      direction: "column",
                      vasanaTypeAssessment,
                      previousPath,
                      pathname,
                    }}
                  />

                  {ShowTab && userInfo && (
                    <HeaderLinkExtraTabs
                      {...{
                        userRoleData,
                        isUser,
                        classes,
                        direction: "column",
                        vasanaTypeAssessment,
                        previousPath,
                        pathname,
                      }}
                    />
                  )}
                  {ShowTab && all_admin.includes(role) && (
                    <Stack className={classes?.changeOrgSelect}>
                      <VSNSelect
                        options={allOrganization}
                        value={
                          organization_admin_name.includes(role)
                            ? userInfo?.organization_original_id
                            : location_admin_name.includes(role)
                            ? userInfo?.location_id
                            : userInfo?.department_id
                        }
                        onChange={handelChangeOption}
                      />
                    </Stack>
                  )}
                </Box>
              </Drawer>
            </>
          ) : !isUser ? (
            <>
              <Drawer
                open={openDrawer}
                onClose={toggleDrawer(false)}
                className={classes.drawerMain}
              >
                <Box
                  sx={{ width: "100%", maxWidth: 250 }}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                >
                  <HeaderTabs
                    {...{
                      userRoleData,
                      isUser,
                      classes,
                      direction: "column",
                      vasanaTypeAssessment,
                      previousPath,
                      pathname,
                    }}
                  />
                </Box>
                <Box role="presentation" onClick={toggleDrawer(false)}>
                  {isSmallScreen && (
                    <Stack
                      alignItems="center"
                      className={classes.loginButtons}
                      gap={1.5}
                    >
                      <VSNButton
                        variant="outlined"
                        handleClick={handleLoginClick}
                      >
                        Login
                      </VSNButton>
                    </Stack>
                  )}
                </Box>
              </Drawer>
              <Stack
                direction="row"
                display={{ mlg: "flex", xs: "none" }}
                sx={{
                  position: { xlg: "absolute" },
                  left: "50%",
                  transform: { xlg: "translateX(-50%)" },
                }}
              >
                <HeaderTabs
                  {...{
                    userRoleData,
                    isUser,
                    classes,
                    direction: "row",
                    vasanaTypeAssessment,
                    previousPath,
                    pathname,
                  }}
                />
              </Stack>
            </>
          ) : null}
          <Stack
            direction="row"
            alignItems="center"
            ml={{ mlg: 0, xs: "auto" }}
          >
            {/* {showText && (
            <VSNTypography className={classes.headerLine}>
              😊 Don’t stress out. There are no right or wrong answers.
            </VSNTypography>
          )} */}

            {isUser ? (
              <>
                {!pathname?.includes("/admin") && (
                  <>
                    <Stack
                      direction="row"
                      display={{ mlg: "flex", xs: "none" }}
                      alignItems="center"
                      gap={2}
                    >
                      {ShowTab && userInfo && (
                        <HeaderLinkExtraTabs
                          {...{
                            userRoleData,
                            isUser,
                            classes,
                            direction: "row",
                            vasanaTypeAssessment,
                            previousPath,
                            pathname,
                          }}
                        />
                      )}
                      {ShowTab && all_admin.includes(role) && (
                        <Stack className={classes?.changeOrgSelect}>
                          <VSNSelect
                            options={allOrganization}
                            value={
                              organization_admin_name.includes(role)
                                ? userInfo?.organization_original_id
                                : location_admin_name.includes(role)
                                ? userInfo?.location_id
                                : userInfo?.department_id
                            }
                            onChange={handelChangeOption}
                          />
                        </Stack>
                      )}
                    </Stack>

                    {/* <Box
                      sx={{
                        width: "38px",
                        height: "38px",
                        background: "#F9F9F9",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      mx={1}
                    >
                      <Search
                        sx={{
                          width: "21px",
                          height: "21px",
                        }}
                      />
                    </Box> */}

                    {userRoleData?.user_profile?.given_name &&
                      !state?.onBoarding && (
                        <Link to="/edit-profile" className={classes.profile}>
                          <VSNMembersAvatar
                            className={classes.profileText}
                            alt={userRoleData?.user_profile?.given_name}
                            src={userRoleData?.user_profile?.profile_picture}
                            vasanaType={vasanaTypeAssessment?.result}
                            title={
                              upperCase(
                                userRoleData?.user_profile?.given_name?.[0]
                              ) +
                              upperCase(
                                userRoleData?.user_profile?.family_name?.[0]
                              )
                            }
                            defaultColor="var(--white)"
                            defaultPrimaryBackgroundColor="#1F396D"
                            defaultSecondaryBackgroundColor="#1F396D"
                          />
                        </Link>
                      )}
                  </>
                )}

                <Box className={classes.logoutIcon}>
                  <ExitIcon onClick={() => toggleSignOut()} />
                </Box>
              </>
            ) : (
              !noLoginLink?.includes(pathname) && (
                <Stack
                  direction="row"
                  alignItems="center"
                  className={classes.loginButtons}
                >
                  {!isSmallScreen && (
                    <VSNButton
                      variant="outlined"
                      handleClick={handleLoginClick}
                    >
                      Login
                    </VSNButton>
                  )}
                  <VSNButton handleClick={handleRegisterClick}>
                    Get Started
                  </VSNButton>
                </Stack>
              )
            )}
          </Stack>
          {ShowTab ? (
            <Box
              aria-hidden
              sx={{
                display: { mlg: "none", xs: "inline-block" },
                ml: 1,
              }}
            >
              <Button
                onClick={toggleDrawer(!openDrawer)}
                sx={{
                  minWidth: "unset",
                  color: (theme) => "var(--gray)",
                  backgroundColor: "#EDEEF1",
                  "&:hover": {
                    backgroundColor: "#EDEEF1",
                  },
                }}
              >
                <HamburgarIcon />
              </Button>
            </Box>
          ) : !isUser ? (
            <Box
              aria-hidden
              sx={{
                display: { mlg: "none", xs: "inline-block" },
                ml: 1,
              }}
            >
              <Button
                onClick={toggleDrawer(!openDrawer)}
                sx={{
                  minWidth: "unset",
                  color: (theme) => "var(--gray)",
                  backgroundColor: "#EDEEF1",
                  "&:hover": {
                    backgroundColor: "#EDEEF1",
                  },
                }}
              >
                <HamburgarIcon />
              </Button>
            </Box>
          ) : null}
        </Toolbar>
        {isLoggingOut && (
          <VSNConfirmBox
            isOpen={isLoggingOut}
            title="Are You Sure?"
            msg="Are you sure you want to log out?"
            handleOk={handleLogOut}
            handleCancel={toggleSignOut}
            okBtnName="Log out"
            cancelBtnName="Cancel"
            className={classes.conformModel}
          />
        )}
        {changeOrgModel && (
          <VSNConfirmBox
            isOpen={changeOrgModel}
            title="Are You Sure?"
            msg={`Are you sure you want to change ${
              organization_admin_name.includes(role)
                ? "organization"
                : location_admin_name.includes(role)
                ? "location"
                : "department"
            } ?`}
            handleOk={handelChangeOrg}
            handleCancel={handelCancelModel}
            okBtnName="Yes"
            cancelBtnName="Cancel"
            className={classes.conformModel}
            isLoading={changeOrgLoader}
          />
        )}
      </AppBar>
    </header>
  );
};

export default Header;
