import { makeStyles } from "@mui/styles";
import bannerBg from "../../assets/landing/png/bannerBg.png";
import BgImg from "../../assets/landing/png/list-shape.png";

export const VasanaForTeamsStyle = makeStyles((theme) => ({
  heroSection: {
    width: "100%",
    padding: "56px 48px",
    background: `url(${bannerBg})`,
    borderRadius: 24,
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    [theme.breakpoints.down("lg")]: {
      padding: "46px 38px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "36px 28px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "36px 15px 25px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      background: "linear-gradient(94.43deg, #FEFEFE 14.47%, #E6F1FA 105%)",
      bottom: 0,
      zIndex: -1,
      borderRadius: 24,
    },
  },
  lgHeading: {
    "&.MuiTypography-root": {
      fontSize: 64,
      lineHeight: "70px",
      color: "#2D2F39",
      [theme.breakpoints.down("xl")]: {
        fontSize: 58,
        lineHeight: "64px",
      },
      [theme.breakpoints.down("xlg")]: {
        fontSize: 50,
        lineHeight: "54px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 40,
        lineHeight: "44px",
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 34,
        lineHeight: "38px",
      },
      "& .orangeText": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
  },
  bannerBg: {
    position: "relative",
    display: "flex",
    margin: "76px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      margin: "76px 0 0 0",
    },
    "& .desc": {
      fontSize: 20,
      color: "var(--gray)",
      marginBottom: 32,
      marginTop: 24,
      fontWeight: 400,
      [theme.breakpoints.down("lg")]: {
        fontSize: 18,
        marginBottom: 15,
        marginTop: 15,
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 16,
      },
      "& span": {
        color: "var(--primary)",
      },
    },
    "& .MuiButtonBase-root": {
      padding: "12px 32px",
      borderRadius: "10px",
      background:
        "linear-gradient(132.61deg, var(--primaryDarker) 32.09%, var(--primaryGradientLighter) 163.78%)",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 22px",
      },
    },
  },
  heroCapsule: {
    position: "relative",
  },
  heroCapsuleImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "0 -10px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "0 -5px",
    },
    "& .capsule-img": {
      width: "33.33%",
      "&:first-child": {
        marginTop: 50,
      },
      "&:last-child": {
        marginTop: 100,
      },
      "& img": {
        borderRadius: "500px",
        padding: "0 10px",
        [theme.breakpoints.down("sm")]: {
          padding: "0 5px",
        },
      },
    },
  },
  bannerShape1: {
    position: "absolute",
    right: "28%",
    top: "4%",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
    [theme.breakpoints.down("sm")]: {
      width: 40,
    },
  },
  bannerShape2: {
    position: "absolute",
    left: "26%",
    bottom: "24%",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      width: 40,
    },
    [theme.breakpoints.down("sm")]: {
      width: 30,
    },
  },
  bannerShape3: {
    position: "absolute",
    right: "25%",
    bottom: "8%",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
    [theme.breakpoints.down("sm")]: {
      width: 50,
    },
  },
  halfOrange: {
    position: "absolute",
    bottom: 0,
    left: "45%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("lg")]: {
      width: 120,
    },
    [theme.breakpoints.down("md")]: {
      width: 100,
    },
  },
  bottomShape: {
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 2,
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 40,
      color: "#2D2F39",
      [theme.breakpoints.down("lg")]: {
        fontSize: 32,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 26,
      },
      "& span": {
        color: "var(--primary)",
        fontWeight: 700,
      },
    },
  },
  teamAboutSection: {
    paddingTop: 150,
    [theme.breakpoints.down("lg")]: {
      paddingTop: 120,
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 100,
    },
  },
  teamAboutImage: {
    borderRadius: "0 50% 0 50%",
    border: "15px solid #fef4ee",
  },
  sectionShape: {
    position: "absolute",
    [theme.breakpoints.down("smd")]: {
      display: "none",
    },
  },
  section1Bg1: {
    left: "8%",
    top: "28%",
    width: 60,
  },
  section1Bg2: {
    left: "8%",
    bottom: "5%",
  },
  section1Bg3: {
    right: "20%",
    top: "45%",
  },
  section1Bg4: {
    right: "6%",
    top: "65%",
    width: 100,
  },
  propellingSection: {
    background: "linear-gradient(94.43deg, #FFF8F8 14.47%, #E6F1FA 105%)",
    padding: [[80, 0]],
    borderRadius: 24,
    [theme.breakpoints.down("lg")]: {
      padding: [[60, 0]],
    },
    [theme.breakpoints.down("md")]: {
      padding: [[40, 0]],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[20, 0]],
      borderRadius: 0,
    },
  },
  propellingTitle: {
    "&.MuiTypography-root": {
      paddingLeft: 30,
      backgroundImage: `url(${BgImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: 20,
      backgroundPosition: "0 10px",
      marginBottom: 10,
      fontSize: 32,
      [theme.breakpoints.down("lg")]: {
        fontSize: 28,
        backgroundPosition: "0 8px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        backgroundPosition: "0 6px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        backgroundPosition: "0 3px",
      },
    },
  },
  workplaceListBox: {
    padding: 48,
    borderRadius: 16,
    marginBottom: 30,
    position: "relative",
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      padding: 38,
    },
    [theme.breakpoints.down("lg")]: {
      padding: 28,
    },
    [theme.breakpoints.down("md")]: {
      padding: [[24, 16, 0]],
    },
  },
  workplaceListContent: {
    maxWidth: "60%",
    [theme.breakpoints.down("smd")]: {
      maxWidth: "100%",
    },
    "& h6": {
      fontSize: 28,
      fontWeight: "bold",
      [theme.breakpoints.down("lg")]: {
        fontSize: 26,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
        lineHeight: "normal",
      },
    },
    "& p": {
      fontSize: 24,
      [theme.breakpoints.down("lg")]: {
        fontSize: 22,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
    },
  },
  workplaceListImage: {
    bottom: 0,
    left: "60%",
    position: "absolute",
    [theme.breakpoints.down("smd")]: {
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      left: "40%",
    },
  },
  workplaceListImage1: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  workplaceListImage2: {
    width: "35%",
    left: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      left: "50%",
    },
  },
  workplaceListImage3: {
    width: "40%",
    bottom: "-20%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  workplaceListImage4: {
    width: "66%",
  },
  section3Bg1: {
    top: 0,
    right: "5%",
    width: 60,
  },
  section3Bg2: {
    top: "25%",
    right: "4%",
    width: 40,
  },
  section3Bg3: {
    top: "45%",
    right: "4%",
    width: 100,
  },
  section3Bg4: {
    top: "25%",
    left: "12%",
    width: 130,
  },
  section3Bg5: {
    top: "45%",
    left: "10%",
    width: 60,
  },
  section3Bg6: {
    top: "70%",
    left: "10%",
    width: 50,
  },
  section3Bg7: {
    bottom: 0,
    right: "5%",
    width: 100,
  },
  workFlowSection: {
    display: "inline-block",
    width: "100%",
  },
  workFlow: {
    display: "flex",
    alignItems: "center",
    width: "45%",
    maxWidth: 600,
    [theme.breakpoints.down("xl")]: {
      width: "38%",
      maxWidth: 530,
    },
    [theme.breakpoints.down("lg")]: {
      width: "43%",
    },
    [theme.breakpoints.down("mlg")]: {
      width: "48%",
    },
    [theme.breakpoints.down("slg")]: {
      width: "100%",
      maxWidth: "100%",
      display: "block",
      textAlign: "center",
    },
    "& > svg": {
      marginRight: 30,
      flex: "0 0 140px",
      [theme.breakpoints.down("xl")]: {
        flex: "0 0 100px",
        marginRight: 15,
        height: 100,
      },
      [theme.breakpoints.down("slg")]: {
        marginRight: 0,
      },
    },
    "& h6": {
      fontSize: 24,
      fontWeight: "bold",
      [theme.breakpoints.down("xl")]: {
        fontSize: 20,
      },
    },
    "& p": {
      fontSize: 18,
      display: "-webkit-box",
      WebkitLineClamp: 4,
      WebkitBoxOrient: "vertical",
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.down("xl")]: {
        fontSize: 16,
      },
    },
  },
  "@keyframes lineMove": {
    "0%": {
      strokeDashoffset: 0,
    },
    "100%": {
      strokeDashoffset: -400,
    },
  },
  workFlowBoxContainer: {
    "&.MuiContainer-root": {
      [theme.breakpoints.down("slg")]: {
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  workFlowBox1: {
    paddingBottom: 100,
    marginTop: "5%",
    float: "left",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "5%",
    },
    [theme.breakpoints.down("xlg")]: {
      marginLeft: "3%",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("slg")]: {
      paddingBottom: 0,
      marginTop: 0,
      order: 1,
    },
  },
  workFlowBoxShape1: {
    position: "absolute",
    left: 45,
    top: 150,
    height: 435,
    width: 230,
    [theme.breakpoints.down("xl")]: {
      top: 120,
      left: 45,
      width: 180,
      height: 405,
    },
    [theme.breakpoints.down("lg")]: {
      top: 110,
      left: 45,
      width: 150,
      height: 365,
    },
    [theme.breakpoints.down("slg")]: {
      display: "none",
    },
    "& svg": {
      width: "100%",
      height: "100%",
      "& path": {
        animation: `$lineMove 5s linear infinite`,
      },
    },
  },
  workFlowBox2: {
    paddingTop: 50,
    marginRight: "14%",
    marginTop: "5%",
    position: "relative",
    float: "right",
    [theme.breakpoints.down("xl")]: {
      marginRight: "18%",
    },
    [theme.breakpoints.down("lg")]: {
      marginRight: "12%",
    },
    [theme.breakpoints.down("mlg")]: {
      marginRight: "4%",
    },
    [theme.breakpoints.down("slg")]: {
      marginRight: 0,
      float: "none",
      marginTop: 0,
      paddingTop: 0,
      order: 3,
    },
  },
  workFlowBoxShape2: {
    position: "absolute",
    right: -150,
    top: -80,
    height: 385,
    [theme.breakpoints.down("xl")]: {
      top: -50,
      left: 50,
      height: 345,
    },
    [theme.breakpoints.down("xlg")]: {
      top: -40,
      left: 80,
    },
    [theme.breakpoints.down("lg")]: {
      left: 30,
      top: -10,
      height: 275,
    },
    [theme.breakpoints.down("mlg")]: {
      left: -50,
      top: 0,
      height: 245,
    },
    [theme.breakpoints.down("slg")]: {
      display: "none",
    },
    "& svg": {
      width: "100%",
      height: "100%",
      "& path": {
        animation: `$lineMove 5s linear infinite`,
      },
    },
  },
  workFlowBox3: {
    marginTop: 120,
    float: "right",
    flexDirection: "row-reverse",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      marginRight: "7%",
    },
    [theme.breakpoints.down("xlg")]: {
      marginRight: "3%",
      marginTop: 100,
    },
    [theme.breakpoints.down("lg")]: {
      marginRight: "-2%",
    },
    [theme.breakpoints.down("mlg")]: {
      marginTop: 70,
    },
    [theme.breakpoints.down("slg")]: {
      float: "none",
      marginRight: 0,
      flexDirection: "row",
      marginTop: 0,
      order: 4,
    },
    "& .MuiBox-root": {
      textAlign: "right",
      [theme.breakpoints.down("slg")]: {
        textAlign: "center",
      },
    },
    "& svg": {
      marginRight: 0,
      marginLeft: 30,
      [theme.breakpoints.down("xl")]: {
        marginLeft: 15,
      },
      [theme.breakpoints.down("slg")]: {
        marginLeft: 0,
      },
    },
  },
  workFlowBoxShape4: {
    position: "absolute",
    left: "48%",
    bottom: "100%",
    height: 325,
    width: 120,
    [theme.breakpoints.down("xl")]: {
      left: "39%",
      width: 90,
      bottom: "95%",
      height: 305,
    },
    [theme.breakpoints.down("xlg")]: {
      left: "42%",
      bottom: "100%",
      height: 285,
    },
    [theme.breakpoints.down("lg")]: {
      left: "45%",
      height: 225,
    },
    [theme.breakpoints.down("mlg")]: {
      left: "42%",
      bottom: "100%",
      height: 215,
    },
    [theme.breakpoints.down("slg")]: {
      display: "none",
    },
    "& svg": {
      width: "100%",
      height: "100%",
      "& path": {
        animation: `$lineMove 5s linear infinite`,
      },
    },
  },
  workFlowBox4: {
    marginLeft: "20%",
    marginTop: "4%",
    float: "left",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "24%",
    },
    [theme.breakpoints.down("xlg")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "19%",
      marginTop: 0,
    },
    [theme.breakpoints.down("mlg")]: {
      marginLeft: "22%",
      marginTop: "2%",
    },
    [theme.breakpoints.down("slg")]: {
      marginLeft: 0,
      marginTop: 0,
      order: 2,
    },
  },
  mobileLine: {
    height: 100,
    [theme.breakpoints.up("slg")]: {
      display: "none",
    },
    "& svg": {
      "& path": {
        animation: `$lineMove 5s linear infinite`,
      },
    },
  },
  listContentMainBox: {
    backgroundColor: "var(--white)",
    border: "1px solid #FFFFFF",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    "& .MuiGrid-item": {
      "&:last-child": {
        [theme.breakpoints.down("sm")]: {
          marginTop: 16,
        },
      },
    },
  },
  missionSection: {
    paddingTop: 50,
    "& .desc": {
      maxWidth: 1060,
      margin: "0 auto",
      textAlign: "center",
    },
  },
  description: {
    color: "var(--gray)",
    fontWeight: 700,
    "& .orangeText": {
      color: "var(--primary)",
      fontWeight: 700,
    },
    "& .mahendi": {
      color: "#86650A",
      fontWeight: 700,
    },
  },

  headMain: {
    width: "100%",
    padding: "15px 0",
    borderBottom: `1px solid #CDD0E1`,
    borderRadius: "0 0 5px 5px",
    [theme.breakpoints.down("lg")]: {
      borderRadius: 5,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0",
    },
    "& .MuiTypography-root": {
      fontSize: 24,
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
    },
    "&.tblHead": {
      backgroundColor: "#F9F7FF",
      borderRadius: "5px 5px 0 5px",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 5,
      },
      "& .MuiTypography-root": {
        fontSize: 20,
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
    },
  },
  singleListBox: {
    "&.MuiGrid-item": {
      "&:nth-child(2)": {
        "& $headMain": {
          borderRadius: 5,
          [theme.breakpoints.down("sm")]: {
            borderRadius: "0 0 5px 5px",
          },
          "&.tblHead": {
            borderRadius: "0 0 5px 0",
            [theme.breakpoints.down("sm")]: {
              borderRadius: 5,
            },
          },
        },
        "& $singleList": {
          "&::after": {
            display: "none",
          },
        },
      },
    },
    "& .listBox": {
      backgroundColor: "var(--white)",
      zIndex: 2,
      position: "relative",
      borderRadius: 5,
    },
  },
  singleList: {
    position: "relative",
    "& .MuiList-root": {
      padding: "25px 22px 25px 0",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        padding: "25px 0 25px 0",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 0 0 0",
      },
      [theme.breakpoints.down("xsm")]: {
        maxWidth: "100%",
        width: "100%",
      },
      "& .MuiListItem-root": {
        padding: "0",
        gap: "0 15px",
        alignItems: "start",
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
          marginBottom: 10,
        },
        "&:last-of-type": {
          marginBottom: 0,
        },
        "& .MuiBox-root:has(svg)": {
          minWidth: 20,
          "& svg": {
            verticalAlign: "bottom",
          },
        },
        "& .MuiTypography-root": {
          color: "var(--gray)",
          fontSize: 18,
          lineHeight: "33px",
          [theme.breakpoints.down("sm")]: {
            fontSize: 16,
            lineHeight: "28px",
          },
          "& span": {
            color: "#86650A",
            fontWeight: 700,
          },
        },
      },
    },

    "&.tblSingleList": {
      padding: "40px 16px",
      maxWidth: 422,
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 16px",
      },
      "& .MuiList-root": {
        padding: 0,
        "& .MuiListItem-root": {
          "& .MuiTypography-root": {
            fontSize: 16,
            lineHeight: "25px",
          },
        },
      },
      "&::after": {
        content: "''",
        position: "absolute",
        top: "50%",
        right: -25,
        transform: "translate(50%, -50%)",
        height: "80%",
        width: 1,
        background: `radial-gradient(74.41% 74.41% at 50% 38.61%, #CDD0E1 0%, rgba(205, 208, 225, 0) 100%);`,
        borderRadius: "50%",
        [theme.breakpoints.down("935")]: {
          right: 0,
        },
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
  },
  smText: {
    "&.MuiTypography-root": {
      fontSize: 24,
      lineHeight: "39px",
      color: "#2D2F39",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        lineHeight: "24px",
      },
      [theme.breakpoints.down("xts")]: {
        fontSize: 16,
        lineHeight: "22px",
      },
      "& span": {
        color: "#86650A",
        fontWeight: 700,
      },
    },
  },
  flowerBlue: {
    position: "absolute",
    top: -45,
    right: "5%",
  },
  panShape: {
    position: "absolute",
    left: "6%",
    top: "17%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  halfRoundBlue: {
    position: "absolute",
    right: "6%",
    bottom: "12%",
    [theme.breakpoints.down("xsm")]: {
      display: "none",
    },
  },
  orangeFlower: {
    position: "absolute",
    left: "16%",
    top: "24%",
    [theme.breakpoints.down("sm")]: {
      top: -40,
      left: "6%",
      zIndex: 3,
    },
  },
  rainbowBlue: {
    position: "absolute",
    right: "6%",
    bottom: 0,
    zIndex: 0,
  },
}));
