import { Box, Stack, Switch } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ReactComponent as BGShape } from "../../assets/cookieConsent/Bg shape 15.svg";
import VSNButton from "../../shared/VSNButton";
import VSNTypography from "../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  cookieMainBox: {
    backgroundColor: "var(--white)",
    borderRadius: 20,
    position: "fixed",
    bottom: 20,
    left: 20,
    maxWidth: 508,
    width: "100%",
    padding: 32,
    overflow: "hidden",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.2)",
    zIndex: 999999,
    [theme.breakpoints.down("sm")]: {
      left: 0,
      bottom: 0,
      maxWidth: "100%",
      borderRadius: "10px 10px 0 0 ",
      padding: [[20, 16]],
    },
    "& .MuiTypography-h6": {
      fontSize: 18,
      lineHeight: "23px",
      fontWeight: "bold",
      color: "#0A0A0B",
    },
    "& .MuiTypography-body1": {
      fontSize: 14,
      lineHeight: "18px",
      color: "#3A3A40",
    },
    "& .MuiButtonBase-root": {
      minWidth: "unset",
      "& .content": {
        fontSize: 14,
        fontWeight: 500,
      },
      "&.MuiButton-contained": {
        padding: [[8, 16]],
      },
      "&.MuiButton-text": {
        padding: 0,
        background: "transparent",
        color: "var(--primary)",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
  btnWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& .MuiButtonBase-root": {
        width: "100%",
      },
    },
  },
  lightBtn: {
    "&.MuiButtonBase-root": {
      background: "var(--primaryLighter)",
      color: "var(--primary)",
      "&:hover": {
        background: "var(--primaryLighter)",
        opacity: 0.7,
      },
    },
  },
  darkBtn: {
    "&.MuiButtonBase-root": {
      background: "var(--primary)",
      color: "var(--white)",
      "&:hover": {
        background: "var(--primary)",
        opacity: 0.7,
      },
    },
  },
  bgShape: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  "&.MuiSwitch-root": {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "var(--white)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          background: "var(--Tertiary)",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.3,
          background: "var(--Tertiary)",
        },
      },
      "& + .MuiSwitch-track": {
        background: "#00000040",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: "all 0.4s ease-in-out",
    },
    "& .MuiSwitch-track": {
      borderRadius: 10,
      opacity: 1,
      boxSizing: "border-box",
    },
  },
}));

const CookieConsent = ({ cookieConsent, setCookieConsent }) => {
  const [showInDetail, setShowInDetail] = useState(false);
  const [statusOfCookies, setStatusOfCookies] = useState({});
  const handelClick = (argument) => {
    setCookieConsent({});
    localStorage.setItem("cookieAccept", JSON.stringify({}));
  };
  const acceptAllCookies = () => {
    setCookieConsent({ Analytics: true, Functionality: true, Targeting: true });
    localStorage.setItem(
      "cookieAccept",
      JSON.stringify({ Analytics: true, Functionality: true, Targeting: true })
    );
  };
  const acceptMyChoiceCookies = () => {
    setCookieConsent(statusOfCookies);
    localStorage.setItem("cookieAccept", JSON.stringify(statusOfCookies));
  };
  const cookieConsentAttribute = [
    {
      switchValue: true,
      switchDisabled: true,
      header: "Essential",
      description:
        "These “strictly necessary” cookies are used for activities that are strictly necessary to operate or deliver the service you requested from us and, therefore, do not require you to consent.",
    },
    {
      switchValue: statusOfCookies?.Analytics,
      header: "Analytics",
      description:
        "These cookies track website visits to help us improve performance and content. They gather data on user behavior, such as visit frequency, duration, and the source of traffic. We may also use analytics cookies to test new ads, pages, or features and gauge user responses.",
    },
    {
      switchValue: statusOfCookies?.Targeting,
      header: "Targeting",
      description:
        "Third-party cookies are set by advertising platforms to track ad performance and deliver targeted ads based on your activity on our website. These cookies may use information from your visit to display relevant ads on our site and others.",
    },
    {
      switchValue: statusOfCookies?.Functionality,
      header: "Functionality",
      description:
        "These cookies store information like your username, language, or region to remember your preferences and personalize your experience. They use persistent cookies so your settings are saved for future visits.",
    },
  ];

  const classes = useStyles();
  return (
    <>
      {cookieConsent === null && (
        <Stack className={classes.cookieMainBox}>
          <Box className={classes.bgShape}>
            <BGShape />
          </Box>
          {showInDetail ? (
            <Box
              maxHeight={{ xs: "350px", sm: "100%" }}
              overflow={{ xs: "auto" }}
            >
              <VSNTypography
                variant="body2"
                sx={{
                  fontWeight: 700,
                  lineHeight: "23.44px",
                  fontSize: "18px",
                  marginBottom: "16px",
                  color: "#0A0A0B",
                }}
              >
                Privacy Preference Center
              </VSNTypography>
              {cookieConsentAttribute?.map((item, index) => {
                return (
                  <Stack key={index} direction="row" gap={2} padding={1} pl="0">
                    <AntSwitch
                      checked={item?.switchValue}
                      disabled={item?.switchDisabled}
                      onChange={() =>
                        setStatusOfCookies((prev) => ({
                          ...prev,
                          [item?.header]: !prev?.[item?.header],
                        }))
                      }
                    />
                    <Stack>
                      <Box>
                        <VSNTypography
                          variant="subtitle1"
                          sx={{
                            lineHeight: 1,
                            marginBottom: "8px",
                            fontWeight: 700,
                          }}
                        >
                          {item.header}
                        </VSNTypography>
                      </Box>
                      <VSNTypography
                        variant="body1"
                        sx={{ color: "#898994 !important" }}
                      >
                        {item.description}
                      </VSNTypography>
                    </Stack>
                  </Stack>
                );
              })}
            </Box>
          ) : (
            <Stack spacing={2}>
              <VSNTypography variant="h6">Cookie Preferences</VSNTypography>
              <VSNTypography>
                Vasana uses cookies to enhance your experience on our website.
                These cookies help us understand how you interact with the site,
                improve functionality, personalize content, and work with
                third-party analytics to target ads. By clicking &quot;Accept
                All&quot;, you agree to the use of cookies for these purposes.
              </VSNTypography>
            </Stack>
          )}
          <Stack
            direction={{ sm: "row" }}
            justifyContent="space-between"
            gap={{ xs: 1, sm: "26px" }}
            alignItems="center"
            mt={4}
          >
            {showInDetail ? (
              <VSNButton
                handleClick={() => setShowInDetail(!showInDetail)}
                startIcon={<KeyboardBackspaceIcon />}
                sx={{
                  "& .MuiButton-startIcon": {
                    marginRight: "4px",
                  },
                }}
              >
                Back
              </VSNButton>
            ) : (
              <VSNButton handleClick={() => setShowInDetail(!showInDetail)}>
                Customize Settings
              </VSNButton>
            )}
            <Stack
              direction={{ xts: "row" }}
              alignItems="center"
              gap={1}
              className={classes.btnWrapper}
            >
              <VSNButton
                variant="contained"
                className={classes.lightBtn}
                handleClick={() => handelClick()}
              >
                Essential Cookies Only
              </VSNButton>
              <VSNButton
                variant="contained"
                className={classes.darkBtn}
                handleClick={() =>
                  showInDetail ? acceptMyChoiceCookies() : acceptAllCookies()
                }
              >
                Accept All
              </VSNButton>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default CookieConsent;
