import { Box, Container, LinearProgress, Stack } from "@mui/material";
import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import VSNTypography from "../../shared/VSNTypography";
import VSNButton from "../../shared/VSNButton";
import CustomQuestion from "./CustomQuestion";
import { findPer } from "../../utils/javascript";
import VSNImage from "../../shared/VSNImage";
import gift from "../../assets/assesments/svg/gift.svg";
import small1 from "../../assets/assesments/svg/small-1.svg";
import small3 from "../../assets/assesments/svg/small-3.svg";
import small4 from "../../assets/assesments/svg/small-4.svg";
import small5 from "../../assets/assesments/svg/small-5.svg";

const useStyles = makeStyles((theme) => ({
  linear: {
    textAlign: "center",
    paddingBottom: 20,
    "& .MuiLinearProgress-root": {
      height: 8,
      borderRadius: 16,
      background: "#D9D9D9",
      "& .MuiLinearProgress-bar": {
        background: "#82C799",
      },
    },
  },
  procccessbar: {
    maxWidth: 660,
    margin: [[0, "auto", 70]],
    position: "relative",
    marginTop: 30,
    [theme.breakpoints.down("md")]: {
      margin: [[30, "auto", 62]],
    },
  },
  headers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "& h3": {
      fontSize: 24,
      lineHeight: "34px",
      fontWeight: 600,
      color: "var(--primary)",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "31px",
      },
    },
    "& h5": {
      fontSize: 20,
      lineHeight: "34px",
      fontWeight: 500,
      color: "var(--darkGray)",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "31px",
      },
    },
    "&.resubmissionHeader": {
      padding: "0 5%",
      marginBottom: 85,
    },
  },
  gift: {
    position: "absolute",
    bottom: "-4px",
    zIndex: 5,
    "& img": {
      width: 70,
      [theme.breakpoints.down("sm")]: {
        width: 60,
      },
    },
  },
  small: {
    position: "absolute",
  },
  small1: {
    top: "-122px",
    left: "30%",
    [theme.breakpoints.down("md")]: {
      top: "-100px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 20,
    },
  },
  small3: {
    top: "-58px",
    left: "8%",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      width: 10,
    },
  },
  small4: {
    top: "-70px",
    right: "30%",
    [theme.breakpoints.down("md")]: {
      top: "-55px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 12,
    },
  },
  small5: {
    bottom: "40px",
    right: "-5%",
    [theme.breakpoints.down("md")]: {
      right: "2%",
    },
    [theme.breakpoints.down("sm")]: {
      width: 15,
    },
  },
  boxContainer: {
    maxWidth: 1210,
    width: "100%",
    margin: "0 auto",
    padding: "0 15px",
  },
  btnMain: {
    maxWidth: 1730,
    width: "100%",
    margin: "auto auto 24px",
    padding: "15px",
  },
}));

const NewQuestionSet = ({
  currentQuestion,
  handelNextQuestion,
  currentQuestionIndex,
  onChange,
  allow_resubmission,
  assessmentFormData,
  totalQuestion,
  getTransformValue,
  giftBox,
  progressBar,
  onChangeMultiChoice,
}) => {
  const classes = useStyles();
  return (
    <>
      {allow_resubmission ? (
        <Stack
          width="100%"
          pt={5}
          flex={1}
          sx={{ minHeight: "calc(100svh - 106px)" }}
        >
          <Box className={`${classes.headers} resubmissionHeader`}>
            <VSNTypography variant="h3">{currentQuestion?.title}</VSNTypography>
            <VSNTypography variant="h5">
              {currentQuestion?.description}
            </VSNTypography>
          </Box>
          <Box>
            {currentQuestion?.questions?.map((item, index) => (
              <Fragment key={index}>
                <CustomQuestion
                  question={item}
                  onChange={onChange}
                  value={assessmentFormData?.[item.id]}
                  handelNextQuestion={handelNextQuestion}
                  currentQuestionIndex={currentQuestionIndex}
                  allow_resubmission={allow_resubmission}
                  onChangeMultiChoice={onChangeMultiChoice}
                />
              </Fragment>
            ))}
          </Box>
          {allow_resubmission && (
            <Stack
              className={classes.btnMain}
              direction="row"
              justifyContent="space-between"
            >
              <VSNButton
                handleClick={() => handelNextQuestion(currentQuestionIndex - 1)}
                disabled={currentQuestionIndex === 0}
              >
                Back
              </VSNButton>
              <br />
              <VSNButton
                handleClick={() => handelNextQuestion(currentQuestionIndex + 1)}
                // handleClick={() => handelNextQuestion(8)}
                disabled={
                  totalQuestion === currentQuestionIndex ||
                  !currentQuestion?.questions.every(
                    (item) => assessmentFormData?.[item?.id]
                  )
                }
              >
                Next
              </VSNButton>
            </Stack>
          )}
        </Stack>
      ) : (
        <Box width="100%" pt={{ md: 14, xs: 12 }} pb={5}>
          <Container>
            {!allow_resubmission && (
              <div className={classes.procccessbar}>
                <div
                  style={{
                    marginLeft: "0px",
                    transition: "transform .4s linear",
                    transform: getTransformValue(currentQuestionIndex),
                  }}
                  className={classes.gift}
                  ref={giftBox}
                >
                  <VSNImage src={gift} alt="gift" />
                </div>
                <VSNImage
                  src={small1}
                  alt="small1"
                  className={`${classes.small1} ${classes.small}`}
                />
                <VSNImage
                  src={small3}
                  alt="small3"
                  className={`${classes.small3} ${classes.small}`}
                />
                <VSNImage
                  src={small4}
                  alt="small4"
                  className={`${classes.small4} ${classes.small}`}
                />
                <VSNImage
                  src={small5}
                  alt="small5"
                  className={`${classes.small5} ${classes.small}`}
                />
                <Box className={classes.linear}>
                  <LinearProgress
                    variant="determinate"
                    value={findPer(currentQuestionIndex, totalQuestion - 1)}
                    ref={progressBar}
                  />
                </Box>
              </div>
            )}
            <VSNTypography variant="h3">
              {currentQuestion?.header}
            </VSNTypography>
            <VSNTypography variant="h5">
              {currentQuestion?.description}
            </VSNTypography>
            {currentQuestion?.questions?.map((item, index) => (
              <Fragment key={index}>
                <CustomQuestion
                  question={item}
                  onChange={onChange}
                  value={assessmentFormData?.[item.id]}
                  handelNextQuestion={handelNextQuestion}
                  currentQuestionIndex={currentQuestionIndex}
                  allow_resubmission={allow_resubmission}
                  onChangeMultiChoice={onChangeMultiChoice}
                />
              </Fragment>
            ))}
          </Container>
        </Box>
      )}
    </>
  );
};

export default NewQuestionSet;
