import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import VSNTypography from "../../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  selectMain: {
    gap: 10,
    marginBottom: 10,
    "& .MuiStack-root": {
      height: 67,
      minWidth: 160,
      maxWidth: 160,
      padding: "13px",
      backgroundColor: "var(--white)",
      border: "1px solid #EAEDF0",
      borderRadius: 5,
      color: "var(--gray)",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      textAlign: "center",
      boxShadow: "0px 9px 20px 0px #968FB01A",
      "&:hover": {
        color: "var(--primary)",
        borderColor: "var(--primary)",
      },
      "&.active": {
        borderColor: "var(--primary)",
        color: "var(--primary)",
        boxShadow: "0px 9px 20px 0px #968FB01A",
      },
    },
  },
  information: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 1730,
    width: "100%",
    margin: "0 auto",
    padding: "0 15px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: 15,
    },
    "& h4": {
      fontSize: 22,
      lineHeight: "38.21px",
      fontWeight: 600,
      color: "var(--darkGray)",
      marginBottom: "8px",
      flex: 1,
      [theme.breakpoints.down("lg")]: {
        textAlign: "center",
      },
      [theme.breakpoints.down("md")]: {
        lineHeight: "31px",
        marginBottom: 10,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        padding: [[0]],
        marginBottom: 10,
      },
    },
  },
}));

const SingleLineSelect = ({ question, onChange, value }) => {
  const classes = useStyles();

  return (
    <Box className={classes.information}>
      <VSNTypography variant="h4" component="h4">
        {`${question?.order}. ${question?.phrase}`}
      </VSNTypography>
      <Stack
        direction="row"
        justifyContent="center"
        flexWrap="wrap"
        className={`${classes.selectMain}`}
      >
        {question?.choices?.map((item, key) => (
          <Stack
            justifyContent="center"
            alignItems="center"
            // mb={2}
            key={key}
            className={value?.value?.result === item?.result ? "active" : ""}
            onClick={() => {
              onChange(item, question);
            }}
          >
            {item?.phrase}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default SingleLineSelect;
