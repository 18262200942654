import dayjs from "dayjs";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoader from "react-spinners/PulseLoader";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
} from "@mui/material";
import { Stop, VolumeOff, VolumeUp } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CachedIcon from "@mui/icons-material/Cached";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import VSNButton from "../../shared/VSNButton";
import VSNForm from "../../shared/VSNForm";
import VSNTextField from "../../shared/VSNTextField";
import VSNTypography from "../../shared/VSNTypography";
import { ReactComponent as VasanaLogo } from "../../assets/Teams/svg/vasana-chat-logo.svg";
import { ReactComponent as Ga } from "../../assets/common/svg/ga.svg";
import { ReactComponent as Ma } from "../../assets/common/svg/ma.svg";
import { ReactComponent as Re } from "../../assets/common/svg/re.svg";
import { ReactComponent as Sa } from "../../assets/common/svg/sa.svg";
import { ReactComponent as Send } from "../../assets/common/svg/send.svg";
import humaChatBotContainer from "../../container/humaChatBot.container";
import { HumaDrawerIcon, HumaEditIcon, RightArrow } from "../../shared/icon";
import { showToast } from "../../utils/toastService";
import { HumaStyle } from "./HumaStyle";
import VSNLoader from "../../shared/VSNLoader";

const topSearches = [
  "Top Search 1",
  "Top Search 2",
  "Top Search 3",
  "Top Search 4",
  "Top Search 5",
];
const todaySearches = ["Recent Search 1", "Recent Search 2", "Recent Search 3"];
const yesterdaySearches = ["Recent Search 4"];
const last7DaysSearches = [
  "Recent Search 5",
  "Recent Search 6",
  "Recent Search 7",
  "Recent Search 8",
];
const previous30DaysSearches = [
  "Recent Search 9",
  "Recent Search 10",
  "Recent Search 11",
  "Recent Search 12",
  "Recent Search 13",
];

const combinedSearches = [
  { title: "Top Searches", items: topSearches },
  { title: "Today", items: todaySearches },
  { title: "Yesterday", items: yesterdaySearches },
  { title: "Last 7 Days", items: last7DaysSearches },
  { title: "Previous 30 Days", items: previous30DaysSearches },
];

const suggestion = [
  { name: "Tips to reduce stress", icon: <Sa />, bgColor: "#F2E7F5" },
  {
    name: "Suggest ways to boost team collaboration",
    icon: <Re />,
    bgColor: "#E3F1F9",
  },
  {
    name: "How would different Vasana types respond to conflict?",
    icon: <Ga />,
    bgColor: "#F0F9F2",
  },
  {
    name: "How can I improve my communication with other types?",
    icon: <Ma />,
    bgColor: "#FFF7EC",
  },
];

let utterance = null;

const App = () => {
  const classes = HumaStyle();
  const theme = useTheme();

  const [openMenu, setOpenMenu] = useState(false);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const messagesEndRef = useRef(null);

  const handlePlayPause = (text, index) => {
    if (playingIndex === index && isPaused) {
      speechSynthesis.resume();
      setIsPaused(false);
    } else if (playingIndex === index && !isPaused) {
      speechSynthesis.pause();
      setIsPaused(true);
    } else {
      speechSynthesis.cancel();
      utterance = new SpeechSynthesisUtterance(text);
      utterance.onend = () => {
        setPlayingIndex(null);
        setIsPaused(false);
      };
      speechSynthesis.speak(utterance);
      setPlayingIndex(index);
      setIsPaused(false);
    }
  };

  const handleStop = () => {
    speechSynthesis.cancel();
    setPlayingIndex(null);
    setIsPaused(false);
  };

  const handleCopyMessage = (message) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        showToast("Message copied to clipboard!", "success");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const {
    message,
    messages,
    typing,
    onMessageChange,
    onSendMessage,
    inputRef,
    // open,
    // toggleChatBox,
    anchorEl: open,
    position,
    ref,
    handleDragStart,
    handleClick,
    regenerateResponse,
    socket,
  } = humaChatBotContainer();

  useEffect(() => {
    if (messagesEndRef?.current) {
      messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Box className={classes.mainWrapper}>
      {/* Sidebar */}
      <Stack
        item
        className={`${classes.chatSidear} ${!openMenu ? "hide" : ""}`}
      >
        {/* Menu and Edit icons */}
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <span
            className={classes.iconWrapper}
            style={{
              background: "#555FDD0D",
            }}
            onClick={() => setOpenMenu((prev) => !prev)}
            aria-hidden
          >
            <HumaDrawerIcon
              sx={{ color: "var(--Tertiary)" }}
              style={{ cursor: "pointer" }}
            />
          </span>
          <span
            className={classes.iconWrapper}
            style={{ background: "#FCE8E1" }}
            aria-hidden
          >
            <HumaEditIcon
              sx={{ color: "var(--Tertiary)" }}
              style={{ cursor: "pointer" }}
            />
          </span>
        </Box> */}

        <Box>
          {combinedSearches.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <VSNTypography className={classes.sliderHeading} gutterBottom>
                {section.title}
              </VSNTypography>
              <List className={classes.sliderSearch}>
                {section.items.map((search, index) => (
                  <ListItem button key={index}>
                    {/* <ListItemText primary={search} /> */}
                    {search}
                  </ListItem>
                ))}
              </List>
            </div>
          ))}
        </Box>
      </Stack>
      {/* Main Content */}
      {/* {!openMenu && (
        <Box
          gap={1.5}
          sx={{
            position: "fixed",
            top: 64,
            left: 0,
            zIndex: 9,
            transition: "all 0.4s ease-in-out",
            backgroundColor: {
              xs: "var(--white)",
              slg: "transparent",
            },
            width: { xs: "100%", slg: "auto" },
          }}
        >
          <Box
            className={classes.iconWrapper}
            sx={{
              background: "#555FDD0D",
              margin: "16px",
            }}
            onClick={() => setOpenMenu((prev) => !prev)}
            aria-hidden
          >
            <HumaDrawerIcon
              sx={{ color: "var(--Tertiary)" }}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <span
            className={classes.iconWrapper}
            style={{ background: "#FCE8E1" }}
            aria-hidden
          >
            <HumaEditIcon
              sx={{ color: "var(--Tertiary)" }}
              style={{ cursor: "pointer" }}
            />
          </span>
        </Box>
      )} */}
      <Stack
        item
        className={`${classes.chatContent} ${!openMenu ? "hide" : ""}`}
      >
        {(messages?.data?.length < 2 || !messages?.data) && (
          <Stack
            className={classes.scrollebleContent}
            height="100%"
            alignItems="center"
            pb={6}
          >
            {socket?.connected ? (
              <>
                <Box textAlign="center">
                  <VSNTypography
                    fontSize={32}
                    fontWeight={700}
                    variant="h4"
                    // gutterBottom
                  >
                    Ask Huma
                  </VSNTypography>
                  <VSNTypography
                    fontSize={16}
                    fontWeight={500}
                    variant="subtitle1"
                  >
                    Your Digital Coach
                  </VSNTypography>
                </Box>
                <Stack
                  className={`${classes.teamViewCard} `}
                  sx={{
                    margin: messages?.data?.length > 1 ? "0 auto" : "24px 0",
                  }}
                >
                  <VSNForm handleSubmit={onSendMessage}>
                    <Stack className={classes.chatInputMain}>
                      <VSNTextField
                        variant="outlined"
                        onChange={onMessageChange}
                        value={message}
                        disabled={typing}
                        placeholder="Ask Huma"
                        className={classes.chatInput}
                        inputRef={inputRef}
                        InputProps={{
                          startAdornment: (
                            <VasanaLogo className={classes.searchIcon} />
                          ),
                          endAdornment: (
                            <VSNButton
                              type="submit"
                              startIcon={<ArrowForwardIcon />}
                              variant="text"
                              className={classes.sendBtn}
                              // handleClick={onSendMessage}
                              disabled={typing}
                            />
                          ),
                        }}
                      />
                    </Stack>
                  </VSNForm>
                </Stack>
                {/* Suggestions */}
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  {suggestion?.map((suggestion, index) => (
                    <Grid item key={index}>
                      <Paper
                        onClick={() => onSendMessage(null, suggestion?.name)}
                        elevation={3}
                        className={classes.paperStyle}
                        sx={{ background: `${suggestion?.bgColor}` }}
                      >
                        {suggestion?.icon}
                        <VSNTypography>{suggestion?.name}</VSNTypography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <VSNLoader />
            )}
          </Stack>
        )}
        {messages?.data?.length > 1 && (
          <Stack className={classes.chatBodyMain}>
            <Stack className={classes.chatBody}>
              <InfiniteScroll
                dataLength={messages?.data?.length || 0}
                // next={onNext}
                // hasMore={
                //   messages?.page?.current_page < messages?.page?.pageLimit
                // }
                // height={height}
                inverse
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  paddingRight: "6px",
                }}
              >
                <div ref={messagesEndRef} />
                {typing ? (
                  <>
                    <Stack
                      className={classes.receivMessage}
                      direction="row"
                      justifyContent="start"
                      mb={1}
                    >
                      <Box
                        className={`${classes.messageImg} ${classes.chatLogoMain}`}
                      >
                        <VasanaLogo />
                      </Box>
                      <PulseLoader
                        size={7}
                        color="var(--primary)"
                        speedMultiplier={0.8}
                        cssOverride={{
                          dispaly: "flex",
                          alignItems: "center",
                        }}
                      />
                    </Stack>
                  </>
                ) : null}
                {messages?.data?.map((data, chatIndex) => {
                  const utcSeconds = Number(
                    data?.ai ? data?.ai?.time : data?.human?.time
                  );

                  const time = new Date(0);
                  time.setUTCSeconds(utcSeconds);

                  const isAiMessage = data?.ai;
                  const textToSpeak = isAiMessage
                    ? isAiMessage?.phrase
                    : data?.human?.phrase;
                  return (
                    <Fragment key={chatIndex}>
                      <Stack
                        className={`${classes.message} ${
                          isAiMessage
                            ? classes.receivMessage
                            : classes.sendMessage
                        }`}
                        direction="row"
                        justifyContent={isAiMessage ? "start" : "end"}
                      >
                        {isAiMessage && (
                          <Box
                            className={`${classes.messageImg} ${classes.chatLogoMain}`}
                          >
                            <VasanaLogo />
                          </Box>
                        )}
                        <Stack ml={data?.human ? "auto" : "unset"}>
                          <VSNTypography
                            variant="body1"
                            className={classes.chatMessage}
                          >
                            {data?.ai ? data?.ai?.phrase : data?.human?.phrase}
                          </VSNTypography>
                          <Stack
                            direction="row"
                            mt="6px"
                            alignItems="center"
                            gap={1}
                          >
                            {/* <VSNTypography
                              variant="body2"
                              className={`${classes.messageTime} ${
                                data?.human ? classes.senderTime : ""
                              }`}
                            >
                              {dayjs(time).format("LT")}
                            </VSNTypography> */}

                            {data?.ai && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                className={classes.iconMainWrapper}
                                gap={0.5}
                              >
                                <IconButton
                                  onClick={() =>
                                    handlePlayPause(textToSpeak, chatIndex)
                                  }
                                >
                                  {playingIndex === chatIndex && !isPaused ? (
                                    <VolumeOff style={{ fontSize: "14px" }} />
                                  ) : (
                                    <VolumeUp style={{ fontSize: "14px" }} />
                                  )}
                                </IconButton>
                                <IconButton
                                  onClick={handleStop}
                                  disabled={playingIndex === null}
                                >
                                  <Stop style={{ fontSize: "14px" }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleCopyMessage(textToSpeak)}
                                >
                                  <ContentCopyIcon
                                    style={{ fontSize: "14px" }}
                                  />
                                </IconButton>

                                {/* <IconButton>
                                  <ThumbUpOffAltIcon
                                    style={{ fontSize: "14px" }}
                                  />
                                </IconButton> */}

                                {/* <IconButton>
                                  <ThumbDownOffAltIcon
                                    style={{ fontSize: "14px" }}
                                  />
                                </IconButton> */}
                                <IconButton
                                  onClick={() => regenerateResponse(data)}
                                >
                                  <CachedIcon style={{ fontSize: "14px" }} />
                                </IconButton>
                              </Stack>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Fragment>
                  );
                })}
              </InfiniteScroll>
            </Stack>
          </Stack>
        )}

        {/* Input Field */}

        {messages?.data?.length > 1 && (
          <Stack
            className={`${classes.teamViewCard} `}
            sx={{
              margin: messages?.data?.length > 1 ? "0 auto" : "auto auto 0",
            }}
          >
            <VSNForm handleSubmit={onSendMessage}>
              <Stack className={classes.chatInputMain}>
                <VSNTextField
                  variant="outlined"
                  onChange={onMessageChange}
                  value={message}
                  disabled={typing}
                  placeholder="Ask Huma"
                  className={classes.chatInput}
                  inputRef={inputRef}
                  InputProps={{
                    startAdornment: (
                      <VasanaLogo className={classes.searchIcon} />
                    ),
                    endAdornment: (
                      <VSNButton
                        type="submit"
                        startIcon={<ArrowForwardIcon />}
                        variant="text"
                        className={classes.sendBtn}
                        // handleClick={onSendMessage}
                        disabled={typing}
                      />
                    ),
                  }}
                />
              </Stack>
            </VSNForm>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default App;
