import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import React from "react";
import VSNButton from "../../shared/VSNButton";
import VSNTypography from "../../shared/VSNTypography";
import { themeHeading } from "../../description/theme.description";
import VSNCheckbox from "../../shared/VSNCheckbox";
import VSNImage from "../../shared/VSNImage";
import { ReactComponent as CheckboxIcon } from "../../assets/common/svg/checkboxIcon.svg";
import { ReactComponent as CheckedIcon } from "../../assets/common/svg/checked.svg";

const useStyles = makeStyles((theme) => ({
  headTitle: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      fontSize: 34,
      lineHeight: "44px",
      color: "#2D2F39",
      marginBottom: 40,
      whiteSpace: "nowrap",
    },
  },
  themeBoxWrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  themeImage: {
    borderRadius: 8,
    width: "100%",
    objectFit: "cover",
  },
  themeInnerWrap: {
    "& .MuiButtonBase-root": {
      justifyContent: "flex-end",
    },
    "& p.MuiTypography-root": {
      fontWeight: 700,
      lineHeight: "21px",
      color: "var(--white)",
      margin: [[19, 8]],
    },
  },
  themeCheckbox: {
    "&.MuiButtonBase-root": {
      position: "absolute",
      right: 0,
      top: 0,
    },
  },
  themeBoxContainer: {
    maxHeight: 620,
    overflowY: "auto",
    paddingRight: 40,
    marginRight: "-60px",
  },
  skipText: {
    "&.MuiTypography-root": {
      margin: [[20, 0]],
      color: "var(--primary)",
      fontSize: "18px",
      fontWeight: 700,
      cursor: "pointer",
    },
  },
}));

const ThemeContainer = ({ themeDetail, onGetStarted, handleCheck }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      <VSNTypography variant="h4" className={classes.headTitle}>
        {themeHeading}
      </VSNTypography>
      <Box className={classes.themeBoxContainer}>
        <Grid container spacing={3}>
          {themeDetail?.map((obj, i) => (
            <Grid item lg={4} key={i}>
              <Box className={classes.themeBoxWrapper}>
                <VSNImage
                  src={obj.imgSrc}
                  alt={obj.text}
                  className={classes.themeImage}
                />
                <VSNCheckbox
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckedIcon />}
                  className={classes.themeCheckbox}
                  onChange={handleCheck}
                />
                <Box
                  className={classes.themeInnerWrap}
                  position="absolute"
                  bottom="0"
                  left="0"
                  right="0"
                >
                  <VSNTypography>{obj.text}</VSNTypography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={9} display="flex" alignAlign="center">
        <VSNButton handleClick={onGetStarted}>Get Started</VSNButton>
        <VSNTypography
          onClick={() => navigate("/")}
          className={classes.skipText}
          color="red"
        >
          Skip for now
        </VSNTypography>
      </Box>
    </>
  );
};

export default ThemeContainer;
