import { AvatarGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  avtarGroup: {
    "&.MuiAvatarGroup-root": {
      "&.MuiAvatarGroup-root": {
        display: "inline-flex",
        cursor: "pointer",
        "& .MuiAvatar-root": {
          borderColor: "var(--white)",
          boxShadow: "0 0 0 2px #EAEDF0",
          boxSizing: "border-box",
          fontSize: 16,
          color: "var(--primary)",
          backgroundColor: "var(--white)",
          height: 36,
          width: 36,
          marginLeft: 0,
          [theme.breakpoints.down("sm")]: {
            height: 30,
            width: 30,
            fontSize: 12,
          },
          [theme.breakpoints.down("359")]: {
            height: 24,
            width: 24,
          },
          "& > .MuiAvatar-circular": {
            height: 40,
            width: 40,
          },
        },
      },
    },
  },
}));

const VSNMembersAvatarGroup = ({ max = 8, className, ...rest }) => {
  const classes = useStyles();

  return (
    <AvatarGroup
      className={cn(classes.avtarGroup, className)}
      max={max}
      {...rest}
    />
  );
};

export default VSNMembersAvatarGroup;
